import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ReactFileReader from 'react-file-reader';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import {SearchBtn,ClearSearchBtn} from '../buttons';

import {api_path,entLittleRoundButtonStyleDisabled,cellStyle,qResultbodycellStyle,qResultheadcellStyle,UGCblue,tablebodyStyle,tableheadrowStyle,tableheadcellStyle,entInfoFontSize,entLittleRoundButtonStyle} from '../../../environment';


const DistributeHeadRows = ["受測者姓名","受測者email","測評名稱","施測情境","單位","職稱","施測時限","受測截止日","編輯","帳號","密碼","受測階段"];
const allButtonStyle={fontFamily:'微軟正黑體',margin:'5px',color:UGCblue,fontSize:'12px',textDecoration:'underline'};
const allButtonDisabledStyle={fontFamily:'微軟正黑體',margin:'5px',color:'rgba(255, 0, 0, 0.3)',fontSize:'12px',textDecoration:'underline'};
const btnStyle = condition => condition?entLittleRoundButtonStyleDisabled:entLittleRoundButtonStyle;
class DeleteModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						msg: "",
						submit: false,
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
						msg: "",
						submit: false,
					});
	}

	fetchDelete = () => {
		let send_body = JSON.stringify({ func:'del_q_assign',
										login_uid: this.props.uid,
										qa_list: this.props.checkedValues,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({msg: response.msg, submit: true});
				this.props.fetchQAssign();
				this.props.fetchTotalAmount();
            }
        })
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button disabled={this.props.auth} style={btnStyle(this.props.auth)} onClick={this.toggle}>受測者資料刪除</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<Typography style={{fontFamily:'微軟正黑體'}}>
					刪除
				</Typography>
				</DialogTitle>
				<DialogContent>
				{this.state.submit?
            	<DialogContentText id="alert-dialog-description">
				<Typography style={{fontFamily:'微軟正黑體'}}>
                {this.state.msg}
				</Typography>
                </DialogContentText>
				:
				<DialogContentText id="alert-dialog-description">
				<Typography style={{fontFamily:'微軟正黑體'}}>
				確認刪除？
				</Typography>
				</DialogContentText>
				}
				</DialogContent>
				{this.state.submit?
            	<DialogActions>
                <Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
                關閉
                </Button>
                </DialogActions>
				:
				<DialogActions>
					<Button size="large" onClick={this.fetchDelete} color="primary" style={{fontFamily:'微軟正黑體'}}>確認</Button>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>取消</Button>
				</DialogActions>
				}
			</Dialog>
		</div>
		);
	}
}

class AssignAddModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						name: "",
						emp_no: "",
						email: "",
						tp_seq: "",
						dept_seq: "",
						title_seq: "",
						q_time_limit: "",
						q_date_limit: "",
						fail_field: "",
						fail_msg: "",
						corpGroup:{department:[],},
						titles: [],
						tpList: [],
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
						name: "",
						emp_no: "",
						email: "",
						dept_seq: "",
						title_seq: "",
						fail_field: "",
						fail_msg: "",
						corpGroup:{department:[],},
						titles: [],
					});
		if(this.state.modal){
			this.props.fetchTotalAmount();
		}
		else{
			this.fetchGroup();
			this.fetchTpList();
		}
	}

	fetchTpList = () => {
		let send_body = JSON.stringify({ func:'get_template_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({tpList:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}

    fetchGroup = () => {
        let send_body = JSON.stringify({ func:'get_group_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corpGroup:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}

    fetchTitle = dept_seq => {
        let send_body = JSON.stringify({ func:'get_title_info',
                                        login_uid: this.props.uid,
                                        dept_seq: dept_seq,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({titles:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchQAssignIns = () => {
		let send_body = JSON.stringify({ func: "ins_q_assign",
										login_uid: this.props.uid,
										name: this.state.name,
										is_stu: this.state.is_stu,
										emp_no: this.state.emp_no,
										email: this.state.email,
										qi_seq: this.state.qi_seq,
										tp_seq: this.state.tp_seq,
										dept_seq: this.state.dept_seq,
										title_seq: this.state.title_seq,
										q_time_limit: this.state.q_time_limit,
										q_date_limit: this.state.q_date_limit,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchQAssign();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button onClick={this.toggle} disabled={this.props.auth} style={btnStyle(this.props.auth)}>
				新增受測者
			</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography style={{fontFamily:'微軟正黑體'}}>
					新增受測者
					</Typography>
				</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="tp_seq"
							label="施測情境"
							value={this.state.tp_seq}
							onChange={e => {
								this.setState({tp_seq : e.target.value});
								if(e.target.value!==""){
									this.setState({ q_time_limit: this.state.tpList.find(tp=>tp.tp_seq===e.target.value).q_time_limit,
													q_date_limit: this.state.tpList.find(tp=>tp.tp_seq===e.target.value).q_date_limit,
												});
								}
								else{ this.setState({ q_time_limit: "",q_date_limit: "",}); }
							}}
							margin="normal"
							select
							SelectProps={{native: true}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							error = {this.state.fail_field === "tp_seq" ? true:null}
							required
							fullWidth
						>
							<option value=""></option>
							{this.state.tpList.map(tp=><option value={tp.tp_seq}>{tp.tp_name}</option>)}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="name"
							label="受測者姓名"
							value={this.state.name}
                            onChange={e => this.setState(({name : e.target.value}))}
							margin="normal"
							required
							fullWidth
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="員編"
							value={this.state.emp_no}
                            onChange={e => this.setState(({emp_no : e.target.value}))}
                            margin="normal"
						/>
					</Grid> */}
                    <Grid item xs={12} sm={6}>
						<TextField
							id="email"
							label="受測者email"
							fullWidth
							multiline
							value={this.state.email}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            onChange={e => this.setState(({email : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="q_time_limit"
							label="施測時限(分鐘)"
							value={this.state.q_time_limit}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            onChange={e => this.setState(({q_time_limit : e.target.value}))}
                            margin="normal"
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="q_date_limit"
							label="受測截止日"
							value={this.state.q_date_limit}
                            onChange={e => this.setState(({q_date_limit : e.target.value}))}
							margin="normal"
							type="date"
							fullWidth
							InputLabelProps={{shrink: true, style: {fontFamily:'微軟正黑體'}}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
                            id="dept_seq"
                            select
                            label="單位"
                            SelectProps={{native: true}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            margin="normal"
                            fullWidth
							value={this.state.dept_seq}
                            onChange={e=>{this.setState({dept_seq:e.target.value});
										if(e.target.value!==""){this.fetchTitle(e.target.value);}}}
                            error = {this.state.fail_field === "dept_seq" ? true:null}
                        >
							<option value=""></option>
                        	{this.state.corpGroup["department"].map(dept=><option value={dept.seq}>{dept.name}</option>)}
                		</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
                            id="title_seq"
                            select
                            label="職稱"
                            SelectProps={{native: true}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            margin="normal"
                            fullWidth
							value={this.state.title_seq}
                            onChange={e => this.setState({title_seq : e.target.value})}
                            error = {this.state.fail_field === "title" ? true:null}
                        >
							<option></option>
							{this.state.titles.map(title => <option value={title.seq}>{title.name}</option>)}
                        </TextField>
					</Grid>
					
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={this.fetchQAssignIns} color="primary" style={{fontFamily:'微軟正黑體'}}>新增</Button>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>取消</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class AssignEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						name: "",
						emp_no: "",
						email: "",
						tp_seq: "",
						dept_seq: "",
						title_seq: "",
						q_time_limit: "",
						q_date_limit: "",
						fail_field: "",
						fail_msg: "",
						corpGroup:{department:[],},
						titles: [],
						tpList: [],
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
						name: this.props.qa.name,
						emp_no: this.props.qa.emp_no,
						email: this.props.qa.email,
						tp_seq: this.props.qa.tp_seq,
						dept_seq: this.props.qa.dept_seq,
						title_seq: this.props.qa.title_seq,
						q_time_limit: this.props.qa.q_time_limit,
						q_date_limit: this.props.qa.q_date_limit,
						fail_field: "",
						fail_msg: "",
						corpGroup:{department:[],},
						titles: [],
						tpList: [],
					});
		this.fetchGroup();
		this.fetchTpList();
		this.fetchTitle(this.props.qa.title_seq);
	}

	fetchTpList = () => {
		let send_body = JSON.stringify({ func:'get_template_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({tpList:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}

    fetchGroup = () => {
        let send_body = JSON.stringify({ func:'get_group_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corpGroup:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}

    fetchTitle = dept_seq => {
        let send_body = JSON.stringify({ func:'get_title_info',
                                        login_uid: this.props.uid,
                                        dept_seq: dept_seq,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({titles:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchQAssignMod = () => {
		let send_body = JSON.stringify({ func: "mod_q_assign",
										login_uid: this.props.uid,
										qa_seq: this.props.qa.qa_seq,
										name: this.state.name,
										is_stu: this.state.is_stu,
										emp_no: this.state.emp_no,
										email: this.state.email,
										qi_seq: this.state.qi_seq,
										tp_seq: this.state.tp_seq,
										dept_seq: this.state.dept_seq,
										title_seq: this.state.title_seq,
										q_time_limit: this.state.q_time_limit,
										q_date_limit: this.state.q_date_limit,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchQAssign();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button disabled={this.props.auth} style={btnStyle(this.props.auth)} onClick={this.toggle}>編輯</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<Typography style={{fontFamily:'微軟正黑體'}}>
					編輯受測者
				</Typography>
				</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="tp_seq"
							label="施測情境"
							value={this.state.tp_seq}
							onChange={e => {
								this.setState({tp_seq : e.target.value});
								if(e.target.value!==""){
									this.setState({ q_time_limit: this.state.tpList.find(tp=>tp.tp_seq===e.target.value).q_time_limit,
													q_date_limit: this.state.tpList.find(tp=>tp.tp_seq===e.target.value).q_date_limit,
												});
								}
								else{
									this.setState({ q_time_limit: "",
													q_date_limit: "",
												});
								}
							}}
							margin="normal"
							select
							SelectProps={{native: true}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							error = {this.state.fail_field === "tp_seq"}
							required
							fullWidth
						>
							<option value=""></option>
							{this.state.tpList.map(tp=><option value={tp.tp_seq}>{tp.tp_name}</option>)}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="name"
							label="受測者姓名"
							value={this.state.name}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            onChange={e => this.setState(({name : e.target.value}))}
							margin="normal"
							required
							fullWidth
						/>
					</Grid>
					{/* <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="員編"
							value={this.state.emp_no}
                            onChange={e => this.setState(({emp_no : e.target.value}))}
                            margin="normal"
						/>
					</Grid> */}
                    <Grid item xs={12} sm={6}>
						<TextField
							id="email"
							label="受測者email"
							fullWidth
							multiline
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							value={this.state.email}
                            onChange={e => this.setState(({email : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="q_time_limit"
							label="施測時限(分鐘)"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							value={this.state.q_time_limit}
                            onChange={e => this.setState(({q_time_limit : e.target.value}))}
                            margin="normal"
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="q_date_limit"
							label="受測截止日"
							value={this.state.q_date_limit}
                            onChange={e => this.setState(({q_date_limit : e.target.value}))}
							margin="normal"
							type="date"
							fullWidth
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink: true,style: {fontFamily:'微軟正黑體'}}}
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
                            id="dept_seq"
                            select
                            label="單位"
                            SelectProps={{native: true}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            margin="normal"
                            fullWidth
							value={this.state.dept_seq}
                            onChange={e=>{this.setState({dept_seq:e.target.value});
										if(e.target.value!==""){this.fetchTitle(e.target.value);}}}
                            error = {this.state.fail_field === "dept_seq" ? true:null}
                        >
							<option value=""></option>
                        	{this.state.corpGroup["department"].map(dept=><option value={dept.seq}>{dept.name}</option>)}
                		</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
                            id="title_seq"
                            select
                            label="職稱"
                            SelectProps={{native: true}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            margin="normal"
                            fullWidth
							value={this.state.title_seq}
                            onChange={e => this.setState({title_seq : e.target.value})}
                            error = {this.state.fail_field === "title" ? true:null}
                        >
							<option></option>
							{this.state.titles.map(title => <option value={title.seq}>{title.name}</option>)}
                        </TextField>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={this.fetchQAssignMod} color="primary" style={{fontFamily:'微軟正黑體'}}>確認</Button>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>取消</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class DisAll extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						msg: "",
						state: "1",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal){this.setState({modal:!this.state.modal,state:"2"})}
		if(this.state.modal&&this.state.state==="1"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "2",
						});
		}
		else if(this.state.modal&&this.state.state==="2"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}
		else if(this.state.modal&&this.state.state==="3"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}

	}

	fetchSendAll = () => {
		let send_body = JSON.stringify({ func: 'proc_all',
										login_uid: this.props.uid,
										type: 'send',
									});
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({msg: response.msg, state: "3"});
				this.props.fetchQAssign();
				this.props.fetchTotalAmount();
            }
        })
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button 
				disabled={this.props.auth}
				// style={btnStyle(this.props.auth)}
				style={this.props.auth?allButtonDisabledStyle:allButtonStyle} 
				onClick={()=>{ 	this.toggle();
								this.fetchSendAll();
								this.setState();
				}}
			>立即分發所有未發送帳密</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<Typography style={{fontFamily:'微軟正黑體'}}>
				立即分發所有未發送帳密
				</Typography>
				</DialogTitle>
				<DialogContent>
				{this.state.state==="2"?
            	<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				:
				this.state.state==="3"?
				<DialogContentText id="alert-dialog-description">
				<Typography style={{fontFamily:'微軟正黑體'}}>
				{this.state.msg}
                </Typography>
				</DialogContentText>
				:
				null
				}
				</DialogContent>
				{this.state.state==="3"?
            	<DialogActions>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
					關閉
					</Button>
                </DialogActions>
				:
				this.state.state==="2"?
				<DialogActions>
				</DialogActions>
				:
				null
				}
			</Dialog>
		</div>
		);
	}
}

class DelAll extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						msg: "",
						state: "1",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal){this.setState({modal:!this.state.modal,state:"2"})}
		if(this.state.modal&&this.state.state==="1"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "2",
						});
		}
		else if(this.state.modal&&this.state.state==="2"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}
		else if(this.state.modal&&this.state.state==="3"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}

	}

	fetchSendAll = () => {
		let send_body = JSON.stringify({ func: 'proc_all',
										login_uid: this.props.uid,
										type: 'del',
									});
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({msg: response.msg, state: "3"});
				this.props.fetchQAssign();
				this.props.fetchTotalAmount();
            }
        })
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button 
				disabled={this.props.auth}
				// style={btnStyle(this.props.auth)}
				style={this.props.auth?allButtonDisabledStyle:allButtonStyle} 
				onClick={()=>{ 	this.toggle();
								this.fetchSendAll();
								this.setState();
				}}
			>立即刪除所有過期/超時測驗</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<Typography style={{fontFamily:'微軟正黑體'}}>
				立即刪除所有過期/超時測驗
				</Typography>
				</DialogTitle>
				<DialogContent>
				{this.state.state==="2"?
            	<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				:
				this.state.state==="3"?
				<DialogContentText id="alert-dialog-description">
				<Typography style={{fontFamily:'微軟正黑體'}}>
				{this.state.msg}
                </Typography>
				</DialogContentText>
				:
				null
				}
				</DialogContent>
				{this.state.state==="3"?
            	<DialogActions>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
					關閉
					</Button>
                </DialogActions>
				:
				this.state.state==="2"?
				<DialogActions>
				</DialogActions>
				:
				null
				}
			</Dialog>
		</div>
		);
	}
}

class PrintAll extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						msg: "",
						state: "1",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal){this.setState({modal:!this.state.modal,state:"2"})}
		if(this.state.modal&&this.state.state==="1"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "2",
						});
		}
		else if(this.state.modal&&this.state.state==="2"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}
		else if(this.state.modal&&this.state.state==="3"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}

	}

	fetchSendAll = () => {
		let send_body = JSON.stringify({ func: 'proc_all',
										login_uid: this.props.uid,
										type: 'prn',
									});
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
					if(response.pdf||response.url){
						var link = document.createElement("a");
						// link.href = 'data:application/pdf;base64,' + encodeURI(response.pdf);
						link.href = response.url;
						link.target="_blank" // 另開分頁
						link.download = response.pdf_fname; // 檔名
						link.click();
					}
				this.setState({msg: response.msg, state: "3",modal:false});
				this.props.fetchQAssign();
				this.props.fetchTotalAmount();
            }
        })
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button 
				disabled={this.props.auth}
				// style={btnStyle(this.props.auth)}
				style={this.props.auth?allButtonDisabledStyle:allButtonStyle} 
				onClick={()=>{ 	
								this.toggle();
								this.fetchSendAll();
				}}
			>立即列印所有未發送帳密</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<Typography style={{fontFamily:'微軟正黑體'}}>
				立即列印所有未發送帳密
				</Typography>
				</DialogTitle>
				<DialogContent>
				{this.state.state==="2"?
            	<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				:
				this.state.state==="3"?
				<DialogContentText id="alert-dialog-description">
				<Typography style={{fontFamily:'微軟正黑體'}}>
				{this.state.msg}
                </Typography>
				</DialogContentText>
				:
				null
				}
				</DialogContent>
				{this.state.state==="3"?
            	<DialogActions>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
					關閉
					</Button>
                </DialogActions>
				:
				this.state.state==="2"?
				<DialogActions>
				</DialogActions>
				:
				null
				}
			</Dialog>
		</div>
		);
	}
}

class Print extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						msg: "",
						state: "1",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal){this.setState({modal:!this.state.modal,state:"2"})}
		if(this.state.modal&&this.state.state==="1"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "2",
						});
		}
		else if(this.state.modal&&this.state.state==="2"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}
		else if(this.state.modal&&this.state.state==="3"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}

	}

	fetchPrintUidPasswd= () => {
        let send_body = JSON.stringify({ func: 'print_pwd_list',
										login_uid: this.props.uid,
										qa_list: this.props.checkedValues,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.pdf||response.url){
					var link = document.createElement("a");
					// link.href = 'data:application/pdf;base64,' + encodeURI(response.pdf);
					link.href = response.url;
					link.target="_blank" // 另開分頁
					link.download = response.pdf_fname; // 檔名
					link.click();
				}
			}
			this.setState({msg: response.msg, state: "3",modal:false});
				this.props.fetchQAssign();
				this.props.fetchTotalAmount();
		})
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button 
				disabled={this.props.disabled}
				style={this.props.style} 
				onClick={()=>{ 	
								this.toggle();
								this.fetchPrintUidPasswd();
				}}
			>帳密列印</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<Typography style={{fontFamily:'微軟正黑體'}}>
				帳密列印
				</Typography>
				</DialogTitle>
				<DialogContent>
				{this.state.state==="2"?
            	<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				:
				null
				}
				</DialogContent>
				{this.state.state==="3"?
            	<DialogActions>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
					關閉
					</Button>
                </DialogActions>
				:
				this.state.state==="2"?
				<DialogActions>
				</DialogActions>
				:
				null
				}
			</Dialog>
		</div>
		);
	}
}

class ImportModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false };
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({modal:!this.state.modal,msg:''});
	}
	handleFiles = file => {
		this.toggle();
		let send_body = JSON.stringify({ func: 'upload_q_assign',
										login_uid: this.props.uid,
                                        file: file.base64,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
            })
        .then(res => res.json())
        .then(response => {
			if(response.status==="SUCC"){
				this.setState({msg:response.msg});
				this.props.fetchQAssign();
			}
			if(response.status==="FAIL"){
				this.setState({msg:response.msg});
				this.props.fetchQAssign();
			}
		})
        .catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<ReactFileReader fileTypes={[".csv",".zip"]} disabled={this.props.disabled} base64={true} handleFiles={this.handleFiles}>
				<Button 
				style={this.props.style} 
				disabled={this.props.disabled}
				// onClick={this.toggle}
				>受測者資料匯入</Button>
			</ReactFileReader>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>受測者資料匯入</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				{this.state.msg===""?
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
				:
				<Typography style={{fontFamily:'微軟正黑體'}}>{this.state.msg}</Typography>
				}
                </DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',}}>關閉</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class DisTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = { qAssignSearchData:[{}],
						checkedValues: [],
						anchorEl: null,
						qStatusList: [{}],
						TotalAmt: {},
						check_type: {},
						isSend: false,
						send_msg: "",
						search_name:'',
						search_email:'',
						select_type:'',
						textfieldValue:'',
					};
	}
	componentDidMount(){
		this.fetchGroup();
		this.fetchQAssign();
		this.fetchQStatus();
		this.fetchTotalAmount();
	}
    fetchTotalAmount = () => {
        let send_body = JSON.stringify({ func:'get_amt_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({TotalAmt:response.result, check_type:response.check_type})
            }
        })
        .catch(error => console.error('Error:', error));
    }
    fetchGroup = () => {
        let send_body = JSON.stringify({ func:'get_group_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corpGroup:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}
	fetchQAssign = () => {
        let send_body = JSON.stringify({ func:'query_q_assign',
										login_uid: this.props.uid,
										email: this.state.search_email,
										name: this.state.search_name,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ qAssignSearchData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	handleResetSearch = () => {
		this.setState({ search_name:'',
						search_email:'',
						select_type:'',
						textfieldValue:'',
					});
		let send_body = JSON.stringify({ func:'query_q_assign',
										login_uid: this.props.uid,
										email: "",
										name: "",
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ qAssignSearchData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	handleChangePage = page_id => {
		let send_body = JSON.stringify({ func:'query_q_assign',
										login_uid: this.props.uid,
										email: this.state.search_email,
										name: this.state.search_name,
										page_id: page_id,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ qAssignSearchData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								show_end: response.end,
								show_start: response.start,
								page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	fetchSend = () => {
		let send_body = JSON.stringify({ func:'send_q_assign',
										login_uid: this.props.uid,
										qa_list: this.state.checkedValues,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({send_msg:response.msg,isSend:true});
				this.fetchQAssign();
				this.fetchTotalAmount();
			}
        })
        .catch(error => console.error('Error:', error));
	}
	fetchQStatus = () => {
		let send_body = JSON.stringify({ func: 'q_status_list',
										login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
										corp_name:this.state.search_corp_name,
										tax_id:this.state.search_tax_id,
									});
		fetch(`${api_path}/ugc/misc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({qStatusList:response.result});
			}
		})
		.catch(error => console.error('Error:', error));
	}
    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
	}
	handleFiles = file => {
		let send_body = JSON.stringify({ func: 'upload_q_assign',
										login_uid: this.props.uid,
                                        file: file.base64,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
            })
        .then(res => res.json())
        .then(response => {
			if(response.status==="SUCC"){
				alert("上傳成功");
				this.fetchQAssign();
			}
		})
        .catch(error => console.error('Error:', error));
	}
	fetchPrintUidPasswd= () => {
        let send_body = JSON.stringify({ func: 'print_pwd_list',
										login_uid: this.props.uid,
										qa_list: this.state.checkedValues,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.pdf){
					var link = document.createElement("a");
					link.href = 'data:application/pdf;base64,' + encodeURI(response.pdf);
					link.download = response.pdf_fname;
					link.click();
					// for pdf open new tab
					// let pdfWindow = window.open("","_blank")
					// pdfWindow.document.write("<iframe width='100%' height='100%' frameborder='0' src='data:application/pdf;base64, " + encodeURI(response.rpt)+"'></iframe>")
					// pdfWindow.document.title = response.filename;
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	handleSelectSearchType = e => {
		if(this.state.select_type === "name"){
			this.setState({ search_name:e.target.value,
							search_email:'',
							textfieldValue:e.target.value,
						});
		}
		else if(this.state.select_type === "email"){
			this.setState({ search_name:'',
							search_email:e.target.value,
							textfieldValue:e.target.value,
						});
		}
	}
	render() {
    return(
	<div>
		<div style={{display:'flex', textAlign:'right', justifyContent:'flex-start', alignItems:'baseline', margin:'10px',}}>
			<div style={{display:'flex',alignItems:'flex-end'}}>
				<div style={{display:'flex', alignItems:'baseline'}}>
					<TextField 
						id="category"
						label="欄位查詢"
						select SelectProps={{native: true}}
						margin="normal"
						value={this.state.select_type} 
						onChange={e=>{this.setState({select_type:e.target.value, textfieldValue:'',search_name:'',search_email:'',})}}
						InputLabelProps={{style:{whiteSpace:'nowrap',fontSize:'14px',fontFamily:'微軟正黑體'}}}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'} }}
						style={{width:'80px'}}
					>
						<option value=""></option>
						<option value="email">email</option>
						<option value="name">姓名</option>
					</TextField>
					<TextField
						id="standard-name"
						margin="normal"
						onChange={this.handleSelectSearchType}
						value={this.state.textfieldValue}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
					/>
				</div>
				<div style={{display:'flex',alignItems:'center'}}>
					<SearchBtn onClick={this.fetchQAssign}/>
					<ClearSearchBtn onClick={this.handleResetSearch}/>
				</div>
			</div>
			{this.state.check_type==="0"?
			<div style={{display:'flex', alignItems:'center'}}>
				<ImportModal 
				disabled={this.props.auth!=="write" || this.state.TotalAmt.rest_amt === 0} 
				style={btnStyle(this.props.auth!=="write"||this.state.TotalAmt.rest_amt===0)} 
				handleFiles={this.handleFiles}
				fetchQAssign={this.fetchQAssign}
				uid={this.props.uid}
				/>
				{/* <ReactFileReader fileTypes={[".csv",".zip"]} disabled={this.props.auth!=="write" || this.state.TotalAmt.rest_amt === 0} base64={true} handleFiles={this.handleFiles}>
					<Button style={btnStyle(this.props.auth!=="write"||this.state.TotalAmt.rest_amt===0)} 
					disabled={this.props.auth!=="write" || this.state.TotalAmt.rest_amt === 0}
					>受測者資料匯入</Button>
				</ReactFileReader> */}
				<AssignAddModal uid={this.props.uid} fetchQAssign={this.fetchQAssign} auth={this.props.auth!=="write" || this.state.TotalAmt.rest_amt === 0} fetchTotalAmount={this.fetchTotalAmount}/>
				<Typography gutterBottom style={{margin:'5px',fontSize:'14px',fontFamily:'微軟正黑體'}}>剩餘量：{this.state.TotalAmt.rest_amt}</Typography>
			</div>
			:
			<div style={{display:'flex', alignItems:'center'}}>
				<ImportModal 
				disabled={this.props.auth!=="write"}
				style={btnStyle(this.props.auth!=="write")}
				fetchQAssign={this.fetchQAssign}
				uid={this.props.uid}
				/>
				{/* <ReactFileReader fileTypes={[".csv",".zip"]} disabled={this.props.auth!=="write"} base64={true} handleFiles={this.handleFiles}>
						<Button style={btnStyle(this.props.auth!=="write")} disabled={this.props.auth!=="write"}>受測者資料匯入</Button>
				</ReactFileReader> */}
				<AssignAddModal uid={this.props.uid} fetchQAssign={this.fetchQAssign} auth={this.props.auth!=="write"} fetchTotalAmount={this.fetchTotalAmount}/>
			</div>
			}
		</div>
		<Paper style={{width:'100%', overflowX:'auto',marginTop:'20px'}}>
			<Table>
			<TableHead>
				<TableRow style={tableheadrowStyle}>
					<TableCell style={qResultheadcellStyle}>
						<IconButton size="small"onClick={e=>{this.setState({anchorEl:e.currentTarget})}}><ArrowDropDown/></IconButton>
						<Menu id="simple-menu"
							anchorEl={this.state.anchorEl}
							keepMounted
							open={Boolean(this.state.anchorEl)}
							onClose={()=>this.setState({anchorEl:null})}
						>
							<MenuItem onClick={()=>{this.setState({anchorEl:null, checkedValues: this.state.qAssignSearchData.map(qa => qa.qa_seq)});}} style={{fontFamily:'微軟正黑體'}}>全選</MenuItem>
							<MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: []})} style={{fontFamily:'微軟正黑體'}}>全不選</MenuItem>
							<MenuItem onClick={()=>this.setState({anchorEl:null,checkedValues: this.state.qAssignSearchData.filter(qa => qa.q_status==="0").map(qa => qa.qa_seq)})} style={{fontFamily:'微軟正黑體'}}>未發送</MenuItem>
							<MenuItem onClick={()=>this.setState({anchorEl:null,checkedValues: this.state.qAssignSearchData.filter(qa => qa.q_status==="8").map(qa => qa.qa_seq)})} style={{fontFamily:'微軟正黑體'}}>測驗超時</MenuItem>
							<MenuItem onClick={()=>this.setState({anchorEl:null,checkedValues: this.state.qAssignSearchData.filter(qa => qa.q_expired===true).map(qa => qa.qa_seq)})} style={{fontFamily:'微軟正黑體'}}>測驗過期</MenuItem>
						</Menu>
					</TableCell>
					{DistributeHeadRows.map(e=><TableCell align="center" style={qResultheadcellStyle}>{e}</TableCell>)}
				</TableRow>
			</TableHead>
			<TableBody>
				{this.state.qAssignSearchData.map((qAssign,index) => 
					<TableRow style={tablebodyStyle(index)} selected={this.state.checkedValues.includes(qAssign.qa_seq)}>
						<TableCell style={qResultbodycellStyle}>
							<Checkbox 
								label={qAssign.qa_seq} 
								key={qAssign.qa_seq}
								checked={this.state.checkedValues.includes(qAssign.qa_seq)}
								onChange={e => this.handleCheck(e,qAssign.qa_seq)}
							/>
						</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.name}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.email}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.qname}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.tp_name}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.dept_name}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.title_name}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.q_time_limit}分鐘</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.q_date_limit}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>
							<AssignEditModal style={cellStyle} uid={this.props.uid} qa={qAssign} fetchQAssign={()=>this.handleChangePage(this.state.page_id)} auth={this.props.auth!=="write" || qAssign.q_status!=="0"} />
						</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.q_uid}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qAssign.q_passwd}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{this.state.qStatusList.find(i=>i.id===parseInt(qAssign.q_status,10))?this.state.qStatusList.find(i=>i.id===parseInt(qAssign.q_status,10)).val:""}</TableCell>
					</TableRow>
				)}
			</TableBody>
			</Table>
		</Paper>
		<div style={{display:'flex',justifyContent:'space-between'}}>
			<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
				<Typography style={{marginRight:'10px',fontFamily:'微軟正黑體'}}>已選：{this.state.checkedValues.length} 項</Typography>
				<Button disabled={this.state.checkedValues.length===0||this.props.auth!=="write"} onClick={this.fetchSend} style={btnStyle(this.state.checkedValues.length===0||this.props.auth!=="write")}>分發</Button>
				{this.state.isSend?
				<Dialog
					fullWidth={true}
					maxWidth="xs"
					open={this.state.isSend}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">分發狀態</DialogTitle>
					<DialogContent>
					<DialogContentText id="alert-dialog-description">{this.state.send_msg}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button size="large" onClick={()=>this.setState({isSend: false})} color="primary">確認</Button>
					</DialogActions>
				</Dialog>
				:null}
				<DeleteModal 
					auth={this.state.checkedValues.length===0 || this.props.auth!=="write"} 
					fetchQAssign={()=>this.handleChangePage(this.state.page_id)}
					checkedValues={this.state.checkedValues}
					uid={this.props.uid}
					fetchTotalAmount={this.fetchTotalAmount}
				/>
				<Print 
					fetchPrintUidPasswd={this.fetchPrintUidPasswd} 
					style={btnStyle(this.state.checkedValues.length===0)}
					disabled={this.state.checkedValues.length===0}
					checkedValues={this.state.checkedValues}
					uid={this.props.uid}
					fetchQAssign={this.fetchQAssign}
					fetchTotalAmount={this.fetchTotalAmount}
				/>
				<DisAll 
					auth={this.props.auth!=="write"} 
					fetchQAssign={this.fetchQAssign}
					uid={this.props.uid}
					fetchTotalAmount={this.fetchTotalAmount}
				/>
				<DelAll 
					auth={this.props.auth!=="write"} 
					fetchQAssign={this.fetchQAssign}
					uid={this.props.uid}
					fetchTotalAmount={this.fetchTotalAmount}
				/>
				<PrintAll 
					auth={this.props.auth!=="write"} 
					fetchQAssign={this.fetchQAssign}
					uid={this.props.uid}
					fetchTotalAmount={this.fetchTotalAmount}
				/>
			</div>
			<div style={{display:'flex',margin:'20px'}}>
			<Button size="large" disabled={this.state.pre_page===""} onClick={()=>this.handleChangePage(this.state.pre_page)} style={{fontFamily:'微軟正黑體',}}>上一頁</Button>
			<Button size="large" disabled={this.state.nxt_page===""} onClick={()=>this.handleChangePage(this.state.nxt_page)} style={{fontFamily:'微軟正黑體',}}>下一頁</Button>
			</div>
		</div>
	</div>
    );
  }
}


export default DisTable;