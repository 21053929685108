import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Background from '../../../pics/assessBgImg.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {api_path,UGCblue} from '../../../environment';

class BuyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        msg: "gjiq;oetqnbevie",
					};
    }
    render() {
        return (
        	<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.props.modal}
				onClose={this.props.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>訂購完成</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    <Typography style={{fontFamily:'微軟正黑體',}}>謝謝您選用UGCareer職業測評，訂單已送出，請您在3日內完成繳費，確認後我們將為您開通測驗，謝謝！</Typography>
				</DialogContentText>
				</DialogContent>
                <DialogActions>
				<Button size="large" onClick={this.props.toggle} style={{fontFamily:'微軟正黑體',color:UGCblue}}>
				關閉
				</Button>
				</DialogActions>
			</Dialog>
        );
    }
}


class Buy extends Component {
    constructor(props){  
        super(props);  
		this.state = { childrenFunc:"消費者",
						name: "",
						email: "",
						tel: "",
						qi_seq: "",
						ord_amt: "",
						mtx_code: "",
						qdetail: [{}],
						fail_msg: "",
						fail_field: "",
					};  
    }

	componentDidMount(){
		this.fetchQquery();
	}

    toggle = () => {
        this.setState({ modal: !this.state.modal});
    }
	fetchQquery= () => {
		let send_body = JSON.stringify({ func: 'q_summary' , login_uid: this.props.uid,});
		fetch(`${api_path}/ugc/misc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({qdetail: response.result})
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchB2cOrder = () => {
        let send_body = JSON.stringify({ func: 'b2c_order',

										name: this.state.name,
										email: this.state.email,
										tel: this.state.tel,
										is_stu: this.state.is_stu,
										qi_seq: this.state.qi_seq,
										ord_amt: this.state.ord_amt,
										mtx_code: this.state.mtx_code,
									});
		fetch(`${api_path}/ugc/misc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
                this.toggle();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
    }
    
	fetchBuyAssess = () => {
		let send_body = JSON.stringify({ func: 'buy_assess',
                                        login_uid: this.props.uid,
                                        qi_seq:this.state.qi_seq,
										ord_amt:this.state.ord_amt,
										mtx_code:this.state.mtx_code,
									});
		fetch(`${api_path}/ugc/user_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({qi_seq: "",
                                ord_amt: "",
                                mtx_code: "",
                                fail_msg: "",
                                fail_field: "",
                                modal: true,
                            });
            }
            else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {

        const mainstyle = {
            width: "100%",
            height: "100%",
            // margin:'-8px',
            // padding: '0',
			backgroundImage: `url(${Background})`,
			backgroundColor: 'white',
			backgroundSize: '35vw 80vh',
            backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            backgroundAttachment: "fixed",
            overflowX:'auto',
            // overflowY:'hidden',
            top:'0',
            left:'0',
            
		};

		const desktopButtonStyle = {
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'30px',
			width:'120px',
			fontSize:'14px',
            margin:'10px',
            padding:'5px 10px 5px 10px'
        };
        
		const desktopButtonStyle2 = {
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.5vw',
			marginBottom:'0.5vw',
		};

		const desktopDisabledButtonStyle = {
            fontFamily:'微軟正黑體',
			backgroundColor:'gray',
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.1vw',
			marginBottom:'2vw',
		};

		const mobileButtonStyle={
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'5vw',
			marginTop:'1.2vw',
			width:'28vw',
			fontSize:'4.5vw',
			marginBottom:'4vw',
		};

		const mobileDisabledButtonStyle={
            fontFamily:'微軟正黑體',
			backgroundColor:'lightgray',
			color:'white',
			borderRadius:'5vw',
			marginTop:'1.2vw',
			width:'28vw',
			fontSize:'4.5vw',
			marginBottom:'4vw',
		};

		const circleBtnStyle = {
            fontFamily:'微軟正黑體',
			fontSize:'4vw',
			fontWeight:'bold',
			borderRadius:'8vw',
			backgroundColor:'white',
			border:'1px solid white',
			color:UGCblue,
			padding:'0',
			minWidth:'7vw',
			margin:'1vw',
		};

		const mobileInfoFontSize = {
            fontFamily:'微軟正黑體',
			fontSize:'4vw',
		};

		const desktopInfoFontSize = {
            fontFamily:'微軟正黑體',
			fontSize:'14px',
		};

	return (
        <div style={{maxWidth:'700px', width:'100%',margin: '0 auto'}}>
            <div style={{display:'flex',width:'100%',marginBottom:'3vh',justifyContent:'center',flexDirection:'column',flexWrap:'wrap'}}>
                <div style={{display:'flex',marginTop:'3vw',}}>
                    <div style={{minWidth:'150px',display:'flex',width:'65%',alignItems:'flex-start',flexDirection:'column',textAlign:'left'}}>
                        <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'20px',marginBottom:'2vw',fontWeight:'bold'}}>訂購步驟:</Typography>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'14px'}}>
                            1. 請輸入以下<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'14px',fontWeight:'bold',fontStyle:'normal'}}>購買資料</em>
                        </Typography>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'14px'}}>
                            2. 訂購 <em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'14px',fontWeight:'bold',fontStyle:'normal'}}>3日內匯款繳費</em>
                        </Typography>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'14px'}}>
                            3. 您將在<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'14px',fontWeight:'bold',fontStyle:'normal'}}>2~3個工作天</em>內收到<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'14px',fontWeight:'bold',fontStyle:'normal'}}>測驗通知信</em>，內含施測連結
                        </Typography>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'14px'}}>
                            4. 完成測驗後，您可依<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'14px',fontWeight:'bold',fontStyle:'normal'}}>帳戶開通信</em>中的帳號密碼登入看報告
                        </Typography>
                        
                    </div>
                    <div style={{minWidth:'140px',display:'flex',width:'32.5%',justifyContent:'center',flexDirection:'column',border:'1px solid gray',borderRadius:'20px',padding:'10px 20px 10px 20px',marginLeft:'2.5%'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'17px',fontWeight:'bold'}}>職業適性測評</Typography>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'15px',fontWeight:'bold'}}>每份新台幣 $1200</Typography>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'12px',marginBottom:'1vw'}}>ATM轉帳 / 線上轉帳</Typography>
                        </div>
                        <div style={{display:'flex',alignItems:'flex-start',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
                            <div style={{display:'flex',alignItems:'flex-start',justifyContent:'center',flexDirection:'column'}}>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'13px'}}>銀行: 玉山銀行(808) 民生分行</Typography>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'13px'}}>帳號: 0211-440-006830</Typography>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'13px'}}>戶名: 優駿企管顧問有限公司</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',width:'100%',alignItems:'flex-start',flexDirection:'column'}}>
                    <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'20px',marginTop:'3vw',marginBottom:'1.5vw',fontWeight:'bold'}}>輸入購買資料:</Typography>
                    <Grid container spacing={1}>
						{this.state.fail_msg!==""?
						<Grid item xs={12} sm={12}>
							<Typography align="center" style={{fontFamily:'微軟正黑體',color:"red", margin:'1vw', fontSize:'14px'}}>{this.state.fail_msg}</Typography>
						</Grid>
						:null}
                        {/* 						
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.name}
                                onChange={e=>{this.setState({name:e.target.value})}}
                                error = {this.state.fail_field === "name" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>姓名：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            />
						</Grid>
                        <Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.email}
                                onChange={e=>{this.setState({email:e.target.value})}}
                                error = {this.state.fail_field === "email" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>Email：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            />
						</Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                placeholder="02-12345678/0912-345678"		value={this.state.tel}
                                onChange={e=>{this.setState({tel:e.target.value})}}
                                error = {this.state.fail_field === "tel" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>電話：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            />
						</Grid>
                        <Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="standard-select-currency-native"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.is_stu}
								onChange={e => {this.setState({ is_stu : e.target.value,
																qi_seq: ""});
												}}
								error = {this.state.fail_field === "is_stu" ? true:null}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>身份：</Typography></InputAdornment>,
									style: desktopInfoFontSize 
								}}
                                fullWidth
                                style={desktopInfoFontSize}
							>
								<option value=""></option>
								<option value={true}>學生</option>
								<option value={false}>社會人士</option>
							</TextField>
                        </Grid>
                        */}
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.qi_seq}
                                onChange={e => this.setState({qi_seq : e.target.value})}
                                error = {this.state.fail_field === "qi_seq" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>購買測評名稱：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            >
                                <option value=""></option>
                                {this.state.qdetail.map(q=><option value={q.qi_seq}>{q.qname}</option>)}
                                {/* {this.state.is_stu==="true" || this.state.is_stu === true?
                                this.state.qdetail.filter(q=>q.is_stu===true).map(q=><option value={q.qi_seq}>{q.qname}</option>)
                                :
                                null}
                                 {this.state.is_stu==="false" || this.state.is_stu === false?
                                this.state.qdetail.filter(q=>q.is_stu===false).map(q=><option value={q.qi_seq}>{q.qname}</option>)
                                :null} */}
                            </TextField>
						</Grid>
                        <Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.ord_amt}
                                onChange={e=>{this.setState({ord_amt:e.target.value})}}
                                error = {this.state.fail_field === "ord_amt" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>訂購量：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
						</Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.mtx_code}
                                onChange={e=>{this.setState({mtx_code:e.target.value})}}
                                error = {this.state.fail_field === "mtx_code" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>匯款帳號末4碼：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
						</Grid>
                        <Grid item xs={12} sm={12}>
                            <div style={{display:'flex',justifyContent:'space-evenly'}}>
                                <Button style={desktopButtonStyle} onClick={this.fetchBuyAssess} >加購</Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
			</div>
            <BuyModal modal={this.state.modal} toggle={this.toggle}/>
        </div>
	);
	}
}

export default Buy;