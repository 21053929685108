import React, { Component } from 'react';
import Main from './tw/containers/main';
import Assess from './tw/containers/assess';
import Buy from './tw/components/buy';

// import EnMain from './en/containers/main';
// import EnAssess from './en/containers/assess';
// import EnBuy from './en/components/buy';
// import CnMain from './cn/containers/main';
// import CnAssess from './cn/containers/assess';
// import CnBuy from './cn/components/buy';

import { BrowserRouter as Router, Route } from "react-router-dom";
import './styles.css';
import { MuiThemeProvider,createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
        typography: { fontFamily: '"微軟正黑體"', }
    }
);
  
class App extends Component {
    render(){
        return (
            <MuiThemeProvider theme={theme}>
                <Router>
                    <Route key="assess" exact path={["/assess", "/assess/:token"]} component={Assess}></Route>
                    <Route key="main" exact path={["/"]} component={Main}></Route>
                    <Route path={["/order"]} component={Buy}></Route>

                    {/* <Route key="main" exact path={["/en"]} component={EnMain}></Route> */}
                    {/* <Route key="assess" exact path={["/en/assess", "/en/assess/:token"]} component={EnAssess}></Route> */}
                    {/* <Route path={["en/order"]} component={EnBuy}></Route> */}
                    
                    {/* <Route key="main" exact path={["/cn"]} component={CnMain}></Route> */}
                    {/* <Route key="assess" exact path={["/cn/assess", "/cn/assess/:token"]} component={CnAssess}></Route> */}
                    {/* <Route path={["cn/order"]} component={CnBuy}></Route> */}
                    
                    
                </Router>
            </MuiThemeProvider>
        );
    }
}
  
export default App;
  