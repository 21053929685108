import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment'

import {api_path,} from '../../environment';

const desktopInfoFontSize = {
    fontFamily:'微軟正黑體',
    fontSize:'15px',
};

class ChangeInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
                        name: "",
                        age: "",
                        gender: "",
                        email: "",
                        is_stu: false,
                        tel: "",
                        age_list:[],
						edu_list:[],
						seniority_list: [],
						corp_type_list: [],
						dept_list: [],
						title_list: [],
						edu_field_list:[],
						edu_status_list:[],
						edu_level_list:[],
						edu_year_list:[],
                        education: "",
                        seniority: "",
                        corp_type: "",
                        corp_type_other: "",
                        corp_name: "",
                        dept_name: "",
                        dept_other: "",
                        title_name: "",
                        title_other: "",
                        year_other: "",
                        fail_field: [],
                        fail_msg: "",
                        uid: "",
                        status:'',
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal){ 
            this.fetchRptInfo();
            this.handleUpdateInfoList(); 
        }
        this.setState({ modal: !this.state.modal,
                        status:'',
                        fail_field:[],
                        fail_msg:'',
                    });
		
	}

	fetchAgeList = () => {
		let send_body = JSON.stringify({ func:'age_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ age_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchEduList = () => {
		let send_body = JSON.stringify({ func:'edu_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ edu_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchSeniorityList = () => {
		let send_body = JSON.stringify({ func:'seniority_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ seniority_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchCorpTypeList = () => {
		let send_body = JSON.stringify({ func:'corp_type_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ corp_type_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchDeptList = () => {
		let send_body = JSON.stringify({ func:'dept_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ dept_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchTitleList = () => {
		let send_body = JSON.stringify({ func:'title_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ title_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchEduLevelList = () => {
		let send_body = JSON.stringify({ func:'edu_level_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				console.log(response.result);
                this.setState({ edu_level_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchEduYearList = () => {
		let send_body = JSON.stringify({ func:'edu_year_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				console.log(response.result);
                this.setState({ edu_year_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
	}

    fetchEduStatusList = () => {
		let send_body = JSON.stringify({ func:'edu_status_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ edu_status_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
    }

    fetchEduFieldList = () => {
		let send_body = JSON.stringify({ func:'edu_field_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ edu_field_list: response.result,
                            });
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	handleUpdateInfoList = ()=>{
		this.fetchEduYearList();
		this.fetchAgeList();
		this.fetchEduList();
		this.fetchSeniorityList();
		this.fetchCorpTypeList();
		this.fetchDeptList();
		this.fetchTitleList();
        this.fetchEduLevelList();
        this.fetchEduStatusList();
        this.fetchEduFieldList();
	}

	fetchRptInfo = () => {
		let send_body = JSON.stringify({ func: 'rpt_user_info',
										login_uid: this.props.uid,
										qa_seq: this.props.qRpt.qa_seq,
									});

        fetch(`${api_path}/ugc/${this.props.role}_mgt.php`, {
            method: 'POST',
			body: send_body,
			headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ status:'SUCC',
                                uid: response.result.uid,
                                is_stu: response.result.is_stu,
                                name: response.result.name,
                                email: response.result.email,
                                age: response.result.age,
                                tel: response.result.tel,
                                gender: response.result.gender,
                                education: response.result.education,
                                seniority: response.result.seniority,
                                corp_type: response.result.corp_type,
                                corp_type_other: response.result.corp_type_other,
                                corp_name: response.result.corp_name,
                                dept_name: response.result.dept_name,
                                dept_other: response.result.dept_other,
                                title_name: response.result.title_name,
                                title_other: response.result.title_other,
                                edu_level: response.result.edu_level,
                                edu_status: response.result.edu_status,
                                edu_field: response.result.edu_field,
                                field_other: response.result.field_other,
                                school_dept: response.result.school_dept,
                                school: response.result.school,
                                school_year: response.result.school_year,
                                year_other: response.result.year_other,
                            });
            }
            else{
                this.setState({status:'FAIL',fail_msg:response.msg,})
            }
        })
        .catch(error => console.error('Error:', error));
    }
    
    fetchUpdateInfo = () => {
		let send_body;
		if(!this.state.is_stu){
		send_body = JSON.stringify({ func:'mod_user_info',
                                    login_uid: this.props.uid,
                                    uid: this.state.uid,
                                    name: this.state.name,
                                    is_stu: this.state.is_stu,
                                    gender: this.state.gender,
                                    age: this.state.age,
                                    email: this.state.email,
                                    tel: this.state.tel,
                                    education: this.state.education,
                                    seniority: this.state.seniority,
                                    corp_type: this.state.corp_type,
                                    corp_type_other: this.state.corp_type_other,
                                    corp_name: this.state.corp_name,
                                    dept_name: this.state.dept_name,
                                    dept_other: this.state.dept_other,
                                    title_name:  this.state.title_name,
                                    title_other: this.state.title_other,
                                });
		}
		else{
			send_body = JSON.stringify({ func:'mod_user_info',
                                        login_uid: this.props.uid,
                                        uid: this.state.uid,
                                        name: this.state.name,
                                        is_stu: this.state.is_stu,
                                        gender: this.state.gender,
                                        email: this.state.email,
                                        age: this.state.age,
                                        tel: this.state.tel,
                                        edu_level: this.state.edu_level,
                                        edu_status: this.state.edu_status,
                                        edu_field: this.state.edu_field,
                                        field_other: this.state.field_other,
                                        school_dept: this.state.school_dept,
                                        school: this.state.school,
                                        school_year: this.state.school_year,
                                        year_other:this.state.year_other,
                                    });
			}
        fetch(`${api_path}/ugc/${this.props.role}_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({status:'SUCC',modal: !this.state.modal});
				if(this.props.succProcess!==undefined){
					this.props.succProcess();
					console.log("succprocess")
				}
			}
			else if(response.status==="FAIL"){
                this.setState({ fail_field:response.fail_field,
                                fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',textTransform:'none',fontWeight:'500',paddingTop:'0',paddingBottom:'0',}}>{this.props.qRpt.name}</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>修改詳細資料</Typography></DialogTitle>
				{this.state.status==="FAIL"?
                <DialogContent>
				<DialogContentText id="alert-dialog-description">
                    <Typography style={{fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
                    </DialogContentText></DialogContent>
                    :null
				}
                {this.state.status==="SUCC"?
                <DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={0}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography align="center" style={{...{color:"red", margin:'1vw'}, ...desktopInfoFontSize}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<TextField 
							id="name" 
							value={this.state.name} 
							onChange={e => this.setState({name : e.target.value})}
							margin="normal"
							error={this.state.fail_field.includes("name")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>姓名 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize 
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField 
							disabled 
							id="is_stu" 
							value={this.state.is_stu?"學生":"社會人士"} 
							margin="normal"
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>身份 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize 
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
                            id="gender"
                            select
                            SelectProps={{native: true}}
                            margin="normal"
                            value={this.state.gender}
                            onChange={e => this.setState({gender : e.target.value})}
							error={this.state.fail_field.includes("gender")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>性別 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize 
							}}
							fullWidth
                        >
							<option value=""></option>
                            <option value="1">男</option>
                            <option value="0">女</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
                            id="age"
                            select
                            SelectProps={{native: true}}
                            margin="normal"
                            value={this.state.age}
                            onChange={e => this.setState({age : e.target.value})}
                            error={this.state.fail_field.includes("age")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>年齡 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize 
							}}
							fullWidth
                        >
							<option value=""></option>
							{this.state.age_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                        </TextField>
					</Grid>
					
				</Grid>
				
				{/* 社會人的問題 */}

				{!this.state.is_stu?
				<Grid container spacing={0}>
					<Grid item xs={12} sm={12}>
						<TextField
                            id="education"
                            select
                            SelectProps={{native: true}}
                            margin="normal"
                            value={this.state.education}
                            onChange={e => this.setState({education : e.target.value})}
                            error={this.state.fail_field.includes("education")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>學歷 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
                        >
							<option value=""></option>
							{this.state.edu_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
                            id="seniority"
                            select
                            SelectProps={{native: true}}
                            margin="normal"
                            value={this.state.seniority}
                            onChange={e => this.setState({seniority : e.target.value})}
                            error={this.state.fail_field.includes("seniority")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>工作年資 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
                        >
                            <option value=""></option>
							{this.state.seniority_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
                            id="corp_type"
                            select
                            SelectProps={{native: true}}
                            margin="normal"
                            value={this.state.corp_type}
                            onChange={e => this.setState({corp_type : e.target.value})}
							error={this.state.fail_field.includes("corp_type")}
							style={{marginRight:'5px'}}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>服務公司的產業類別 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
                        >
                            <option value=""></option>
							{this.state.corp_type_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                        </TextField>
					</Grid>
					{this.state.corp_type==="-1"?
					<Grid item xs={12} sm={8} style={{marginLeft:'20px'}}>
						<TextField
							placeholder="其他"
							id="corp_type_other" 
							value={this.state.corp_type_other}
							onChange={e => this.setState({corp_type_other : e.target.value})}
							error={this.state.fail_field.includes("corp_type_other")}
							margin="normal"
							InputProps={{style: desktopInfoFontSize }}
							fullWidth
						/>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<TextField 
							id="corp_name" 
							value={this.state.corp_name}
							onChange={e => this.setState({corp_name : e.target.value})}
							error={this.state.fail_field.includes("corp_name")}
                            margin="normal" 
							required
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>服務的公司/機構 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
                            id="dept_name"
                            select
                            SelectProps={{native: true}}
                            margin="normal"
                            value={this.state.dept_name}
                            onChange={e => this.setState({dept_name : e.target.value})}
							error={this.state.fail_field.includes("dept_name")}
							style={{marginRight:'5px'}}
							required
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>在貴公司擔任的職務類別 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
                        >
						    <option value=""></option>
							{this.state.dept_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                        </TextField>
					</Grid>
					{this.state.dept_name==="-1"?
					<Grid item xs={12} sm={8} style={{marginLeft:'20px'}}>
						<TextField 
							placeholder="其他"
							id="dept_other" 
							value={this.state.dept_other}
							onChange={e => this.setState({dept_other : e.target.value})}
							margin="normal"
							InputProps={{style: desktopInfoFontSize }}
							error={this.state.fail_field.includes("dept_other")}
						/>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<TextField
							id="title_name"
							select
							SelectProps={{native: true}}
							margin="normal"
							value={this.state.title_name}
							onChange={e => this.setState({title_name : e.target.value})}
							error={this.state.fail_field.includes("title_name")}
							style={{marginRight:'5px'}}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>在貴公司的職級為 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
						>
							<option value=""></option>
							{this.state.title_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
						</TextField>
					</Grid>
					{this.state.title_name==="-1"?
					<Grid item xs={12} sm={12}>
						<TextField 
							placeholder="其他"
							id="title_other"
							value={this.state.title_other}
							onChange={e => this.setState({title_other : e.target.value})}
							margin="normal"
							InputProps={{style: desktopInfoFontSize }}
							error={this.state.fail_field.includes("title_other")}
						/>
					</Grid>
					:null}
				</Grid>
				:null}

				{/* 學生的問題 */}

				{this.state.is_stu?
				<Grid container spacing={0}>
					<Grid item xs={12} sm={12}>
						<TextField
							id="edu_level"
							select
							SelectProps={{native: true}}
							margin="normal"
							value={this.state.edu_level}
							onChange={e => this.setState({edu_level : e.target.value})}
							error={this.state.fail_field.includes("edu_level")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>就學階段 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
						>
							<option value=""></option>
							{this.state.edu_level_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
							id="edu_status"
							select
							SelectProps={{native: true}}
							margin="normal"
							value={this.state.edu_status}
							onChange={e => this.setState({edu_status : e.target.value})}
							error={this.state.fail_field.includes("edu_status")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>就學狀態 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
						>
							<option value=""></option>
							{this.state.edu_status_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
							id="edu_field"
							select
							SelectProps={{native: true}}
							margin="normal"
							value={this.state.edu_field}
							onChange={e => this.setState({edu_field : e.target.value})}
							error={this.state.fail_field.includes("edu_field")}
							style={{marginRight:'5px'}}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>就讀的領域 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
						>
							<option value=""></option>
							{this.state.edu_field_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
						</TextField>
					</Grid>
					{this.state.edu_field==="-1"?
					<Grid item xs={12} sm={8} style={{marginLeft:'20px'}}>
						<TextField 
							placeholder="其他"
							id="field_other" 
							value={this.state.field_other}
							onChange={e => this.setState({field_other : e.target.value})}
							margin="normal" 
							error={this.state.fail_field.includes("field_other")}
						/>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<TextField 
							id="school" 
							value={this.state.school}
							onChange={e => this.setState({school : e.target.value})}
							margin="normal"
							error={this.state.fail_field.includes("school")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>就讀學校 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField 
							id="school_dept" 
							value={this.state.school_dept}
							onChange={e => this.setState({school_dept : e.target.value})}
							margin="normal"
							error={this.state.fail_field.includes("school_dept")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>就讀科系 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField 
							id="school_year" 
							value={this.state.school_year}
							select SelectProps={{native: true}}
							onChange={e => this.setState({school_year : e.target.value})}
							margin="normal"
							error={this.state.fail_field.includes("school_year")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>就讀年級 * ：</Typography></InputAdornment>,
								style: desktopInfoFontSize
							}}
							fullWidth
						
						>
						<option value=""></option>
						{this.state.edu_year_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
						</TextField>
					</Grid>
					{this.state.school_year==="-1"?
					<Grid item xs={12} sm={8} style={{marginLeft:'20px'}}>
						<TextField 
							placeholder="其他"
							id="year_other" 
							value={this.state.year_other}
							onChange={e => this.setState({year_other : e.target.value})}
							InputProps={{style: desktopInfoFontSize }}
							margin="normal" 
							error={this.state.fail_field.includes("field_other")}
						/>
					</Grid>
					:null}
				</Grid>
				:null}
				<Grid container spacing={0}>
					<Grid item xs={12} sm={12}>
						<TextField 
							id="email" 
							value={this.state.email} 
							onChange={e => this.setState({email : e.target.value})}
                            margin="normal"
							error={this.state.fail_field.includes("email")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>信箱E-mail  ：</Typography></InputAdornment>,
								style: desktopInfoFontSize 
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField 
							id="tel" 
							placeholder="02-12345678/0912-345678"		value={this.state.tel}
							onChange={e => this.setState({tel : e.target.value})}
                            margin="normal"
                            error={this.state.fail_field.includes("tel")}
							InputProps={{
								startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>手機  ：</Typography></InputAdornment>,
								style: desktopInfoFontSize 
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
                :null}
				<DialogActions>
                    {this.state.status==="FAIL"?null:
					<Button size="large" onClick={this.fetchUpdateInfo} color="primary" style={{fontFamily:'微軟正黑體',}}>修改</Button>
                    }
                    <Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>取消</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

export default ChangeInfo;