import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Create from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {api_path} from '../../../../environment';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';
import * as CSS from '../../../../css.js';
import {SearchBtn,ClearSearchBtn,CheckAgainMsgBtn,MsgBtn} from '../../buttons';

const b2bUserHeadRows =["建檔日","經銷商名稱","企業名稱","企業名稱縮寫","統編","企客密碼","結帳型態","測評名稱及測評單價","資料修改","地址","主聯絡人","主聯絡email","主聯絡電話","帳號狀態","狀態修改日","備註"]

class SendUserModal extends React.Component {
    constructor(props) {
        super(props);
		this.state = { modal: false, 
						fail_msg: "",
						fail_field: "",
						tp_seq: "",
						tpList: [{}],
						msg: "",
					};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState({ modal: !this.state.modal,});
		this.setState({ submit: false,
						fail_msg: "",
						fail_field: "",
						tp_seq: "",
						tpList: [{}],
						msg: "",
					});
		if(!this.state.modal){this.fetchTpList();}
    }
	
	fetchTpList = () => {
		let send_body = JSON.stringify({ func: "get_template_list",
										login_uid: this.props.uid,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({tpList:response.result.filter(tp=>tp.tp_type==="1")});
			}
			else if(response.status==="FAIL"){}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchB2bUserSend=()=> {
		let send_body = JSON.stringify({func: "b2b_user_send",
										login_uid: this.props.uid,
										tax_id_list: this.props.checkedValues,
										tp_seq: this.state.tp_seq,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({msg: response.msg, submit: true});
			}
		})
		.catch(error => console.error('Error:', error));
	}

    render() {
        return (
        <div>
			<Button 
				style={CSS.UGCbtnStyle(this.props.checkedValues.length===0?true:null)}
				disabled={this.props.checkedValues.length===0?true:null}
				onClick={this.toggle}
			>email帳密發送</Button>

			{this.state.submit?
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={this.state.modal}
                // onClose={this.toggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>
				發送狀態</Typography></DialogTitle>
				<DialogContent>
                <DialogContentText id="alert-dialog-description">
				<Typography style={CSS.justFont}>
				{this.state.msg}
                </Typography></DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button size="large" onClick={this.toggle} color="primary">
                關閉
                </Button>
                </DialogActions>
            </Dialog>
            :
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>
				選擇信件樣式</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="信件模板"
                            SelectProps={{native: true}}
                            fullWidth
							value={this.state.tp_seq}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            onChange={e => this.setState({tp_seq : e.target.value})}
                            error = {this.state.fail_field === "qname" ? true:null}
                        >
							<option value=""></option>
							{this.state.tpList.map(tp=><option value={tp.tp_seq}>{tp.tp_name}</option>)}
                        </TextField>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchB2bUserSend} color="primary" style={CSS.justFont}>
				發送
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
			}
        </div>
        );
    }
}

class UserAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
						role: null,
						corp_name: "",
						tax_id: "",
						check_type: "",
						addr: "",
						contact: "",
						tel: "",
						try_days: "",
						email: "",
						fail_field: "",
						fail_msg: "",
						add: false,
						q_list: [{}],
						add_qprice: "",
						add_qi_seq: "",
						qdetail: [{}],
					};
        this.toggle = this.toggle.bind(this);
	}

    toggle() {
		this.setState({ modal: !this.state.modal,
						role: null,
						corp_name: "",
						tax_id: "",
						check_type: "",
						addr: "",
						contact: "",
						tel: "",
						try_days: "",
						email: "",
						fail_field: "",
						fail_msg: "",
						qdetail: [{}],
						add: false,
						q_list: [],
						add_qprice: "",
						add_qi_seq: "",
						corp_name_abbr: "",
					});
		this.fetchQquery();
	}

	fetchQquery= () => {
        let send_body = JSON.stringify({ func: 'q_summary' , login_uid: this.props.uid,});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qdetail:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchB2bRegister = () => {
		let send_body = JSON.stringify({ func:'b2b_user_ins',
										login_uid: this.props.uid,
										role: this.state.role,
										corp_name: this.state.corp_name,
										tax_id:  this.state.tax_id,
										check_type: this.state.check_type,
										addr: this.state.addr,
										contact: this.state.contact,
										email: this.state.email,
										tel: this.state.tel,
										try_days: this.state.try_days,
										q_list: this.state.q_list,
										corp_name_abbr: this.state.corp_name_abbr,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchB2bUsers();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

    render() {
        return (
        <div>
            <Button style={CSS.UGCbtnStyle(this.props.auth!=="write")} disabled={this.props.auth!=="write"} onClick={this.toggle}>新增客戶</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>新增客戶</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="客戶類型"
                            SelectProps={{native: true}}
                            fullWidth
							value={this.state.role}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            onChange={e => this.setState({role : e.target.value})}
                            error = {this.state.fail_field === "role" ? true:null}
                        >
							<option value={null}></option>
                            <option value={1}>B2B</option>
                            <option value={2}>B2B試用客戶</option>
                            <option value={3}>經銷商</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						{this.state.role==="2"?
						<TextField
							id="standard-name"
							label="試用天數"
							value={this.state.try_days}
                            onChange={e => this.setState({try_days : e.target.value})}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							fullWidth
                            error = {this.state.fail_field === "try_days" ? true:null}
						/>
						:null}
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label={this.state.role === "3"?"經銷商名稱":"企業名稱"}
							value={this.state.corp_name}
                            onChange={e => this.setState({corp_name : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "corp_name" ? true:null}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label={"企業名稱縮寫"}
							value={this.state.corp_name_abbr}
                            onChange={e => this.setState({corp_name_abbr : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "corp_name_abbr" ? true:null}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="統一編號"
							value={this.state.tax_id}
                            onChange={e => this.setState({tax_id : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "tax_id" ? true:null}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="結帳型態"
                            SelectProps={{native: true}}
                            fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							value={this.state.check_type}
                            onChange={e => this.setState({check_type : e.target.value})}
                            error = {this.state.fail_field === "check_type" ? true:null}
                        >
							<option value={null}></option>
                            <option value={1}>月結型</option>
                            <option value={0}>預購型</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="地址"
							value={this.state.addr}
                            onChange={e => this.setState({addr : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "addr" ? true:null}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="聯絡人"
							value={this.state.contact}
                            onChange={e => this.setState({contact : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "contact" ? true:false}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="聯絡email"
							value={this.state.email}
                            onChange={e => this.setState({email : e.target.value})}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							fullWidth
                            error = {this.state.fail_field === "email" ? true:false}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="聯絡電話"
							placeholder="02-12345678/0912-345678"		value={this.state.tel}
                            onChange={e => this.setState({tel : e.target.value})}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							fullWidth
                            error = {this.state.fail_field === "tel" ? true:false}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.adminInputStyle}>測評名稱及測評單價</Typography>
						<Button onClick={()=>this.setState({add:true,})} style={{...CSS.UGCbtnStyle(false),...{marginLeft:'20px'}}}>新增</Button>
						</div>
						{this.state.add?
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<TextField
							label="測評名稱"
							select
							SelectProps={{native: true}}
							value={this.state.qi_seq}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							onChange={e => this.setState({add_qi_seq : e.target.value, add_qprice:this.state.qdetail.find(q=>e.target.value === q.qi_seq).qprice})}
							error = {this.state.fail_field === "qi_seq" ? true:null}
							style ={{marginRight:'5px'}}
						>
							<option value=""></option>
							{this.state.qdetail.map(q=><option value={q.qi_seq}>{q.qname}</option>)}
						</TextField>
						<TextField
							id="standard-name"
							label="單價"
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							value={this.state.add_qprice}
                            onChange={e => this.setState({add_qprice : e.target.value})}
							error = {this.state.fail_field === "add_qprice" ? true:false}
						/>
						<IconButton 
							disabled={this.state.q_list.find(q=>q.qi_seq === this.state.add_qi_seq)!==undefined}
							onClick={ () => 
								this.setState({q_list: this.state.q_list.concat({qi_seq:this.state.add_qi_seq,
																				qprice: this.state.add_qprice,
																				}),
												add: false,
												add_qi_seq: "",
												add_qprice: "",
										})
							}>
							<Done style={{fontSize:'13px'}}/>
						</IconButton>
						<IconButton onClick={()=>this.setState({add:false, add_qi_seq: "", add_qprice: ""})}><Clear style={{fontSize:'13px'}}/></IconButton>
						</div>
						:null}
						{this.state.q_list.length!==0?
							this.state.q_list.map(q=>
								<li style={{display:'flex',alignItems:'center'}}>
									{this.state.qdetail.length!==0 && this.state.qdetail.find(e=>q.qi_seq === e.qi_seq)!== undefined?
										<Typography style={CSS.adminInputStyle}>{"・ "+this.state.qdetail.find(e=>q.qi_seq === e.qi_seq).qname+"  單價："+q.qprice}</Typography>
									:null}
									{this.state.qdetail.length!==0 && this.state.qdetail.find(e=>q.qi_seq === e.qi_seq)!== undefined?
										<IconButton onClick={()=>this.setState({q_list: this.state.q_list.filter(e=>q.qi_seq!==e.qi_seq)})}><Clear style={{fontSize:'13px'}}/></IconButton>
									:null}
								</li>)
						:null}
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchB2bRegister} color="primary" style={CSS.justFont}>
				新增
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class UserEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
						login_uid: "",
						corp_name:'',
						uid: "",
						passwd: "",
						check_type: "",
						addr: "",
						contact: "",
						email: "",
						tel: "",
						uid_status: "",
						try_days: "",
						note: "",
						fail_field:"",
						fail_msg:"",
						qdetail: [{}],
						add: false,
						q_list: [],
						add_qprice: "",
						add_qi_seq: "",
						corp_name_abbr: "",
					};
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        if(!this.state.modal){
            this.setState({ login_uid: this.props.login_uid,
                            corp_name:this.props.user.corp_name,
                            uid: this.props.user.uid,
							passwd: this.props.user.passwd,
							check_type: this.props.user.check_type,
							addr: this.props.user.addr,
							contact: this.props.user.contact,
							email: this.props.user.email,
							tel: this.props.user.tel,
							uid_status: this.props.user.uid_status,
							try_days: this.props.user.try_days,
							note: this.props.user.note,
							q_list: this.props.user.q_list,
							corp_name_abbr: this.props.user.corp_name_abbr,
                            fail_field:'',
                            fail_msg:'',
							qdetail: [{}],
							add: false,
							add_qprice: "",
							add_qi_seq: "",
						});
			this.fetchQquery();
		}
		this.setState({modal: !this.state.modal});
		
	}

	fetchQquery= () => {
        let send_body = JSON.stringify({ func: 'q_summary' , login_uid: this.props.uid,});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qdetail:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}

	handleSubmit = () => {
		let send_body = JSON.stringify({ func: 'b2b_user_mod',
                                        login_uid: this.props.uid,
										tax_id: this.props.user.tax_id,
                                        corp_name: this.state.corp_name,
										// org_uid: this.props.user.uid,
										// uid: this.state.uid,
										passwd: this.state.passwd,
										check_type: this.state.check_type,
										addr: this.state.addr,
										contact: this.state.contact,
										email: this.state.email,
										tel: this.state.tel,
										corp_name_abbr: this.state.corp_name_abbr,
										org_status: this.props.user.uid_status,
										uid_status: this.state.uid_status,
										try_days: this.state.try_days,
										note: this.state.note,
										q_list: this.state.q_list,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
				this.toggle();
                this.props.fetchB2bUsers(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div>
            <IconButton disabled={this.props.auth!=="write"} onClick={this.toggle}><Create style={{fontSize:'20px'}}/></IconButton>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>修改資料</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="企業名稱"
							value={this.state.corp_name}
                            onChange={e => this.setState({corp_name : e.target.value})}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "corp_name" ? true:null}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="企業名稱縮寫"
							value={this.state.corp_name_abbr}
                            onChange={e => this.setState({corp_name_abbr : e.target.value})}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "corp_name_abbr" ? true:null}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="密碼"
							value={this.state.passwd}
                            onChange={e => this.setState({passwd : e.target.value})}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "passwd" ? true:null}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="地址"
							value={this.state.addr}
                            onChange={e => this.setState({addr : e.target.value})}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "addr" ? true:null}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="聯絡人"
							value={this.state.contact}
                            onChange={e => this.setState({contact : e.target.value})}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "contact" ? true:null}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="聯絡電話"
							placeholder="02-12345678/0912-345678"		value={this.state.tel}
                            onChange={e => this.setState({tel : e.target.value})}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "tel" ? true:false}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="聯絡email"
							value={this.state.email}
                            onChange={e => this.setState({email : e.target.value})}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "email" ? true:null}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="結帳型態"
                            SelectProps={{native: true}}
                            fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            value={this.state.check_type}
                            onChange={e => this.setState({check_type : e.target.value})}
                            error = {this.state.fail_field === "check_type" ? true:null}
                        >
                            <option value="1">月結型</option>
                            <option value="0">預購型</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="帳號狀態"
                            SelectProps={{native: true}}
                            fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            value={this.state.uid_status}
                            onChange={e => this.setState({uid_status : e.target.value})}
                            error = {this.state.fail_field === "uid_status" ? true:null}
                        >
                            <option value="0">一般</option>
                            <option value="1">試用</option>
                            <option value="2">凍結</option>
                            <option value="3">終止</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						{this.state.uid_status==="1"?
						<TextField
							id="standard-name"
							label="試用天數"
							value={this.state.try_days}
                            onChange={e => this.setState({try_days : e.target.value})}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							fullWidth
                            error = {this.state.fail_field === "try_days" ? true:null}
						/>
						:null}
					</Grid>
                    <Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="備註"
                            onChange={e => this.setState({note : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            error = {this.state.fail_field === "note" ? true:null}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.adminInputStyle}>測評名稱及測評單價</Typography>
						<Button onClick={()=>this.setState({add:true,})} style={{...CSS.UGCbtnStyle(false),...{marginLeft:'20px'}}}>新增</Button>
						</div>
						{this.state.add?
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<TextField
							label="測評名稱"
							select
							SelectProps={{native: true}}
							value={this.state.qi_seq}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							onChange={e => this.setState({add_qi_seq : e.target.value, add_qprice:this.state.qdetail.find(q=>e.target.value === q.qi_seq).qprice})}
							error = {this.state.fail_field === "qi_seq" ? true:null}
							style ={{marginRight:'5px'}}
						>
							<option value=""></option>
							{this.state.qdetail.map(q=><option value={q.qi_seq}>{q.qname}</option>)}
						</TextField>
						<TextField
							id="standard-name"
							label="單價"
							value={this.state.add_qprice}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            onChange={e => this.setState({add_qprice : e.target.value})}
						    error = {this.state.fail_field === "add_qprice" ? true:false}
						/>
						<IconButton 
							disabled={this.state.q_list.find(q=>q.qi_seq === this.state.add_qi_seq)!==undefined}
							onClick={ () => 
								this.setState({q_list: this.state.q_list.concat({qi_seq:this.state.add_qi_seq,
																				qprice: this.state.add_qprice,
																				}),
												add: false,
												add_qi_seq: "",
												add_qprice: "",
										})
							}>
							<Done style={{fontSize:'13px'}}/>
						</IconButton>
						<IconButton onClick={()=>this.setState({add:false, add_qi_seq: "", add_qprice: ""})}><Clear style={{fontSize:'13px'}}/></IconButton>
						</div>
						:null}
						{this.state.q_list.length!==0?
							this.state.q_list.map(q=>
								<li style={{display:'flex',alignItems:'center'}}>
									{this.state.qdetail.length!==0 && this.state.qdetail.find(e=>q.qi_seq === e.qi_seq)!== undefined?
										<Typography style={CSS.adminInputStyle}>{"・ "+this.state.qdetail.find(e=>q.qi_seq === e.qi_seq).qname+"  單價："+q.qprice}</Typography>
									:null}
									{this.state.qdetail.length!==0 && this.state.qdetail.find(e=>q.qi_seq === e.qi_seq)!== undefined?
										<IconButton onClick={()=>this.setState({q_list: this.state.q_list.filter(e=>q.qi_seq!==e.qi_seq)})}><Clear style={{fontSize:'13px'}}/></IconButton>
									:null}
								</li>)
						:null}
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.handleSubmit} color="primary" style={CSS.justFont}>
				修改
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class B2BUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = { searchB2bUserData:[{q_str:[],}],
						search_dtype:'ORD',
						search_start:'',
						search_end:'',
						search_name:'',
						search_email:'',
						search_tel:'',
						search_tax_id:'',
						seach_corp_name:'',
						search_qname:'',
						select_type:'',
						textfieldValue:'',
                        b2bUserStatusList:[{}],
                        checkedValues: [],
                        anchorEl: null,
						show_start: "",
						show_end: "",
						page_id: 1,
					};
	}

	componentDidMount() {
        this.fetchB2bUsers();
		this.fetchB2bUserStatus();
	}

	fetchB2bUserStatus = ()=>{
		let send_body = JSON.stringify({ func: 'b2b_user_status_list',
										login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
										corp_name:this.state.search_corp_name,
										tax_id:this.state.search_tax_id,
									});
		fetch(`${api_path}/ugc/misc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({b2bUserStatusList:response.result});
			}
		})
		.catch(error => console.error('Error:', error));
	}
    
    fetchB2bUsers= () => {
		let send_body = JSON.stringify({ func: 'b2b_user_query',
										login_uid: this.props.uid,
										corp_name: this.state.search_corp_name,
										tax_id: this.state.search_tax_id,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2bUserData: response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
                        	});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleResetSearch = () => {
		this.setState({ search_tax_id: "",
						search_corp_name: "",
						select_type: "",
						textfieldValue: "",
					});
		let send_body = JSON.stringify({ func: 'b2b_user_query',
										login_uid: this.props.uid,
										corp_name: "",
										tax_id: "",
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2bUserData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchB2bUserDownload=()=> {
		let send_body = JSON.stringify({func: "b2b_user_download",
										login_uid: this.props.uid,
										start: this.state.show_start,
										end: this.state.show_end,
										dtoken: this.state.dtoken,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleChangePage = page_id => {
		let send_body = JSON.stringify({ func: 'b2b_user_query',
										login_uid: this.props.uid,
										corp_name: this.state.search_corp_name,
										tax_id: this.state.search_tax_id,
										page_id: page_id,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				window.scroll(0,0);
				this.setState({ searchB2bUserData: response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleSelectSearchType = e => {
		if(this.state.select_type === "tax_id"){
			this.setState({ search_tax_id: e.target.value,
							search_corp_name: '',
							textfieldValue: e.target.value,
						});
		}
		else if(this.state.select_type === "corp_name"){
			this.setState({ search_tax_id: '',
							search_corp_name: e.target.value,
							textfieldValue: e.target.value,
						});
		}
	}

    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
    }
    
  	render() {
   	return (
		<div style ={{display:'flex',flexDirection:'column'}}>
            <div style={{display:'flex',textAlign:'right',justifyContent:'flex-start',margin:'5px',alignItems:'flex-end'}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div style={{display:'flex',alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>欄位查詢：</Typography>
                        <TextField
							id="standard-select-currency-native"
							select
							SelectProps={{native: true}}
							value={this.state.select_type} 
							onChange={e=>{this.setState({select_type:e.target.value,textfieldValue:''})}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							style={{margin:'5px'}}
						>
							<option value=""></option>
							<option value="corp_name">企業名稱</option>
							<option value="tax_id">統一編號</option>
						</TextField>
                        <TextField
                            id="standard-name"
                            margin="dense"
                            style={{margin:'5px'}}
                            onChange={this.handleSelectSearchType}
							value={this.state.textfieldValue}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                        />
                    </div>
                </div>
				<div style={{display:'flex',alignItems:'center'}}>
				<SearchBtn onClick={this.fetchB2bUsers}/>
				<ClearSearchBtn onClick={this.handleResetSearch}/>
                <UserAddModal uid={this.props.uid} fetchB2bUsers={this.fetchB2bUsers} auth={this.props.auth}/>
				</div>
			</div>
            <Paper style={{width:'100%',overflowX:'auto',minWidth: "100%"}}>
                <Table>
                    <TableHead><TableRow style={CSS.headRowStyle}>
                        <TableCell align="center" style={CSS.headCellStyle}>
							<IconButton size="small"onClick={e=>this.setState({anchorEl:e.currentTarget})}><ArrowDropDown/></IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={()=>this.setState({anchorEl:null})}
                            >
                                <MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: this.state.searchB2bUserData.map(user => user.uid)})} style={CSS.justFont}>全選</MenuItem>
                                <MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: []})} style={CSS.justFont}>全不選</MenuItem>
                            </Menu>
                        </TableCell>
                        {b2bUserHeadRows.map(e=><TableCell align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
                    </TableRow></TableHead>
                    <TableBody>
                        {this.state.searchB2bUserData.map((user,index)=>
							<TableRow key={user.id} selected={this.state.checkedValues.includes(user.uid)} style={CSS.tablebodyStyle(index)}>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
                                    <Checkbox 
                                        label={user.uid} key={user.uid}
                                        checked={this.state.checkedValues.includes(user.uid)}
                                        onChange={e => this.handleCheck(e,user.uid)}
                                    />
                                </TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.create_dtm}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.vendor_name}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.corp_name}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.corp_name_abbr}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.tax_id}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.passwd}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.check_type==="0"?"預購":null}{user.check_type==="1"?"月結":null}</TableCell>
                                <TableCell align="left" style={CSS.bodyCellStyle}>{user.q_str.map(str=><li>{str}</li>)}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>
                                    <UserEditModal user ={user} uid={this.props.uid} fetchB2bUsers={()=>this.handleChangePage(this.state.page_id)} auth={this.props.auth}/>
                                </TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.addr}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.contact}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.email}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.tel}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{this.state.b2bUserStatusList.find(i=>i.id===parseInt(user.uid_status,10))?this.state.b2bUserStatusList.find(i=>i.id===parseInt(user.uid_status,10)).val:""}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.mod_dtm}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.note}</TableCell>
                            </TableRow>
                        )}
						<TableRow>
							<TableCell colSpan={18} align="left" style={CSS.adminInputStyle}>{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}</TableCell>
						</TableRow>
                    </TableBody>
                </Table>
			</Paper>
			<div style={{display:'flex',justifyContent:'space-between'}}>
				<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
					<Typography style={{...{marginRight:'10px'},...CSS.adminInputStyle}}>已選：{this.state.checkedValues.length} 項</Typography>
					<SendUserModal
						uid={this.props.uid}
						fetchB2bUsers={this.fetchB2bUsers}
						checkedValues={this.state.checkedValues}
						auth={this.props.auth}
					/>
					<MsgBtn 
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(false)}}
						send_body={JSON.stringify({ func: "b2b_user_download",
													login_uid: this.props.uid,
													start: this.state.show_start,
													end: this.state.show_end,
													dtoken: this.state.dtoken,
											})
									}
						btn_name="下載"
					/>
					<CheckAgainMsgBtn
						disabled={this.state.checkedValues.length===0||this.props.auth!=="write"}
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(this.state.checkedValues.length===0||this.props.auth!=="write")}}
						send_body={JSON.stringify({ func: "b2b_user_del",
													login_uid: this.props.uid,
													txid_list: this.state.checkedValues,
											})
									}
						btn_name="刪除"
						check_msg="與所選客戶相關的訂單，銷售紀錄等相關資料也將一並刪除，確認刪除？"
						succProcess={()=>this.handleChangePage(this.state.page_id)}
					/>
				</div>
				<div style={{display:'flex',margin:'20px'}}>
				<Button size="large" disabled={this.state.pre_page===""} onClick={()=>this.handleChangePage(this.state.pre_page)} style={CSS.justFont}>上一頁</Button>
				<Button size="large" disabled={this.state.nxt_page===""} onClick={()=>this.handleChangePage(this.state.nxt_page)} style={CSS.justFont}>下一頁</Button>
				</div>
			</div>
        </div>
	);
	}
}

export default B2BUser;