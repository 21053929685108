import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import {UGCblue} from '../../environment';
import logo3 from '../../pics/logo3.png';

class BottomBar extends Component {
    render() {
        return (
            <div style={{width:'100%',position:'fixed',height:'10vh',top:'90vh',display:'flex',alignItems:'center',flexDirection:'row',justifyContent:'center'}}>
                <img src={logo3} alt="Logo3" style={{height:'2.56vh'}}/>
                <Typography style={{marginLeft:'10px', color:UGCblue,fontSize:'1.15vh'}}>{" ⓒ 2019 UGC. all rights reserved."}</Typography>
            </div>
        );
	}
}

export default BottomBar;