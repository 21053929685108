import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Information from '../components/b2c/information';
import Buy from '../components/b2c/buy';
import AssessMgt from '../components/b2c/assessMgt';
import { UGCblue } from '../../environment';

import * as CSS from '../../css';


const b2c_button = ["已購買測評","會員資料","測評加購"];

class RenderB2c extends Component {
    constructor(props){  
        super(props);  
        this.state = {func_now : "已購買測評"};  
    
    }

	render() {
		return (
            <div style = {{ textAlign:'center', width:'100%' }}>
                <AppBar position="sticky" color="default" 
                        style={{position:'fixed', top:'65px', verticalAlign:'middle', minHeight:'55px', background:'white'}} >
                    <Toolbar style={{ verticalAlign:'middle', overflowX:'auto'}}>
                    {b2c_button.map(func_name => 
                        <Button key={func_name} size="large" onClick={e=>this.setState({func_now:e.target.innerText})}
                                style={CSS.appbarBtnStyle(func_name,this.state.func_now)}
                        >{func_name}</Button>
                    )}
                    </Toolbar>
                </AppBar>

                <div style ={{width:'100%', textAlign:'center'}}>
                {this.state.func_now === "會員資料" ?
                    <Information uid={this.props.uid}/>:null
                }
                {this.state.func_now === "已購買測評" ?
                    <AssessMgt uid={this.props.uid}/>:null
                }
                {this.state.func_now === "測評加購" ?
                    <Buy uid={this.props.uid}/>:null
                }
                {this.state.func_now === "" ?
                    null:null
                }
                </div>
            </div>
		);
	}
}

export default RenderB2c;