import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Create from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import ReactFileReader from 'react-file-reader';
import FileCopy from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as CSS from '../../../css.js';
import {api_path,cellStyle} from '../../../environment';

const ScenarioMiddle= `《自動登入網址》`;

const LetterMiddle= `系統網址：http://assessment.ugcareer.com/
登入帳號：《統編》
登入密碼：《企客密碼》
試用期限：《試用期限》`;

class ScenarioShowModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						qdetail:[],
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						qi_seq: "",
						q_time_limit: "",
						q_delta: "",
						tp_notice: "",
						// tp_image: "",
						is_stu: "",
						pr_type: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal) {
			this.fetchTpDetail();
			this.fetchQquery();
			// this.fetchTpImg();
		}
		this.setState({ modal: !this.state.modal });
	}
	// fetchTpImg = () => {
	// 	let send_body = JSON.stringify({ func: 'get_tp_image',
	// 									login_uid: this.props.uid,
	// 									tp_seq: this.props.tp.tp_seq,
	// 								});
    //     fetch(`${api_path}/ugc/ugc_mgt.php`, {
    //             method: 'POST',
    //             body: send_body,
    //             headers: new Headers(),
    //     })
    //     .then(res => res.json())
    //     .then(response => {
    //         if(response.status==="SUCC"){
    //             this.setState({ tp_image: response.result[0].tp_image,});
    //         }
    //         else if(response.status==="FAIL"){
    //             this.setState({fail_field:response.fail_field, fail_msg:response.msg});
    //         }
    //     })
    //     .catch(error => console.error('Error:', error));
	// }

	fetchTpDetail = () => {
		let send_body = JSON.stringify({ func: 'get_tp_detail',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ tp_name: response.result[0].tp_name,
								tp_begin: response.result[0].tp_begin,
								tp_end: response.result[0].tp_end,
								tp_contact: response.result[0].tp_contact,
								tp_subject: response.result[0].tp_subject,
								qname: response.result[0].qname,
								qi_seq: response.result[0].qi_seq,
								q_time_limit: response.result[0].q_time_limit,
								q_delta: response.result[0].q_delta,
								tp_notice: response.result[0].tp_notice,
								// tp_image: response.result[0].tp_image,
								is_stu: response.result[0].is_stu,
								pr_type: response.result[0].pr_type,
							});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	fetchQquery= () => {
        let send_body = JSON.stringify({ func: 'q_query', login_uid: this.props.uid});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qdetail:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	render() {
	return (
		<div>
			<Button size="large" onClick={this.toggle} style={{...{fontSize:'18px'},...CSS.justFont}}>{this.props.tp.tp_name}</Button>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>{this.props.tp.tp_name}</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>施測情境名稱：{this.state.tp_name}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>受測者身份：{this.state.is_stu?"學生":"社會人士"}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>測評名稱：{this.state.qname}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>施測時限：{this.state.q_time_limit}分鐘</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>受測截止日： 發信日 ＋ {this.state.q_delta} 日</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>比對常模：{this.state.pr_type==="0"?"全部":this.state.pr_type==="1"?"同單位":this.state.pr_type==="2"?"同公司":null}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>測評開通信件樣式：</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Paper elevation="5" square>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12} >
								<Typography noWrap style={CSS.justFont}>主旨： {this.state.tp_subject}</Typography>
							</Grid>
						</Grid>
						<Divider variant="ScenarioMiddle" style={{margin:'5px'}}/>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12}>
								<Typography style={CSS.justFont}>《消費者姓名》，您好！</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{this.state.tp_begin}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{ScenarioMiddle}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{this.state.tp_end}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{this.state.tp_contact}</Typography>
							</Grid>
						</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>測評說明：</Typography>
						<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{this.state.tp_notice}</Typography>
					</Grid>
					{/* <Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>測評說明圖示：</Typography>
						{this.state.tp_image!==null?
							<img alt=""src={`${this.state.tp_image}`} style = {{maxWidth:'200px', margin:'5px'}}/>
						:null}
					</Grid> */}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				關閉
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ScenarioEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						qdetail: [],
						// tp_image: undefined,
						qi_seq: "",
						q_time_limit: "",
						q_delta: "",
						tp_notice: "",
						is_stu: null,
						fail_field: "",
						fail_msg: "",
						pr_type: "",
						isRunning: false,
						stuprList:[],
						prList:[],
						status:"fillin",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal) {
			this.fetchTpDetail();
			this.fetchQquery();
			// this.fetchTpImg();
			this.fetchPRList();
			this.fetchStuPRList();
			this.setState({isRunning: false});
		}
		this.setState({ modal: !this.state.modal,
						fail_field: "",
						fail_msg: "",
						status:"fillin",
					});
	}
	// fetchTpImg = () => {
	// 	let send_body = JSON.stringify({ func: 'get_tp_image',
	// 									login_uid: this.props.uid,
	// 									tp_seq: this.props.tp.tp_seq,
	// 								});
    //     fetch(`${api_path}/ugc/ugc_mgt.php`, {
    //             method: 'POST',
    //             body: send_body,
    //             headers: new Headers(),
    //     })
    //     .then(res => res.json())
    //     .then(response => {
    //         if(response.status==="SUCC"){
    //             this.setState({ tp_image: response.result[0].tp_image,});
    //         }
    //         else if(response.status==="FAIL"){
    //             this.setState({fail_field:response.fail_field, fail_msg:response.msg});
    //         }
    //     })
    //     .catch(error => console.error('Error:', error));
	// }
	fetchTpDetail = () => {
		let send_body = JSON.stringify({ func: 'get_tp_detail',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ tp_name: response.result[0].tp_name,
								tp_begin: response.result[0].tp_begin,
								tp_end: response.result[0].tp_end,
								tp_contact: response.result[0].tp_contact,
								tp_subject: response.result[0].tp_subject,
								qi_seq: response.result[0].qi_seq,
								q_time_limit: response.result[0].q_time_limit,
								q_delta: response.result[0].q_delta,
								tp_notice: response.result[0].tp_notice,
								// tp_image: response.result[0].tp_image,
								is_stu: response.result[0].is_stu?"true":"false",
								pr_type: response.result[0].pr_type,
							});
            }
            else if(response.status==="FAIL"){
				this.setState({ fail_field:response.fail_field,
								fail_msg:response.msg,
								status:"fillin",
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchPRList = () => {
		let send_body = JSON.stringify({ func:'get_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ prList:response.result,
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	fetchStuPRList = () => {
		let send_body = JSON.stringify({ func:'get_stu_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ stuprList:response.result,
							});
            }
        })
		.catch(error => console.error('Error:', error));
	}
	fetchQquery= () => {
        let send_body = JSON.stringify({ func: 'q_query' , login_uid: this.props.uid,});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qdetail:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
    }
	fetchTpMod = () => {
		let send_body = JSON.stringify({ func:'mod_tp',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
										tp_name: this.state.tp_name,
										tp_begin: this.state.tp_begin,
										tp_end: this.state.tp_end,
										tp_contact: this.state.tp_contact,
										tp_subject: this.state.tp_subject,
										qi_seq: this.state.qi_seq,
										q_time_limit: this.state.q_time_limit,
										q_delta: this.state.q_delta,
										tp_notice: this.state.tp_notice,
										// tp_image: this.state.tp_image,
										is_stu: this.state.is_stu,
										tp_type: '0',
										pr_type: this.state.pr_type,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({ fail_field:response.fail_field,
								fail_msg:response.msg,
								status:"fillin",
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	handleFiles = file => {
		this.setState({tp_image:file.base64});
	}

	render() {
	return (
		<div>
			<IconButton size="large" disabled={this.props.auth!=="write"} onClick={this.toggle}><Create/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯 {this.state.tp_name} 施測情境</Typography></DialogTitle>
				<DialogContent>
				{this.state.status==="loading"?
					<div style={{display:'flex',width:'100%',justifyContent:'center'}}>
					<CircularProgress/>
					</div>
				:
				this.state.status==="fillin"?
					<DialogContentText id="alert-dialog-description">
					<Grid container spacing={2}>
						{this.state.fail_msg!==""?
						<Grid item xs={12} sm={12}>
							<Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
						</Grid>
						:null}
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',alignItems:'baseline'}}> 
							<Typography style={CSS.justFont}>施測情境名稱：</Typography>
							<TextField
								value={this.state.tp_name}
								InputProps={{style:CSS.adminScenarioInputStyle}}
								InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
								onChange={e=>this.setState({tp_name:e.target.value})}
								error = {this.state.fail_field === "tp_name" ? true:null}
							/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',alignItems:'baseline'}}> 
							<Typography style={CSS.justFont}>受測者身份：</Typography>
							<TextField
								select
								SelectProps={{native: true}}
								value={this.state.is_stu}
								InputProps={{style:CSS.adminScenarioInputStyle}}
								InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
								onChange={e => this.setState({is_stu : e.target.value})}
								error = {this.state.fail_field === "is_stu" ? true:null}
							>
								<option value={null}></option>
								<option value={true}>學生</option>
								<option value={false}>社會人士</option>
							</TextField>
							</div>
						</Grid>
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',alignItems:'baseline'}}> 
							<Typography style={CSS.justFont}>測評名稱：</Typography>
							<TextField
								select
								SelectProps={{native: true}}
								value={this.state.qi_seq}
								InputProps={{style:CSS.adminScenarioInputStyle}}
								InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
								onChange={e => this.setState({qi_seq : e.target.value})}
								error = {this.state.fail_field === "qi_seq" ? true:null}
							>
								<option value=""></option>
								{this.state.is_stu==="true" || this.state.is_stu === true?
								this.state.qdetail.filter(q=>q.is_stu===true).map(q=><option value={q.qi_seq}>{q.qname}</option>)
								:
								null}
								{this.state.is_stu==="false" || this.state.is_stu === false?
								this.state.qdetail.filter(q=>q.is_stu===false).map(q=><option value={q.qi_seq}>{q.qname}</option>)
								:null}
							</TextField>
							</div>
						</Grid>
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',alignItems:'baseline'}}> 
							<Typography style={CSS.justFont}>施測時限：</Typography>
							<TextField
								value={this.state.q_time_limit}
								onChange={e=>this.setState({q_time_limit:e.target.value})}
								style={{width:'80px'}}
								InputProps={{style:CSS.adminScenarioInputStyle}}
								InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
								error = {this.state.fail_field === "q_time_limit" ? true:null}
							/>
							<Typography style={CSS.justFont}>分鐘</Typography>
							</div>
						</Grid>
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',alignItems:'baseline'}}> 
							<Typography style={CSS.justFont}>受測截止日： 發信日 ＋ </Typography>
							<TextField
								value={this.state.q_delta}
								onChange={e=>this.setState({q_delta:e.target.value})}
								style={{width:'40px'}}
								InputProps={{style:CSS.adminScenarioInputStyle}}
								InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
								error = {this.state.fail_field === "q_delta" ? true:null}
							/>
							<Typography style={CSS.justFont}>日</Typography>
							</div>
						</Grid>
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',alignItems:'baseline'}}> 
							<Typography style={CSS.justFont}>比對常模：</Typography>
							<TextField
								select
								SelectProps={{native: true}}
								value={this.state.pr_type}
								InputProps={{style:CSS.adminScenarioInputStyle}}
								InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
								onChange={e => this.setState({pr_type : e.target.value})}
								error = {this.state.fail_field === "pr_type" ? true:null}
							>
								<option value=""></option>
							{this.state.is_stu==="true"?this.state.stuprList.map(e=><option value={e.id}>{e.val}</option>):null}
							{this.state.is_stu==="false"?this.state.prList.map(e=><option value={e.id}>{e.val}</option>):null}
							</TextField>
							</div>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography style={CSS.justFont}>測評開通信件樣式：</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Paper elevation="5" square>
							<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
								<Grid item xs={12} sm={12} >
									<div style={{display:'flex',alignItems:'baseline'}}> 
									<Typography noWrap style={CSS.justFont}>主旨：</Typography>
									<TextField
										variant="outlined"
										value={this.state.tp_subject}
										onChange={e=>this.setState({tp_subject:e.target.value})}
										style={{width:'70%'}}
										margin="dense"
										InputProps={{style:CSS.adminScenarioInputStyle}}
										InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
										error = {this.state.fail_field === "tp_subject" ? true:null}
									/>
									</div>
								</Grid>
							</Grid>
							<Divider variant="ScenarioMiddle" style={{margin:'5px'}}/>
							<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
								<Grid item xs={12} sm={12}>
									<Typography style={CSS.justFont}>《消費者姓名》，您好！</Typography>
								</Grid>
								<Grid item xs={12} sm={12}>
									<TextField
										variant="outlined"
										multiline
										label="《信件開頭》"
										value={this.state.tp_begin}
										onChange={e=>this.setState({tp_begin:e.target.value})}
										fullWidth
										InputProps={{style:CSS.adminScenarioInputStyle}}
										InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
										error = {this.state.fail_field === "tp_begin" ? true:null}
									/>
								</Grid>
								<Grid item xs={12} sm={12}>
									<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{ScenarioMiddle}</Typography>
								</Grid>
								<Grid item xs={12} sm={12}>
									<TextField
										variant="outlined"
										multiline
										label="《信件結尾》"
										value={this.state.tp_end}
										onChange={e=>this.setState({tp_end:e.target.value})}
										fullWidth
										InputProps={{style:CSS.adminScenarioInputStyle}}
										InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
										error = {this.state.fail_field === "tp_end" ? true:null}
									/>
								</Grid>
								<Grid item xs={12} sm={12}>
									<TextField
										variant="outlined"
										multiline
										label="《HR聯絡人資訊》"
										value={this.state.tp_contact}
										onChange={e=>this.setState({tp_contact:e.target.value})}
										fullWidth
										InputProps={{style:CSS.adminScenarioInputStyle}}
										InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
										error = {this.state.fail_field === "tp_contact" ? true:null}
									/>
								</Grid>
							</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography style={CSS.justFont}>測評說明：</Typography>
							<TextField
								margin="normal"
								variant="outlined"
								multiline
								value={this.state.tp_notice}
								onChange={e=>this.setState({tp_notice:e.target.value})}
								fullWidth
								InputProps={{style:CSS.adminScenarioInputStyle}}
								InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
								error = {this.state.fail_field === "tp_notice" ? true:null}
							/>
						</Grid>
						{/* <Grid item xs={12} sm={12}>
							<div style={{display:'flex',alignItems:'baseline'}}> 
							<Typography style={CSS.justFont}>測評說明圖示：</Typography>
							<ReactFileReader fileTypes={[".png"]} base64={true} handleFiles={this.handleFiles}>
								<Button style={{marginLeft:'20px'}} style={CSS.UGCbtnStyle(false)}>上傳</Button>
							</ReactFileReader>
							</div>
							{this.state.tp_image!==undefined?
							<img alt=""src={`${this.state.tp_image}`} style = {{maxWidth:'200px', margin:'5px'}}/>
							:
							<div style={{width:'200px', textAlign:'center', verticalAlign:'middle'}}>
								<CircularProgress style={{margin:'0 auto'}}/>
							</div>
							}
						</Grid> */}
					</Grid>
					</DialogContentText>
				:null}
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={()=>{this.setState({status:"loading"}); this.fetchTpMod();}} 
						color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ScenarioCopyModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tp_name: "",
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		if(!this.state.modal) {
			this.setState({ tp_name: this.props.tp.tp_name+'(複製)'})
		}
		this.setState({ modal: !this.state.modal,
						fail_field: "",
						fail_msg: "",
					});
	}

	fetchTpCpy = () => {
		let send_body = JSON.stringify({ func:'tp_cpy',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
										tp_name: this.state.tp_name,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleFiles = file => {
		this.setState({tp_image:file.base64});
	}

	render() {
	return (
		<div>
			<IconButton size="large" disabled={this.props.auth!=="write"} onClick={this.toggle}><FileCopy/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>複製 {this.props.tp.tp_name} 施測情境</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>新施測情境名稱：</Typography>
						<TextField
							value={this.state.tp_name}
							onChange={e=>this.setState({tp_name:e.target.value})}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            error = {this.state.fail_field === "tp_name" ? true:null}
						/>
						</div>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpCpy} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ScenarioAddModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						qdetail: [],
						// tp_image: null,
						qi_seq: "",
						q_time_limit: "",
						q_delta: "",
						tp_notice: "",
						is_stu:null,
						fail_field: "",
						fail_msg: "",
						pr_type: "",
						stuprList:[],
						prList:[],
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						qdetail: [],
						// tp_image: null,
						qi_seq: "",
						q_time_limit: "",
						q_delta: "",
						tp_notice: "",
						is_stu:null,
						fail_field: "",
						fail_msg: "",
						pr_type: "",
						stuprList:[],
						prList:[],
					});

		if(!this.state.modal) { 
			this.fetchQquery();
			this.fetchPRList();
			this.fetchStuPRList(); 
		}
	}

	fetchPRList = () => {
		let send_body = JSON.stringify({ func:'get_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ prList:response.result,
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchStuPRList = () => {
		let send_body = JSON.stringify({ func:'get_stu_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ stuprList:response.result,
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}

	fetchQquery= () => {
        let send_body = JSON.stringify({ func: 'q_query', login_uid: this.props.uid,});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qdetail:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
    }

	handleFiles = file => {
		this.setState({tp_image:file.base64});
	}

	fetchTpIns = () => {
		let send_body = JSON.stringify({ func:'ins_tp',
										login_uid: this.props.uid,
										tp_name: this.state.tp_name,
										tp_begin: this.state.tp_begin,
										tp_end: this.state.tp_end,
										tp_contact: this.state.tp_contact,
										tp_subject: this.state.tp_subject,
										qi_seq: this.state.qi_seq,
										q_time_limit: this.state.q_time_limit,
										q_delta: this.state.q_delta,
										tp_notice: this.state.tp_notice,
										// tp_image: this.state.tp_image,
										is_stu: this.state.is_stu,
										tp_type: "0",
										pr_type: this.state.pr_type,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<Button onClick={this.toggle} disabled={this.props.auth!=="write"} style={{...CSS.UGCbtnStyle(this.props.auth!=="write"),...{margin:'20px'}}}>新增施測情境</Button>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>新增施測情境</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>施測情境名稱：</Typography>
						<TextField
							value={this.state.tp_name}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
							onChange={e=>this.setState({tp_name:e.target.value})}
                            error = {this.state.fail_field === "tp_name" ? true:null}
						/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>受測者身份：</Typography>
						<TextField
                            select
							SelectProps={{native: true}}
                            value={this.state.is_stu}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState({is_stu : e.target.value})}
                            error = {this.state.fail_field === "is_stu" ? true:null}
                        >
							<option value={null}></option>
                            <option value={true}>學生</option>
                            <option value={false}>社會人士</option>
                        </TextField>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>測評名稱：</Typography>
						<TextField
                            select
                            SelectProps={{native: true}}
                            value={this.state.qi_seq}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
							onChange={e => this.setState({qi_seq : e.target.value})}
                            error = {this.state.fail_field === "qi_seq" ? true:null}
                        >
							<option value=""></option>
							{this.state.is_stu==="true" || this.state.is_stu === true?
							this.state.qdetail.filter(q=>q.is_stu===true).map(q=><option value={q.qi_seq}>{q.qname}</option>)
							:
							null}
							{this.state.is_stu==="false" || this.state.is_stu === false?
							this.state.qdetail.filter(q=>q.is_stu===false).map(q=><option value={q.qi_seq}>{q.qname}</option>)
							:null}
                        </TextField>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>施測時限：</Typography>
						<TextField
							value={this.state.q_time_limit}
							onChange={e=>this.setState({q_time_limit:e.target.value})}
							style={{width:'80px'}}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            error = {this.state.fail_field === "q_time_limit" ? true:null}
						/>
						<Typography style={CSS.justFont}>分鐘</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>受測截止日： 發信日 ＋ </Typography>
						<TextField
							value={this.state.q_delta}
							onChange={e=>this.setState({q_delta:e.target.value})}
							style={{width:'40px'}}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            error = {this.state.fail_field === "q_delta" ? true:null}
						/>
						<Typography style={CSS.justFont}>日</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>比對常模：</Typography>
						<TextField
                            select
							SelectProps={{native: true}}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            value={this.state.pr_type}
                            onChange={e => this.setState({pr_type : e.target.value})}
                            error = {this.state.fail_field === "pr_type" ? true:null}
                        >
							<option value=""></option>
							{this.state.is_stu==="true"?this.state.stuprList.map(e=><option value={e.id}>{e.val}</option>):null}
							{this.state.is_stu==="false"?this.state.prList.map(e=><option value={e.id}>{e.val}</option>):null}
                        </TextField>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>測評開通信件樣式：</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Paper elevation="5" square>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12} >
								<div style={{display:'flex',alignItems:'baseline'}}> 
								<Typography noWrap style={CSS.justFont}>主旨：</Typography>
								<TextField
									variant="outlined"
									value={this.state.tp_subject}
									onChange={e=>this.setState({tp_subject:e.target.value})}
									style={{width:'70%'}}
									margin="dense"
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									error = {this.state.fail_field === "tp_subject" ? true:null}
								/>
								</div>
							</Grid>
						</Grid>
						<Divider variant="ScenarioMiddle" style={{margin:'5px'}}/>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12}>
								<Typography style={CSS.justFont}>《消費者姓名》，您好！</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《信件開頭》"
									value={this.state.tp_begin}
									onChange={e=>this.setState({tp_begin:e.target.value})}
									fullWidth
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									error = {this.state.fail_field === "tp_begin" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{ScenarioMiddle}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《信件結尾》"
									value={this.state.tp_end}
									onChange={e=>this.setState({tp_end:e.target.value})}
									fullWidth
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									error = {this.state.fail_field === "tp_end" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《HR聯絡人資訊》"
									value={this.state.tp_contact}
									onChange={e=>this.setState({tp_contact:e.target.value})}
									fullWidth
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									error = {this.state.fail_field === "tp_contact" ? true:null}
								/>
							</Grid>
						</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>測評說明：</Typography>
						<TextField
							margin="normal"
							variant="outlined"
							multiline
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
							value={this.state.tp_notice}
							onChange={e=>this.setState({tp_notice:e.target.value})}
							fullWidth
                            error = {this.state.fail_field === "tp_notice" ? true:null}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>測評說明圖示：</Typography>
						<ReactFileReader fileTypes={[".png"]} base64={true} handleFiles={this.handleFiles}>
							<Button style={{...{marginLeft:'20px'},...CSS.UGCbtnStyle(false)}}>上傳</Button>
						</ReactFileReader>
						</div>
						{this.state.tp_image!==null?
							<img alt=""src={`${this.state.tp_image}`} style = {{maxWidth:'200px', margin:'5px'}}/>
						:null}
					</Grid> */}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpIns} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ScenarioDelModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal });
	}

	fetchTpDel = () => {
		let send_body = JSON.stringify({ func:'del_tp',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<IconButton size="large" disabled={this.props.auth!=="write"} onClick={this.toggle}><Delete/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>刪除 {this.props.tp.tp_name} 施測情境</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Typography style={CSS.justFont}>
				確定刪除 {this.props.tp.tp_name} 施測情境嗎？
				</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpDel} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class LetterShowModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						qdetail:[],
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal) {this.fetchTpDetail();}
		this.setState({ modal: !this.state.modal });
	}
	fetchTpDetail = () => {
		let send_body = JSON.stringify({ func: 'get_tp_detail',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ tp_name: response.result[0].tp_name,
								tp_begin: response.result[0].tp_begin,
								tp_end: response.result[0].tp_end,
								tp_contact: response.result[0].tp_contact,
								tp_subject: response.result[0].tp_subject,
								qi_seq: response.result[0].qi_seq,
								q_time_limit: response.result[0].q_time_limit,
								q_delta: response.result[0].q_delta,
								tp_notice: response.result[0].tp_notice,
								// tp_image: response.result[0].tp_image,
								is_stu: response.result[0].is_stu
							});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<Button size="large" onClick={this.toggle} style={{...{fontSize:'18px'},...CSS.justFont}}>{this.props.tp.tp_name}</Button>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>{this.props.tp.tp_name}</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>信件樣式名稱：{this.props.tp.tp_name}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>信件樣式內容：</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Paper elevation="5" square>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12} >
								<Typography noWrap style={CSS.justFont}>主旨： {this.state.tp_subject}</Typography>
							</Grid>
						</Grid>
						<Divider variant="middle" style={{margin:'5px'}}/>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12}>
								<Typography style={CSS.justFont}>《主聯絡人》，您好！</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{this.state.tp_begin}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{LetterMiddle}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{this.state.tp_end}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{this.state.tp_contact}</Typography>
							</Grid>
						</Grid>
						</Paper>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				關閉
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class LetterEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal) {this.fetchTpDetail();}
		this.setState({ modal: !this.state.modal,
						tp_name: this.props.tp.tp_name,
						fail_field: "",
						fail_msg: "",
					});
	}
	fetchTpDetail = () => {
		let send_body = JSON.stringify({ func: 'get_tp_detail',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ tp_name: response.result[0].tp_name,
								tp_begin: response.result[0].tp_begin,
								tp_end: response.result[0].tp_end,
								tp_contact: response.result[0].tp_contact,
								tp_subject: response.result[0].tp_subject,
								qi_seq: response.result[0].qi_seq,
								q_time_limit: response.result[0].q_time_limit,
								q_delta: response.result[0].q_delta,
								tp_notice: response.result[0].tp_notice,
								// tp_image: response.result[0].tp_image,
								is_stu: response.result[0].is_stu,
							});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
		.catch(error => console.error('Error:', error));
	}
	fetchTpMod = () => {
		let send_body = JSON.stringify({ func:'mod_tp',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
										tp_name: this.state.tp_name,
										tp_begin: this.state.tp_begin,
										tp_end: this.state.tp_end,
										tp_contact: this.state.tp_contact,
										tp_subject: this.state.tp_subject,
										tp_type: '1',
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<IconButton size="large" disabled={this.props.auth!=="write"} onClick={this.toggle}><Create/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯 {this.props.tp.tp_name} 樣式</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>信件樣式名稱：</Typography>
						<TextField
							value={this.state.tp_name}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
							onChange={e=>this.setState({tp_name:e.target.value})}
                            error = {this.state.fail_field === "tp_name" ? true:null}
						/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>信件樣式內容：</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Paper elevation="5" square>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12} >
								<div style={{display:'flex',alignItems:'baseline'}}> 
								<Typography noWrap style={CSS.justFont}>主旨：</Typography>
								<TextField
									variant="outlined"
									value={this.state.tp_subject}
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									onChange={e=>this.setState({tp_subject:e.target.value})}
									style={{width:'70%'}}
									margin="dense"
									error = {this.state.fail_field === "tp_subject" ? true:null}
								/>
								</div>
							</Grid>
						</Grid>
						<Divider variant="middle" style={{margin:'5px'}}/>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12}>
								<Typography style={CSS.justFont}>《主聯絡人》，您好！</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《信件開頭》"
									value={this.state.tp_begin}
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									onChange={e=>this.setState({tp_begin:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_begin" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{LetterMiddle}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《信件結尾》"
									value={this.state.tp_end}
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									onChange={e=>this.setState({tp_end:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_end" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《HR聯絡人資訊》"
									value={this.state.tp_contact}
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									onChange={e=>this.setState({tp_contact:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_contact" ? true:null}
								/>
							</Grid>
						</Grid>
						</Paper>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpMod} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class LetterAddModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						fail_field: "",
						fail_msg: "",
					});
	}

	fetchTpIns = () => {
		let send_body = JSON.stringify({ func:'ins_tp',
										login_uid: this.props.uid,
										tp_name: this.state.tp_name,
										tp_begin: this.state.tp_begin,
										tp_end: this.state.tp_end,
										tp_contact: this.state.tp_contact,
										tp_subject: this.state.tp_subject,
										tp_type: '1',
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button onClick={this.toggle} disabled={this.props.auth!=="write"} style={{...CSS.UGCbtnStyle(this.props.auth!=="write"),...{margin:'20px'}}}>新增信件樣式</Button>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>新增信件樣式</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>信件樣式名稱：</Typography>
						<TextField
							value={this.state.tp_name}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
							onChange={e=>this.setState({tp_name:e.target.value})}
                            error = {this.state.fail_field === "tp_name" ? true:null}
						/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={CSS.justFont}>信件樣式內容：</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Paper elevation="5" square>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12} >
								<div style={{display:'flex',alignItems:'baseline'}}> 
								<Typography noWrap style={CSS.justFont}>主旨：</Typography>
								<TextField
									variant="outlined"
									value={this.state.tp_subject}
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									onChange={e=>this.setState({tp_subject:e.target.value})}
									style={{width:'70%'}}
									margin="dense"
									error = {this.state.fail_field === "tp_subject" ? true:null}
								/>
								</div>
							</Grid>
						</Grid>
						<Divider variant="middle" style={{margin:'5px'}}/>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12}>
								<Typography style={CSS.justFont}>《主聯絡人》，您好！</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《信件開頭》"
									value={this.state.tp_begin}
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									onChange={e=>this.setState({tp_begin:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_begin" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{...{whiteSpace:'pre-line'},...CSS.justFont}}>{LetterMiddle}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《信件結尾》"
									value={this.state.tp_end}
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									onChange={e=>this.setState({tp_end:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_end" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《HR聯絡人資訊》"
									value={this.state.tp_contact}
									InputProps={{style:CSS.adminScenarioInputStyle}}
									InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
									onChange={e=>this.setState({tp_contact:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_contact" ? true:null}
								/>
							</Grid>
						</Grid>
						</Paper>
					</Grid>
					
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpIns} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class LetterDelModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
					});
	}
	fetchTpDel = () => {
		let send_body = JSON.stringify({ func:'del_tp',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<IconButton size="large" disabled={this.props.auth!=="write"} onClick={this.toggle}><Delete/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>刪除 {this.props.tp.tp_name} 樣式</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Typography style={CSS.justFont}>
				確定刪除 {this.props.tp.tp_name} 樣式嗎？
				</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpDel} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class LetterCopyModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tp_name: "",
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		if(!this.state.modal) {
			this.setState({ tp_name: this.props.tp.tp_name+'(複製)'})
		}
		this.setState({ modal: !this.state.modal,
						fail_field: "",
						fail_msg: "",
					});
	}

	fetchTpCpy = () => {
		let send_body = JSON.stringify({ func:'tp_cpy',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
										tp_name: this.state.tp_name,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleFiles = file => {
		this.setState({tp_image:file.base64});
	}

	render() {
	return (
		<div>
			<IconButton size="large" disabled={this.props.auth!=="write"} onClick={this.toggle}><FileCopy/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>複製 {this.props.tp.tp_name} 信件樣式</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={CSS.justFont}>新信件樣式名稱：</Typography>
						<TextField
							value={this.state.tp_name}
							onChange={e=>this.setState({tp_name:e.target.value})}
							InputProps={{style:CSS.adminScenarioInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            error = {this.state.fail_field === "tp_name" ? true:null}
						/>
						</div>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpCpy} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isAddressChange: false,
						corp_data: { contact:[{}],},
						editAddr: "",
						tpList: [],
						img64: "",
					};
	}
	componentDidMount() {
		this.fetchTpList();
		this.fetchLogoPath();
	}

	fetchTpList = () => {
		let send_body = JSON.stringify({ func: "get_template_list",
										login_uid: this.props.uid,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({tpList:response.result});
			}
			else if(response.status==="FAIL"){}
		})
		.catch(error => console.error('Error:', error));
	}
	
	handleFiles = (file) => {
		let send_body = JSON.stringify({ func: 'corp_logo_upload',
										login_uid: this.props.uid,
                                        file: file.base64,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
            })
        .then(res => res.json())
        .then(response => {
			if(response.status==="SUCC"){
				alert("上傳成功");
				this.fetchLogoPath();
			}
		})
        .catch(error => console.error('Error:', error));
	}

	fetchLogoPath = () => {
		let send_body = JSON.stringify({ func:'get_logo_path',
										login_uid:this.props.uid,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({img64:response.img64});
			}
			else if(response.status==="FAIL"){
				this.setState({img64:""});
			}
		})
		.catch(error => console.error('Error:', error));
	}
    
	render() {
	
    return(
	<div style={{maxWidth:'750px', width:'100%',textAlign:'left',margin: '0 auto'}}>
		<Grid container spacing={2} alignItems="baseline">
			<Grid item xs={12} sm={12}>
			<div style ={{display:'flex', textAlign:'left', alignItems:'baseline' }}>
				<Typography gutterBottom variant="h4" style={{...CSS.justFont,...{marginTop:'20px'}}}>施測情境管理</Typography>
				<ScenarioAddModal uid={this.props.uid} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
			</div>
			</Grid>
			<Grid item xs={12} sm={6}>
				{this.state.tpList.filter(tp=>tp.tp_type==="0").map(tp=>
				<div style={{display:'flex'}}>
					<ScenarioShowModal uid={this.props.uid} tp={tp}/>
					<ScenarioEditModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
					<ScenarioCopyModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
					<ScenarioDelModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
				</div>)}
			</Grid>
			<Grid item xs={12} sm={12}>
			<div style ={{display:'flex', textAlign:'left', alignItems:'baseline' }}>
				<Typography gutterBottom variant="h4" style={{...CSS.justFont,...{marginTop:'20px'}}}>信件樣式管理</Typography>
				<LetterAddModal uid={this.props.uid} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
			</div>
			</Grid>
			<Grid item xs={12} sm={6}>
				{this.state.tpList.filter(tp=>tp.tp_type==="1").map(tp=>
				<div style={{display:'flex'}}>
					<LetterShowModal uid={this.props.uid} tp={tp}/>
					<LetterEditModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
					<LetterCopyModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
					<LetterDelModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
				</div>)}
			</Grid>
			{/* <Grid item xs={12} sm={12}>
				<div style ={{display:'flex', alignItems:'baseline'}}>
				<Typography gutterBottom variant="h4" style={{...CSS.justFont,...{marginTop:'20px'}}}>Logo 管理</Typography>
				<ReactFileReader fileTypes={[".png"]} base64={true} handleFiles={this.handleFiles}>
					<Button disabled={this.props.auth!=="write"} style={{...CSS.UGCbtnStyle(this.props.auth!=="write"),...{marginLeft:'20px'}}}>上傳</Button>
				</ReactFileReader>
				</div>
			</Grid>
			{this.state.img64!==""?
			<Grid item xs={12} sm={12}>
				<img alt=""src={`${this.state.img64}`} style = {{maxWidth:'200px', margin:'5px'}}/>
			</Grid>
			:null} */}
			
			
		</Grid>
	</div>
    );
  }
}


export default Info;