import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {api_path,cellStyle} from '../../../environment';
import * as CSS from '../../../css.js';
import {SearchBtn,ClearSearchBtn,MsgBtn} from '../buttons';

const totalHeadRows = ["銷售類型","經銷商名稱","經銷商統編","銷售總量","銷售總額"];

class AdminVolume extends React.Component {
	constructor(props) {
		super(props);
		this.state = { subfunction:'總銷售量',
						searchAllData:[{}],
						search_start:'',
						search_end:'',
						show_start: "",
						show_end: "",
					};
	}

	fetchAllAmount = () => {
		let send_body = JSON.stringify({func: 'query_amt',
										login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchAllData:response.result,
								show_end: response.end,
								show_start: response.start,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	componentDidMount() {
		this.fetchAllAmount();
	}

	handleChangeSubfunction = e => { this.setState({subfunction : e.target.innerText, select_type:''}); }
	
	handleResetSearch = () => {
		this.setState({ search_start:'',
						search_end:'',
					});
		let send_body = JSON.stringify({func: 'query_amt',
										login_uid: this.props.uid,
										start: "",
										end: "",
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchAllData:response.result,
								show_end: response.end,
								show_start: response.start,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchDownloadAmt=()=> {
        let send_body = JSON.stringify({ func: 'download_amt',
										login_uid: this.props.uid,
										start: this.state.show_start,
										end: this.state.show_end,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	

  	render() {
   	return (
		<div style={{maxWidth:'750px',width:'100%',textAlign:'left',margin:'0 auto'}}>
			<div style={{display:'flex',textAlign:'right',justifyContent:'flex-start',margin:'5px',alignItems:'baseline'}}>
				<Typography style={CSS.adminInputStyle}>區間查詢：</Typography>
				<TextField 
					id="date"
					type="date"
					InputProps={{style:CSS.adminInputStyle}}
					InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
					value={this.state.search_start}
					style={{margin:'5px'}}
					onChange={e=>{this.setState({search_start:e.target.value})}}
				/>
				<Typography style={CSS.adminInputStyle}>～</Typography>
				<TextField 
					id="date"
					type="date"
					InputProps={{style:CSS.adminInputStyle}}
					InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
					value={this.state.search_end}
					style={{margin:'5px'}}
					onChange={e=>{this.setState({search_end:e.target.value})}}
				/>
				<div style={{display:'flex',alignItems:'center'}}>
					<SearchBtn onClick={this.fetchAllAmount}/>
					<ClearSearchBtn onClick={this.handleResetSearch}/>
					<MsgBtn 
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(false)}}
						send_body={JSON.stringify({ func: 'download_amt',
													login_uid: this.props.uid,
													start: this.state.show_start,
													end: this.state.show_end,
											})
									}
						btn_name="資料下載"
					/>
				</div>
			</div>
			<Paper style={{width:'100%',overflowX:'auto',minWidth: "100%",marginBottom:'30px'}}>
				<Table>
					<TableHead>
						<TableRow style={CSS.headRowStyle}>
							{totalHeadRows.map(e=><TableCell align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.searchAllData.map((e,index) => (
							<TableRow key={e.id} style={CSS.tablebodyStyle(index)}>
								<TableCell align="center" style={{fontFamily:'微軟正黑體',padding:'10px'}}>{e.sale_type}</TableCell>
								<TableCell align="center" style={{fontFamily:'微軟正黑體',padding:'10px'}}>{e.corp_name}</TableCell>
								<TableCell align="center" style={{fontFamily:'微軟正黑體',padding:'10px'}}>{e.tax_id}</TableCell>
								<TableCell align="center" style={{fontFamily:'微軟正黑體',padding:'10px'}}>{e.amt}</TableCell>
								<TableCell align="center" style={{fontFamily:'微軟正黑體',padding:'10px'}}>{e.total}</TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell colSpan={5} align="left" sytle={CSS.adminInputStyle}><Typography style={{fontFamily:'微軟正黑體'}}>{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}</Typography></TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
		</div>
	);
	}
}

export default AdminVolume;