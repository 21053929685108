import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import QClassPanel from './adminQClass';
import * as CSS from '../../../../css.js';
import {api_path,cellStyle} from '../../../../environment';
const qDetailHeadRows = ["類別","分數","題目內容","編輯","刪除"];

class EditQDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        qc_seq: "",
                        qd_level: "",
                        qd_content: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if(this.state.modal===false){
			this.setState({ qc_seq: this.props.qd.qc_seq,
                            qd_level: this.props.qd.qd_level,
                            qd_content: this.props.qd.qd_content,
						});
		}
		this.setState(prevState => ({ modal: !prevState.modal }));
    }
    
	fetchQDetailMod = () => {
        let send_body = JSON.stringify({ func: "mod_q_detail",
                                        login_uid: this.props.uid,
                                        qd_seq: this.props.qd.qd_seq,
                                        qc_seq: this.state.qc_seq,
                                        qd_level: this.state.qd_level,
                                        qd_content: this.state.qd_content,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQDetail(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>編輯題目</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="類別"
							value={this.state.qc_seq}
                            onChange={e => this.setState(({qc_seq : e.target.value}))}
                            select
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            SelectProps={{native:true}}
                            fullWidth
							error = {this.state.fail_field==="qc_seq"?true:null}
						>
                            <option value=""></option>
                            {this.props.q_class.map(c=><option value={c.qc_seq}>{c.qc_name}</option>)}
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="分數"
							value={this.state.qd_level}
                            fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qd_level : e.target.value}))}
						/>
					</Grid>
                    <Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="題目內容"
                            value={this.state.qd_content}
                            fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            multiline
                            onChange={e => this.setState(({qd_content : e.target.value}))}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQDetailMod} color="primary" style={CSS.justFont}>
				完成
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}


class AddQDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        qc_seq: "",
                        qd_level: "",
                        qd_content: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState(prevState => ({ modal: !prevState.modal }));
    }
    
	fetchQDetailIns = () => {
        let send_body = JSON.stringify({ func: "ins_q_detail",
                                        login_uid: this.props.uid,
                                        qg_seq: this.props.qg.qg_seq,
                                        qc_seq: this.state.qc_seq,
                                        qd_level: this.state.qd_level,
                                        qd_content: this.state.qd_content,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQDetail();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>新增題目</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>新增</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="類別"
							value={this.state.qc_seq}
                            onChange={e => this.setState(({qc_seq : e.target.value}))}
                            select
                            SelectProps={{native:true}}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
							error = {this.state.fail_field==="qc_seq"?true:null}
						>
                            <option value=""></option>
                            {this.props.q_class.map(c=><option value={c.qc_seq}>{c.qc_name}</option>)}
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="分數"
							value={this.state.qd_level}
                            onChange={e => this.setState(({qd_level : e.target.value}))}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						/>
					</Grid>
                    <Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="題目內容"
                            value={this.state.qd_content}
                            multiline
                            onChange={e => this.setState(({qd_content : e.target.value}))}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQDetailIns} color="primary" style={CSS.justFont}>
				新增
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class DelQDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState(prevState => ({ modal: !prevState.modal }));
	}

	fetchQDetailDel = () => {
        let send_body = JSON.stringify({ func: "del_q_detail",
											login_uid: this.props.uid,
											qd_seq: this.props.qd.qd_seq,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQDetail(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>刪除題目</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>刪除題目</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Typography style={CSS.justFont}>確定刪除 {this.props.qd.qd_content} 題目？</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQDetailDel} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}


class QDetailPanel  extends React.Component {
    constructor(props) {
        super(props);
		this.state = { modal: false,
						qg: {},
                        qDetailData:[{}],
                        q_class:[{}],
					};
		this.toggle = this.toggle.bind(this);
	}
	
    toggle() {
        this.fetchQDetail();
        this.fetchQDetailClass();
		this.setState(prevState => ({ modal: !prevState.modal }));
    }
    
	componentDidMount(){
		this.fetchQDetail();
	}

    fetchQDetail = () => {
		let send_body = JSON.stringify({ func: "query_q_detail",
										login_uid: this.props.uid,
										qg_seq: this.props.qg.qg_seq,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({qDetailData:response.result});
			}
			else if(response.status==="FAIL"){ }
		})
		.catch(error => console.error('Error:', error));
	}

    fetchQDetailClass = () => {
        let send_body = JSON.stringify({ func: "query_q_class",
                                        login_uid: this.props.uid,
                                        qd_seq: this.props.qd_seq,
                                        qc_seq: this.state.qc_seq,
                                        qd_level: this.state.qd_level,
                                        qd_content: this.state.qd_content,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({q_class:response.result});
			}
			else if(response.status==="FAIL"){
				
			}
		})
        .catch(error => console.error('Error:', error));
    }

    class_seqToName = seq => {
        for(let i = 0; i < this.state.q_class.length; i++){
            if(this.state.q_class[i].qc_seq === seq){
                return this.state.q_class[i].qc_name;
            }
        }
    }
    
	render() {
		// const cellStyle = {whiteSpace: 'nowrap'};
		return (
		<div style = {{display:'inline-block'}}>
            <Button  onClick={this.toggle} style={CSS.UGCbtnStyle(false)}>檢視題目</Button>
            <Dialog
				fullWidth={true}
				maxWidth="lg"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>{this.props.qi.qi_name} -> {this.props.qg.qg_topic} 題組</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    <Grid container spacing={2} style={{margin:'20px'}}>
                        <Grid item xs={12} sm={3}>
                            <AddQDetailModal fetchQDetail={this.fetchQDetail} qg={this.props.qg} uid={this.props.uid} q_class={this.state.q_class} auth={this.props.auth}/>
                            <QClassPanel uid={this.props.uid} fetchQDetailClass={this.fetchQDetailClass} auth={this.props.auth}/>
                        </Grid>
                    </Grid>

                    <Paper style={{overflowX:'auto',minWidth: "100%", marginTop:'20px', marginBottom:'30px'}}>
                        <Table>
                            <TableHead><TableRow style={CSS.headRowStyle}>
                                {qDetailHeadRows.map(e=><TableCell style={CSS.headCellStyle}>{e}</TableCell>)}
                            </TableRow></TableHead>
                            <TableBody>
                                {this.state.qDetailData.map((qd,index)=>
                                <TableRow key={qd.qd_seq} style={CSS.tablebodyStyle(index)}>
                                    <TableCell style={CSS.bodyCellStyle}>{this.class_seqToName(qd.qc_seq)}</TableCell>
                                    <TableCell style={CSS.bodyCellStyle}>{qd.qd_level}</TableCell>
                                    <TableCell style={CSS.bodyCellStyle}>{qd.qd_content}</TableCell>
                                    <TableCell style={CSS.bodyCellStyle}>
                                        <EditQDetailModal fetchQDetail={this.fetchQDetail} qd={qd} uid={this.props.uid} q_class={this.state.q_class} auth={this.props.auth}/>
                                    </TableCell>
                                    <TableCell style={CSS.bodyCellStyle}>
                                        <DelQDetailModal qd={qd} uid={this.props.uid} fetchQDetail={this.fetchQDetail} auth={this.props.auth}/>
                                    </TableCell>
                                </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				完成
				</Button>
				</DialogActions>
			</Dialog>
        </div>

        );
    }
}

export default QDetailPanel;