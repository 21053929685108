import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {api_path,UGCblue} from '../../environment';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Language from '@material-ui/icons/Language';
import Lock from '@material-ui/icons/Lock';
import Divider from '@material-ui/core/Divider';
import logo2 from '../../pics/logo2.png';
import logo1 from '../../pics/logo1.png';
import logo3 from '../../pics/logo3.png';
import Background from '../../pics/assessBgImg.png';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CountDown from './assess/countdown';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PlayArrow from '@material-ui/icons/PlayArrow';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { Link } from "react-router-dom";
import BottomBar from './bottomBar';


class BuyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        msg: "gjiq;oetqnbevie",
					};
    }


    render() {
        return (
        	<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.props.modal}
				onClose={this.props.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>訂購完成</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    <Typography style={{fontFamily:'微軟正黑體',}}>謝謝您選用UGCareer職業測評，訂單已送出，請您在3日內完成繳費，確認後我們將為您開通個人帳戶，謝謝！</Typography>
				</DialogContentText>
				</DialogContent>
                <DialogActions>
				<Button size="large" onClick={this.props.toggle} style={{fontFamily:'微軟正黑體',color:UGCblue}}>
				關閉
				</Button>
				</DialogActions>
			</Dialog>
        );
    }
}

class Buy extends Component {
    constructor(props){  
        super(props);  
        this.state = { ta: '消費者',
						name: "",
						email: "",
						tel: "",
						qi_seq: "",
						ord_amt: "",
						mtx_code: "",
						is_stu: "",
						qdetail: [{}],
						q_select: [{}],
						fail_field: "",
                        fail_msg: "",
                        modal: false,
                    };  
	}
	componentDidMount(){
		this.fetchQquery();
	}

    toggle = () => {
        this.setState({ modal: !this.state.modal});
        if(this.state.modal){
            this.setState({ name: "",
                            email: "",
                            tel: "",
                            qi_seq: "",
                            ord_amt: "",
                            mtx_code: "",
                            is_stu: "",
                            q_select: [{}],
                            fail_field: "",
                            fail_msg: "",
                        });
        }
    }

	fetchQquery= () => {
		let send_body = JSON.stringify({ func: 'q_query', login_uid: "" });
		fetch(`${api_path}/ugc/misc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({qdetail: response.result})
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchB2cOrder = () => {
		let send_body = JSON.stringify({ func: 'b2c_order',
										name: this.state.name,
										email: this.state.email,
										tel: this.state.tel,
										is_stu: this.state.is_stu,
										qi_seq: this.state.qi_seq,
										ord_amt: this.state.ord_amt,
										mtx_code: this.state.mtx_code,
									});
		fetch(`${api_path}/ugc/misc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
                this.toggle();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}


	render() {
        const mainstyle = {
            width: "100%",
            height: "100%",
            margin:'-8px',
            // padding: '0',
			backgroundImage: `url(${Background})`,
			backgroundColor: 'white',
			backgroundSize: '35vw 80vh',
            backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            backgroundAttachment: "fixed",
            overflowX:'auto',
            // overflowY:'hidden',
            top:'-8px',
            left:'-8px',
            
		};

		const desktopButtonStyle = {
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.1vw',
			marginBottom:'2vw',
        };
        
		const desktopButtonStyle2 = {
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.5vw',
			marginBottom:'0.5vw',
		};

		const desktopDisabledButtonStyle = {
            fontFamily:'微軟正黑體',
			backgroundColor:'gray',
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.1vw',
			marginBottom:'2vw',
		};

		const mobileButtonStyle={
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'5vw',
			marginTop:'1.2vw',
			width:'28vw',
			fontSize:'4.5vw',
			marginBottom:'4vw',
		};

		const mobileDisabledButtonStyle={
            fontFamily:'微軟正黑體',
			backgroundColor:'lightgray',
			color:'white',
			borderRadius:'5vw',
			marginTop:'1.2vw',
			width:'28vw',
			fontSize:'4.5vw',
			marginBottom:'4vw',
		};

		const circleBtnStyle = {
            fontFamily:'微軟正黑體',
			fontSize:'4vw',
			fontWeight:'bold',
			borderRadius:'8vw',
			backgroundColor:'white',
			border:'1px solid white',
			color:UGCblue,
			padding:'0',
			minWidth:'7vw',
			margin:'1vw',
		};

		const mobileInfoFontSize = {
            fontFamily:'微軟正黑體',
			fontSize:'4vw',
		};

		const desktopInfoFontSize = {
            fontFamily:'微軟正黑體',
			fontSize:'1.2vw',
		};

        var isMobile = window.innerWidth <= 500;
        if(!isMobile){
        return (
            <div style={{width:"100%", height:'100%',position:'absolute',top:'0',left:'0'}}>
        
		<div style={mainstyle}>
			<AppBar position="fixed" style={{width:'100%',height:'8vw',backgroundColor:'transparent',boxShadow:'none'}}>
				<Toolbar style={{boxShadow:'none',padding:'0'}}>
					<div style={{display:'flex',height:'100%',alignItems:'center'}}>
						<div style={{display:'flex',width:'38vw'}}>
                            <Link className="toMain" to="/">
                                <img src={logo2} alt="Logo2" style={{height:'2.5vw',marginTop:'4vh',marginBottom:'4vh',marginLeft:'4.5vw'}}/>
                            </Link>
                        </div>
					</div>
				</Toolbar>
			</AppBar>

			<div style={{display:'flex',width:'55vw',marginLeft:'40vw',marginTop:'6vw',marginBottom:'3vh',justifyContent:'center',flexDirection:'column'}}>
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				    <Button disabled style={desktopButtonStyle2}>訂購說明</Button>
                </div>
                <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
                    <Button style={this.state.ta==="消費者"?{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.8vw'}:{fontFamily:'微軟正黑體',color:'gray',fontSize:'1.8vw'}}
                            onClick={()=>this.setState({ta:'消費者'})}
                    >消費者</Button>
                    <Typography style={{color:UGCblue,fontSize:'3vw'}}>|</Typography>
				    <Button style={this.state.ta==="企業團體"?{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.8vw'}:{fontFamily:'微軟正黑體',color:'gray',fontSize:'1.8vw'}}
                            onClick={()=>this.setState({ta:'企業團體'})}
                    >企業團體</Button>
                </div>
                {this.state.ta==="消費者"?
                <div style={{display:'flex',marginTop:'3vw'}}>
                    <div style={{minWidth:'150px',display:'flex',width:'65%',justifyContent:'center',flexDirection:'column',fontFamily:'微軟正黑體',}}>
                        <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.5vw',marginBottom:'2vw',fontWeight:'bold'}}>訂購步驟:</Typography>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}>
                            1. 請輸入以下<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.2vw',fontWeight:'bold',fontStyle:'normal'}}>購買資料</em>
                        </Typography>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}>
                            2. 訂購 <em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.2vw',fontWeight:'bold',fontStyle:'normal'}}>3日內匯款繳費</em>
                        </Typography>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}>
                            3. 您將在<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.2vw',fontWeight:'bold',fontStyle:'normal'}}>2~3個工作天</em>內收到<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.2vw',fontWeight:'bold',fontStyle:'normal'}}>測驗通知信</em>，內含施測連結
                        </Typography>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}>
                            4. 完成測驗後，您可依<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.2vw',fontWeight:'bold',fontStyle:'normal'}}>帳戶開通信</em>中的帳號密碼登入看報告
                        </Typography>
                        
                    </div>
                    <div style={{minWidth:'100px',display:'flex',width:'32.5%',justifyContent:'center',flexDirection:'column',border:'1px solid gray',borderRadius:'20px',padding:'10px 20px 10px 20px',marginLeft:'2.5%'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.4vw',fontWeight:'bold'}}>職業適性測評</Typography>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.1vw',fontWeight:'bold'}}>每份新台幣 $1200</Typography>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'0.9vw',marginBottom:'1vw'}}>ATM轉帳 / 線上轉帳</Typography>
                        </div>
                        <div style={{display:'flex',alignItems:'flex-start',justifyContent:'center',flexDirection:'column'}}>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}>銀行: 玉山銀行(808) 民生分行</Typography>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}>帳號: 0211-440-006830</Typography>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}>戶名: 優駿企管顧問有限公司</Typography>
                        </div>
                    </div>
                </div>
                :this.state.ta==="企業團體"?
                <div style={{display:'flex',flexDirection:'column',marginTop:'3vw'}}>
                    <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.3vw',fontWeight:'bold',marginBottom:'4vw'}}>如果您對於優駿企管的職業測評服務有興趣，不論是客製化的顧問服務、測評報告內容或測評系統功能，都歡迎聯繫我們。</Typography>
                    <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.3vw',fontWeight:'bold'}}>請洽服務人員 +886-2-2723-0969 徐小姐</Typography>
                    {/* <Link className="toMain" to="/" style={{ textDecoration: 'none' }}>
                    <Button style={desktopDisabledButtonStyle}>返回</Button>
                    </Link */}
                </div>
                
                :null}
                {this.state.ta==="消費者"?
                <div style={{display:'flex',width:'100%',justifyContent:'center',flexDirection:'column'}}>
                    <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'1.5vw',marginTop:'3vw',marginBottom:'1.5vw',fontWeight:'bold'}}>輸入購買資料:</Typography>
                    <Grid container spacing={1}>
						{this.state.fail_msg!==""?
						<Grid item xs={12} sm={12}>
							<Typography align="center" style={{fontFamily:'微軟正黑體',color:"red", margin:'1vw', fontSize:'1.2vw'}}>{this.state.fail_msg}</Typography>
						</Grid>
						:null}
						
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.name}
                                onChange={e=>{this.setState({name:e.target.value})}}
                                error = {this.state.fail_field === "name" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>姓名：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            />
						</Grid>
                        <Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.email}
                                onChange={e=>{this.setState({email:e.target.value})}}
                                error = {this.state.fail_field === "email" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>Email：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            />
						</Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                placeholder="02-12345678/0912-345678"		value={this.state.tel}
                                onChange={e=>{this.setState({tel:e.target.value})}}
                                error = {this.state.fail_field === "tel" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>電話：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            />
						</Grid>
                        <Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="standard-select-currency-native"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.is_stu}
								onChange={e => {this.setState({ is_stu : e.target.value,
																qi_seq: ""});
												}}
								error = {this.state.fail_field === "is_stu" ? true:null}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>身份：</Typography></InputAdornment>,
									style: desktopInfoFontSize 
								}}
                                fullWidth
                                style={desktopInfoFontSize}
							>
								<option value=""></option>
								<option value={false}>社會人士</option>
								<option value={true}>學生</option>
							</TextField>
						</Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.qi_seq}
                                onChange={e => this.setState({qi_seq : e.target.value})}
                                error = {this.state.fail_field === "qi_seq" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>購買測評名稱：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            >
                                <option value=""></option>
                                {this.state.is_stu==="true" || this.state.is_stu === true?
                                this.state.qdetail.filter(q=>q.is_stu===true).map(q=><option value={q.qi_seq}>{q.qname}</option>)
                                :
                                null}
                                {this.state.is_stu==="false" || this.state.is_stu === false?
                                this.state.qdetail.filter(q=>q.is_stu===false).map(q=><option value={q.qi_seq}>{q.qname}</option>)
                                :null}
                            </TextField>
						</Grid>
                        <Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.ord_amt}
                                onChange={e=>{this.setState({ord_amt:e.target.value})}}
                                error = {this.state.fail_field === "ord_amt" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>訂購量：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            />
						</Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.mtx_code}
                                onChange={e=>{this.setState({mtx_code:e.target.value})}}
                                error = {this.state.fail_field === "mtx_code" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>匯款帳號末4碼：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            />
						</Grid>
                        <Grid item xs={12} sm={12} style={{marginTop:'2vw'}}>
                            <div style={{display:'flex'}}>
                            <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}><li></li></Typography>
                            <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}>請務必留意填寫您正確的個人 Email，這將用作您的登入帳號。</Typography>
                            </div>
                            <div style={{display:'flex'}}>
                            <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}><li></li></Typography>
                            <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw'}}>如您在匯款2-3天個工作天後未收到帳戶開通信，請先留意通知信是否被收至 Email 信箱垃圾郵件，若仍未收到，請來信客服信箱 service@ugc.com.tw，將有專人為您服務。</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <div style={{display:'flex',justifyContent:'space-evenly'}}>
                                <Button style={desktopButtonStyle} onClick={this.fetchB2cOrder}>購買</Button>
                                {/* <Link className="toMain" to="/" style={{ textDecoration: 'none' }}>
                                    <Button style={desktopDisabledButtonStyle}>返回</Button>
                                </Link> */}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                :null}
			</div>
            <BuyModal modal={this.state.modal} toggle={this.toggle}/>
		</div>
        </div>
        );
        }
        else {
            return (
                <div style={{width:"100%", height:'100%',position:'absolute',top:'0',left:'0'}} elevation={0} square={true}>
					{/* LOGO條 */}
					<Paper style={{display:'flex',justifyContent:'center',alignItems:'center',width:"100%", height:'8vh', textAlign:'center'}} elevation={0} square={true}>
						<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
							<img src={logo1} alt="Logo1" style={{height:'3vh'}}/>
						</div>
					</Paper>
					
					{/* 藍色條 */}
					<Paper style={{backgroundColor:UGCblue,display:'flex',alignItems:'center',width:"100%", height:'7vh'}} elevation={0} square={true}>
						<Typography variant="h6" noWrap style={{fontFamily:'微軟正黑體',margin:'1vh',fontSize:'2.5vh',fontWeight:'bold',color:'white'}}>
							訂購說明：
						</Typography>
					</Paper>

					<Paper style={{overflowY:'auto',height:'75vh',width:"100%",left:'0px',display:'flex',flexDirection:'column',alignItems:'center'}} elevation={0} square={true}>
                    <Grid container spacing={0} style={{width:'90%'}}>
                        <Grid item xs={12} sm={12}>    
                            <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
                                <Button style={this.state.ta==="消費者"?{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'5vw'}:{fontFamily:'微軟正黑體',color:'gray',fontSize:'5vw'}}
                                        onClick={()=>this.setState({ta:'消費者'})}
                                >消費者</Button>
                                <Typography style={{color:UGCblue,fontSize:'8vw'}}>|</Typography>
                                <Button style={this.state.ta==="企業團體"?{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'5vw'}:{fontFamily:'微軟正黑體',color:'gray',fontSize:'5vw'}}
                                        onClick={()=>this.setState({ta:'企業團體'})}
                                >企業團體</Button>
                            </div>
                        </Grid>

                        {this.state.ta==="消費者"?
                        <Grid item xs={12} sm={12}>    
                        {/* <div style={{display:'flex',marginTop:'3vw',flexDirection:'column',alignItems:'center',alignItems:'center'}}> */}
                            <div style={{display:'flex',width:'100%',justifyContent:'center',flexDirection:'column'}}>
                                <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'4vw',marginBottom:'2vw',fontWeight:'bold'}}>訂購步驟:</Typography>
                                <Typography style={{color:'black',fontSize:'4vw'}}>
                                    1. 請輸入以下<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'4vw',fontWeight:'bold',fontStyle:'normal'}}>購買資料</em>
                                </Typography>
                                <Typography style={{color:'black',fontSize:'4vw'}}>
                                    2. 訂購 <em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'4vw',fontWeight:'bold',fontStyle:'normal'}}>3日內匯款繳費</em>
                                </Typography>
                                <Typography style={{color:'black',fontSize:'4vw'}}>
                                    3. 您將在<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'4vw',fontWeight:'bold',fontStyle:'normal'}}>2~3個工作天</em>內收到<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'4vw',fontWeight:'bold',fontStyle:'normal'}}>測驗通知信</em>，內含施測連結
                                </Typography>
                                <Typography style={{color:'black',fontSize:'4vw'}}>
                                    4. 完成測驗後，您可依<em style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'4vw',fontWeight:'bold',fontStyle:'normal'}}>帳戶開通信</em>中的帳號密碼登入看報告
                                </Typography>
                            </div>
                        </Grid>
                        :null}
                        {this.state.ta==="消費者"?
                        
                        <Grid item xs={12} sm={12}>    
                            <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                            <div style={{display:'flex',width:'80%',alignItems:'center',justifyContent:'space-evenly',flexDirection:'column',border:'1px solid gray',borderRadius:'20px',marginTop:'4vw',minHeight:'20vh'}}>
                                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                                    <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'5vw',fontWeight:'bold'}}>職業適性測評</Typography>
                                    <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'4vw',fontWeight:'bold'}}>每份新台幣 $1200</Typography>
                                    <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'3.5vw',marginBottom:'1vw'}}>ATM轉帳 / 線上轉帳</Typography>
                                </div>
                                <div style={{display:'flex',alignItems:'flex-start',justifyContent:'center',flexDirection:'column'}}>
                                    <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'4vw'}}>銀行: 玉山銀行(808) 民生分行</Typography>
                                    <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'4vw'}}>帳號: 0211-440-006830</Typography>
                                    <Typography noWrap style={{fontFamily:'微軟正黑體',color:'black',fontSize:'4vw'}}>戶名: 優駿企管顧問有限公司</Typography>
                                </div>
                            </div>
                            </div>
                        {/* </div> */}
                        </Grid>
                        :this.state.ta==="企業團體"?
                        <Grid item xs={12} sm={12}>    
                        
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <div style={{display:'flex',flexDirection:'column',marginTop:'3vw',width:'90%'}}>
                                <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'4.5vw',fontWeight:'bold',marginBottom:'4vw'}}>如果您對於優駿企管的職業測評服務有興趣，不論是客製化的顧問服務、測評報告內容或測評系統功能，都歡迎聯繫我們。</Typography>
                                <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'4.5vw',fontWeight:'bold'}}>請洽服務人員 +886-2-2723-0969 徐小姐</Typography>
                                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
                                <Link className="toMain" to="/" style={{ textDecoration: 'none' }}>
                                    <Button style={mobileDisabledButtonStyle}>返回</Button>
                                </Link>
                                </div>
                            </div>
                        </div>
                        </Grid>
                        
                        :null}
                        {this.state.ta==="消費者"?
                        <Grid item xs={12} sm={12}>
                        <div style={{display:'flex',width:'100%',alignItems:'center',flexDirection:'column'}}>
                            <div style={{display:'flex',width:'100%',alignItems:'flex-start'}}>
                            <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'4vw',marginBottom:'1.5vw',fontWeight:'bold',marginTop:'5vw'}}>輸入購買資料:</Typography>
                            </div>
                            <div style={{display:'flex',width:'100%'}}>
                            <Grid container spacing={0}>
                                {this.state.fail_msg!==""?
                                <Grid item xs={12} sm={12}>
                                    <Typography align="center" style={{fontFamily:'微軟正黑體',color:"red", margin:'1vw', fontSize:'4vw'}}>{this.state.fail_msg}</Typography>
                                </Grid>
                                :null}
                                
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="standard-select-currency-native"
                                        margin="normal"
                                        value={this.state.name}
                                        onChange={e=>{this.setState({name:e.target.value})}}
                                        error = {this.state.fail_field === "name" ? true:null}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Typography noWrap style={{fontFamily:'微軟正黑體',}}>姓名：</Typography></InputAdornment>,
                                            style: mobileInfoFontSize 
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="standard-select-currency-native"
                                        margin="normal"
                                        value={this.state.email}
                                        onChange={e=>{this.setState({email:e.target.value})}}
                                        error = {this.state.fail_field === "email" ? true:null}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Typography noWrap style={{fontFamily:'微軟正黑體',}}>Email：</Typography></InputAdornment>,
                                            style: mobileInfoFontSize 
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="standard-select-currency-native"
                                        margin="normal"
                                        placeholder="02-12345678/0912-345678"		value={this.state.tel}
                                        onChange={e=>{this.setState({tel:e.target.value})}}
                                        error = {this.state.fail_field === "tel" ? true:null}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Typography noWrap style={{fontFamily:'微軟正黑體',}}>電話：</Typography></InputAdornment>,
                                            style: mobileInfoFontSize 
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        SelectProps={{native: true}}
                                        margin="normal"
                                        value={this.state.is_stu}
                                        onChange={e => {this.setState({ is_stu : e.target.value,
                                                                        qi_seq: ""});
                                                        }}
                                        error = {this.state.fail_field === "is_stu" ? true:null}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Typography noWrap style={{fontFamily:'微軟正黑體',}}>身份：</Typography></InputAdornment>,
                                            style: mobileInfoFontSize 
                                        }}
                                        fullWidth
                                    >
                                        <option value=""></option>
                                        <option value={true}>學生</option>
                                        <option value={false}>社會人士</option>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        SelectProps={{native: true}}
                                        margin="normal"
                                        value={this.state.qi_seq}
                                        onChange={e => this.setState({qi_seq : e.target.value})}
                                        error = {this.state.fail_field === "qi_seq" ? true:null}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Typography noWrap style={{fontFamily:'微軟正黑體',}}>購買測評名稱：</Typography></InputAdornment>,
                                            style: mobileInfoFontSize 
                                        }}
                                        fullWidth
                                    >
                                        <option value=""></option>
                                        {this.state.is_stu==="true" || this.state.is_stu === true?
                                        this.state.qdetail.filter(q=>q.is_stu===true).map(q=><option value={q.qi_seq}>{q.qname}</option>)
                                        :
                                        null}
                                        {this.state.is_stu==="false" || this.state.is_stu === false?
                                        this.state.qdetail.filter(q=>q.is_stu===false).map(q=><option value={q.qi_seq}>{q.qname}</option>)
                                        :null}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="standard-select-currency-native"
                                        margin="normal"
                                        value={this.state.ord_amt}
                                        onChange={e=>{this.setState({ord_amt:e.target.value})}}
                                        error = {this.state.fail_field === "ord_amt" ? true:null}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Typography noWrap style={{fontFamily:'微軟正黑體',}}>訂購量：</Typography></InputAdornment>,
                                            style: mobileInfoFontSize 
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="standard-select-currency-native"
                                        margin="normal"
                                        value={this.state.mtx_code}
                                        onChange={e=>{this.setState({mtx_code:e.target.value})}}
                                        error = {this.state.fail_field === "mtx_code" ? true:null}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Typography noWrap style={{fontFamily:'微軟正黑體',}}>匯款帳號末4碼：</Typography></InputAdornment>,
                                            style: mobileInfoFontSize 
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start',marginTop:'5vw',marginBottom:'5vw'}}>
                                    <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'3.5vw'}}><li>請務必留意填寫您正確的個人 Email，這將用作您的登入帳號。</li></Typography>
                                    <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'3.5vw'}}><li>如您在匯款2-3天個工作天後未收到帳戶開通信，請先留意通知信是否被收至 Email 信箱垃圾郵件，若仍未收到，請來信客服信箱 service@ugc.com.tw，將有專人為您服務。</li></Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div style={{display:'flex',justifyContent:'space-evenly'}}>
                                        <Button style={mobileButtonStyle} onClick={this.fetchB2cOrder}>購買</Button>
                                        {/* <Link className="toMain" to="/" style={{ textDecoration: 'none' }}>
                                            <Button style={desktopDisabledButtonStyle}>返回</Button>
                                        </Link> */}
                                    </div>
                                </Grid>
                            </Grid>
                            </div>
                        </div>
                        </Grid>
                        :null}

                    </Grid>
					</Paper>
					
                    <div style={{width:'100%',position:'fixed',height:'10vh',top:'90vh',display:'flex',alignItems:'center',flexDirection:'row',justifyContent:'center'}}>
                        <img src={logo3} alt="Logo3" style={{height:'2.56vh'}}/>
                        <Typography style={{fontFamily:'微軟正黑體',marginLeft:'10px', color:UGCblue,fontSize:'1.15vh'}}>{" ⓒ 2019 UGC. all rights reserved."}</Typography>
                    </div>
                    <BottomBar/>
                    <BuyModal modal={this.state.modal} toggle={this.toggle}/>
		</div>
        );
        }
	}
}

export default Buy;