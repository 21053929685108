import {UGCblue} from './environment';
import Background from './pics/assessBgImg.png';

export const mainstyle = {
    width: "100vw",
    height: "100vh",
    margin:'-8px',
    padding: '0',
    backgroundImage: `url(${Background})`,
    backgroundColor: 'white',
    backgroundSize: '33vw 80vh',
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    overflowX:'hidden',
};

export const desktopButtonStyle = {
    fontFamily:'微軟正黑體',
    backgroundColor:UGCblue,
    color:'white',
    borderRadius:'2.2vw',
    marginTop:'1.2vw',
    width:'10vw',
    fontSize:'1.1vw',
    marginBottom:'3vw',
};

export const desktopDisabledButtonStyle = {
    fontFamily:'微軟正黑體',
    backgroundColor:'lightgray',
    color:'white',
    borderRadius:'2.2vw',
    marginTop:'1.2vw',
    width:'10vw',
    fontSize:'1.1vw',
    marginBottom:'3vw',
};

export const mobileButtonStyle={
    fontFamily:'微軟正黑體',
    backgroundColor:UGCblue,
    color:'white',
    borderRadius:'5vw',
    marginTop:'1.2vw',
    width:'28vw',
    fontSize:'4.5vw',
    marginBottom:'4vw',
};

export const mobileDisabledButtonStyle={
    fontFamily:'微軟正黑體',
    backgroundColor:'lightgray',
    color:'white',
    borderRadius:'5vw',
    marginTop:'1.2vw',
    width:'28vw',
    fontSize:'4.5vw',
    marginBottom:'4vw',
};

export const circleBtnStyle = {
    fontFamily:'微軟正黑體',
    fontSize:'4vw',
    fontWeight:'bold',
    borderRadius:'8vw',
    backgroundColor:'white',
    border:'1px solid white',
    color:UGCblue,
    padding:'0',
    minWidth:'7vw',
    margin:'1vw',
};

export const mobileInfoFontSize = {
    fontSize:'4vw',
    fontFamily:'微軟正黑體',
};

export const desktopInfoFontSize = {
    fontFamily:'微軟正黑體',
    fontSize:'1.2vw',
};