import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {UGCblue} from '../../../environment';
import Divider from '@material-ui/core/Divider';
import * as CSS from '../../../assessCss.js'


class Prob extends Component {
    constructor(props){  
        super(props);  
		this.state = { page_id:0,
						tm_begin: new Date(),
						page_info: {prob:[],},
						value: null,
        };  
	}
	componentDidMount(){
		this.setState({value:null});
	}
	createRadio(score, handleChange,value){
		let radios=[];
		let desc = this.props.btn===[]?["非常不符合","不符合","有點不符合","有點符合","符合","非常符合"]:this.props.btn;
		for(let i = 1;i <= score; i++){
			let btn = <div style={{display:'flex',alignItems:'baseline'}}>
					<Button 
						onClick = {e=>{handleChange(i)}}
						style={value===i?{fontFamily:'微軟正黑體',fontSize:'1.1vw',borderRadius:'1.1vw',border:'1px solid #385B8E',color:'white',backgroundColor:UGCblue,padding:'0',minWidth:'2vw',width:'2vw'}:{fontFamily:'微軟正黑體',fontSize:'1.1vw',borderRadius:'1.1vw',border:'1px solid #385B8E',color:UGCblue,padding:'0',minWidth:'2vw',width:'2vw'}}
						variant="ountlined"
					>{i.toString(10)}</Button>
					<Typography style={{fontFamily:'微軟正黑體',fontSize:'1.1vw',marginLeft:'0.5vw'}}>{desc[i-1]}</Typography>
					</div>
			radios.push(btn);
		}
		return radios;
	}
	handleChange = e => {
		if(this.state.value !== e){
		this.setState({value: e});
		this.props.setSendAns({ qd_seq: this.props.prob.qd_seq,
								ans: e,
								q_tm: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
							});
		}
	}
	render() {
		return (
			<Grid align="center" item xs={12} sm={12}>
				<Typography align="left" style={{fontFamily:'微軟正黑體',margin:'10px', fontSize:'1.15vw',fontWeight:'bold'}}>{this.props.prob.desc}</Typography>
					<div style={{display:'flex',alignItems:'baseline',justifyContent:'space-evenly'}}>
						{this.createRadio(this.props.prob.score,this.handleChange,this.state.value)}
					</div>
			</Grid>
		);
	}
}

class MobileProb extends Component {
    constructor(props){  
        super(props);  
		this.state = { page_id:0,
						tm_begin: new Date(),
						page_info: {prob:[],},
						value: null,
        };  
	}
	componentDidMount(){
		this.setState({value:null});
	}

	createRadio(score, handleChange,value){
        let radios=[];
        score=score<0?(-1*score):score; //有時候score回傳回來是負的
		for(let i = 1;i <= score; i++){
			let btn = <div style={{display:'flex',alignItems:'baseline'}}>
					<Button 
						onClick = {e=>{handleChange(i)}}
						style={value===i?{fontFamily:'微軟正黑體',fontSize:'4vw',fontWeight:'bold',borderRadius:'9vw',border:'1px solid #385B8E',color:'white',backgroundColor:UGCblue,padding:'0',minWidth:'7vw'}
											:{fontFamily:'微軟正黑體',fontSize:'4vw',fontWeight:'bold',borderRadius:'9vw',border:'1px solid #385B8E',color:UGCblue,padding:'0',minWidth:'7vw'}}
						variant="ountlined"
					>{i.toString(10)}</Button>
					</div>
			radios.push(btn);
		}
		return radios;
	}

	handleChange = e => {
		if(this.state.value !== e){
            this.setState({value: e});
            this.props.setSendAns({ qd_seq: this.props.prob.qd_seq,
                                    ans: e,
                                    q_tm: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
                                });
		}
	}

	render() {
		return (
			<Grid align="center" item xs={12} sm={12}>
				<Typography align="left" style={{fontFamily:'微軟正黑體',margin:'1.5vw', fontSize:'4.5vw',fontWeight:'bold',margin:'2vh'}}>{this.props.prob.desc}</Typography>
					<div style={{display:'flex',alignItems:'baseline',justifyContent:'space-evenly'}}>
						{this.createRadio(this.props.prob.score,this.handleChange,this.state.value)}
					</div>
			</Grid>
		);
	}
}

class PageFour extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
		var elmnt = document.getElementById("content");
		elmnt.scrollTop=0;
	}

    render(){
        if(!this.props.isMobile){
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography style={{fontFamily:'微軟正黑體',marginTop:'2vw',color:UGCblue, fontSize:'1.3vw',fontWeight:'bold', whiteSpace:'pre-line'}}>{this.props.page_info.grp_topic}</Typography>
                    </Grid>
                    {this.props.page_info.grp_desc!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw',whiteSpace:'pre-line'}}>{this.props.page_info.grp_desc}</Typography>
                    </Grid>
                    :null}
                    {this.props.page_info.ans_desc!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'1.2vw',whiteSpace:'pre-line'}}>{this.props.page_info.ans_desc}</Typography>
                    </Grid>
                    :null}
                    <Grid item xs={12} sm={12}>
                        <Divider style={{width:'100%',height:'0.05vw',backgroundColor:UGCblue}}/>
                    </Grid>

                    {this.props.page_info.prob.map(prob=><Prob prob={prob} setSendAns={this.props.setSendAns} btn={this.props.btn}/>)}
                    
                    <Grid item xs={12} sm={12}>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <Button onClick={this.props.fetchAssess} 
                                    style={this.props.nextBtnDisabled?CSS.desktopDisabledButtonStyle:CSS.desktopButtonStyle}
                                    disabled={this.props.nextBtnDisabled}
                            >下一頁</Button>
                        </div>
                    </Grid>
                </Grid>
            );
        }
        else{
            return(
                <Grid item xs={12} sm={12} style={{display:'flex',justifyContent:'center'}}>
					<Grid container spacing={0}>
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',justifyContent:'center'}}>
							<Typography style={{fontFamily:'微軟正黑體',marginTop:'2vh',color:UGCblue, fontSize:'5vw',fontWeight:'bold', whiteSpace:'pre-line'}}>{this.props.page_info.grp_topic}</Typography>
							</div>
						</Grid>
						{this.props.page_info.grp_desc!==""?
						<Grid item xs={12} sm={12}>
							<Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'4vw',whiteSpace:'pre-line',margin:'2vh'}}>{this.props.page_info.grp_desc}</Typography>
						</Grid>
						:null}
						{this.props.page_info.ans_desc!==""?
						<Grid item xs={12} sm={12}>
							<Typography style={{fontFamily:'微軟正黑體',color:'black',fontSize:'4vw',whiteSpace:'pre-line',margin:'2vh'}}>{this.props.page_info.ans_desc}</Typography>
						</Grid>
						:null}
						<Grid item xs={12} sm={12}>
							<Paper style={{backgroundColor:UGCblue,marginLeft:'2vh',marginRight:'2vh'}}>
								<div style={{display:'flex',margin:'2vw',justifyContent:'flex-start',alignItems:'baseline'}}>
									<Button disabled style={CSS.circleBtnStyle}>1</Button>
									<Typography style={{fontFamily:'微軟正黑體',color:'white',fontSize:'3.5vw',whiteSpace:'pre-line',marginRight:'2vw'}}>{this.props.btn===[]?"非常不符合":this.props.btn[0]}</Typography>
									<Button disabled style={CSS.circleBtnStyle}>3</Button>
									<Typography style={{fontFamily:'微軟正黑體',color:'white',fontSize:'3.5vw',whiteSpace:'pre-line',marginRight:'2vw'}}>{this.props.btn===[]?"有點不符合":this.props.btn[2]}</Typography>
									<Button disabled style={CSS.circleBtnStyle}>5</Button>
									<Typography style={{fontFamily:'微軟正黑體',color:'white',fontSize:'3.5vw',whiteSpace:'pre-line'}}>{this.props.btn===[]?"符合":this.props.btn[4]}</Typography>
								</div>
								<div style={{display:'flex',margin:'2vw',justifyContent:'flex-end',alignItems:'baseline'}}>
									<Button disabled style={CSS.circleBtnStyle}>2</Button>
									<Typography style={{fontFamily:'微軟正黑體',color:'white',fontSize:'3.5vw',whiteSpace:'pre-line',marginRight:'2vw'}}>{this.props.btn===[]?"不符合":this.props.btn[1]}</Typography>
									<Button disabled style={CSS.circleBtnStyle}>4</Button>
									<Typography style={{fontFamily:'微軟正黑體',color:'white',fontSize:'3.5vw',whiteSpace:'pre-line',marginRight:'2vw'}}>{this.props.btn===[]?"有點符合":this.props.btn[3]}</Typography>
									<Button disabled style={CSS.circleBtnStyle}>6</Button>
									<Typography style={{fontFamily:'微軟正黑體',color:'white',fontSize:'3.5vw',whiteSpace:'pre-line'}}>{this.props.btn===[]?"非常符合":this.props.btn[5]}</Typography>
								</div>
							</Paper>
						</Grid>

						{this.props.page_info.prob.map(prob=><MobileProb prob={prob} setSendAns={this.props.setSendAns}/>)}
						
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',justifyContent:'center',marginTop:'3vh'}}>
								<Button onClick={this.props.fetchAssess}
										style={this.props.nextBtnDisabled?CSS.mobileDisabledButtonStyle:CSS.mobileButtonStyle}
										disabled={this.props.nextBtnDisabled}
								>下一頁</Button>
							</div>
						</Grid>
					</Grid>
                </Grid>
            );
        }
        
    }
  }
  
  export default PageFour;