import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import renderHTML from 'react-render-html';
import {api_path,entLittleRoundButtonStyle,entusagetablebodycellStyle, qResultbodycellStyle,tableheadcellStyle,UGCblue,tablebodyStyle,tableheadrowStyle,entbuttonStyleBlue,entbuttonStyleBlueDisabled} from '../../../environment';

const AssessHeadRows = ["訂購日","測評名稱","狀態","測評完成日",""]


class Print extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						msg: "",
						state: "1",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal){this.setState({modal:!this.state.modal,state:"2"})}
		if(this.state.modal&&this.state.state==="1"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "2",
						});
		}
		else if(this.state.modal&&this.state.state==="2"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}
		else if(this.state.modal&&this.state.state==="3"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}

	}
	fetchDownloadRpt= qa_seq => {
		// var windowReference = window.open();
		let send_body = JSON.stringify({ func: 'download_rpt',
										login_uid: this.props.uid,
										qa_seq: qa_seq,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.url){
					var link = document.createElement("a");
                    link.href = response.url;
					link.target="_blank"
					// link.download = response.rpt_fname;
                    link.click();
					this.setState({ msg: response.msg, 
									state: "3",
									modal:false
								});
				}
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchPrintUidPasswd= () => {
        let send_body = JSON.stringify({ func: 'print_pwd_list',
										login_uid: this.props.uid,
										qa_list: this.props.checkedValues,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.pdf){
					var link = document.createElement("a");
					link.href = 'data:application/pdf;base64,' + encodeURI(response.pdf);
					link.download = response.pdf_fname;
					link.click();
				}
			}
			this.setState({msg: response.msg, state: "3",modal:false});
				this.props.fetchQAssign();
				this.props.fetchTotalAmount();
		})
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button
				style={entLittleRoundButtonStyle} 
				onClick={()=>{ 	
								this.toggle();
								this.fetchDownloadRpt(this.props.qa_seq);
				}}
			>看報告</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體'}}>報告將顯示，請稍待</Typography></DialogTitle>
				<DialogContent>
				{this.state.state==="2"?
            	<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				:
				null
				}
				</DialogContent>
				{this.state.state==="3"?
            	<DialogActions>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
					關閉
					</Button>
                </DialogActions>
				:
				this.state.state==="2"?
				<DialogActions>
				</DialogActions>
				:
				null
				}
			</Dialog>
		</div>
		);
	}
}

class RptModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field: "",
						fail_msg: "",
                        html: "",
                        pre_page: "",
                        nxt_page: "",
                    };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
		if(!this.state.modal){this.fetchRpt();}
        this.setState({ modal: !this.state.modal,
                        fail_field: "",
                        fail_msg: "",
                        html: "",
                        pre_page: "",
                        nxt_page: "",
                    });
    }

	fetchRpt= () => {
        let send_body = JSON.stringify({ func: 'show_rpt',
										login_uid: this.props.uid,
                                        qa_seq: this.props.qa_seq,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ html: response.html,
                                pre_page: response.pre_page,
                                nxt_page: response.nxt_page
                            });
			}
		})
        .catch(error => console.error('Error:', error));
	}
    fetchPrev= () => {
        this.setState({ html: ""});
        let send_body = JSON.stringify({ func: 'show_rpt',
										login_uid: this.props.uid,
                                        qa_seq: this.props.qa_seq,
                                        page_id: this.state.pre_page,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ html: response.html,
                                pre_page: response.pre_page,
                                nxt_page: response.nxt_page
                            });
			}
		})
        .catch(error => console.error('Error:', error));
    }
    fetchNext= () => {
        this.setState({ html: ""});
        let send_body = JSON.stringify({ func: 'show_rpt',
										login_uid: this.props.uid,
                                        qa_seq: this.props.qa_seq,
                                        page_id: this.state.nxt_page,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ html: response.html,
                                pre_page: response.pre_page,
                                nxt_page: response.nxt_page
                            });
			}
		})
        .catch(error => console.error('Error:', error));
	}
    render() {
        return (
            <div>
                <Button onClick={this.toggle} style={entLittleRoundButtonStyle}>看報告</Button>
                <Dialog
                    fullWidth={true}
                    maxWidth="xl"
                    open={this.state.modal}
                    onClose={this.toggle}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullScreen
                >
                    <DialogTitle id="alert-dialog-title"></DialogTitle>
                    {this.state.html===""?
                    <div style={{height:'100vh',minWidth:'100vw'}}>
                    <CircularProgress style={{top:'50vh',left:'50vw',margin:'0 auto'}}/>
                    </div>
                    :
                    renderHTML(this.state.html)
                    }
                    <Divider style={{marginTop:'20px'}}/>
                    <DialogActions>
                    <Button disabled={this.state.pre_page===""} size="large" onClick={this.fetchPrev} color="primary" >
                    上一頁
                    </Button>
                    <Button disabled={this.state.nxt_page===""} size="large" onClick={this.fetchNext} color="primary">
                    下一頁
                    </Button>
                    <Button size="large" onClick={this.toggle} color="primary">
                    關閉
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

class AssessMgt extends React.Component {
	constructor(props) {
		super(props);
		this.state = { B2cAssessData:[{}],
					};
	}

	componentDidMount() {
        this.fetchQList();
	}

	fetchDoAssess= qa_seq => {
		var windowReference = window.open();
					
        let send_body = JSON.stringify({ func: 'do_assess',
										login_uid: this.props.uid,
										qa_seq: qa_seq,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.url){
					// window.open(response.url);

					windowReference.location = response.url;
					
				}
			}
		})
        .catch(error => console.error('Error:', error));
    }
    
	fetchShowRpt= qa_seq => {
        let send_body = JSON.stringify({ func: 'download_rpt',
										login_uid: this.props.uid,
										qa_seq: qa_seq,
										start: this.state.show_start,
										end: this.state.show_end,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.rpt){
                    var link = document.createElement("a");
					link.href = 'data:application/pdf;base64,' + encodeURI(response.rpt);

					// link.download = response.rpt_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
	fetchQList = () => {
		let send_body = JSON.stringify({ func: 'query_q_list',
										login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
										corp_name:this.state.search_corp_name,
										tax_id:this.state.search_tax_id,
									});
		fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => { if(response.status==="SUCC"){ this.setState({ B2cAssessData: response.result, }); }
		})
		.catch(error => console.error('Error:', error));
    }

  	render() {
   	return (
        <div style={{maxWidth:'750px', width:'100%',textAlign:'left',margin: '0 auto'}}>
	        <Paper style={{width:'100%', overflowX:'auto',minWidth: "100%", marginTop:'20px',  marginBottom:'30px'}}>
                <Table>
                    <TableHead><TableRow style={tableheadrowStyle}>
                        {AssessHeadRows.map(e=><TableCell align="center" style={tableheadcellStyle}>{e}</TableCell>)}
                    </TableRow></TableHead>
                    <TableBody>
                        {this.state.B2cAssessData.map((assess,index) => (
                            <TableRow style={tablebodyStyle(index)} key={assess.id}>
                                <TableCell align="center" style={entusagetablebodycellStyle}>{assess.ord_date}</TableCell>
                                <TableCell align="center" style={entusagetablebodycellStyle}>{assess.qname}</TableCell>
                                <TableCell align="center" style={entusagetablebodycellStyle}>{assess.qstatus}</TableCell>
                                <TableCell align="center" style={entusagetablebodycellStyle}>{assess.qf_date}</TableCell>
                                <TableCell style={entusagetablebodycellStyle}>
                                    {assess.qf_date!==""?
                                    <Print uid={this.props.uid} qa_seq={assess.qa_seq}/>
				                    :
                                    <Button onClick={()=>this.fetchDoAssess(assess.qa_seq)} style={entLittleRoundButtonStyle}>做測評</Button>
                                    }
                                </TableCell>
								
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

        </div>
            
	);
	}
}

export default AssessMgt;