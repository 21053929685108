import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import * as CSS from '../../../css.js';
import {api_path,cellStyle} from '../../../environment';


const AdminHeadRows = ["姓名","信箱","電話","帳號","密碼","身分","B2C訂單及客戶管理","B2B訂單及客戶管理","總銷售量查詢","管理資料設定","測評報告","試卷管理","修改","刪除"];


class AddAdminModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        name: "",
                        email: "",
                        tel: "",
                        uid: "",
                        passwd: "",
                        ac_b2c: "",
                        ac_b2b: "",
                        ac_amt: "",
                        ac_setup:  "",
                        ac_rpt: "",
                        ac_qmgt: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal,
                        fail_field:"",
                        fail_msg:"",
                        name: "",
                        email: "",
                        tel: "",
                        uid: "",
                        passwd: "",
                        ac_b2c: "",
                        ac_b2b: "",
                        ac_amt: "",
                        ac_setup:  "",
                        ac_rpt: "",
                        ac_qmgt: "",
                    });
    }
    
	fetchInsQInfo = () => {
        let send_body = JSON.stringify({ func: "ins_admin",
                                        login_uid: this.props.uid,
                                        name: this.state.name,
                                        email: this.state.email,
                                        tel: this.state.tel,
                                        uid: this.state.uid,
                                        passwd: this.state.passwd,
                                        ac_b2c: this.state.ac_b2c,
                                        ac_b2b: this.state.ac_b2b,
                                        ac_amt: this.state.ac_amt,
                                        ac_setup: this.state.ac_setup,
                                        ac_rpt: this.state.ac_rpt,
                                        ac_qmgt: this.state.ac_qmgt,
            
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchAdminList(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
		
	}

    handleSubmit=()=>{
        this.fetchInsQInfo();
    }

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>新增</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>新增</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="帳號"
							value={this.state.uid}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState(({uid : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="姓名"
							value={this.state.name}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState(({name : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="信箱"
							value={this.state.email}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState(({email : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
                            label="電話"
                            placeholder="02-12345678/0912-345678"
                            value={this.state.tel}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState(({tel : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="B2C訂單及客戶管理"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_b2c}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState({ac_b2c : e.target.value})}
                            error = {this.state.fail_field === "ac_b2c" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="B2B訂單及客戶管理"
                            SelectProps={{native: true}}
                            margin="normal"
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            fullWidth
							value={this.state.ac_b2b}
                            onChange={e => this.setState({ac_b2b : e.target.value})}
                            error = {this.state.fail_field === "ac_b2b" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="總銷售量查詢"
                            SelectProps={{native: true}}
                            margin="normal"
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            fullWidth
							value={this.state.ac_amt}
                            onChange={e => this.setState({ac_amt : e.target.value})}
                            error = {this.state.fail_field === "ac_amt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="管理資料設定"
                            SelectProps={{native: true}}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_setup}
                            onChange={e => this.setState({ac_setup : e.target.value})}
                            error = {this.state.fail_field === "ac_setup" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="測評報告"
                            SelectProps={{native: true}}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_rpt}
                            onChange={e => this.setState({ac_rpt : e.target.value})}
                            error = {this.state.fail_field === "ac_rpt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="試卷管理"
                            SelectProps={{native: true}}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_qmgt}
                            onChange={e => this.setState({ac_qmgt : e.target.value})}
                            error = {this.state.fail_field === "ac_qmgt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.handleSubmit} color="primary" style={CSS.justFont}>
				新增
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class EditAdminModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        name: "",
                        email: "",
                        tel: "",
                        uid: "",
                        passwd: "",
                        ac_b2c: "",
                        ac_b2b: "",
                        ac_amt: "",
                        ac_setup:  "",
                        ac_rpt: "",
                        ac_qmgt: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal,
                        name: this.props.admin.name,
                        email: this.props.admin.email,
                        tel: this.props.admin.tel,
                        uid: this.props.admin.uid,
                        passwd: this.props.admin.passwd,
                        ac_b2c: this.props.admin.ac_b2c,
                        ac_b2b: this.props.admin.ac_b2b,
                        ac_amt: this.props.admin.ac_amt,
                        ac_setup: this.props.admin.ac_setup,
                        ac_rpt: this.props.admin.ac_rpt,
                        ac_qmgt: this.props.admin.ac_qmgt,
                    });
    }
    
	fetchModQInfo = () => {
        let send_body = JSON.stringify({ func: "mod_admin",
                                        login_uid: this.props.uid,
                                        name: this.state.name,
                                        email: this.state.email,
                                        tel: this.state.tel,
                                        uid: this.props.admin.uid,
                                        passwd: this.state.passwd,
                                        ac_b2c: this.state.ac_b2c,
                                        ac_b2b: this.state.ac_b2b,
                                        ac_amt: this.state.ac_amt,
                                        ac_setup: this.state.ac_setup,
                                        ac_rpt: this.state.ac_rpt,
                                        ac_qmgt: this.state.ac_qmgt,
            
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchAdminList(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
		
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <IconButton disabled={this.props.auth!=="write"}  onClick={this.toggle}><Create /></IconButton>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="帳號"
							value={this.props.admin.uid}
                            margin="normal"
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            disabled
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="密碼"
							value={this.state.passwd}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState(({passwd : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="姓名"
							value={this.state.name}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState(({name : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="信箱"
							value={this.state.email}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState(({email : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="電話"
                            placeholder="02-12345678/0912-345678"
                            value={this.state.tel}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState(({tel : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="B2C訂單及客戶管理"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_b2c}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            onChange={e => this.setState({ac_b2c : e.target.value})}
                            error = {this.state.fail_field === "ac_b2c" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="B2B訂單及客戶管理"
                            SelectProps={{native: true}}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_b2b}
                            onChange={e => this.setState({ac_b2b : e.target.value})}
                            error = {this.state.fail_field === "ac_b2b" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="總銷售量查詢"
                            SelectProps={{native: true}}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_amt}
                            onChange={e => this.setState({ac_amt : e.target.value})}
                            error = {this.state.fail_field === "ac_amt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="管理資料設定"
                            SelectProps={{native: true}}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_setup}
                            onChange={e => this.setState({ac_setup : e.target.value})}
                            error = {this.state.fail_field === "ac_setup" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="測評報告"
                            SelectProps={{native: true}}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_rpt}
                            onChange={e => this.setState({ac_rpt : e.target.value})}
                            error = {this.state.fail_field === "ac_rpt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="試卷管理"
                            SelectProps={{native: true}}
                            InputProps={{style:CSS.adminScenarioInputStyle}}
                            InputLabelProps={{shrink:true,style:CSS.adminScenarioInputStyle}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_qmgt}
                            onChange={e => this.setState({ac_qmgt : e.target.value})}
                            error = {this.state.fail_field === "ac_qmgt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchModQInfo} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}


class DelAdminModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal,
                        fail_field:"",
                        fail_msg:"",
                    });
    }
    fetchModQInfo = () => {
        let send_body = JSON.stringify({ func: "del_admin",
                                        login_uid: this.props.uid,
                                        uid: this.props.admin.uid,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchAdminList();
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
    }

    render() {
    return (
    <div>
        <IconButton disabled={this.props.auth!=="write"}  onClick={this.toggle}><Delete /></IconButton>
        <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={this.state.modal}
            // onClose={this.toggle}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>刪除</Typography></DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <Typography style={CSS.justFont}>
            {this.state.fail_msg===""?"確認刪除?":this.state.fail_msg}
        </Typography>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
            {this.state.fail_msg===""?
            <Button size="large" onClick={this.fetchModQInfo} color="primary" style={CSS.justFont}>
                確認
            </Button>
            :null}
            <Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
                取消
            </Button>
        </DialogActions>
        </Dialog>
    </div>
    );
    }
}


class Admin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
					subfunction:'總銷售量',
					admin_list_data:[{},{}],
					search_dtype:'ORD',
					search_start:'',
					search_end:'',
					search_name:'',
					search_email:'',
					search_tel:'',
					search_tax_id:'',
					seach_corp_name:'',
					search_mtx_code:'',
					select_type:'',
                    textfieldValue:'',
					};
	}

	fetchAdminList= () => {
		let send_body = JSON.stringify({ func: 'query_admin_list',
									login_uid: this.props.uid,
								});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({admin_list_data:response.result})
			}
		})
		.catch(error => console.error('Error:', error));
	}

	componentDidMount() {
        this.fetchAdminList();
	}

  	render() {
	// const cellStyle = {whiteSpace: 'nowrap'};

	return (
		<div style={{textAlign:'center', display:'block',alignItems:'center'}}>
			<div style ={{display:'flex', flexDirection:'Column' }}>
				<div style={{display:'flex', textAlign:'right', justifyContent:'flex-start', alignItems:'flex-end'}}>
					<AddAdminModal uid={this.props.uid} fetchAdminList={this.fetchAdminList} auth={this.props.auth}/>
				</div>
                <Paper style={{overflowX:'auto',minWidth: "100%", marginTop:'20px', marginBottom:'30px'}}>
                <Table>
                    <TableHead><TableRow style={CSS.headRowStyle}>
                        {AdminHeadRows.map(e=><TableCell align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
                    </TableRow></TableHead>
                    <TableBody>
                        {this.state.admin_list_data.map((admin,index)=>
                            <TableRow key={admin.id} style={CSS.tablebodyStyle(index)}>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.name}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.email}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.tel}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.uid}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.passwd}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.pos}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.ac_b2c}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.ac_b2b}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.ac_amt}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.ac_setup}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.ac_rpt}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{admin.ac_qmgt}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
                                    <EditAdminModal uid={this.props.uid} admin={admin} fetchAdminList={this.fetchAdminList} auth={this.props.auth}/>
                                </TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
                                    {/* <QGroupPanel uid={this.props.uid} admin={admin}/> */}
                                    <DelAdminModal uid={this.props.uid} admin={admin} fetchAdminList={this.fetchAdminList} auth={this.props.auth}/>
                                </TableCell>
                                
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    </div>
			
	);
	}
}

export default Admin;