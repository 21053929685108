import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {api_path,UGCblue} from '../../environment';
import Background from '../../pics/mainBgImg.jpg';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Language from '@material-ui/icons/Language';
import Lock from '@material-ui/icons/Lock';
import Divider from '@material-ui/core/Divider';
import logo2 from '../../pics/logo2.png';
import logo1 from '../../pics/logo1.png';
import logo3 from '../../pics/logo3.png';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from "react-router-dom";
import BottomBar from './bottomBar';

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius:'40px',
                fontSize:'20px',

            },
        },
    },
})(TextField);


class AssessMainPage extends Component {
    constructor(props){  
        super(props);  
		this.state = {  uid: "",
                        passwd: "",
                        fail_msg: "",
                        fail_field: "",
                        role: "ent",
                        language: "chinese",
					};  
    }

	componentDidMount(){
	}

    handleLogin = () => {
        fetch(`${api_path}/ugc/mbr_mgt.php`, {
            method: 'POST',
            body: JSON.stringify({ func:'login',
                                    uid : this.state.uid,
                                    passwd : this.state.passwd,
                                }),
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status === "SUCC"){
                this.props.handleLoginResp(response);
            }
            else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg,passwd:''});
			}
        })
        .catch(error => console.error('Error:', error));
    };


	render() {
        const mainstyle = {
            width: "100vw",
            height: "100vh",
            margin:'-8px',
            padding: '0',
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            overflowX:'hidden',
            position:'fixed',
            fontFamily:'微軟正黑體',
        };

		const mobileButtonStyle={
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'5vw',
			marginTop:'1.2vw',
			width:'28vw',
			fontSize:'16px',
            marginBottom:'4vw',
            fontFamily:'微軟正黑體',
        };
        
		const desktopButtonStyle = {
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.1vw',
            // marginBottom:'2vw',
            fontFamily:'微軟正黑體',
		};
        var isMobile = window.innerWidth <= 500;
        if(!isMobile){
        return (
            <div style={mainstyle}>
                <div style={{height:'12.4vh', width:'100%'}}>
                    <div style={{height:'12.47vh'}}>
                        {/* <Link className="toNothing" to="/" style={{ textDecoration: 'none' }}> */}
                        {/* <Button onClick={()=>window.location.assign("http://ugc.ugcareer.com")} > */}
                        <img src={logo2} alt="Logo2" style={{height:'2.5vw',marginTop:'4vh',marginBottom:'4vh',marginLeft:'4.5vw'}}/>
                        {/* </Button> */}
                        {/* </Link> */}
                    </div>
                </div>
                <div style={{display:'flex', justifyContent:'space-evenly'}}>
                    <div style={{display:'flex',justifyContent:'flex-start', flexDirection:'column',justifyItems:'center',textAlign:'center'}}>
                        <div style={{display:'flex',justifyContent:'center', flexDirection:'column',justifyItems:'center',textAlign:'center',marginBottom:'2vw',marginTop:'5vw'}}>
                            <div style={{display:'flex', textAlign:'center',marginTop:'1vw',justifyContent:'center'}}>
                                <Typography style={{fontFamily:'微軟正黑體',fontSize:'3.6vw',color:"white", textAlign:'center',fontWeight:'bold',whiteSpace: 'nowrap'}}>{"Welcome to "}</Typography>
                                <Typography style={{fontFamily:'微軟正黑體',fontSize:'3.6vw',color:"#BD9022", textAlign:'center',marginLeft:'10px',fontWeight:'bold'}}>{" UGCareer"}</Typography>
                            </div>
                            <Typography style={{color:"white", textAlign:'center', fontSize:'2.2vw',margin:'10px'}}>Assessment Instrument Online</Typography>
                            <Typography style={{fontFamily:'微軟正黑體',color:"white", textAlign:'center', fontSize:'2.7vw',margin:'10px',fontWeight:'bold'}}>UGCareer 測評系統</Typography>
                        </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <Paper style={{width:"28vw",minHeight:'550px',
                        // height:'65vh',
                         borderRadius:'30px', textAlign:'center',marginBottom:'2vw'}}>
                            <Typography 
                            style={{color:"#385B8E", textAlign:'center',marginTop:'4.3vw',marginBottom:'5vh',fontWeight:'bold', fontSize:'2.7vw'}}
                            >LOGIN</Typography>
                            <div style={{display:'flex', justifyContent:'space-evenly',marginLeft:'2vw',marginRight:'2vw'}}>
                                <Button disabled style={{fontFamily:'微軟正黑體',color:UGCblue,fontWeight:'bold',fontSize:'1.1vw'}}>測驗登入</Button>
                            </div>
                            <Grid container spacing={2} style={{marginTop:'3vh',marginBottom:'1vh'}}>
                            <Grid item xs={12} sm={12}>
                                <CssTextField
                                    placeholder={"帳號"}
                                    id="uid"
                                    variant="outlined"
                                    value={this.state.uid}
                                    onChange={e => this.setState({uid: e.target.value})}
                                    InputProps={{
                                    startAdornment: <InputAdornment position="start"><AccountCircle style={{color:'#C9CACA'}}/></InputAdornment>,
                                    style:{fontSize:'1vw',fontFamily:'微軟正黑體'}
                                    }}
                                    style={{width:'18vw',fontFamily:'微軟正黑體',}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <CssTextField 
                                    type="password"
                                    placeholder="密碼"
                                    id="password"
                                    variant="outlined"
                                    value={this.state.passwd}
                                    onChange={e => this.setState({passwd: e.target.value})}
                                    InputProps={{
                                    startAdornment: <InputAdornment position="start"><Lock style={{color:'#C9CACA'}}/></InputAdornment>,
                                    style:{fontSize:'1vw',fontFamily:'微軟正黑體'}
                                    }}
                                    style={{width:'18vw',fontFamily:'微軟正黑體',}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} style={{height:'3vw'}}>
                                <Typography style={{color:"red",fontsize:'1.1vw',fontFamily:'微軟正黑體',marginBottom:'2vw'}}>{this.props.fail_msg}</Typography>
                            </Grid>                            
                            <Grid item xs={12} sm={12} style={{marginBottom:'10vh'}}>
                                <Button onClick={()=>this.props.fetchLogin(this.state.uid,this.state.passwd)} style={desktopButtonStyle}>登入</Button>
                            </Grid>
                        </Grid>
                        </Paper>
                    </div>
                </div>

            </div>
        );
        }
        else {
        return (
        <div style={{width:"100%", height:'100%',position:'absolute',top:'0',left:'0'}}>
            <Paper style={{width:"100%",textAlign:'center',height:'90vh',overflowY:'auto',overflowX:'hidden'}} elevation={0} square={true}>
                <div style={{display:'flex',justifyContent:'center', alignItems:'center', marginBottom:'20vw', marginTop:'6vw'}}>
                    <Language style={{color:UGCblue,fontSize:'3vw'}}/>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Typography style={{marginLeft:'10px', color:UGCblue,fontSize:'3vw'}}>{" Language : "}</Typography>
                        <Select
                            value={this.state.language}
                            onChange={e=>this.setState({language:e.target.value})}
                            disableUnderline
                            style={{marginLeft:'10px',color:UGCblue,fontSize:'3vw'}}
                        >
                            <MenuItem value="chinese" style={{fontFamily:'微軟正黑體'}}>繁體中文</MenuItem>
                        </Select>
                    </div>
                </div>
                
                <img src={logo1} alt="Logo1" style={{height:'30px'}}/>
                <Divider style={{width:'180px',margin:'1.92vh auto', height:'3px', backgroundColor:UGCblue}}/>
                <Typography variant="h5" style={{fontFamily:'微軟正黑體',color:"#385B8E", textAlign:'center',margin:'10px',fontsize:'2.56vh',fontWeight:'700'}}>測評系統</Typography>
                
                <div style={{display:'flex', justifyContent:'space-evenly',marginTop:'40px',marginBottom:'40px'}}>
                    <Button style={{color:UGCblue,fontWeight:'bold',fontSize:'16px',fontFamily:'微軟正黑體'}} disabled onClick={()=>this.setState({role:"user"})}>測評登入</Button>
                </div>
                
                <div style={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',marginTop:'40px'}}>
                    <CssTextField
                        placeholder={"帳號"}
                        id="uid"
                        variant="outlined"
                        value={this.state.uid}
                        onChange={e => this.setState({uid: e.target.value})}
                        InputProps={{
                        startAdornment: <InputAdornment position="start"><AccountCircle style={{color:'#C9CACA'}}/></InputAdornment>,
                        style:{fontSize:'16px',fontFamily:'微軟正黑體'}
                        }}
                        style={{marginBottom:'3.2vh',width:'70vw'}}
                    />
                    <CssTextField type="password"
                        placeholder="Password"
                        id="password"
                        variant="outlined"
                        value={this.state.passwd}
                        onChange={e => this.setState({passwd: e.target.value})}
                        InputProps={{
                        startAdornment: <InputAdornment position="start"><Lock style={{color:'#C9CACA'}}/></InputAdornment>,
                        style:{fontSize:'16px',fontFamily:'微軟正黑體'}
                        }}
                        style={{marginBottom:'4vw',width:'70vw'}}
                    />
                </div>
                {this.state.fail_msg!==""?
                    <Typography style={{color:"red",fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
                :null}
                
                <Grid container spacing={2} style={{marginTop:'50px'}}>
                    <Grid item xs={12} sm={12}>
                        <div style={{display:'flex',justifyContent:'space-evenly'}}>
                        <Button onClick={()=>this.props.fetchLogin(this.state.uid,this.state.passwd)} style={mobileButtonStyle}>登入</Button>
                        {/* <Link className="toOrder" to="/order" style={{ textDecoration: 'none' }}>
                            <Button variant="outlined" style={mobileButtonStyle}>直接訂購</Button>
                        </Link> */}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        
                    </Grid>
                </Grid>
            </Paper>
                <BottomBar/>
            </div>
        );
        }
	}
}

export default AssessMainPage;