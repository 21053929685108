import React from 'react';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AppBar from '@material-ui/core/AppBar';
import B2BPreOrder from './b2b/b2bPreOrder';
import B2BAmount from './b2b/b2bAmount';
import B2BUser from './b2b/b2bUser';
import B2BMonthOrder from './b2b/b2bMonthOrder';
import B2BMonthCheck from './b2b/b2bMonthCheck';

const subfunction = ["預購型訂單","月結型對帳單","月結型發票開立","B2B銷售紀錄","B2B客戶資料"]

class B2B extends React.Component {
	constructor(props) {
		super(props);
		this.state = { subfunction:'預購型訂單', };
	}

	componentDidMount() {
	}

	handleChangeSubfunction = e => { 
		this.setState({ subfunction: e.target.innerText, });
	}
	

  	render() {
	return (
		<div style={{textAlign:'center', display:'block',alignItems:'center'}}>
			<AppBar position="sticky" color="default" 
				style={{position:'fixed',top:'102px',backgroundColor:'white', width:'100%',height:'40px',padding:'0px',boxShadow:'0 2px 2px lightgray,0 -2px black'}}
			>
			<Breadcrumbs aria-label="Breadcrumb">
				{subfunction.map(e => <Button 
				style={e===this.state.subfunction?{fontFamily:'微軟正黑體',display:'flex',borderBottom:"4px solid",padding:'2px',margin:'4px 8px 0px 8px'}:{fontFamily:'微軟正黑體',display:'flex',padding:'4px',margin:'4px 8px 0px 8px'}} 
				size="large" onClick={this.handleChangeSubfunction} color={(e===this.state.subfunction)?'textPrimary':'inherit'}>{e}</Button>)}
			</Breadcrumbs>
			</AppBar>
			<div style={{height:'50px'}}></div>
			{this.state.subfunction==="預購型訂單"?
			<B2BPreOrder uid={this.props.uid} auth={this.props.auth}/>
			:null}
			{this.state.subfunction==="月結型對帳單"?
			<B2BMonthCheck uid={this.props.uid} auth={this.props.auth} />
			:null}
			{this.state.subfunction==="月結型發票開立"?
			<B2BMonthOrder uid={this.props.uid} auth={this.props.auth}/>
			:null}
			{this.state.subfunction==="B2B銷售紀錄"?
			<B2BAmount uid={this.props.uid} auth={this.props.auth}/>
			:null}
            {this.state.subfunction==="B2B客戶資料"?
			<B2BUser uid={this.props.uid} auth={this.props.auth}/>
			:null}
		</div> 
	);
	}
}

export default B2B;