import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {api_path,cellStyle} from '../../../../environment';
import * as CSS from '../../../../css.js';


const qClassHeadRows = ["類別名稱","編輯","刪除"];

class EditQClassModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        qc_seq: "",
                        qc_name: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if(this.state.modal===false){
            this.setState({ qc_name: this.props.c.qc_name,
                            fail_field:"",
                            fail_msg:"",
                            qc_seq: "",
						});
		}
		this.setState(prevState => ({ modal: !prevState.modal }));
    }
    
	fetchQClassMod = () => {
        let send_body = JSON.stringify({ func: "mod_q_class",
                                        login_uid: this.props.uid,
                                        qc_seq: this.props.c.qc_seq,
                                        qc_name: this.state.qc_name,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQClass(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <IconButton disabled={this.props.auth!=="write"} onClick={this.toggle}><Create style={{fontSize:'20px'}}/></IconButton>
            <Dialog
				fullWidth={true}
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯 {this.props.c.qc_name}</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="類別名稱"
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            value={this.state.qc_name}
                            onChange={e => this.setState(({qc_name : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQClassMod} color="primary" style={CSS.justFont}>
				完成
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class AddQClassModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        qc_name: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({ modal: !prevState.modal,
                                    fail_field:"",
                                    fail_msg:"",
                                    qc_seq: "",
                                    qc_name: "",
                                    }));
    }
    
	fetchQClassIns = () => {
        let send_body = JSON.stringify({ func: "ins_q_class",
                                        login_uid: this.props.uid,
                                        qc_name: this.state.qc_name,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQClass(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>新增類別</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>新增類別</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="類別名稱"
                            value={this.state.qc_name}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qc_name : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQClassIns} color="primary" style={CSS.justFont}>
				完成
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class DelQClassModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({ modal: !prevState.modal,
                                        fail_field:"",
                                        fail_msg:"", 
                                    }));
	}

	fetchQClassDel = () => {
        let send_body = JSON.stringify({ func: "del_q_class",
											login_uid: this.props.uid,
											qc_seq: this.props.c.qc_seq,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQClass(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <IconButton disabled={this.props.auth!=="write"} onClick={this.toggle}><Delete style={{fontSize:'20px'}}/></IconButton>
            <Dialog
				fullWidth={true}
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>刪除類別</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Typography style={CSS.justFont}>確定刪除 {this.props.c.qc_name} 類別？</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQClassDel} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class QClassPanel  extends React.Component {
    constructor(props) {
        super(props);
		this.state = { modal: false,
						qg: {},
                        qDetailData:[{}],
                        q_class:[{}],
					};
		this.toggle = this.toggle.bind(this);
	}
	
    toggle() {
        this.fetchQClass();
        this.props.fetchQDetailClass();
		this.setState(prevState => ({ modal: !prevState.modal }));
    }
    
    fetchQClass = () => {
        let send_body = JSON.stringify({ func: "query_q_class",
                                        login_uid: this.props.uid,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({q_class:response.result});
			}
			else if(response.status==="FAIL"){
				
			}
		})
        .catch(error => console.error('Error:', error));
    }
    
	render() {
		// const cellStyle = {whiteSpace: 'nowrap'};
		return (
		<div style = {{display:'inline-block'}}>
            <Button onClick={this.toggle} style={CSS.UGCbtnStyle(false)}>檢視/編輯題目類別</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯類別</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    <Grid container spacing={2} style={{margin:'20px'}}>
                        <Grid item xs={12} sm={12}>
                            <AddQClassModal fetchQClass={this.fetchQClass} uid={this.props.uid} auth={this.props.auth}/>
                        </Grid>
                    </Grid>

                    <Paper style={{overflowX:'auto',minWidth: "100%", marginTop:'20px', marginBottom:'30px'}}>
                        <Table>
                            <TableHead><TableRow style={CSS.headRowStyle}>
                                {qClassHeadRows.map(e=><TableCell align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
                            </TableRow></TableHead>
                            <TableBody>
                                {this.state.q_class.map((c,index)=>
                                <TableRow key={c.qc_seq} style={CSS.tablebodyStyle(index)}>
                                    <TableCell align="center" style={CSS.bodyCellStyle}>{c.qc_name}</TableCell>
                                    <TableCell align="center" style={CSS.bodyCellStyle}>
                                        <EditQClassModal uid={this.props.uid} fetchQClass={this.fetchQClass} c={c} auth={this.props.auth}/>
                                    </TableCell>
                                    <TableCell align="center" style={CSS.bodyCellStyle}>
                                        <DelQClassModal uid={this.props.uid} c={c} fetchQClass={this.fetchQClass} auth={this.props.auth}/>
                                    </TableCell>
                                </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				完成
				</Button>
				</DialogActions>
			</Dialog>
        </div>

        );
    }
}

export default QClassPanel;