import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NativeSelect from '@material-ui/core/NativeSelect';
import IconButton from '@material-ui/core/IconButton';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FileCopy from '@material-ui/icons/FileCopy';
import QGroupPanel from './q_mgt/adminQGroupPanel';
import * as CSS from '../../../css.js';
import {SearchBtn,ClearSearchBtn} from '../buttons';
import {api_path,cellStyle} from '../../../environment';

const qInfoHeadRows = ["試卷編號","版本","測評名稱(點擊查看)","內容簡述","單價","狀態","適用對象","使用次數","建檔日期","修改日期",""];


class DelQInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal,
						fail_field:"",
						fail_msg:"", 
					});
	}

	fetchQInfoDel = () => {
        let send_body = JSON.stringify({ func: "del_q_info",
											login_uid: this.props.uid,
											qi_seq: this.props.qi.qi_seq,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
				this.toggle();
                alert("刪除成功");
                this.props.fetchQueryQInfo();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <IconButton disabled={this.props.auth!=="write" || (this.props.qi.qi_status!=="0" && this.props.qi.qi_status!=="0") } onClick={this.toggle}><Delete /></IconButton>
            <Dialog
				fullWidth={true}
				open={this.state.modal}
				// onClose={this.toggle}
				maxWidth="xs"
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>刪除測評</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Typography style={CSS.justFont}>確定刪除 {this.props.qi.qi_name} 測評？</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQInfoDel} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class AddQInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        qi_id: "",
                        qi_ver: "0",
                        qi_name: "",
                        qi_remark: "",
						qi_status: "",
						qi_price: "0",
						obj: null,
						obj_list: [{}],
                    };
        this.toggle = this.toggle.bind(this);
    }

	toggle() {
		this.setState(prevState => ({ modal: !prevState.modal }));
		this.fetchObjList();
    }
    
	fetchInsQInfo = () => {
        let send_body = JSON.stringify({ func: "ins_q_info",
                                        login_uid: this.props.uid,
                                        qi_id: this.state.qi_id,
                                        qi_ver: this.state.qi_ver,
                                        qi_name: this.state.qi_name,
										qi_remark: this.state.qi_remark,
										qi_price: this.state.qi_price,
										obj: this.state.obj,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQueryQInfo(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
		
	}

	fetchObjList= () => {
        let send_body = JSON.stringify({ func: 'obj_list'});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({obj_list:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}


    handleSubmit=()=>{
        this.fetchInsQInfo();
    }

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>新增</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>新增問卷</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="試卷編號"
							fullWidth
							value={this.state.qi_id}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qi_id : e.target.value}))}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="試卷版本"
							fullWidth
							value={this.state.qi_ver}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qi_ver : e.target.value}))}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="單價"
							fullWidth
							value={this.state.qi_price}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qi_price : e.target.value}))}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							select
							label="狀態"
							SelectProps={{native: true}}
                            value={this.state.qi_status}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
							fullWidth
                            onChange={e => this.setState({qi_status : e.target.value})}
                            error = {this.state.fail_field === "qi_status" ? true:null}
                        >
							<option value={null}></option>
                            <option value={0}>未上線</option>
                            <option value={1}>上線</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							select
							label="適用對象"
							SelectProps={{native: true}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            value={this.state.obj}
							fullWidth
                            onChange={e => this.setState({obj : e.target.value})}
                            error = {this.state.fail_field === "obj" ? true:null}
                        >
							<option value={null}></option>
							{this.state.obj_list.map(obj=><option value={obj.id}>{obj.val}</option>)}
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="內容描述"
                            value={this.state.qi_remark}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            fullWidth
                            onChange={e => this.setState(({qi_remark : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.handleSubmit} color="primary" style={CSS.justFont}>
				新增
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class CopyQInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        qi_id: "",
                        qi_ver: "",
                        qi_name: "",
                        qi_remark: "",
						qi_status: "",
						qi_price: "",
						obj: null,
						obj_list: [{}],
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if(this.state.modal===false){
			this.setState({ qi_id: this.props.qi.qi_id+"-copy",
                            qi_ver: this.props.qi.qi_ver,
                            qi_name: this.props.qi.qi_name+"(複製)",
                            qi_remark: this.props.qi.qi_remark,
							qi_status: this.props.qi.qi_status,
							qi_price: this.props.qi.qi_price,
							obj: this.props.qi.obj,
						});
			
			this.fetchObjList();
		}
		this.setState(prevState => ({ modal: !prevState.modal }));
    }
    
	fetchObjList= () => {
        let send_body = JSON.stringify({ func: 'obj_list'});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({obj_list:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}


	fetchQInfoCpy = () => {
        let send_body = JSON.stringify({ func: "cpy_q_info",
                                        login_uid: this.props.uid,
										qi_seq: this.props.qi.qi_seq,
										qi_id: this.state.qi_id,
										qi_ver: this.state.qi_ver,
                                        qi_name: this.state.qi_name,
                                        qi_remark: this.state.qi_remark,
										qi_status: this.state.qi_status,
										qi_price: this.state.qi_price,
										obj: this.state.obj,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQueryQInfo();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
		
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <IconButton disabled={this.props.auth!=="write"} onClick={this.toggle}><FileCopy/></IconButton>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>複製 {this.props.qi.qi_name}</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="試卷編號"
							fullWidth
							value={this.state.qi_id}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
							onChange={e => this.setState(({qi_id : e.target.value}))}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="試卷版本"
							fullWidth
							value={this.state.qi_ver}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
							onChange={e => this.setState(({qi_ver : e.target.value}))}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="測評名稱"
							fullWidth
							value={this.state.qi_name}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qi_name : e.target.value}))}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="單價"
							fullWidth
							value={this.state.qi_price}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState({qi_price : e.target.value})}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							select
							label="狀態"
							SelectProps={{native: true}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            value={this.state.qi_status}
							fullWidth
                            onChange={e => this.setState({qi_status : e.target.value})}
                            error = {this.state.fail_field === "qi_status" ? true:null}
                        >
							<option value={null}></option>
                            <option value={0}>未上線</option>
                            <option value={1}>上線</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							select
							label="適用對象"
							SelectProps={{native: true}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
							value={this.state.obj}
							fullWidth
                            onChange={e => this.setState({obj : e.target.value})}
                            error = {this.state.fail_field === "is_stu" ? true:null}
                        >
							<option value={null}></option>
							{this.state.obj_list.map(obj=><option value={obj.id}>{obj.val}</option>)}
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="內容描述"
							fullWidth
							value={this.state.qi_remark}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qi_remark : e.target.value}))}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQInfoCpy} color="primary" style={CSS.justFont}>
				複製
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class EditQInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        qi_id: "",
                        qi_ver: "",
                        qi_name: "",
                        qi_remark: "",
						qi_status: "",
						qi_price: "",
						obj: null,
						obj_list: [{}],
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if(this.state.modal===false){
			this.setState({ qi_id: this.props.qi.qi_id,
                            qi_ver: this.props.qi.qi_ver,
                            qi_name: this.props.qi.qi_name,
                            qi_remark: this.props.qi.qi_remark,
							qi_status: this.props.qi.qi_status,
							qi_price: this.props.qi.qi_price,
							obj: this.props.qi.obj,
						});
			this.fetchObjList();
		}
		this.setState(prevState => ({ modal: !prevState.modal }));
    }
    
	fetchObjList= () => {
        let send_body = JSON.stringify({ func: 'obj_list'});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({obj_list:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}


	fetchQInfoMod = () => {
        let send_body = JSON.stringify({ func: "mod_q_info",
                                        login_uid: this.props.uid,
                                        qi_seq: this.props.qi.qi_seq,
                                        qi_name: this.state.qi_name,
                                        qi_remark: this.state.qi_remark,
										qi_status: this.state.qi_status,
										qi_price: this.state.qi_price,
										qi_id: this.state.qi_id,
										qi_ver: this.state.qi_ver,
										obj: this.state.obj,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQueryQInfo();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
		
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <IconButton disabled={this.props.auth!=="write" || (this.props.qi.qi_status!=="0" && this.props.qi.qi_status!=="0")} onClick={this.toggle}><Create/></IconButton>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="試卷編號"
							fullWidth
							value={this.state.qi_id}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qi_id : e.target.value}))}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="試卷版本"
							fullWidth
							value={this.state.qi_ver}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qi_ver : e.target.value}))}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="測評名稱"
							fullWidth
							value={this.state.qi_name}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qi_name : e.target.value}))}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="單價"
							fullWidth
							value={this.state.qi_price}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({qi_price : e.target.value}))}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							select
							label="狀態"
							SelectProps={{native: true}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            value={this.state.qi_status}
							fullWidth
                            onChange={e => this.setState({qi_status : e.target.value})}
                            error = {this.state.fail_field === "qi_status" ? true:null}
                        >
							<option value={null}></option>
                            <option value={0}>未上線</option>
                            <option value={1}>上線</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							select
							label="適用對象"
							SelectProps={{native: true}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            value={this.state.obj}
							fullWidth
                            onChange={e => this.setState({obj : e.target.value})}
                            error = {this.state.fail_field === "obj" ? true:null}
                        >
							<option value={null}></option>
							{this.state.obj_list.map(obj=><option value={obj.id}>{obj.val}</option>)}
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="內容描述"
							fullWidth
							value={this.state.qi_remark}
                            onChange={e => this.setState({qi_remark : e.target.value})}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQInfoMod} color="primary" style={CSS.justFont}>
				完成
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class EditQStatusModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
						qi_status: "",
						obj: null,
						obj_list: [{}],
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if(!this.state.modal){
			this.setState({ qi_status: this.props.qi.qi_status,});
		}
		this.setState(prevState => ({ modal: !prevState.modal }));
    }

	fetchQStatusMod = () => {
        let send_body = JSON.stringify({ func: "mod_q_status",login_uid: this.props.uid,
                                        qi_seq: this.props.qi.qi_seq,
										qi_status: this.state.qi_status,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQueryQInfo();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
		
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={{fontFamily:'微軟正黑體'}}>{this.props.qi.qi_status==="0"?"未上線":null}{this.props.qi.qi_status==="1"?"已上線":null}</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>修改狀態</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							select
							label="狀態"
							SelectProps={{native: true}}
                            value={this.state.qi_status}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
							fullWidth
                            onChange={e => this.setState({qi_status : e.target.value})}
                            error={this.state.fail_field === "qi_status" ? true:null}
                        >
							<option value={null}></option>
							<option value={0}>下線</option>
							<option value={1}>上線</option>
                        </TextField>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQStatusMod} color="primary" style={CSS.justFont}>
				完成
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class Qmanage extends React.Component {
	constructor(props) {
		super(props);
		this.state = { subfunction: '總銷售量',
						q_info_data: [{},{}],
						search_dtype: 'ORD',
						start: "",
						end: "",
						qi_id: "",
						qi_name: "",
						qi_remark: "",
						qi_status: "",
						select_type: "",
						textfieldValue: "",
						obj_list: [{}],
					};
	}

	fetchObjList= () => {
        let send_body = JSON.stringify({ func: 'obj_list'});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({obj_list:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}

	fetchQueryQInfo= () => {
		let send_body = JSON.stringify({ func: 'query_q_info',
										login_uid: this.props.uid,
										start: this.state.start,
										end: this.state.end,
										qi_id: this.state.qi_id,
										qi_name: this.state.qi_name,
										qi_remark: this.state.qi_remark,
										qi_status: this.state.qi_status,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({q_info_data:response.result,
							})
			}
		})
		.catch(error => console.error('Error:', error));
	}

	componentDidMount() {
		this.fetchQueryQInfo();
		this.fetchObjList();
	}

	handleChangeSubfunction = e => { this.setState({subfunction : e.target.innerText, select_type:''}); }
	
	handleResetSearch = () =>{
		this.setState({ search_dtype: 'ORD',
						start: "",
						end: "",
						qi_id: "",
						qi_name: "",
						qi_remark: "",
						qi_status: "",
						select_type: "",
						textfieldValue: "",
					});
		let send_body = JSON.stringify({ func: 'query_q_info',
										login_uid: this.props.uid,
										start: "",
										end: "",
										qi_id: "",
										qi_name: "",
										qi_remark: "",
										qi_status: "",
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({q_info_data:response.result,
							})
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleSelectSearchType = e => {
		if(this.state.select_type === "qi_id"){
			this.setState({ qi_id: e.target.value,
							qi_name: "",
							qi_remark: "",
							qi_status: "",
							textfieldValue: e.target.value,
						});
		}
		else if(this.state.select_type === "qi_name"){
			this.setState({ qi_id: "",
							qi_name: e.target.value,
							qi_remark: "",
							qi_status: "",
							textfieldValue: e.target.value,
						});
		}
		else if(this.state.select_type === "qi_remark"){
			this.setState({ qi_id: "",
							qi_name: "",
							qi_remark: e.target.value,
							qi_status: "",
							textfieldValue: e.target.value,
						});
		}
		else if(this.state.select_type === "qi_status"){
			this.setState({ qi_id: "",
							qi_name: "",
							qi_remark: "",
							qi_status: e.target.value,
							textfieldValue: e.target.value,
						});
		}
	}

	getObjVal = id => {
		if(this.state.obj_list.find(obj => id === obj.id)===undefined)
			return ""
		else
			return this.state.obj_list.find(obj => id === obj.id).val
	}
	
  	render() {
	return (
		<div style={{textAlign:'center',display:'block',alignItems:'center'}}>
			<div style ={{display:'flex',flexDirection:'column'}}>
				<div style={{display:'flex',textAlign:'right',justifyContent:'flex-start',margin:'5px',alignItems:'flex-end'}}>
					<div style={{display:'flex',flexDirection:'column'}}>
						<div style={{display:'flex',alignItems:'baseline'}}>
							<Typography style={CSS.adminInputStyle}>欄位查詢：</Typography>
							<TextField
								id="standard-select-currency-native"
								select
								SelectProps={{native: true}}
								style={{margin:'5px'}} 
								value={this.state.select_type}
								InputProps={{style:CSS.adminInputStyle}}
								InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
								onChange={e=>this.setState({select_type: e.target.value,
															textfieldValue: '',
															qi_id: "",
															qi_name: "",
															qi_remark: "",
															qi_status: "",
														})
								}
							>
								<option value=""></option>
								<option value="qi_id">試卷編號</option>
								<option value="qi_name">測評名稱</option>
								<option value="qi_remark">內容簡述</option>
								<option value="qi_status">狀態</option>
							</TextField>
							<TextField
								id="standard-name"
								margin="dense"
								style={{margin:'5px'}}
								InputProps={{style:CSS.adminInputStyle}}
								InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
								onChange={this.handleSelectSearchType}
								value={this.state.textfieldValue}
							/>
						</div>
						<div style={{display:'flex', alignItems:'baseline'}}>
							<Typography style={CSS.adminInputStyle}>訂購日區間查詢：</Typography>
							<TextField 
								id="date"
								type="date"
								InputProps={{style:CSS.adminInputStyle}}
								InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
								value={this.state.start}
								style={{margin:'5px'}}
								onChange={e=>{this.setState({start:e.target.value})}}
							/>
							<Typography style={CSS.adminInputStyle}>～</Typography>
							<TextField 
								id="date"
								type="date"
								InputProps={{style:CSS.adminInputStyle}}
								InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
								value={this.state.end}
								style={{margin:'5px'}}
								onChange={e=>{this.setState({end:e.target.value})}}
							/>
						</div>
					</div>
					<div style={{display:'flex',alignItems:'center'}}>
					<SearchBtn onClick={this.fetchQueryQInfo}/>
					<ClearSearchBtn onClick={this.handleResetSearch}/>
					<AddQInfoModal uid={this.props.uid} fetchQueryQInfo={this.fetchQueryQInfo} auth={this.props.auth}/>
					</div>
				</div>
                <Paper style={{overflowX:'auto',minWidth: "100%",marginBottom:'30px'}}>
                <Table>
                    <TableHead><TableRow style={CSS.headRowStyle}>
                        {qInfoHeadRows.map(e=><TableCell align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
                    </TableRow></TableHead>
                    <TableBody>
                        {this.state.q_info_data.map((qi,index)=>
                            <TableRow key={qi.id} style={CSS.tablebodyStyle(index)}>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{qi.qi_id}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{qi.qi_ver}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
									<QGroupPanel uid={this.props.uid} qi={qi} auth={this.props.auth}/>
								</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{qi.qi_remark}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{qi.qi_price}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>
									<EditQStatusModal  uid={this.props.uid} qi={qi} fetchQueryQInfo={this.fetchQueryQInfo} auth={this.props.auth}/>
								</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{this.getObjVal(parseInt(qi.obj,10))}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{qi.qi_used}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{qi.create_dtm}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{qi.mod_dtm}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
									<div style={{display:'flex',alignItems:'center'}}>
                                    <EditQInfoModal uid={this.props.uid} qi={qi} fetchQueryQInfo={this.fetchQueryQInfo} auth={this.props.auth}/>
                                    <CopyQInfoModal uid={this.props.uid} qi={qi} fetchQueryQInfo={this.fetchQueryQInfo} auth={this.props.auth}/>
                                    <DelQInfoModal uid={this.props.uid} qi={qi} fetchQueryQInfo={this.fetchQueryQInfo} auth={this.props.auth}/>
									</div>
								</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    </div>
			
	);
	}
}

export default Qmanage;