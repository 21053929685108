import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';

import {api_path,cellStyle,entusagetablebodycellStyle, qResultbodycellStyle,tableheadcellStyle,UGCblue,tablebodyStyle,tableheadrowStyle,entbuttonStyleBlue,entbuttonStyleBlueDisabled} from '../../../environment';
const PreOrderSearchHeadRows=["訂單編號","訂單日","測驗名稱","購買量","使用量","剩餘量"];
const MonthOrderSearchHeadRows=["訂單編號","受測完成日","受測者","測驗名稱"];
const btnStyle = condition=>!condition?entbuttonStyleBlue:entbuttonStyleBlueDisabled;
class BuyMoreModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
                        q_detail:[],
                        qi_seq:"",
                        qname:"",
                        ord_amt:"",
                        submit: false,
                        msg: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal,
                        fail_field: "",
                        fail_msg: "",
                        qi_seq: "",
                        qname: "",
                        ord_amt: "",
                        submit: false,
                        msg: "",
                    });
        this.fetchQquery();
    }
    
    fetchQquery = () => {
        let send_body = JSON.stringify({ func: 'q_summary' , login_uid: this.props.uid,});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({q_detail:response.result});
            }
            else if(response.status==="FAIL"){
            }
        })
        .catch(error => console.error('Error:', error));
    }

    fetchBuyPreorder= () => {
        let send_body = JSON.stringify({ func: "buy_preorder",
                                        login_uid: this.props.uid,
                                        ord_amt: this.state.ord_amt,
                                        qi_seq: this.state.qi_seq,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({msg:response.msg, submit: true });
                this.props.fetchTotalAmount();
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
    }


	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button style={btnStyle(this.props.auth!=="write")} disabled={this.props.auth!=="write"} onClick={this.toggle}>加購</Button>
            {this.state.submit?
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={this.state.modal}
                // onClose={this.toggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體'}}>加購</Typography></DialogTitle>
				<DialogContent>
                <DialogContentText id="alert-dialog-description">
                {this.state.msg}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
                關閉
                </Button>
                </DialogActions>
            </Dialog>
            :
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體'}}>加購</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="測驗名稱"
							value={this.state.qi_seq}
                            onChange={e => this.setState(({qi_seq : e.target.value}))}
                            margin="normal"
                            select
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            SelectProps={{native:true}}
                            fullWidth
							error = {this.state.fail_field==="qc_seq"?true:null}
						>
                            <option value=""></option>
                            {this.state.q_detail.map(q=><option value={q.qi_seq}>{q.qname}</option>)}
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="購買量"
							value={this.state.ord_amt}
                            onChange={e => this.setState(({ord_amt : e.target.value}))}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchBuyPreorder} color="primary" style={{fontFamily:'微軟正黑體'}}>
				加購
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
            }
        </div>
        );
    }
}

class EntUsage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isSearch: true,
                        TotalAmt: {},
                        search_start: "",
                        search_end: "",
                        search_department: "",
                        searchAmt:[],
                        searchTotal:[{}],
                        corpGroup:{department:[],},
                        check_type: "",
                        show_end:"",
                        show_start:"",
                    };
    }

    componentDidMount(){
        this.fetchTotalAmount();
        this.fetchGroup();
        this.fetchSearchAmt();
    }

    fetchTotalAmount = () => {
        let send_body = JSON.stringify({ func:'get_amt_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({TotalAmt:response.result, check_type:response.check_type})
            }
        })
        .catch(error => console.error('Error:', error));
    }

    fetchGroup = () => {
        let send_body = JSON.stringify({ func:'get_group_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corpGroup:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
    }

    handleResetSearch= () => {
        this.setState({search_start:"",search_end:""});
		let send_body = JSON.stringify({ func: 'amt_query',
										login_uid: this.props.uid,
										start:"",
										end:"",
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
                this.setState({ searchAmt:response.result,
                                searchTotal:response.total,
                                show_end: response.end,
                                show_start: response.start,
                            });
			}
		})
		.catch(error => console.error('Error:', error));
    }

	fetchSearchAmt= () => {
		let send_body = JSON.stringify({ func: 'amt_query',
										login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
										department:this.state.search_department,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
                this.setState({ searchAmt:response.result,
                                searchTotal:response.total,
                                show_end: response.end,
                                show_start: response.start,
                            });
			}
		})
		.catch(error => console.error('Error:', error));
    }
    handleSearch = () => {
        this.fetchSearchAmt();
    }

	fetchDownloadAmt=()=> {
        let send_body = JSON.stringify({ func: 'download_amt_info',
                                        login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
                                        check_type: this.state.check_type,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}

    render() {
    // const cellStyle = {whiteSpace: 'nowrap'};

    return (
    <div style={{maxWidth:'750px', width:'100%',textAlign:'left',margin: '0 auto'}}>
	    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center', textAlign:'center', marginBottom:'20px',marginTop:'15px'}}>
            <div style={{display:'flex', justifyContent:'center'}}>
            {this.state.check_type === "0"?
                <Card elevation={0} style={{margin:'20px',width:'180px',borderRadius:'10px',border:'black 1px solid'}}>
                    <Typography style={{fontFamily:'微軟正黑體',color:UGCblue, fontWeight:'600', fontSize:'70px'}}>{this.state.TotalAmt.buy_amt}</Typography>
                    <Typography style={{fontFamily:'微軟正黑體',color:'black', fontWeight:'700', fontSize:'16px',marginBottom:'20px'}}>測評購買數量</Typography>
                </Card>
            :null}
                <Card elevation={0} style={{margin:'20px',width:'180px',borderRadius:'10px',border:'black 1px solid'}}>
                    <Typography style={{fontFamily:'微軟正黑體',color:UGCblue, fontWeight:'600', fontSize:'70px'}}>{this.state.TotalAmt.used_amt}</Typography>
                    <Typography style={{fontFamily:'微軟正黑體',color:'black', fontWeight:'700', fontSize:'16px',marginBottom:'20px'}}>已使用數量</Typography>
                </Card>
            {this.state.check_type === "0"?
                <Card elevation={0} style={{margin:'20px',width:'180px',borderRadius:'10px',border:'black 1px solid'}}>
                    <Typography style={{fontFamily:'微軟正黑體',color:UGCblue, fontWeight:'600', fontSize:'70px'}}>{this.state.TotalAmt.rest_amt}</Typography>
                    <Typography style={{fontFamily:'微軟正黑體',color:'black', fontWeight:'700', fontSize:'16px',marginBottom:'20px'}}>剩餘數量</Typography>
                </Card>
            :null}
            </div>
            {this.state.check_type === "1"?
            <div style={{display:'flex', justifyContent:'center'}}>
            <Typography color="black" style={{fontFamily:'微軟正黑體'}}>自動顯示當月1日至查詢當下之累計使用量</Typography>
            </div>
            :null}
        </div>
        
        {this.state.check_type === "0"?
        <div style={{display:'flex', justifyContent:'center',marginTop:'10px',width:'100%'}}>
        <BuyMoreModal uid={this.props.uid} auth={this.props.auth} fetchTotalAmount={this.fetchTotalAmount}/>
        </div>
        :null}

        <div style={{display:'flex', textAlign:'center',width:'100%',marginTop:'30px'}}>
            <div style ={{display:'flex', flexDirection:'column',width:'100%'}}>
                <div style={{display:'flex', alignItems:'baseline',justifyContent:'space-between'}}>
                    <div style={{display:'flex',alignItems:'baseline',flexDirection:'row'}}>
                    <Typography style={{fontFamily:'微軟正黑體',fontSize:'14px',color:'black'}}>日期區間：</Typography>
                    <TextField 
                        id="search_start"
                        type="date"
                        InputLabelProps={{shrink:true}}
                        value={this.state.search_start}
                        style={{ margin:'5px' }}
                        onChange={e=>{this.setState({search_start:e.target.value})}}
                        style={{margin:'5px',width:'130px'}}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
                    />
                    <Typography style={{fontSize:'14px',color:'black'}}> ～ </Typography>
                    <TextField 
                        id="search_end"
                        type="date"
                        InputLabelProps={{shrink:true}}
                        value={this.state.search_end}
                        style={{ margin:'5px' }}
                        onChange={e=>{this.setState({search_end:e.target.value})}}
                        style={{margin:'5px',width:'130px'}}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
                    />
                    </div>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Button onClick={this.handleSearch} style={{fontFamily:'微軟正黑體',marginRight:'5px',fontSize:'14px'}}><Search style={{fontSize:'14px'}}/>查詢</Button>
                        <Button onClick={this.fetchDownloadAmt} style={{fontFamily:'微軟正黑體',marginRight:'5px',fontSize:'14px'}}><SaveAlt style={{fontSize:'14px'}}/>下載</Button>
                        <Button onClick={this.handleResetSearch} style={{fontFamily:'微軟正黑體',marginRight:'5px',fontSize:'14px'}}>清除查詢</Button>
                    </div>
                </div>
                {this.state.check_type === "0"?
                <Paper style={{marginTop:'10px',marginBottom:'40px',overflowX:'auto'}}>
                    <Table>
                    <TableHead><TableRow style={tableheadrowStyle}>
                        {PreOrderSearchHeadRows.map(e=><TableCell align="center" style={tableheadcellStyle}>{e}</TableCell>)}
                    </TableRow></TableHead>
                    <TableBody>
                    {this.state.searchAmt.map((e,index) => (
                        <TableRow style={tablebodyStyle(index)} key={e.date}>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.po_no}</TableCell>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.ord_date}</TableCell>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.qname}</TableCell>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.ord_amt}</TableCell>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.used_amt}</TableCell>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.rest_amt}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={tablebodyStyle(1)}>
                        <TableCell colSpan={6} align="left" style={{fontFamily:'微軟正黑體',}}>{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}</TableCell>
                    </TableRow>
                    </TableBody>
                    </Table>
                </Paper>
                :null}
                {this.state.check_type === "1"?
                <Paper style={{marginTop:'20px',marginBottom:'40px',overflowX:'auto'}}>
                    <Table>
                    <TableHead><TableRow style={tableheadrowStyle}>
                        {MonthOrderSearchHeadRows.map(e=><TableCell align="center" style={tableheadcellStyle}>{e}</TableCell>)}
                    </TableRow></TableHead>
                    <TableBody>
                    {this.state.searchAmt.map((e,index) => (
                        <TableRow style={tablebodyStyle(index)} key={e.date}>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.po_no}</TableCell>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.qf_date}</TableCell>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.name}</TableCell>
                            <TableCell align="center" style={entusagetablebodycellStyle}>{e.qname}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={tablebodyStyle(1)}>
                        <TableCell  colSpan={5} align="left" style={{fontFamily:'微軟正黑體',}}>{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}</TableCell>
                    </TableRow>
                    </TableBody>
                    </Table>
                </Paper>
                :null}
            </div>
        </div>
    </div>
    );}
}


export default EntUsage;