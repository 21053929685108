import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Create from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {api_path} from '../../../../environment';
import * as CSS from '../../../../css.js';
import {SearchBtn,ClearSearchBtn,CheckAgainMsgBtn,MsgBtn} from '../../buttons';

const b2cUserHeadRows = ["姓名","email","電話","密碼","身份","購買測評紀錄","資料修改"];

class EditB2cUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
						org_uid: "",
						uid: "",
						passwd: "",
						name: "",
						email: "",
						ord_date: "",
						pay_date: "",
						ord_amt: "",
						qname: "",
						qprice: "",
						charged_date: "",
						fail_field: "",
						fail_msg: "",
					};
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
		this.setState({ modal: !this.state.modal,
						passwd: this.props.user.passwd,
						name: this.props.user.name,
						email: this.props.user.email,
						tel: this.props.user.tel,
						is_stu: this.props.user.is_stu,
					});
	}

	handleSubmit = () => {
		let send_body = JSON.stringify({ func: "b2c_mod_user",
										login_uid: this.props.login_uid,
										uid: this.props.user.uid,
										passwd: this.state.passwd,
										name: this.state.name,
										email: this.state.email,
										tel: this.state.tel,
										is_stu: this.state.is_stu,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
				this.toggle();
				this.props.fetchB2cUsers();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div>
            <IconButton onClick={this.toggle} disabled={this.props.auth!=="write"}><Create style={{fontSize:'20px'}}/></IconButton>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>修改資料</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="姓名"
							fullWidth
							value={this.state.name}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
                            onChange={e => this.setState(({name : e.target.value}))}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="email"
							fullWidth
							value={this.state.email}
                            onChange={e => this.setState(({email : e.target.value}))}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="電話"
							fullWidth
							placeholder="02-12345678/0912-345678"
							value={this.state.tel}
                            onChange={e => this.setState(({tel : e.target.value}))}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="密碼"
							fullWidth
							value={this.state.passwd}
                            onChange={e => this.setState(({passwd : e.target.value}))}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
                            id="standard-select-currency-native"
							select
							label="身份"
							fullWidth
                            SelectProps={{native: true}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
                            value={this.state.is_stu}
                            onChange={e => this.setState({is_stu : e.target.value})}
                            error = {this.state.fail_field === "is_stu" ? true:null}
                        >
							<option value={null}></option>
                            <option value={true}>學生</option>
                            <option value={false}>社會人士</option>
                        </TextField>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.handleSubmit} color="primary" style={CSS.justFont}>
				修改
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class B2CUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
					searchB2cUserData:[{tx_rec:[]}],
					search_dtype:'ORD',
					search_start:'',
					search_end:'',
					search_name:'',
					search_email:'',
					search_tel:'',
					search_tax_id:'',
					seach_corp_name:'',
					search_mtx_code:'',
					select_type:'',
					textfieldValue:'',
					checkedValues: [],
					anchorEl: null,
					page_id: 1,
					};
	}

	componentDidMount() {
		this.fetchB2cUsers();
    }
    
	fetchB2cUsers= () => {
		let send_body = JSON.stringify({ func: 'b2c_user_query',
										login_uid: this.props.uid,
										dtype: this.state.search_dtype,
										start:this.state.search_start,
										end:this.state.search_end,
										name:this.state.search_name,
										email:this.state.search_email,
										tel:this.state.search_tel,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2cUserData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	
	handleResetSearch = () => {
		this.setState({ search_name:'',
						search_email:'',
						search_tel:'',
						select_type:'',
						textfieldValue:'',
					});
		let send_body = JSON.stringify({ func: 'b2c_user_query',
										login_uid: this.props.uid,
										name: "",
										email: "",
										tel: "",
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2cUserData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	
	handleChangePage = page_id => {
		let send_body = JSON.stringify({ func: 'b2c_user_query',
										login_uid: this.props.uid,
										dtype: this.state.search_dtype,
										start:this.state.search_start,
										end:this.state.search_end,
										name:this.state.search_name,
										email:this.state.search_email,
										tel:this.state.search_tel,
										page_id:page_id,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2cUserData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	
	handleSelectSearchType = e => {
		if(this.state.select_type === "name"){
			this.setState({ search_name:e.target.value,
							search_tel:'',
							search_email:'',
							textfieldValue:e.target.value,
						});
		}
		else if(this.state.select_type === "tel"){
			this.setState({ search_name:'',
							search_tel:e.target.value,
							search_email:'',
							textfieldValue:e.target.value,
						});
		}
		else if(this.state.select_type === "email"){
			this.setState({ search_name:'',
							search_tel:'',
							search_email:e.target.value,
							textfieldValue:e.target.value,
						});
		}
	}

    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
	}

	fetchDownloadB2cUser=()=> {
        let send_body = JSON.stringify({ func: 'b2c_user_download',
										login_uid: this.props.uid,
										start: this.state.show_start,
										end: this.state.show_end,
										dtoken: this.state.dtoken,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}

  	render() {
	return (
        <div style ={{display:'flex',flexDirection:'column'}}>
            <div style={{display:'flex',textAlign:'right',justifyContent:'flex-start',margin:'5px',alignItems:'flex-end'}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div style={{display:'flex',alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>欄位查詢：</Typography>
						<TextField
							id="standard-select-currency-native"
							select
							SelectProps={{native: true}}
							value={this.state.select_type} 
							onChange={e=>{this.setState({select_type:e.target.value,textfieldValue:''})}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							style={{margin:'5px'}}
						>
							<option value=""></option>
							<option value="name">姓名</option>
							<option value="tel">電話</option>
							<option value="email">email</option>
						</TextField>
                        <TextField
                            id="standard-name"
                            margin="dense"
                            style={{margin:'5px'}}
                            onChange={this.handleSelectSearchType}
                            value={this.state.textfieldValue}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                        />
                    </div>
                </div>
				<div style={{display:'flex',alignItems:'center'}}>
				<SearchBtn onClick={this.fetchB2cUsers}/>
				<ClearSearchBtn onClick={this.handleResetSearch}/>
				</div>
            </div>
            <Paper style={{width:'100%',overflowX:'auto',minWidth: "100%"}}>
                <Table>
                    <TableHead><TableRow style={CSS.headRowStyle}>
                        <TableCell align="center" style={CSS.headCellStyle}>
							<IconButton size="small"onClick={e=>this.setState({anchorEl:e.currentTarget})}><ArrowDropDown/></IconButton>
                            <Menu id="simple-menu"
								anchorEl={this.state.anchorEl}
								keepMounted
								open={Boolean(this.state.anchorEl)}
								onClose={()=>this.setState({anchorEl:null})}
							>
								<MenuItem onClick={()=>{this.setState({anchorEl:null, checkedValues: this.state.searchB2cUserData.map(user => user.uid)});}} style={CSS.justFont}>全選</MenuItem>
								<MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: []})} style={CSS.justFont}>全不選</MenuItem>
							</Menu>
						</TableCell>
                        {b2cUserHeadRows.map(e=><TableCell key={e} align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
                    </TableRow></TableHead>
                    <TableBody>
                        {this.state.searchB2cUserData.map((user,index)=>
                            <TableRow key={user.uid} selected={this.state.checkedValues.includes(user.uid)} style={CSS.tablebodyStyle(index)}>
								<TableCell align="center" style={CSS.bodyCellStyle}>
									<Checkbox 
										label={user.uid} key={user.uid}
										checked={this.state.checkedValues.includes(user.uid)}
										onChange={e => this.handleCheck(e,user.uid)}
									/>
								</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.name}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.email}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{user.tel}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{user.passwd}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{user.is_stu===true?"學生":null}{user.is_stu===false?"社會人士":null}</TableCell>
								<TableCell align="left" style={CSS.bodyCellStyle}>{user.tx_rec.map(rec=><li>{rec}</li>)}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
                                    <EditB2cUserModal user ={user} login_uid={this.props.uid} fetchB2cUsers={()=>this.handleChangePage(this.state.page_id)} auth={this.props.auth}/>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
			<div style={{display:'flex',justifyContent:'space-between'}}>
				<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
					<Typography style={{...{marginRight:'10px'},...CSS.adminInputStyle}}>已選：{this.state.checkedValues.length} 項</Typography>
					<MsgBtn 
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(false)}}
						send_body={JSON.stringify({ func: 'b2c_user_download',
													login_uid: this.props.uid,
													start: this.state.show_start,
													end: this.state.show_end,
													dtoken: this.state.dtoken,
											})
									}
						btn_name="下載"
					/>
					<CheckAgainMsgBtn
						disabled={this.state.checkedValues.length===0||this.props.auth!=="write"}
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(this.state.checkedValues.length===0||this.props.auth!=="write")}}
						send_body={JSON.stringify({ func: 'b2c_user_del',
													login_uid: this.props.uid,
													uid_list: this.state.checkedValues,
											})
									}
						btn_name="刪除"
						check_msg="確認刪除？"
						succProcess={()=>this.handleChangePage(this.state.page_id)}
					/>
				</div>
				<div style={{display:'flex',margin:'20px'}}>
				<Button size="large" disabled={this.state.pre_page===""} onClick={()=>this.handleChangePage(this.state.pre_page)} style={CSS.justFont}>上一頁</Button>
				<Button size="large" disabled={this.state.nxt_page===""} onClick={()=>this.handleChangePage(this.state.nxt_page)} style={CSS.justFont}>下一頁</Button>
				</div>
			</div>
        </div>
	);
	}
}

export default B2CUser;