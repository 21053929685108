import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class MessageModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
					};
		this.toggle = this.toggle.bind(this);
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(this.props.msg !== prevProps.msg){
          this.setState({modal:true});
        }
    }

	toggle() {
		this.setState({ modal: false,});
		if(!this.props.onClose){ this.props.onClose();}
    }
    
	render() {
	return (
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				{this.props.msg}
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={this.toggle} color="primary">關閉</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default MessageModal;