import React from 'react';
import Typography from '@material-ui/core/Typography';

class Clock extends React.Component {
    format(time) {
      let seconds = time % 60;
      let minutes = Math.floor(time / 60);
      minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
      seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
      return minutes + ':' + seconds;
    }
    render () {
      const {time} = this.props;
      return (
          <Typography style={{fontSize:`${this.props.fontSize}`,color:`${this.props.color}`,fontWeight:'bold'}}>{this.format(time)}</Typography>
      )
    }
  }
  
class CountDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { count: 0,
                        running: false,
                    }
    }
    componentDidMount(){
        this.setState({ count: this.props.countdown,
                        running: true,
                    });
	}
	
    componentDidUpdate(prevProps, prevState) {
		if(this.state.running !== prevState.running) {
			if(this.state.running) {
				this.handleStart();
			}
		}
    }
    
    handleStart() {
        this.timer = setInterval(() => {
            const newCount = this.state.count - 1;
            this.setState({count: newCount >= 0 ? newCount : 0});
            this.props.setCountdown(newCount >= 0 ? newCount : 0);
            if(newCount <= 0){
                this.props.fetchAssess();
                this.handleStop();
            }
        }, 1000);
    }
    
    handleStop() {
        if(this.timer) {
            clearInterval(this.timer);
            this.setState({running:false});
        }
    }

    render() {
      return (
        <Clock time={this.state.count} fontSize={this.props.fontSize} color={this.props.color}/>
      )
    }
}
  
export default CountDown;