import React from 'react';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
import {api_path} from '../../environment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export class SearchBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
    }
    
    
	render() {
	return (
        <Button onClick={this.props.onClick} style={{...{margin:'5px',fontSize:'14px',fontFamily:'微軟正黑體'},...this.props.style}}><Search style={{fontSize:'14px'}}/><Typography style={{fontSize:'14px',fontFamily:'微軟正黑體',fontWeight:'500'}}>查詢</Typography></Button>
					
		);
	}
}

export class ClearSearchBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
    }
    
	render() {
	return (
        <Button onClick={this.props.onClick} style={{...{margin:'5px',fontSize:'14px',fontFamily:'微軟正黑體'},...this.props.style}}>清除查詢</Button>			
		);
	}
}



export class MsgBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						status: "loading",
					};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({ modal:!this.state.modal});
		if(!this.state.modal){
			this.setState({ status:"loading",
							msg:"",
						});
		}	
		
	}

	fetchAPI = () => {
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: this.props.send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({status:'SUCC'})
				// for download
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
				if(response.url){
				    var link = document.createElement("a");
                	link.href = response.url;
					link.target="_blank"
					// link.download = response.rpt_fname;
					link.click();
				}
				if(this.props.succProcess!==undefined){
					this.props.succProcess();
				}
				// this.setState({modal:false});
				this.toggle();
			}
			else{
				this.setState({ msg:response.msg,
								status:'FAIL'
							});
			}
		})
        .catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<Button
				style={this.props.style} 
				disabled={this.props.disabled}
				variant={this.props.variant}
				onClick={()=>{ 	this.toggle(); this.fetchAPI(); }}
			>{this.props.btn_name}</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>{this.props.btn_name}</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				{this.state.status==="FAIL"?
				<Typography style={{fontFamily:'微軟正黑體',}}>{this.state.msg}</Typography>
				:
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
				}
                </DialogContentText>
				</DialogContent>
				<DialogActions>
				{this.state.status==="FAIL"?
					<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',}}>關閉</Button>
				:null}
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

export class CheckAgainMsgBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						status: "check",
					};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({modal:!this.state.modal});
		if(!this.state.modal){
			this.setState({ status:"check",
							msg:"",
						});
		}
		
	}

	fetchAPI = () => {
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: this.props.send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({msg:response.msg,status:'SUCC'})
				// for download
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
				if(response.url){
				    var link = document.createElement("a");
                	link.href = response.url;
					link.target="_blank"
					// link.download = response.rpt_fname;
					link.click();
				}
				if(this.props.succProcess!==undefined){
					this.props.succProcess();
				}
				// this.setState({modal:false});
			}
			else{
				this.setState({ msg:response.msg,
								status:'FAIL'
							});
			}
		})
        .catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<Button
				style={this.props.style} 
				disabled={this.props.disabled}
				variant={this.props.variant}
				onClick={()=>{ 	this.toggle();}}
			>{this.props.btn_name}</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>{this.props.btn_name}</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				{this.state.status==="check"?
				<Typography style={{fontFamily:'微軟正黑體',}}>{this.props.check_msg}</Typography>
				:
				this.state.status==="FAIL"?
				<Typography style={{fontFamily:'微軟正黑體',}}>{this.state.msg}</Typography>
				:
				this.state.status==="SUCC"?
				<Typography style={{fontFamily:'微軟正黑體',}}>{this.state.msg}</Typography>
				:
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
				}
                </DialogContentText>
				</DialogContent>
				{this.state.status==="check"?
				<DialogActions>
					<Button onClick={()=>{this.setState({status:'loading'});this.fetchAPI();}} style={{fontFamily:'微軟正黑體',}}>確認</Button>
					<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',}}>取消</Button>
				</DialogActions>
				:this.state.status==="FAIL"?
				<DialogActions>
					<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',}}>關閉</Button>
				</DialogActions>
				:this.state.status==="SUCC"?
				<DialogActions>
					<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',}}>關閉</Button>
				</DialogActions>
				:
				<DialogActions>
				</DialogActions>
				}
			</Dialog>
		</div>
		);
	}
}