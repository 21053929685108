import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QDetailPanel from './adminQDetailPanel';
import * as CSS from '../../../../css.js';
import {api_path,cellStyle} from '../../../../environment';

class EditQGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
						qi_seq: "",
						qg_seq: "",
						qg_order: "",
						qg_topic: "",
						qg_remark: "",
						qg_ans_remark: "",
						qg_num: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if(this.state.modal===false){
			this.setState({ qg_order: this.props.qg.qg_order,
							qg_topic: this.props.qg.qg_topic,
							qg_remark: this.props.qg.qg_remark,
							qg_ans_remark: this.props.qg.qg_ans_remark,
							qg_num: this.props.qg.qg_num,
						});
		}
		this.setState(prevState => ({ modal: !prevState.modal }));
	}

	fetchQGroupMod = () => {
        let send_body = JSON.stringify({ func: "mod_q_group",
											login_uid: this.props.uid,
											qi_seq: this.props.qi_seq,
											qg_seq: this.props.qg.qg_seq,
											qg_order: this.state.qg_order,
											qg_topic: this.state.qg_topic,
											qg_remark: this.state.qg_remark,
											qg_ans_remark: this.state.qg_ans_remark,
											qg_num:	this.state.qg_num,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQGroup(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>編輯題組說明</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="次序"
							value={this.state.qg_order}
							onChange={e => this.setState({qg_order : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="題組標題"
							value={this.state.qg_topic}
                            onChange={e => this.setState({qg_topic : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="作答題數"
							value={this.state.qg_num}
                            onChange={e => this.setState({qg_num : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						/>
					</Grid>
                    <Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="題組說明"
							multiline
							value={this.state.qg_remark}
                            onChange={e => this.setState({qg_remark : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="答題說明"
							fullWidth
							multiline
							value={this.state.qg_ans_remark}
                            onChange={e => this.setState({qg_ans_remark : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQGroupMod} color="primary" style={CSS.justFont}>
				完成
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class AddQGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
						qg_order: "",
						qg_topic: "",
						qg_remark: "",
						qg_ans_remark: "",
						qg_num: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState(prevState => ({ modal: !prevState.modal }));
	}

	fetchQGroupIns = () => {
        let send_body = JSON.stringify({ func: "ins_q_group",
											login_uid: this.props.uid,
											qi_seq: this.props.qi_seq,
											qg_order: this.state.qg_order,
											qg_topic: this.state.qg_topic,
											qg_remark: this.state.qg_remark,
											qg_ans_remark: this.state.qg_ans_remark,
											qg_num:	this.state.qg_num,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQGroup(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>新增題組</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>新增題組</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="次序"
							value={this.state.qg_order}
							onChange={e => this.setState({qg_order : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="題組標題"
							value={this.state.qg_topic}
                            onChange={e => this.setState({qg_topic : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="作答題數"
							value={this.state.qg_num}
                            onChange={e => this.setState({qg_num : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
					</Grid>
                    <Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="題組說明"
							fullWidth
							multiline
							value={this.state.qg_remark}
                            onChange={e => this.setState({qg_remark : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="答題說明"
							fullWidth
							multiline
							value={this.state.qg_ans_remark}
                            onChange={e => this.setState({qg_ans_remark : e.target.value})}
							fullWidth
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQGroupIns} color="primary" style={CSS.justFont}>
				新增
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class DelQGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
                        fail_msg:"",
						qi_seq: "",
						qg_seq: "",
						qg_order: "",
						qg_topic: "",
						qg_remark: "",
						qg_ans_remark: "",
						qg_num: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState(prevState => ({ modal: !prevState.modal }));
	}

	fetchQGroupDel = () => {
        let send_body = JSON.stringify({ func: "del_q_group",
											login_uid: this.props.uid,
											qi_seq: this.props.qi_seq,
											qg_seq: this.props.qg.qg_seq,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
                this.toggle();
                this.props.fetchQGroup(); // renew orginal page's table
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        return (
        <div style = {{display:'inline-block'}}>
            <Button disabled={this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>刪除題組</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>刪除題組</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Typography style={CSS.justFont}>確定刪除 {this.props.qg.qg_topic} 題組？</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchQGroupDel} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class QGroupPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			modal: false,
			qGroupData: [{}],
			fail_field: "",
			fail_msg: "",
			nowQDetailData: [{}],
        };
        this.toggle = this.toggle.bind(this);
	}
	componentDidMount(){
	}
	
    toggle() {
		this.fetchQGroup();
		this.setState(prevState => ({ modal: !prevState.modal }));
	}

	fetchQGroup = () => {
		let send_body = JSON.stringify({ func: "query_q_group",
										login_uid: this.props.uid,
										qi_seq: this.props.qi.qi_seq,
									});
		fetch(`${api_path}/ugc/q_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({qGroupData:response.result,fail_field:"", fail_msg:""});
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
		
        return (
        <div style = {{display:'inline-block'}}>
            <Button onClick={this.toggle} style={CSS.justFont}>{this.props.qi.qi_name}</Button>
            <Dialog
				fullWidth={true}
				maxWidth="lg"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableBackdropClick
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>試卷編號：{this.props.qi.qi_id}</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    <Grid container spacing={2} style={{margin:'20px'}}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={CSS.justFont}>試卷名稱：{this.props.qi.qi_name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography style={CSS.justFont}>版本：{this.props.qi.qi_ver}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AddQGroupModal qi_seq={this.props.qi.qi_seq} uid={this.props.uid} fetchQGroup={this.fetchQGroup} auth={this.props.auth}/>
                        </Grid>
                    </Grid>
                    {this.state.qGroupData.map(qg=>
					<ExpansionPanel>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id={qg.qg_seq}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography style={CSS.justFont}>次序: {qg.qg_order}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <Typography style={CSS.justFont}>題組標題: {qg.qg_topic}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                <Typography style={CSS.justFont}>作答題數: {qg.qg_num}</Typography>
                                </Grid>
                            </Grid>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography style={CSS.justFont}>題組說明:</Typography>
                                    <Typography style={CSS.justFont}>{qg.qg_remark}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography style={CSS.justFont}>答題說明:</Typography>
                                    <Typography style={CSS.justFont}>{qg.qg_ans_remark}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <QDetailPanel qg={qg} uid={this.props.uid} qi={this.props.qi} auth={this.props.auth}/>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <EditQGroupModal qg={qg} qi_seq={this.props.qi.qi_seq} uid={this.props.uid} fetchQGroup={this.fetchQGroup} auth={this.props.auth}/>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <DelQGroupModal qg={qg} qi_seq={this.props.qi.qi_seq} uid={this.props.uid} fetchQGroup={this.fetchQGroup} auth={this.props.auth}/>
                                </Grid>
                            </Grid>
						</ExpansionPanelDetails>
					</ExpansionPanel>
                    )}
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				完成
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

export default QGroupPanel;