import React, { Component } from 'react';
import RenderB2c from './renderB2c.js';
import RenderEnt from './renderEnt.js';
import RenderBackstage from './renderAdmin.js';
import RenderAppBar from '../components/appbar.js';
import {api_path,ver, stage} from '../../environment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MainPage from '../components/mainPage';
import Buy from '../components/buy';
import { Typography } from '@material-ui/core';


class RefreshModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false };
		this.toggle = this.toggle.bind(this);
    }
    
	toggle() {
		this.setState({ modal: false });
    }
    
	render() {
	return (
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={true}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Typography style={{fontFamily:'微軟正黑體'}}>已有最新版本，請重新整理</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button style={{fontFamily:'微軟正黑體'}} size="large" onClick={()=>window.location.reload()} color="primary">重新整理</Button>
                    <Button style={{fontFamily:'微軟正黑體'}} size="large" onClick={this.props.onClose} color="primary">關閉</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = { user_state: "",
                        role: "",
                        uid: "",
                        tkn: "",
                        func_list: [{}],
                        detail: false,
                        img64: "",
                        refresh:false,
                        func:"main",
                    };
    }

    componentDidMount(){
        var tkn = "tkn=";
        var ca = document.cookie.split(';');

        if(ca==""){
            this.setState({user_state:"not_login"});
        }

        for(var i=0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(tkn)===0) {
                this.fetchTokenEval(c.substring(tkn.length,c.length));
            }
        }

        setInterval(() => {
            this.fetchVer();
        }, 5*60*1000);
    }

    fetchVer = () => {
        let send_body = JSON.stringify({ func: 'get_ver' ,});
            fetch(`${api_path}/ugc/misc_mgt.php`, {
                    method: 'POST',
                    body: send_body,
                    headers: new Headers(),
            })
            .then(res => res.json())
            .then(response => {
                if(response.ver===ver){
                    this.setState({refresh: false});
                }
                else{
                    this.setState({refresh: true});
                }
            });
    }
    
    fetchTokenEval = tkn => {
        fetch(`${api_path}/ugc/mbr_mgt.php`, {
            method: 'POST',
            body: JSON.stringify({ func: 'tkn_eval',
                                    tkn : tkn,
                                }),
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status === "SUCC"){
                this.setState({ name: response.name,
                                role: response.role,
                                uid: response.login_id,
                                user_state : "login",
                                func_list: response.func_list,
                            });
                if(response.role==="enterprise"){
                    this.fetchCorpData(response.login_id);
                }
                this.fetchLogo(response.login_id);
            }
            else{
                this.setState({ user_state : "not_login",});
            }
        })
        .catch(error => console.error('Error:', error));
    }

	fetchLogo = uid => {
		let send_body = JSON.stringify({ func:'get_logo_path',
										login_uid:uid,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({img64:response.img64});
			}
			else if(response.status==="FAIL"){
				this.setState({img64:""});
			}
		})
		.catch(error => console.error('Error:', error));
    }
    
    handleLoginResp = resp => {
        this.setState({ role: resp.role,
                        uid: resp.login_id,
                        tkn: resp.tkn,
                        func_list: resp.func_list, 
                        user_state: "login",
                        name: resp.name,
                    });
        if(resp.role==="enterprise"){
            this.fetchCorpData(resp.login_id);
        }
        this.fetchLogo(resp.login_id);
        document.cookie = "tkn=" + resp.tkn + "; ";
    }

    fetchCorpData = uid => {
        let send_body = JSON.stringify({ func:'get_corp_data',
                                        login_uid:uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corp_name:response.corp_name})
            }
        })
        .catch(error => console.error('Error:', error));
    }

    handleLogout = () => { 
        this.setState({ user_state: "not_login", 
                        role: "",
                        uid: "",
                        tkn: "",
                        func_list: [{}],
                        img64: "",
                    }); 
        document.cookie = "tkn=;expires=Thu, 01 Jan 1970 00:00:00 GMT; "
    }
    
    changeFunc = e => this.setState({func: e})

    back = () => this.setState({detail:false})
    
    showDetail = () => this.setState({detail:true})

    render(){
        let render;
        if (this.state.user_state === "login"){
            if (this.state.role === "b2c"){
                render = <RenderB2c uid = {this.state.uid}/>
            }
            else if (this.state.role === "enterprise"){
                render = <RenderEnt uid = {this.state.uid} func_list={this.state.func_list} handleLogout={this.handleLogout}/>
            }
            // else if (this.state.role === "dealer"){
            //     render = <RenderDealer uid = {this.state.uid}/>
            // }
            else if (this.state.role === "admin"){
                render = <RenderBackstage uid = {this.state.uid} func_list={this.state.func_list}/>
            }
        }
        return (
                <div>
                    {this.state.user_state === "not_login" && this.state.func === "main"? 
                    <MainPage handleLoginResp={this.handleLoginResp} changeFunc={this.changeFunc}/>
                    :
                    this.state.func === "buy"?
                    <Buy changeFunc={this.changeFunc}/>
                    :
                    <RenderAppBar 
                        user_state={this.state.user_state}
                        handleLogOut={this.handleLogout}
                        role={this.state.role}
                        showDetail={this.showDetail} 
                        back={this.back}
                        name={this.state.name}
                        handleLoginResp={this.handleLoginResp}
                        img64={this.state.img64}
                        corp_name={this.state.corp_name}
                    />
                    }

                    {this.state.user_state === "not_login" ? null : render}

                    {this.state.refresh&& stage==="prod"?<RefreshModal onClose={()=>this.setState({refresh:false})}/>:null}
                    
                </div>
           
        );
        
    }
  }
  
  export default Main;
  