import React, { Component } from 'react';
import RenderAssess from './renderAssess.js';
import RenderAppBar from '../components/appbar.js';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {api_path} from '../../environment';
import AssessMainPage from '../components/assessMainPage.js';
import Background from '../../pics/mainBgImg.jpg';
import { WSAENETDOWN } from 'constants';

class Assess extends Component {
    constructor(props) {
        super(props);
		this.state = { page_id: "",
						nxt_token: "",
						name: "",
						img64: "",
						corp_name: "",
						uid: "",
						passwd: "",
						notice: "",
						visible: false,
						p5_resp: {},
						p4_resp: {},
						url: "",
        			};
    }
    componentDidMount(){
		const { token } = this.props.match.params;
		if(token !== undefined){
            this.fetchToken(token);
		}
		else{
			this.setState({page_id: "-1"});
			// DEBUG
			// this.setState({page_id: 5});
		}
	}
	
	fetchLogin = (uid,passwd) => {
		let send_body = JSON.stringify({ func:'login',
										uid: uid,
										passwd: passwd,
                                    });
        fetch(`${api_path}/ugc/assess_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ nxt_token: response.nxt_token,
								page_id: response.page_id,
								name: response.name,
								img64: response.logo,
								corp_name: response.corp_name,
								notice: response.notice,
								qa_info: response.qa_info,
								countdown: response.countdown,
								corp_name_abbr: response.corp_name_abbr,
							});
				if(response.page_id===3){
					this.setState({ notice1: response.notice1,
									notice2: response.notice2,
									image: response.image,
					});
				}
				if(response.page_id===4){
					this.setState({ p4_resp: response,});
				}
				if(response.page_id===5){
					this.setState({ p5_resp: response,});
				}
				if(response.page_id===8){
					this.setState({ page_id: response.page_id,
									msg: response.msg,
									url: response.url
								});
				}
			}
			else if(response.status==="FAIL"){
				
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
			
        })
        .catch(error => console.error('Error:', error));
	}
	
    fetchToken = token => {
        let send_body = JSON.stringify({ func:'do_login',
                                    	token:token,
                                    });
        fetch(`${api_path}/ugc/assess_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ nxt_token: response.nxt_token,
								page_id: response.page_id,
								name: response.name,
								img64: response.logo,
								corp_name: response.corp_name,
								notice: response.notice,
								qa_info: response.qa_info,
								countdown: response.countdown,
								corp_name_abbr: response.corp_name_abbr,
							});
				if(response.page_id===3){
					this.setState({ notice: response.notice,
									notice1: response.notice1,
									notice2: response.notice2,
									image: response.image,
					});
				}
				if(response.page_id===4){
					this.setState({ p4_resp: response,});
				}
				if(response.page_id===5){
					this.setState({ p5_resp: response,});
				}
				if(response.page_id===6){
					this.setState({ url: response.url,});
				}
				if(response.page_id===7){
					this.setState({ rpt_token: response.token, url: response.url});
				}
				if(response.page_id===8){
					this.setState({ msg: response.msg, url: response.url });
				}
			}
			
			else if(response.status==="FAIL"){
				this.setState({ fail_field:response.fail_field, 
								fail_msg:response.msg,
								page_id: "FAIL"
							});
			}
        })
        .catch(error => console.error('Error:', error));
    }

    render(){
	return (
		<div>
			{this.state.page_id==="FAIL"?
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={true}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">測評登入</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<Typography>{this.state.fail_msg}</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" color="primary" onClick={()=>window.location.assign("http://ugc.ugcareer.com/assess")}>
				返回測評登入頁
				</Button>

				</DialogActions>
			</Dialog>
			:null}
			{this.state.page_id === "-1" ? 
			<AssessMainPage fetchLogin={this.fetchLogin} fail_msg={this.state.fail_msg}/>
			:null}
			{this.state.page_id === ""?null:null}
			{this.state.page_id !== "" && this.state.page_id !== "-1" && this.state.page_id!=="FAIL"?
			<RenderAssess 
				corp_name_abbr={this.state.corp_name_abbr}
				name={this.state.name}
				countdown={this.state.countdown}
				page_id={this.state.page_id} 
				nxt_token={this.state.nxt_token}
				notice={this.state.notice}
				notice1={this.state.notice1}
				notice2={this.state.notice2}
				image={this.state.image}
				qa_info={this.state.qa_info}
				p5_resp={this.state.p5_resp}
				p4_resp={this.state.p4_resp}
				url={this.state.url}
				rpt_token={this.state.rpt_token}
				msg={this.state.msg}
			/>
			:null}
		</div>
	);
        
    }
  }
  
  export default Assess;
  