import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {api_path,UGCblue} from '../../environment';
import Background from '../../pics/mainBgImg.jpg';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Language from '@material-ui/icons/Language';
import Lock from '@material-ui/icons/Lock';
import Divider from '@material-ui/core/Divider';
import logo2 from '../../pics/logo2.png';
import logo1 from '../../pics/logo1.png';
import logo3 from '../../pics/logo3.png';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from "react-router-dom";
import BottomBar from './bottomBar';
import BtnImg1 from '../../pics/btn1.png';
import BtnImg2 from '../../pics/btn2.png';
import BtnImg3 from '../../pics/btn3.png';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";



const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius:'40px',
                fontSize:'20px',
            },
        },
    },
})(TextField);


class ForgetPasswdModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        email: "",
                        msg: "",
					};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal});
        if(!this.state.modal){
            this.setState({ email: "",
                            msg: "",
                        });
        }
    }

    handleForgetPasswd = () => {
        fetch(`${api_path}/ugc/mbr_mgt.php`, {
            method: 'POST',
            body: JSON.stringify({ func:'find_passwd',
                                    email: this.state.email,
                                }),
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status === "SUCC"){
                this.setState({msg: response.msg});
            }
            else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
    };

	

    render() {
        return (
        <div>
            <Button style={{fontFamily:'微軟正黑體',color:'gray',fontSize:!this.props.isMobile?'1.1vw':'14px',marginBottom:'2vw'}} onClick={this.toggle} >忘記密碼?</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" style={{fontFamily:'微軟正黑體',}}><Typography style={{fontFamily:'微軟正黑體'}}>忘記密碼</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    {this.state.msg===""?
                    <div style={{display:'flex',alignItems:'baseline'}}>
                        {!this.props.isMobile?
                        <Typography noWrap style={{fontFamily:'微軟正黑體',}}>請輸入email：</Typography>
                        :null}
                        <TextField
                            placeholder={!this.props.isMobile?null:"請輸入email"}
                            id="email"
                            value={this.state.email}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
                            onChange={e => this.setState({email: e.target.value})}
                            fullWidth={this.props.isMobile}
                            style={{fontFamily:'微軟正黑體',}}
                        />
                    </div>
                    :
                    <Typography style={{fontFamily:'微軟正黑體',}}>{this.state.msg}</Typography>
                    }
				</DialogContentText>
				</DialogContent>
                {this.state.msg===""?
				<DialogActions>
				<Button size="large" onClick={this.handleForgetPasswd} style={{fontFamily:'微軟正黑體',color:UGCblue}}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} style={{fontFamily:'微軟正黑體',color:UGCblue}}>
				取消
				</Button>
				</DialogActions>
                :
                <DialogActions>
				<Button size="large" onClick={this.toggle} style={{fontFamily:'微軟正黑體',color:UGCblue}}>
				關閉
				</Button>
				</DialogActions>
                }
			</Dialog>
        </div>
        );
    }
}

class MainPage extends Component {
    constructor(props){  
        super(props);  
		this.state = {  uid: "",
                        passwd: "",
                        fail_msg: "",
                        fail_field: "",
                        role: "ent",
                        language: "chinese",
					};  
    }

	componentDidMount(){
	}

    handleLogin = () => {
        fetch(`${api_path}/ugc/mbr_mgt.php`, {
            method: 'POST',
            body: JSON.stringify({ func:'login',
                                    uid : this.state.uid,
                                    passwd : this.state.passwd,
                                }),
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status === "SUCC"){
                this.props.handleLoginResp(response);
            }
            else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg,passwd:''});
			}
        })
        .catch(error => console.error('Error:', error));
    };


	render() {
        const mainstyle = {
            width: "100%",
            height: "100%",
            margin:'-8px',
            padding: '0',
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // backgroundSize: "100% 100%",
            backgroundAttachment: "fixed",
            // overflowY:'hidden',
            position:'fixed',
            fontFamily:'微軟正黑體',
        };
        const mainstyle2 = {
            width: "100vw",
            height: "100vh",
            margin:'-8px',
            padding: '0',
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            overflowX:'hidden',
            position:'fixed',
            fontFamily:'微軟正黑體',
        };

		const mobileButtonStyle={
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'5vw',
			width:'28vw',
			fontSize:'14px',
            fontFamily:'微軟正黑體',
        };
        
		const desktopButtonStyle = {
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.1vw',
            // marginBottom:'2vw',
            fontFamily:'微軟正黑體',
        };


        if(isBrowser){
        return (
            <div style={mainstyle2}>
                <div style={{height:'12.4vh', width:'100%'}}>
                    <div style={{height:'12.47vh'}}>
                        <Link className="toNothing" to="/" style={{ textDecoration: 'none' }}>
                        {/* <Button onClick={()=>window.location.assign("http://ugc.ugcareer.com")} > */}
                        <img src={logo2} alt="Logo2" style={{height:'2.5vw',marginTop:'4vh',marginBottom:'4vh',marginLeft:'4.5vw'}}/>
                        {/* </Button> */}
                        </Link>
                    </div>
                </div>
                <div style={{display:'flex', justifyContent:'space-evenly'}}>
                    <div style={{display:'flex',justifyContent:'space-around', flexDirection:'column',justifyItems:'center',textAlign:'center'}}>
                        
                        <div style={{display:'flex',justifyContent:'center', flexDirection:'column',justifyItems:'center',textAlign:'center',marginBottom:'2vw',marginTop:'3vw'}}>
                            <div style={{display:'flex', textAlign:'center',marginTop:'1vw',justifyContent:'center'}}>
                                <Typography style={{fontFamily:'微軟正黑體',fontSize:'3.6vw',color:"white", textAlign:'center',fontWeight:'bold',whiteSpace: 'nowrap'}}>{"Welcome to "}</Typography>
                                <Typography style={{fontFamily:'微軟正黑體',fontSize:'3.6vw',color:"#BD9022", textAlign:'center',marginLeft:'10px',fontWeight:'bold'}}>{" UGCareer"}</Typography>
                            </div>
                            <Typography style={{color:"white", textAlign:'center', fontSize:'2.2vw',margin:'10px'}}>Assessment Instrument Online</Typography>
                            <Typography style={{fontFamily:'微軟正黑體',color:"white", textAlign:'center', fontSize:'2.7vw',margin:'10px',fontWeight:'bold'}}>UGCareer 測評系統</Typography>
                        </div>
                        <div style={{display:'flex', justifyContent:'center',marginBottom:'5vw'}}>
                            <Link className="toOrder" to="/order" style={{ textDecoration: 'none' }}>
                                <Button style={{padding:'0',backgroundImage:`url(${BtnImg1})`,height:'3.055vw',width:'9vw',margin:'1vw',backgroundSize: '100%',backgroundRepeat: "no-repeat"}}/>
                            </Link>
                            <Button 
                                onClick={()=>window.location.assign("https://www.ugc.com.tw/assessment.html")}
                                style={{padding:'0',backgroundImage:`url(${BtnImg2})`,height:'3.055vw',width:'9vw',margin:'1vw',backgroundSize: '100%',backgroundRepeat: "no-repeat"}}
                            />
                            <Button 
                                onClick={()=>window.location.assign("https://www.ugc.com.tw/contact.html")}
                                style={{padding:'0',backgroundImage:`url(${BtnImg3})`,height:'3.055vw',width:'9vw',margin:'1vw',backgroundSize: '100%',backgroundRepeat: "no-repeat"}}
                            />
                        </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <Paper style={{width:"28vw",minHeight:'550px',
                        // height:'65vh',
                         borderRadius:'30px', textAlign:'center',marginBottom:'2vw'}}>
                            <Typography 
                            style={{color:"#385B8E", textAlign:'center',margin:'4.3vw',fontWeight:'bold', fontSize:'2.7vw'}}
                            >LOGIN</Typography>
                            <div style={{display:'flex', justifyContent:'space-evenly',marginLeft:'2vw',marginRight:'2vw'}}>
                                <Button style={"ent"===this.state.role?{fontFamily:'微軟正黑體',color:UGCblue,fontWeight:'bold',fontSize:'1.1vw'}:{fontFamily:'微軟正黑體',color:'#C9CACA',fontSize:'1.1vw',fontWeight:'bold'}} onClick={()=>this.setState({role:"ent"})}>企業登入</Button>
                                <Button style={"user"===this.state.role?{fontFamily:'微軟正黑體',color:UGCblue,fontWeight:'bold', fontSize:'1.1vw'}:{fontFamily:'微軟正黑體',color:'#C9CACA',fontSize:'1.1vw',fontWeight:'bold'}} onClick={()=>this.setState({role:"user"})}>個人登入</Button>
                            </div>
                            <Grid container spacing={2} style={{marginTop:'3vh',marginBottom:'1vh'}}>
                            <Grid item xs={12} sm={12}>
                                <CssTextField
                                    placeholder={this.state.role==="ent"?"統一編號 / 帳號":"Email Address"}
                                    id="uid"
                                    variant="outlined"
                                    value={this.state.uid}
                                    onChange={e => this.setState({uid: e.target.value})}
                                    InputProps={{
                                    startAdornment: <InputAdornment position="start"><AccountCircle style={{color:'#C9CACA'}}/></InputAdornment>,
                                    style: {fontFamily:'微軟正黑體'}
                                    }}
                                    style={{width:'18vw',fontFamily:'微軟正黑體',}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <CssTextField 
                                    type="password"
                                    placeholder="Password"
                                    id="password"
                                    variant="outlined"
                                    value={this.state.passwd}
                                    onChange={e => this.setState({passwd: e.target.value})}
                                    InputProps={{
                                    startAdornment: <InputAdornment position="start"><Lock style={{color:'#C9CACA'}}/></InputAdornment>,
                                    style: {fontFamily:'微軟正黑體'}
                                    }}
                                    style={{width:'18vw',fontFamily:'微軟正黑體',}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} style={{height:'3vw'}}>
                                <Typography style={{color:"red",fontsize:'1.1vw',fontFamily:'微軟正黑體',marginBottom:'2vw'}}>{this.state.fail_msg}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button disabled={this.state.uid==="" || this.state.passwd===""} onClick={this.handleLogin} style={desktopButtonStyle}>登入</Button>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <ForgetPasswdModal uid={this.props.uid}/>
                            </Grid>
                            </Grid>
                        </Paper>
                    </div>
                </div>

            </div>
        );
        }
        // else {
        else if (isMobile){
        return (
        <div style={{width:"100%", height:'100%',position:'absolute',top:'0',left:'0'}}>
            <Paper style={{width:"100%",textAlign:'center',height:'90vh',overflowY:'auto',overflowX:'hidden'}} elevation={0} square={true}>
                <div style={{display:'flex',justifyContent:'center', alignItems:'center', marginBottom:'50px', marginTop:'30px'}}>
                    <Language style={{color:UGCblue,fontSize:'3vw'}}/>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Typography style={{fontFamily:'微軟正黑體',marginLeft:'10px', color:UGCblue,fontSize:'3vw'}}>{" Language : "}</Typography>
                        <Select
                            value={this.state.language}
                            onChange={e=>this.setState({language:e.target.value})}
                            disableUnderline
                            style={{mfontFamily:'微軟正黑體',arginLeft:'10px',color:UGCblue,fontSize:'3vw'}}
                        >
                            <MenuItem value="chinese" style={{fontFamily:'微軟正黑體'}}>繁體中文</MenuItem>
                        </Select>
                    </div>
                </div>
                <img src={logo1} alt="Logo1" style={{height:'30px'}}/>
                <Divider style={{width:'180px',margin:'1.92vh auto', height:'3px', backgroundColor:UGCblue}}/>
                <Typography variant="h5" style={{fontFamily:'微軟正黑體',color:"#385B8E", textAlign:'center',margin:'10px',fontsize:'2.56vh',fontWeight:'700'}}>測評系統</Typography>
                
                <div style={{display:'flex', justifyContent:'space-evenly',marginTop:'40px',marginBottom:'3.2vh'}}>
                    <Button style={"ent"===this.state.role?{color:UGCblue,fontWeight:'bold',fontSize:'16px',fontFamily:'微軟正黑體'}:{fontFamily:'微軟正黑體',color:'#C9CACA',fontSize:'16px'}} onClick={()=>this.setState({role:"ent"})}>企業登入</Button>
                    <Button style={"user"===this.state.role?{color:UGCblue,fontWeight:'bold',fontSize:'16px',fontFamily:'微軟正黑體'}:{fontFamily:'微軟正黑體',color:'#C9CACA',fontSize:'16px'}} onClick={()=>this.setState({role:"user"})}>個人登入</Button>
                </div>
                <div style={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center'}}>
                    <CssTextField
                        placeholder={this.state.role==="ent"?"統一編號 / 帳號":"Email Address"}
                        id="uid"
                        variant="outlined"
                        value={this.state.uid}
                        onChange={e => this.setState({uid: e.target.value})}
                        InputProps={{
                        startAdornment: <InputAdornment position="start"><AccountCircle style={{color:'#C9CACA'}}/></InputAdornment>,
                        style: {fontFamily:'微軟正黑體'}
                        }}
                        style={{marginBottom:'3.2vh',width:'70vw',maxWidth:'800px'}}
                    />
                    <CssTextField type="password"
                        placeholder="Password"
                        id="password"
                        variant="outlined"
                        value={this.state.passwd}
                        onChange={e => this.setState({passwd: e.target.value})}
                        InputProps={{
                        startAdornment: <InputAdornment position="start"><Lock style={{color:'#C9CACA'}}/></InputAdornment>,
                        style: {fontFamily:'微軟正黑體'}
                        }}
                        style={{marginBottom:'4vw',width:'70vw',maxWidth:'800px'}}
                    />
                </div>
                <Typography style={{color:"red",fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
                
                <Grid container spacing={1} style={{marginTop:'30px'}}>
                    <Grid item xs={12} sm={12}>
                        <div style={{display:'flex',justifyContent:'space-evenly'}}>
                        <Button onClick={this.handleLogin} style={mobileButtonStyle}>登入</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Link className="toOrder" to="/order" style={{ textDecoration: 'none' }}>
                            <Button style={{...mobileButtonStyle,...{marginTop:'12px'}}}>直接訂購</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <ForgetPasswdModal uid={this.props.uid} isMobile={true}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        
                    </Grid>
                </Grid>
            </Paper>
            <BottomBar/>
        </div>
        );
        }
	}
}

export default MainPage;