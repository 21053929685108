import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import EntGroup from '../components/ent/entGroup';
import EntUsage from '../components/ent/entUsage';
import DisTable from '../components/ent/entDistribute';
import EntQresult from '../components/ent/entQresult';
import EntInformation from '../components/ent/entInformation';
import EntPasswd from '../components/ent/entPasswd';
import * as CSS from '../../css';

class RenderEnt extends Component {
    constructor(props){  
        super(props);  
        this.state = {func_now : ""};
    }
    
    componentDidMount(){
        this.setState({func_now: this.props.func_list[0].name});
    }

	render() {
		return (
            <div style = {{ textAlign:'center', width:'100%' }}>
                <AppBar position="sticky" color="default" 
                        style={{position:'fixed', top:'65px', verticalAlign:'middle', maxHeight:'60px', minHeight:'60px', background:'white'}}>
                    <Toolbar style={{verticalAlign:'middle',paddingLeft:'40px'}}>
                        {this.props.func_list.map(func=>func.name).map(func_name => 
                            <Button key={func_name} size="large" onClick={e=>this.setState({func_now:e.target.innerText})}
                                    style={CSS.ent_appbarBtnStyle(func_name,this.state.func_now)}
                            >{func_name}</Button>
                        )}
                    </Toolbar>
                </AppBar>
                
                <div style ={{display:'inline-block', width:'100%', textAlign:'center' }}>
                {this.state.func_now === "使用量查詢" ?
                    <EntUsage uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="使用量查詢").auth}/>
                    :null
                }
                {this.state.func_now === "企業資料設定" ?
                    <EntInformation uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="企業資料設定").auth}/>
                    :null
                }
                {this.state.func_now === "測評分發設定" ?
                    <DisTable uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="測評分發設定").auth}/>
                    :null
                }
                {this.state.func_now === "群組設定" ?
                    <EntGroup  uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="群組設定").auth}/>
                    :null
                }
                {this.state.func_now === "測評報告及分類統計" ?
                    <EntQresult uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="測評報告及分類統計").auth}/>
                    :null
                }
                {this.state.func_now === "密碼修改" ?
                    <EntPasswd uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="密碼修改").auth} handleLogout={this.props.handleLogout}/>
                    :null
                }
                {this.state.func_now === "" ? null:null }
                </div>
            </div>
		);
	}
}

export default RenderEnt;


