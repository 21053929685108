import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Create from '@material-ui/icons/Create';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {api_path,cellStyle} from '../../../../environment';
import * as CSS from '../../../../css.js';
import {SearchBtn,ClearSearchBtn,MsgBtn,CheckAgainMsgBtn} from '../../buttons';

const monthOrderHeadRows = ["經銷商名稱","企業名稱","統編","測評名稱","訂購量","測評單價","銷售額","使用區間","發票號碼","發票日期","匯款確認","主聯絡人","聯絡人電話","聯絡人email"];


class Show extends React.Component {
    constructor(props) {
        super(props);
		this.state = { modal: false,
						qprice: "",
						fail_msg: "",
						fail_field: "",
						detail: [{}],
					};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.fetchB2bMonthOrderDetail();
		this.setState({ modal: !this.state.modal,
						fail_msg:"",
						fail_field:"",
					});
    }
    
    fetchB2bMonthOrderDetail=()=>{
        let send_body = JSON.stringify({ func:'b2b_month_order_detail',
										login_uid: this.props.uid,
										txid: this.props.mOrder.txid,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({detail:response.result});
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
    }

    render() {
		return (
        <div>
			<Button variant="text" onClick={this.toggle} style={CSS.justFont}>{this.props.mOrder.ord_amt}</Button>
			<Dialog
				fullWidth={true}
				maxWidth="lg"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>訂購量詳細資料</Typography></DialogTitle>
				<DialogContent>
				<Paper style={{width:'100%', overflowX:'auto',minWidth: "100%", marginTop:'20px',  marginBottom:'30px'}}>
                <Table>
                	<TableHead><TableRow>
                        <TableCell style={{...cellStyle,...CSS.justFont}}>受測完成日</TableCell>
						<TableCell style={{...cellStyle,...CSS.justFont}}>訂單編號</TableCell>
						<TableCell style={{...cellStyle,...CSS.justFont}}>測評名稱</TableCell>
						<TableCell style={{...cellStyle,...CSS.justFont}}>測評單價</TableCell>
						<TableCell style={{...cellStyle,...CSS.justFont}}>姓名</TableCell>
						<TableCell style={{...cellStyle,...CSS.justFont}}>職稱</TableCell>
                    </TableRow></TableHead>
                    <TableBody>
					{this.state.detail.map(detail => (
						<TableRow key={detail.id}>
							<TableCell style={{...cellStyle,...CSS.justFont}}>{detail.qf_date}</TableCell>
							<TableCell style={{...cellStyle,...CSS.justFont}}>{detail.po_no}</TableCell>
							<TableCell style={{...cellStyle,...CSS.justFont}}>{detail.qname}</TableCell>
							<TableCell style={{...cellStyle,...CSS.justFont}}>{detail.qprice}</TableCell>
							<TableCell style={{...cellStyle,...CSS.justFont}}>{detail.name}</TableCell>
							<TableCell style={{...cellStyle,...CSS.justFont}}>{detail.title}</TableCell>
						</TableRow>
					))}
                    </TableBody>
                </Table>
            </Paper>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				關閉
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class ChangeQPriceModal extends React.Component {
    constructor(props) {
        super(props);
		this.state = { modal: false,
						qprice: "",
						fail_msg: "",
						fail_field: "",
					};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState({ modal: !this.state.modal,
						qprice: this.props.mOrder.qprice,
						fail_msg:"",
						fail_field:"",
					});
    }
    
    fetchB2bMonthModQPrice=()=>{
        let send_body = JSON.stringify({ func:'b2b_month_mod_qprice',
										login_uid: this.props.uid,
										txid: this.props.mOrder.txid,
										qprice: this.state.qprice,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchB2bMonthOrder();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
    }

    render() {
        return (
        <div>
			<IconButton variant="text" onClick={this.toggle} disabled={this.props.auth!=="write"}><Create style={{fontSize:'16px',margin:'0px'}}/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>修改測評單價</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
                    <Grid item xs={12} sm={6}>
					<TextField
						id="standard-name"
						label="測評單價"
						value={this.state.qprice}
						onChange={e => this.setState(({qprice : e.target.value}))}
						margin="normal"
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{style:CSS.adminInputStyle}}
						error={this.state.fail_field==="qprice"?true:false}
					/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchB2bMonthModQPrice} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class CheckTransferModal extends React.Component {
    constructor(props) {
        super(props);
		this.state = { modal: false, 
						pay_date: "",
						fail_msg: "",
						fail_field: "",
					};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState({ modal: !this.state.modal,
						pay_date: new Date().toISOString().slice(0,10),
						fail_msg: "",
						fail_field: "",
					});
    }
    
    fetchPayDateEnsure=()=>{
        let send_body = JSON.stringify({ func:'b2b_pay_date_ensure',
										login_uid: this.props.uid,
										txid: this.props.mOrder.txid,
										pay_date: this.state.pay_date,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchB2bMonthOrder();
            }
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
    }

    render() {
        return (
        <div>
			{this.props.mOrder.pay_date!==""?
			this.props.mOrder.pay_date
			:
			<Button disabled={this.props.mOrder.invoice_no===""||this.props.auth!=="write"} onClick={this.toggle} style={CSS.UGCbtnStyle(this.props.mOrder.invoice_no===""||this.props.auth!=="write")}>確認</Button>
			}
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>輸入匯款日期</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
                    <Grid item xs={12} sm={12}>
					<TextField
						id="standard-name"
						label="匯款日期"
						value={this.state.pay_date}
						onChange={e => this.setState(({pay_date : e.target.value}))}
						margin="normal"
						type="date"
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
					/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchPayDateEnsure} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class ChangeInvoiceModal extends React.Component {
    constructor(props) {
        super(props);
		this.state = { modal:false,
						pay_date:"", 
						fail_msg:"",
						fail_field:"",
						invoice_no:"",
						invoice_date:"",
						txid:"",
					};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState({ modal: !this.state.modal,
						login_uid: this.props.uid,
						txid: this.props.mOrder.txid,
						invoice_no: this.props.mOrder.invoice_no,
						invoice_date: this.props.mOrder.invoice_date,
						fail_msg:"",
						fail_field:"",
					});
		if(this.props.mOrder.invoice_date === ""){
			this.setState({invoice_date: new Date().toISOString().slice(0,10)});
		}
    }
    
    fetchB2bPreOrdInvoice=()=>{
        let send_body = JSON.stringify({ func:'b2b_invoice_ensure',
										login_uid: this.props.uid,
										txid: this.props.mOrder.txid,
										invoice_no: this.state.invoice_no,
										invoice_date: this.state.invoice_date,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchB2bMonthOrder();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
    }

    render() {
        return (
        <div>
			<Button 
			variant="text" 
			onClick={this.toggle}
			disabled={this.props.auth!=="write"}
			style={{fontFamily:'微軟正黑體'}}
			>
				{this.props.mOrder.invoice_no !== "" ? this.props.mOrder.invoice_no:"輸入發票號碼"}
			</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯發票資訊</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
                    <Grid item xs={12} sm={6}>
					<TextField
						id="standard-name"
						label="發票號碼"
						value={this.state.invoice_no}
						onChange={e => this.setState(({invoice_no : e.target.value}))}
						margin="normal"
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						error={this.state.fail_field==="invoice_no"?true:false}
					/>
					</Grid>
					<Grid item xs={12} sm={6}>
					<TextField
						id="standard-name"
						label="發票日期"
						value={this.state.invoice_date}
						onChange={e => this.setState(({invoice_date : e.target.value}))}
						margin="normal"
						type="date"
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						error={this.state.fail_field==="invoice_date"?true:false}
					/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchB2bPreOrdInvoice} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class B2BMonthOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = { searchB2bMonthOrdData:[{}],
						search_tax_id: "",
						search_corp_name: "",
						search_qname: "",
						search_start: "",
						search_end: "",
						select_type: "",
						textfieldValue: "",
                        checkedValues: [],
                        anchorEl: null,
						show_start: "",
						show_end: "",
						page_id:1,
					};
	}

	componentDidMount() {
		this.fetchB2bMonthOrder();
	}

	fetchB2bMonthOrder=()=> {
		let send_body = JSON.stringify({func: "b2b_month_order_list",
										login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
										corp_name:this.state.search_corp_name,
										tax_id:this.state.search_tax_id,
										qname:this.state.search_qname,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2bMonthOrdData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleResetSearch = () => {
		this.setState({ search_tax_id: "",
						search_corp_name: "",
						search_qname: "",
						search_start: "",
						search_end: "",
						select_type: "",
						textfieldValue: "",
					});
		let send_body = JSON.stringify({func: "b2b_month_order_list",
										login_uid: this.props.uid,
										start: "",
										end: "",
										corp_name: "",
										tax_id: "",
										qname: "",
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2bMonthOrdData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleChangePage = page_id => {
		let send_body = JSON.stringify({func: "b2b_month_order_list",
										login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
										corp_name:this.state.search_corp_name,
										tax_id:this.state.search_tax_id,
										qname:this.state.search_qname,
										page_id: page_id,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2bMonthOrdData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchB2bMonthOrderDownload=()=> {
		let send_body = JSON.stringify({func: "b2b_month_order_download",
										login_uid: this.props.uid,
										start: this.state.show_start,
										end: this.state.show_end,
										dtoken: this.state.dtoken,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleSelectSearchType = e => {
		if(this.state.select_type === "tax_id"){
			this.setState({ search_tax_id: e.target.value,
							search_corp_name: '',
							textfieldValue: e.target.value,
						});
		}
		else if(this.state.select_type === "corp_name"){
			this.setState({ search_tax_id: '',
							search_corp_name: e.target.value,
							textfieldValue: e.target.value,
						});
		}
	}

    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
	}

  	render() {
	// const cellStyle = {whiteSpace: 'nowrap'};

   	return (
        <div style ={{display:'flex', flexDirection:'Column' }}>
            <div style={{display:'flex', textAlign:'right', justifyContent:'flex-start', margin:'5px', alignItems:'flex-end'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>欄位查詢：</Typography>
                        <TextField
							id="standard-select-currency-native"
							select
							SelectProps={{native: true}}
							value={this.state.select_type} 
							onChange={e=>{this.setState({select_type:e.target.value,textfieldValue:''})}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							style={{margin:'5px'}}
						>
							<option value=""></option>
							<option value="corp_name">企業名稱</option>
							<option value="tax_id">統一編號</option>
						</TextField>
                        <TextField
                            id="standard-name"
                            margin="dense"
                            style={{margin:'5px'}}
                            onChange={this.handleSelectSearchType}
							value={this.state.textfieldValue}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                        />
                    </div>
					<div style={{display:'flex', alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>測評名稱查詢：</Typography>
					    <TextField
                            id="standard-name"
                            margin="dense"
                            style={{margin:'5px'}}
                            onChange={e=>this.setState({search_qname:e.target.value})}
							value={this.state.search_qname}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                        />
                    </div>
                    <div style={{display:'flex', alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>訂購日區間查詢：</Typography>
                        <TextField 
                            id="date"
                            type="date"
                            margin='dense'
							value={this.state.search_start}
                            style={{margin:'5px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e=>{this.setState({search_start:e.target.value})}}
                        />
                        <Typography style={CSS.adminInputStyle}>～</Typography>
                        <TextField 
                            id="date"
                            type="date"
							value={this.state.search_end}
                            style={{margin:'5px'}}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e=>{this.setState({search_end:e.target.value})}}
                        />
                    </div>
                </div>
				<div style={{display:'flex',alignItems:'center'}}>
				<SearchBtn onClick={this.fetchB2bMonthOrder}/>
				<ClearSearchBtn onClick={this.handleResetSearch}/>
				</div>
            </div>
            <Paper style={{width:'100%',overflowX:'auto',minWidth: "100%"}}>
                <Table>
                    <TableHead><TableRow style={CSS.headRowStyle}>
                        <TableCell align="center" style={CSS.headCellStyle}>
                            <IconButton size="small"onClick={e=>this.setState({anchorEl:e.currentTarget})}><ArrowDropDown/></IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={()=>this.setState({anchorEl:null})}
                            >
                                <MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: this.state.searchB2bMonthOrdData.map(mOrder => mOrder.txid)})} style={CSS.justFont}>全選</MenuItem>
                                <MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: []})} style={CSS.justFont}>全不選</MenuItem>
                            </Menu>
                        </TableCell>
                        {monthOrderHeadRows.map(e=><TableCell align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
                    </TableRow></TableHead>
                    <TableBody>
                        {this.state.searchB2bMonthOrdData.map((mOrder,index) => (
                            <TableRow key={mOrder.id} selected={this.state.checkedValues.includes(mOrder.txid)} style={CSS.tablebodyStyle(index)}>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
                                    <Checkbox label={mOrder.txid} key={mOrder.txid} checked={this.state.checkedValues.includes(mOrder.txid)} onChange={e => this.handleCheck(e,mOrder.txid)}/>
                                </TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.dealer_name}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.corp_name}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.tax_id}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.qname}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>
									<Show uid={this.props.uid} mOrder={mOrder}/>
								</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>
									<div style={{display:'flex', alignItems:'baseline'}}>
									{mOrder.qprice}
									<ChangeQPriceModal mOrder={mOrder} uid={this.props.uid} fetchB2bMonthOrder={()=>this.handleChangePage(this.state.page_id)}auth={this.props.auth}/>
									</div>
								</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.sale_amt}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.interval}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
                                    <ChangeInvoiceModal mOrder={mOrder} uid={this.props.uid} fetchB2bMonthOrder={()=>this.handleChangePage(this.state.page_id)}auth={this.props.auth}/>
                                </TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.invoice_date}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
                                    <CheckTransferModal uid={this.props.uid} mOrder={mOrder} fetchB2bMonthOrder={()=>this.handleChangePage(this.state.page_id)}auth={this.props.auth}/>
                                </TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.contact}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.tel}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{mOrder.email}</TableCell>
                            </TableRow>
                        ))}
						<TableRow>
							<TableCell colSpan={14} align="left" style={CSS.adminInputStyle}>{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}</TableCell>
						</TableRow>
                    </TableBody>
                </Table>
            </Paper>
            <div style={{display:'flex',justifyContent:'space-between'}}>
				<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
					<Typography style={{...{marginRight:'10px'},...CSS.adminInputStyle}}>已選：{this.state.checkedValues.length} 項</Typography>
					<MsgBtn 
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(false)}}
						send_body={JSON.stringify({ func: "b2b_month_order_download",
													login_uid: this.props.uid,
													start: this.state.show_start,
													end: this.state.show_end,
													dtoken: this.state.dtoken,
											})
									}
						btn_name="下載"
					/>
					<CheckAgainMsgBtn
						disabled={this.state.checkedValues.length===0||this.props.auth!=="write"}
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(this.state.checkedValues.length===0||this.props.auth!=="write")}}
						send_body={JSON.stringify({ func: 'b2b_month_order_del',
													login_uid: this.props.uid,
													txid_list: this.state.checkedValues,
											})
									}
						btn_name="刪除"
						check_msg="確認刪除？"
						succProcess={()=>this.handleChangePage(this.state.page_id)}
					/>
				</div>
				<div style={{display:'flex',margin:'20px'}}>
				<Button size="large" disabled={this.state.pre_page===""} onClick={()=>this.handleChangePage(this.state.pre_page)} style={CSS.justFont}>上一頁</Button>
				<Button size="large" disabled={this.state.nxt_page===""} onClick={()=>this.handleChangePage(this.state.nxt_page)} style={CSS.justFont}>下一頁</Button>
				</div>
			</div>
        </div>
        
        
	);
	}
}

export default B2BMonthOrder;