import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {api_path, ver} from '../../environment';
import {isMobile} from "react-device-detect";
import logo2 from '../../pics/logo2.png';

class RenderAppBar extends Component {
    constructor(props){  
        super(props);  
        this.state = {};  
    }

    componentDidMount() {}

    render() {
    return (
    <div>
        <div style={{height:'125px'}}>
            <AppBar position="fixed" style={{minHeight:'65px', verticalAlign:'middle', height:'65px', background:`linear-gradient(to right,#193A69,#4969E1)`}}>
                <Toolbar>
                <div style={{display:'flex', alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <img src={logo2} alt="Logo1" style={{height:'28px',marginLeft:'17px'}}/>
                        {!isMobile?
                        <Typography noWrap style={{marginLeft:'20px',fontSize:'17px',fontFamily:'微軟正黑體',}}>
                        {this.props.role === "admin"? `後台管理系統`
                        :this.props.role === "enterprise"? `UGCareer 測評系統`
                        :this.props.role === "b2c"? `UGCareer 測評系統`
                        :null}
                        </Typography>
                        :null}
                    </div>
                    
                    {this.props.user_state === 'login'?
                    <div style={{display:'flex',alignItems:'baseline',flexDirection:'row'}}>
                        {this.props.name!=="" && this.props.user_state === 'login'?
                        <Typography style={{fontSize:'18px',marginRight:'40px',fontFamily:'微軟正黑體',}}>{this.props.name}，您好！</Typography>
                        :null}
                        <Button onClick={this.props.handleLogOut} style={{fontFamily:'微軟正黑體',color:'white',fontSize:'17px',fontWeight:'500'}}>登出</Button>
                    </div>
                    :null
                    }
                </div>
                </Toolbar>
            </AppBar>
        </div>
    </div>
    );
	}
}

export default RenderAppBar;