import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MessageModal from '../messageModal'; 
import {api_path,cellStyle,UGCblue} from '../../../environment';
import InputAdornment from '@material-ui/core/InputAdornment';

class EditPasswdModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
						fail_msg:"",
						old_passwd: "",
						new_passwd: "",
						cfm_passwd: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState({ modal: !this.state.modal,
						old_passwd: "",
						new_passwd: "",
						cfm_passwd: "",
					});
    }
    
	fetchModPasswd = () => {
        let send_body = JSON.stringify({ func: "mod_passwd",
										login_uid: this.props.uid,
										old_passwd: this.state.old_passwd,
										new_passwd: this.state.new_passwd,
										cfm_passwd: this.state.cfm_passwd,
									});
		fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
				this.setState({fail_field:"", fail_msg:""});
				this.toggle();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
        const desktopButtonStyle = {
            fontFamily:'微軟正黑體',
			backgroundColor:'white',
			color:UGCblue,
			borderRadius:'20px',
			width:'80px',
			fontSize:'13px',
            whiteSpace:'nowrap',
            borderColor:UGCblue,
            marginRight:'0px',
            maxWidth:'200px'
        };
        
		const desktopInfoFontSize = {
            fontFamily:'微軟正黑體',
			fontSize:'14px',
        };
        
        return (
        <div>
            <Button variant="outlined"  onClick={this.toggle} style={desktopButtonStyle}>更改密碼</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體'}}>更改密碼</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體',fontSize:'14px',}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="舊密碼"
							value={this.state.old_passwd}
							type="password"
                            onChange={e => this.setState(({old_passwd : e.target.value}))}
                            margin="normal"
                            InputProps={{ style: desktopInfoFontSize }}
                            InputLabelProps={{ style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="新密碼"
							value={this.state.new_passwd}
							type="password"
                            onChange={e => this.setState(({new_passwd : e.target.value}))}
                            margin="normal"
                            InputProps={{ style: desktopInfoFontSize }}
                            InputLabelProps={{ style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
                    <Grid item xs={12} sm={12}>
						<TextField
							id="standard-name"
							label="確認新密碼"
							value={this.state.cfm_passwd}
							type="password"
                            onChange={e => this.setState(({cfm_passwd : e.target.value}))}
                            margin="normal"
                            InputProps={{ style: desktopInfoFontSize }}
                            InputLabelProps={{ style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchModPasswd} color="primary"style={{fontFamily:'微軟正黑體'}}>
				完成
				</Button>
				<Button size="large" onClick={this.toggle} color="primary"style={{fontFamily:'微軟正黑體'}}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class Information extends Component {
    constructor(props){  
        super(props);  
		this.state = { activestep:0,
                        tm_begin: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
                        page_info: {prob:[],},
                        send_ans: [],
                        percent: 50,
                        age_list:[],
                        edu_list:[],
                        seniority_list: [],
                        corp_type_list: [],
                        dept_list: [],
                        title_list: [],
                        edu_level_list: [{}],
                        edu_status_list: [],
                        edu_field_list: [],
                        edu_year_list: [],
                        name: "",
                        passwd:'239rgweiyf',
						age: "",
						gender: "",
						email: "",
						is_stu: "true",
						tel: "",
						education: "",
						seniority: "",
						corp_type: "",
						corp_type_other: "",
						corp_name: "",
						dept_name: "",
						dept_other: "",
						title_name: "",
						title_other: "",
						fail_field: [],
                        fail_msg: "",
                        modal: false,
                    };
		this.toggle = this.toggle.bind(this);
	}
	
    componentDidMount() {
		this.handleUpdateInfoList();
    }
    
	toggle() {
		this.setState({ modal: !this.state.modal,});
    }
    

	handleUpdateInfoList = ()=>{
		this.fetchAgeList();
		this.fetchEduList();
		this.fetchSeniorityList();
		this.fetchCorpTypeList();
		this.fetchDeptList();
        this.fetchTitleList();
        this.fetchUserInfo();
        this.fetchEduLevelList();
        this.fetchEduStatusList();
        this.fetchEduFieldList();
        this.fetchEduYearList();
    }
    
	fetchEduYearList = () => {
		let send_body = JSON.stringify({ func:'edu_year_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				console.log(response.result);
                this.setState({ edu_year_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
    }
    
	fetchUserInfo = () => {
		let send_body = JSON.stringify({ func:'get_user_info',
                                        login_uid:this.props.uid,
									});
        fetch(`${api_path}/ugc/user_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                if(response.result.is_stu){
                    this.setState({ name: response.result.name,
                                    is_stu: response.result.is_stu?"true":"false",
                                    email: response.result.email,
                                    gender: response.result.gender,
                                    age: response.result.age,
                                    tel: response.result.tel,
                                    edu_level: response.result.edu_level,
                                    edu_status: response.result.edu_status,
                                    edu_field: response.result.edu_field,
                                    field_other: response.result.field_other,
                                    school_dept: response.result.school_dept,
                                    school: response.result.school,
                                    school_year: response.result.school_year,

                    });
                }
                else{
                    this.setState({ name: response.result.name,
                                    passwd:"000000",
                                    age: response.result.age,
                                    gender: response.result.gender,
                                    email: response.result.email,
                                    is_stu: response.result.is_stu?"true":"false",
                                    tel: response.result.tel,
                                    education: response.result.education,
                                    seniority: response.result.seniority,
                                    corp_type: response.result.corp_type,
                                    corp_type_other: response.result.corp_type_other,
                                    corp_name: response.result.corp_name,
                                    dept_name: response.result.dept_name,
                                    dept_other: response.result.dept_other,
                                    title_name: response.result.title_name,
                                    title_other: response.result.title_other,
                                });
                }
            };
        })
        .catch(error => console.error('Error:', error));
    }

    fetchModUserInfo = () => {
        let send_body;
        if(this.state.is_stu==="true"){
            send_body = JSON.stringify({ func:'mod_user_info',
                                        login_uid:this.props.uid,
                                        name: this.state.name,
                                        age: this.state.age,
                                        gender: this.state.gender,
                                        email: this.state.email,
                                        is_stu: true,
                                        tel: this.state.tel,
                                        edu_level: this.state.edu_level,
                                        edu_status: this.state.edu_status,
                                        edu_field: this.state.edu_field,
                                        field_other: this.state.field_other,
                                        school_dept: this.state.school_dept,
                                        school: this.state.school,
                                        school_year: this.state.school_year,
                                    });
        }
        else if(this.state.is_stu==="false"){
            send_body = JSON.stringify({ func:'mod_user_info',
                                        login_uid:this.props.uid,
                                        name: this.state.name,
                                        age: this.state.age,
                                        gender: this.state.gender,
                                        email: this.state.email,
                                        is_stu: false,
                                        tel: this.state.tel,
                                        education: this.state.education,
                                        seniority: this.state.seniority,
                                        corp_type: this.state.corp_type,
                                        corp_type_other: this.state.corp_type_other,
                                        corp_name: this.state.corp_name,
                                        dept_name: this.state.dept_name,
                                        dept_other: this.state.dept_other,
                                        title_name: this.state.title_name,
                                        title_other: this.state.title_other,
                                    });
        }
        else {
            send_body = JSON.stringify({ func:'mod_user_info',
                                        login_uid:this.props.uid,
                                        name: this.state.name,
                                        age: this.state.age,
                                        gender: this.state.gender,
                                        email: this.state.email,
                                        is_stu: null,
                                    });
        }
		
        fetch(`${api_path}/ugc/user_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({fail_field:[], fail_msg:"", msg:"更新成功"});
                this.toggle();
                this.fetchUserInfo();
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
				window.scrollTo(0, 0);
			}
            
        })
        .catch(error => console.error('Error:', error));
	}

	fetchAgeList = () => {
		let send_body = JSON.stringify({ func:'age_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ age_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchEduList = () => {
		let send_body = JSON.stringify({ func:'edu_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ edu_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchSeniorityList = () => {
		let send_body = JSON.stringify({ func:'seniority_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ seniority_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchCorpTypeList = () => {
		let send_body = JSON.stringify({ func:'corp_type_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ corp_type_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchDeptList = () => {
		let send_body = JSON.stringify({ func:'dept_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ dept_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchTitleList = () => {
		let send_body = JSON.stringify({ func:'title_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ title_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}
    
	fetchEduLevelList = () => {
		let send_body = JSON.stringify({ func:'edu_level_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                console.log(response.result);
                this.setState({ edu_level_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
	}

    fetchEduStatusList = () => {
		let send_body = JSON.stringify({ func:'edu_status_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ edu_status_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
    }

    fetchEduFieldList = () => {
		let send_body = JSON.stringify({ func:'edu_field_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ edu_field_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
    }
    
	render() {
		const desktopButtonStyle = {
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'30px',
			width:'120px',
			fontSize:'14px',
            margin:'10px',
            padding:'5px 10px 5px 10px'
        };
        
		const desktopButtonStyle2 = {
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.5vw',
			marginBottom:'0.5vw',
		};

		const desktopDisabledButtonStyle = {
            fontFamily:'微軟正黑體',
			backgroundColor:'gray',
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.1vw',
			marginBottom:'2vw',
		};

		const mobileButtonStyle={
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'5vw',
			marginTop:'1.2vw',
			width:'28vw',
			fontSize:'4.5vw',
			marginBottom:'4vw',
		};

		const mobileDisabledButtonStyle={
            fontFamily:'微軟正黑體',
			backgroundColor:'lightgray',
			color:'white',
			borderRadius:'5vw',
			marginTop:'1.2vw',
			width:'28vw',
			fontSize:'4.5vw',
			marginBottom:'4vw',
		};

		const circleBtnStyle = {
            fontFamily:'微軟正黑體',
			fontSize:'4vw',
			fontWeight:'bold',
			borderRadius:'8vw',
			backgroundColor:'white',
			border:'1px solid white',
			color:UGCblue,
			padding:'0',
			minWidth:'7vw',
			margin:'1vw',
		};

		const mobileInfoFontSize = {
            fontFamily:'微軟正黑體',
			fontSize:'4vw',
		};

		const desktopInfoFontSize = {
            fontFamily:'微軟正黑體',
			fontSize:'14px',
		};

		return (
            <div style={{maxWidth:'1000px', width:'60%',textAlign:'left',margin: '0 auto'}}>
                {/* <MessageModal msg={this.state.msg} title="會員資料"/> */}
                <div style={{display:'flex',width:'100%',justifyContent:'center',flexDirection:'column'}}>
                    <Typography noWrap style={{fontFamily:'微軟正黑體',color:UGCblue,fontSize:'20px',marginTop:'3vw',marginBottom:'1.5vw',fontWeight:'bold'}}>會員資料</Typography>
                    <Grid container spacing={1}>
						{this.state.fail_msg!==""?
						<Grid item xs={12} sm={12}>
							<Typography align="center" style={{fontFamily:'微軟正黑體',color:"red", margin:'1vw', fontSize:'1.2vw'}}>{this.state.fail_msg}</Typography>
						</Grid>
						:null}
						
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.name}
                                onChange={e=>{this.setState({name:e.target.value})}}
                                error = {this.state.fail_field === "name" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>您的姓名 * ：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
						</Grid>
                        <Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                value={this.state.email}
                                onChange={e=>{this.setState({email:e.target.value})}}
                                error = {this.state.fail_field === "email" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>您的信箱 E-mail * ：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
						</Grid>
                        <Grid item xs={12} sm={5}>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                type="password"
                                // disabled
                                value="------"
                                error = {this.state.fail_field === "passwd" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>密碼 * ：</Typography></InputAdornment>,
                                    style: {...desktopInfoFontSize,...{color:'black'}},
                                    readOnly: true
                                }}
                                style={{width:'80%'}}
                            />
                            <EditPasswdModal uid={this.props.uid}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="standard-select-currency-native"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.is_stu}
								onChange={e => {this.setState({ is_stu : e.target.value,
																qi_seq: ""});
												}}
								error = {this.state.fail_field === "is_stu" ? true:null}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>您的身份 * ：</Typography></InputAdornment>,
									style: desktopInfoFontSize 
								}}
                                fullWidth
							>
								{/* <option value=""></option> */}
								<option value={true}>學生</option>
								<option value={false}>社會人士</option>
							</TextField>
						</Grid>
                        <Grid item xs={12} sm={5}>
							<TextField
								id="standard-select-currency-native"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.gender}
								onChange={e => this.setState({gender:e.target.value})}
								error = {this.state.fail_field === "gender" ? true:null}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>您的性別：</Typography></InputAdornment>,
									style: desktopInfoFontSize 
								}}
                                fullWidth
							>
								<option value=""></option>
								<option value="1">男</option>
								<option value="0">女</option>
								<option value="-1">其他</option>
							</TextField>
						</Grid>
                        <Grid item xs={12} sm={2}></Grid>
                        <Grid item xs={12} sm={5}>
							<TextField
								id="standard-select-currency-native"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.age}
								onChange={e => this.setState({age:e.target.value})}
                                error={this.state.fail_field.includes("age")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>您的年齡：</Typography></InputAdornment>,
									style: desktopInfoFontSize 
								}}
                                fullWidth
							>
								<option value=""></option>
                                {this.state.age_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
						</Grid>
						<Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                margin="normal"
                                placeholder="02-12345678/0912-345678"		value={this.state.tel}
                                onChange={e=>{this.setState({tel:e.target.value})}}
                                error = {this.state.fail_field === "tel" ? true:null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>您的電話：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                                style={desktopInfoFontSize}
                            />
						</Grid>
                        <Grid item xs={12} sm={2}></Grid>
						{this.state.is_stu==="false"?
                        <Grid item xs={12} sm={5}>
                            <TextField
                                id="education"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.education}
                                onChange={e => this.setState({education : e.target.value})}
                                error={this.state.fail_field.includes("education")}
                                InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>您的學歷：</Typography></InputAdornment>,
									style: desktopInfoFontSize 
								}}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.edu_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        :null}
                        {this.state.is_stu==="true"?
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    id="edu_level"
                                    select
                                    SelectProps={{native: true}}
                                    margin="normal"
                                    value={this.state.edu_level}
                                    onChange={e => this.setState({edu_level : e.target.value})}
                                    error={this.state.fail_field.includes("edu_level")}
                                    style={{marginRight:'5px'}}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize} color={this.state.fail_field.includes("edu_level")?"error":"text"}>就學階段：</Typography></InputAdornment>,
                                        style: desktopInfoFontSize 
                                    }}
                                    fullWidth
                                >
                                    <option value=""></option>
                                    {this.state.edu_level_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                                </TextField>
                            </Grid>
                        :null}
                        {this.state.is_stu==="false"?
                        <Grid item xs={12} sm={5}>
                            <TextField
                                id="standard-select-currency-native"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.seniority}
                                onChange={e => this.setState({seniority : e.target.value})}
                                error={this.state.fail_field.includes("seniority")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>工作年資：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.seniority_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        :null}
                        {this.state.is_stu==="true"?
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    id="edu_status"
                                    select
                                    SelectProps={{native: true}}
                                    margin="normal"
                                    value={this.state.edu_status}
                                    onChange={e => this.setState({edu_status : e.target.value})}
                                    error={this.state.fail_field.includes("edu_status")}
                                    style={{marginRight:'5px'}}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize} color={this.state.fail_field.includes("edu_status")?"error":"text"}>就學狀態：</Typography></InputAdornment>,
                                        style: desktopInfoFontSize 
                                    }}
                                    fullWidth
                                >
                                    <option value=""></option>
                                    {this.state.edu_status_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                                </TextField>
                            </Grid>
                        :null}
                        <Grid item xs={12} sm={2}></Grid>
                        {this.state.is_stu==="false"?
                        <Grid item xs={12} sm={5}>
                            <TextField 
                                id="corp_name" 
                                value={this.state.corp_name}
                                onChange={e => this.setState({corp_name : e.target.value})}
                                margin="normal" 
                                error={this.state.fail_field.includes("corp_name")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}>您服務的公司/機構：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                        </Grid>
                        :null}
                        {this.state.is_stu==="true"?
                        <Grid item xs={12} sm={5}>
                            <TextField
                                id="edu_field"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.edu_field}
                                onChange={e => this.setState({edu_field : e.target.value})}
                                error={this.state.fail_field.includes("edu_field")}
                                style={{marginRight:'5px'}}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize} color={this.state.fail_field.includes("edu_field")||this.state.fail_field.includes("field_other")?"error":"text"}>您就讀的領域：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.edu_field_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                            {this.state.edu_field==="-1"?
                            <TextField 
                                placeholder="其他領域"
                                id="field_other" 
                                value={this.state.field_other}
                                onChange={e => this.setState({field_other : e.target.value})}
                                error={this.state.fail_field.includes("field_other")}
                                margin="normal" 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}></Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                            :null}
                        </Grid>
                        :null}
                        {this.state.is_stu==="false"?
                        <Grid item xs={12} sm={5}>
                            <TextField
                                id="corp_type"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.corp_type}
                                onChange={e => this.setState({corp_type : e.target.value})}
                                error={this.state.fail_field.includes("corp_type")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize} color={this.state.fail_field.includes("corp_type")||this.state.fail_field.includes("corp_type_other")?"error":"text"}>您服務公司的產業類別：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.corp_type_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                            {this.state.corp_type==="-1"?
                            <TextField 
                                placeholder="其他服務公司的產業類別"
                                id="corp_type_other" 
                                value={this.state.corp_type_other}
                                onChange={e => this.setState({corp_type_other : e.target.value})}
                                margin="normal"
                                error={this.state.fail_field.includes("corp_type_other")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}></Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                            :null}
                        </Grid>
                        :null}
                        {this.state.is_stu==="true"?
                        <Grid item xs={12} sm={5}>
                            <TextField 
                                id="school_dept" 
                                value={this.state.school_dept}
                                onChange={e => this.setState({school_dept : e.target.value})}
                                error={this.state.fail_field.includes("school_dept")}
                                margin="normal" 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize} color={this.state.fail_field.includes("school_dept")?"error":"text"}>就讀科系：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                        </Grid>
                        :null}
                        <Grid item xs={12} sm={2}></Grid>
                        {this.state.is_stu==="false"?
                        <Grid item xs={12} sm={5}>
                            <TextField
                                id="dept_name"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.dept_name}
                                onChange={e => this.setState({dept_name : e.target.value})}
                                error={this.state.fail_field.includes("dept_name")}
                                style={{marginRight:'5px'}}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize} color={this.state.fail_field.includes("dept_name")||this.state.fail_field.includes("dept_other")?"error":"text"}>您在貴公司擔任的職務類別：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.dept_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                            {this.state.dept_name==="-1"?
                            <TextField 
                                placeholder="其他職類"
                                id="dept_other" 
                                value={this.state.dept_other}
                                onChange={e => this.setState({dept_other : e.target.value})}
                                margin="normal" 
                                error={this.state.fail_field.includes("dept_other")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}></Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                            :null}
                        </Grid>
                        :null}
                        {this.state.is_stu==="true"?
                        <Grid item xs={12} sm={5}>
                            <TextField 
                                id="school" 
                                value={this.state.school}
                                onChange={e => this.setState({school : e.target.value})}
                                error={this.state.fail_field.includes("school")}
                                margin="normal" 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize} color={this.state.fail_field.includes("school")?"error":"text"}>就讀學校：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                        </Grid>
                        :null}
                        {this.state.is_stu==="false"?
                        <Grid item xs={12} sm={5}>
                            <TextField
                                id="title_name"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.title_name}
                                onChange={e => this.setState({title_name : e.target.value})}
                                error={this.state.fail_field.includes("title_name")}
                                style={{marginRight:'5px'}}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize} color={this.state.fail_field.includes("title_name")||this.state.fail_field.includes("title_other")?"error":"text"}>您在貴公司的職級為：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.title_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                            {this.state.title_name==="-1"?
                            <TextField 
                                placeholder="其他職級"
                                id="title_other" 
                                value={this.state.title_other}
                                onChange={e => this.setState({title_other : e.target.value})}
                                error={this.state.fail_field.includes("title_other")}
                                margin="normal" 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize}></Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                            :null}
                        </Grid>
                        :null}
                        {this.state.is_stu==="true"?
                        <Grid item xs={12} sm={5}>
                            <TextField 
                                id="school_year" 
                                value={this.state.school_year}
                                select SelectProps={{native: true}}
                                onChange={e => this.setState({school_year : e.target.value})}
                                error={this.state.fail_field.includes("school_year")}
                                margin="normal" 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={desktopInfoFontSize} color={this.state.fail_field.includes("school_year")?"error":"text"}>就讀年級：</Typography></InputAdornment>,
                                    style: desktopInfoFontSize 
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.edu_year_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        :null}
                        <Grid item xs={12} sm={12}>
                        <div style={{display:'flex', alignItems:'baseline', justifyContent:'center',marginTop:'20px',marginBottom:'40px'}}>
                            <Button size="large" style={desktopButtonStyle} onClick={this.fetchUserInfo}>復原</Button>
                            <Button size="large" style={desktopButtonStyle} onClick={this.fetchModUserInfo}>存檔</Button>
                        </div>
                        </Grid>
                    </Grid>
                </div>
                {this.state.modal?
                <Dialog
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.modal}
                    onClose={this.toggle}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體'}}>會員資料</Typography></DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <Typography style={{fontFamily:'微軟正黑體'}}>
                    {this.state.msg}
                    </Typography>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>關閉</Button>
                    </DialogActions>
                </Dialog>
                :null}
            </div>
		);
	}
}

export default Information;