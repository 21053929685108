import React from 'react';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import B2CUser from './b2c/b2cUser';
import B2COrder from './b2c/b2cOrder';
import B2CAmount from './b2c/b2cAmount';

const subfunction = ["B2C匯款確認及帳號開通","B2C銷售紀錄及發票","B2C客戶資料"];

class B2C extends React.Component {
	constructor(props) {
		super(props);
		this.state = { subfunction:'B2C匯款確認及帳號開通',
					};
	}
    
	componentDidMount() {
	}

	handleChangeSubfunction = e => { 
		this.setState({subfunction : e.target.innerText, });
	}
	
  	render() {
	// // const cellStyle = {whiteSpace: 'nowrap'};
	// const headercellStyle = {whiteSpace: 'nowrap', position:'sticky',zIndex: '10',top: '0',backgroundColor: 'white'};

	return (
		<div style={{textAlign:'center', display:'block',alignItems:'center'}}>
			<AppBar position="sticky" color="default" 
                    style={{position:'fixed',top:'102px',backgroundColor:'white', width:'100%',height:'40px',padding:'0px',boxShadow:'0 2px 2px lightgray,0 -2px black'}}
			>
				<Breadcrumbs style={{width:'100%', verticalAlign:'top', overflowX:'auto', alignItems:'center'}}>
					{subfunction.map(e => 
						<Button key={e} 
								style={e===this.state.subfunction?{fontFamily:'微軟正黑體',display:'flex',borderBottom:"4px solid",padding:'2px',margin:'4px 8px 0px 8px'}:{fontFamily:'微軟正黑體',display:'flex',padding:'4px',margin:'4px 8px 0px 8px'}} 
								size="large" 
								onClick={this.handleChangeSubfunction} color={(e===this.state.subfunction)?'default':'inherit'}>{e}</Button>)}
				</Breadcrumbs>
			</AppBar>
			<div style={{height:'50px'}}></div>
			{this.state.subfunction==="B2C匯款確認及帳號開通"?
			<B2COrder uid={this.props.uid} auth={this.props.auth}/>
			:null}
			{this.state.subfunction==="B2C銷售紀錄及發票"?
			<B2CAmount uid={this.props.uid} auth={this.props.auth}/>
			:null}
			{this.state.subfunction==="B2C客戶資料"?
			<B2CUser uid={this.props.uid} auth={this.props.auth}/>
			:null}
		</div> 
			
	);
	}
}

export default B2C;