import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {api_path,UGCblue} from '../../environment';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import logo2 from '../../pics/logo2.png';
import logo1 from '../../pics/logo1.png';
import CountDown from '../components/assess/countdown';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import PlayArrow from '@material-ui/icons/PlayArrow';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import BottomBar from '../components/bottomBar';
import PageThree from '../components/assess/page3.js';
import PageFour from '../components/assess/page4.js';
import PageFive from '../components/assess/page5.js';
import test_intro from '../../pics/test_intro.jpg';
import test_end from '../../pics/test_end.jpg';
import * as CSS from '../../assessCss.js'


let steps = [{desc:"流程說明",page_id:3},{desc:"開始填答",page_id:4},{desc:"基本資料",page_id:5},{desc:"測驗結束",page_id:6}];

class MobileProb extends Component {
    constructor(props){  
        super(props);  
		this.state = { page_id:0,
						tm_begin: new Date(),
						page_info: {prob:[],},
						value: null,
        };  
	}
	componentDidMount(){
		this.setState({value:null});
	}

	createRadio(score, handleChange,value){
		let radios=[];
		let desc = ["非常不符合","不符合","有點不符合","有點符合","符合","非常符合"]
		for(let i = 1;i <= score; i++){
			let btn = <div style={{display:'flex',alignItems:'baseline'}}>
					<Button 
						onClick = {e=>{handleChange(i)}}
						style={value===i?{fontFamily:'微軟正黑體',fontSize:'4vw',fontWeight:'bold',borderRadius:'9vw',border:'1px solid #385B8E',color:'white',backgroundColor:UGCblue,padding:'0',minWidth:'7vw'}
											:{fontFamily:'微軟正黑體',fontSize:'4vw',fontWeight:'bold',borderRadius:'9vw',border:'1px solid #385B8E',color:UGCblue,padding:'0',minWidth:'7vw'}}
						variant="ountlined"
					>{i.toString(10)}</Button>
					</div>
			radios.push(btn);
		}
		return radios;
	}

	handleChange = e => {
		if(this.state.value !== e){
		this.setState({value: e});
		this.props.setSendAns({ qd_seq: this.props.prob.qd_seq,
								ans: e,
								q_tm: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
							});
		}
	}


	render() {
		return (
			<Grid align="center" item xs={12} sm={12}>
				<Typography align="left" style={{fontFamily:'微軟正黑體',margin:'1.5vw', fontSize:'4.5vw',fontWeight:'bold',margin:'2vh'}}>{this.props.prob.desc}</Typography>
					<div style={{display:'flex',alignItems:'baseline',justifyContent:'space-evenly'}}>
						{this.createRadio(this.props.prob.score,this.handleChange,this.state.value)}
					</div>
			</Grid>
		);
	}
}
class PrivacyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal});
    }

    render() {
        return (
        <div>
            <Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.props.privacymodal}
				onClose={this.props.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" style={{fontFamily:'微軟正黑體',}}><Typography style={{fontFamily:'微軟正黑體'}}>UGCareer測評系統網站個資蒐集前告知</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    <Typography style={{fontFamily:'微軟正黑體',whiteSpace:'pre-line',color:'black'}}>
						{
`
UGCareer測評系統網站為優駿企管顧問有限公司經營管理，為了確保消費者之個人資料、隱私及消費者權益之保護，於交易過程中將使用消費者之個人資料，謹依個人資料保護法第8條規定告知以下事項：

(一) 蒐集目的及方式
本公司之蒐集目的在於進行其他諮詢與顧問服務、人事管理、客戶管理與服務、會員管理及行銷與統計調查與分析(法定特定目的項目編號為182, 002, 090, 052, 040, 157)。蒐集方式將透過企業徵才、加入會員或訂購單填寫方式進行個人資料之蒐集。

(二) 蒐集之個人資料類別
本公司於網站內蒐集的個人資料包括
1. C001辨識個人者： 如姓名、電子郵件、電話/行動電話、網路平臺申請之帳號等資訊。
2. C002辨識財務者： 如信用卡或轉帳帳戶資訊。
3. C011個人描述、C014個性：如年齡、性別、國籍、個性等評述意見等。
4. C038職業、C061現行之受僱情形、C071工作之評估細節： 職業、工作描述、職級、工作表現等。
5. C051學校記錄、C052資格或技術： 學校、學歷等。

三、利用期間、地區、對象及方式
(一) 期間：本公司營運期間。
(二) 地區：蒐集之個人資料將用於營運地區。
(三) 利用對象及方式：個人資料蒐集除用於本公司之其他諮詢與顧問服務、人事管理、會員管理、客戶管理之檢索查詢等功能外，將有以下利用：
1. 金融交易及授權：消費者所提供之財務相關資訊，將於金融交易過程(如信用卡授權、轉帳)時提交給金融機構以完成金融交易。
2. 行銷：本公司將利用個人資料之郵件、電話號碼進行本公司或合作廠商商品之宣傳行銷。

四、個人資料之權利
交付本公司之個人資料者，依個資法得行使以下權利：
(一) 查詢或請求閱覽。
(二) 請求製給複製本。
(三) 請求補充或更正。
(四) 請求停止蒐集、處理或利用。
(五) 請求刪除。

可來信洽詢本公司客服進行申請，客服信箱: service@ugc.com.tw。`
}
</Typography>
				</DialogContentText>
				</DialogContent>
                <DialogActions>
				<Button size="large" onClick={this.props.toggle} style={{fontFamily:'微軟正黑體',color:UGCblue}}>
				關閉
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}
class RenderAssess extends Component {
    constructor(props){  
        super(props);  
		this.state = { page_id:"",
						tm_begin: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
						page_info: {prob:[],},
						send_ans: [],
						buyer_type: "2",
						is_stu: false,
						countdown: "",
						percent: null,
						needEmail: false,
                        privacyCheck: false,
						privacymodal: false,
						p5_resp:{},
						fail_msg: "",
						fail_field:[],
						btn:["","","","","",""],
        };  
	}
	
    componentDidMount() {
		this.setState({ page_id:this.props.page_id,
						tm_begin: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
						rpt_token: this.props.rpt_token,
					});
	}

	componentDidUpdate(prevProps) {
		if(this.props.page_id===5){
			if(this.props.p5_resp!==prevProps.p5_resp){
				this.setState({ p5_resp:this.props.p5_resp,
								needEmail:this.props.p5_resp.email===""?true:false,
							});
			}
			if(this.props.page_id===4){
				this.setState({ nxt_token: this.props.p4_resp.nxt_token,
								page_info: this.props.p4_resp.page_info,
								tm_begin: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
								countdown: this.props.p4_resp.countdown,
								percent: this.props.p4_resp.percent,
							});
			}
		}
		
    }
	
	setCountdown = i => { this.setState({countdown: i}); }

	handlePageResp = response =>{
		if(response.status==="SUCC"){
			window.scroll(0,0);
			this.setState({ nxt_token: response.nxt_token,
							page_id: response.page_id,
						});
			if(response.page_id=== 4){
				this.setState({ nxt_token: response.nxt_token,
								page_id: response.page_id,
								page_info: response.page_info,
								btn: response.page_info.btn,
								is_stu: response.is_stu,
								send_ans:[],
								tm_begin: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
								countdown: response.countdown,
								percent: response.percent,
								prog_bar: response.prog_bar,
							});
			}
			else if(response.page_id===5){
				this.setState({ p5_resp: response,
								send_ans: [],
							});
			}
			else if(response.page_id===6||response.page_id===7){
				this.setState({ url: response.url,});
			}
			// 錯誤訊息
			else if(response.page_id===8){
				this.setState({ msg: response.msg,
								url: response.url
							});
			}
		}
		else if(response.status==="FAIL"){
			this.setState({ fail_field:response.fail_field, 
							fail_msg:response.msg,
						});
			window.scrollTo(0, 0);
		};
	}

	fetchAssess = () => {
		this.setState({page_info: {prob:[],},});
		let send_body = JSON.stringify({ func:'assess',
                                        token:this.state.nxt_token?this.state.nxt_token:this.props.nxt_token,
										tm_begin: this.state.tm_begin,
										ans: this.state.send_ans,
										countdown: this.state.countdown,
                                    });
        fetch(`${api_path}/ugc/assess_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
			this.handlePageResp(response);
        })
        .catch(error => console.error('Error:', error));
	}

	fetchDoAssess = () => {
		let send_body = JSON.stringify({ func:'do_assess',
                                        token:this.props.nxt_token,
										tm_begin: this.state.tm_begin,
										tm_end: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
                                    });
        fetch(`${api_path}/ugc/assess_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
			this.handlePageResp(response);
        })
        .catch(error => console.error('Error:', error));
	}

	fetchUpdateInfo = info => {
		this.setState({page_info: {prob:[],},});
		let send_body;
		send_body =JSON.stringify({...{ func:'update_info',
										token:this.state.nxt_token?this.state.nxt_token:this.props.nxt_token,
									}
									,...info});
		fetch(`${api_path}/ugc/assess_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json()).then(response => {
			this.handlePageResp(response);
        })
        .catch(error => console.error('Error:', error));
	}
	
	setSendAns=ans=>{ this.setState({ send_ans:this.state.send_ans.concat(ans) });}

	render() {
        var isMobile = window.innerWidth <= 500;
        if(!isMobile){
        return (
		<div style={CSS.mainstyle}>
			<AppBar position="fixed" style={{width:'100%',height:'80px',backgroundColor:'transparent',boxShadow:'none'}}>
				<Toolbar style={{boxShadow:'none',height:'80px',padding:'0',display:'flex',alignItems:'center'}}>
					<div style={{display:'flex',height:'100%',alignItems:'center'}}>
						<div style={{display:'flex',width:'35vw',alignItems:'center'}}>
							{this.props.corp_name_abbr===null||this.props.corp_name_abbr===""?
							<img src={logo2} alt="Logo2" style={{height:'30px',marginLeft:'60px'}}/>
							:
							<Typography noWrap style={{fontWeight:'600',fontSize:'23px',fontFamily:'微軟正黑體',marginLeft:'40px'}}>{this.props.corp_name_abbr}</Typography>
							}
							{this.props.name !== undefined?
							<Typography noWrap style={{fontSize:'18px',fontFamily:'微軟正黑體',marginLeft:'40px'}}>{this.props.name}，您好！</Typography>
							:null}
						</div>
						
						{this.state.page_id===4?
						<div style={{display:'flex',width:'58vw',maxWidth:'51vw',backgroundColor:'white',height:'100%',alignItems:'center',paddingLeft:'7vw'}}>
							{this.state.page_id===4&&this.state.countdown!==""?
							<div style={{ display:'flex',
											width:'12vw',
											backgroundColor:'white',
											height:'100%',
											alignItems:'center',
											justifyContent:'center',
											margin:'0 auto',
											verticalAlign:'center'
										}}
							>
								<CountDown color={"#385B8E"} fontSize = {"1.8vw"} countdown={this.state.countdown} setCountdown={this.setCountdown} fetchAssess={this.fetchAssess} style={{display:'flex',margin:'0 auto',alignItems:'center',justifyContent:'center'}}/>
							</div>
							:
							<div style={{ display:'flex',
											width:'1vw',
											backgroundColor:'white',
											height:'100%',
											alignItems:'center',
											justifyContent:'center',
											margin:'0 auto',
											verticalAlign:'center'
										}}
							></div>
							}
							
							
							<div style={{display:'flex',height:'30px',width:'100%',paddingLeft:'2vw',paddingRight:'2vw'}}>
								<div style={{border:'2px solid #385B8E',height:'1.7vw',width:`${this.state.percent}%`,color:'white',backgroundColor:UGCblue,textAlign:'left',fontSize:'1.1vw',verticalAlign:'middle',fontWeight:'400',fontFamily:'微軟正黑體',letterSpacing:'2px',paddingLeft:'10px'}}>
									{this.state.percent>= 20?`目前進度${this.state.percent}%`:null}
								</div>
								<div style={{border:'2px solid #385B8E',height:'1.7vw',width:`${100-this.state.percent}%`,color:UGCblue,textAlign:'left',fontSize:'1.1vw',verticalAlign:'middle',fontWeight:'400',fontFamily:'微軟正黑體',letterSpacing:'2px',paddingLeft:'10px'}}>
									{this.state.percent< 20?`目前進度${this.state.percent}%`:null}
								</div>
							</div>

							
							
						</div>
						:null}
						{this.state.page_id===4?
						<div style={{display:'flex',width:'10vw',backgroundColor:'white',height:'100%',alignItems:'center'}}></div>
						:null}
					</div>
				</Toolbar>
			</AppBar>

			<div id="content" style={{width:'50vw',marginLeft:'43vw',marginTop:'6vw',marginBottom:'3vh'}}>
				{/* 步驟條 */}
				{this.state.prog_bar===false && this.state.page_id === 4 ? null :
				<div style={{display:'flex',justifyContent:'space-evenly',alignItems:'baseline',paddingTop:'15px',marginBottom:'15px'}}>
				{steps.map(e=>
					<div style={{display:'flex'}}>
						<Typography style={this.state.page_id===e.page_id?{fontFamily:'微軟正黑體',fontSize:'1.3vw',fontWeight:'bold',color:UGCblue}:{fontFamily:'微軟正黑體',fontSize:'1.3vw',color:'#A6ACAF'}}>{e.desc}</Typography>
						{this.state.page_id===e.page_id && this.state.page_id<6?
							<div style={{display:'flex',alignItems:'center',marginLeft:'1vw'}}>
								<PlayArrow style={{fontSize:'1.3vw',color:UGCblue}}/><PlayArrow style={{fontSize:'1.3vw',}}/><PlayArrow style={{fontSize:'1.3vw',}}/><PlayArrow style={{fontSize:'1.3vw',}}/>
							</div>
						:e.desc!=="測驗結束"?
							<div style={{display:'flex',marginLeft:'1vw'}}>
								<PlayArrow style={{fontSize:'1.3vw',color:'white'}}/><PlayArrow style={{fontSize:'1.3vw',color:'white'}}/><PlayArrow style={{fontSize:'1.3vw',color:'white'}}/><PlayArrow style={{fontSize:'1.3vw',color:'white'}}/>
							</div>
						:null
						}
					</div>
					)
				}
				</div>
				}

				{this.state.page_id===3?
				<PageThree  isMobile={isMobile}
							notice1={this.props.notice1}
							notice2={this.props.notice2}
							countdown={this.props.countdown}
							fetchDoAssess={this.fetchDoAssess}
				/>
				:null}
				
				{this.state.page_id===4?
				<PageFour   isMobile={isMobile}
							page_info={this.state.page_info}
							countdown={this.state.countdown}
							fetchAssess={this.fetchAssess}
							setSendAns={this.setSendAns}
							nextBtnDisabled={[...new Set(this.state.send_ans.map(x=>x.qd_seq))].length===this.state.page_info.prob.length?false:true}
							btn={this.state.btn}
				/>
				:null}

				{this.state.page_id===5?
				<PageFive p5_resp={this.state.p5_resp}
						  fetchUpdateInfo={this.fetchUpdateInfo}
						  isMobile={isMobile}
						  fail_field={this.state.fail_field}
						  fail_msg={this.state.fail_msg}
				/>
				:null}

				{this.state.page_id===6||this.state.page_id===7?
				<Grid container spacing={2} style={{marginTop:'6vw',marginBottom:'3vh'}}>
					<Grid item xs={12} sm={12}>
						<img alt=""src={`${test_end}`} style = {{width:'100%'}}/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',justifyContent:'flex-start'}}>
						<Typography style={{fontSize:'18px',fontFamily:'微軟正黑體'}}>測驗完成，感謝您的填答！</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} style={{marginTop:'100px'}}>
					<div style={{display:'flex',justifyContent:'center'}}>
						<Button
							style={CSS.desktopButtonStyle}
							onClick={()=>{
								if(this.props.url){window.location.assign(this.props.url);}
								else {window.location.assign(this.state.url);}
							}}
						>登出</Button>
					</div>
					</Grid>
				</Grid>
				:null}
				
				{this.state.page_id===8?
				<Dialog
					fullWidth={true}
					maxWidth="xs"
					open={true}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title"></DialogTitle>
					<DialogContent>
					<DialogContentText id="alert-dialog-description">
					<Typography style={{fontFamily:'微軟正黑體'}}>
					{this.props.msg?this.props.msg:this.state.msg?this.state.msg:null}
					</Typography>
					</DialogContentText>
					</DialogContent>
					<DialogActions>
					<Button size="large" 
							onClick={()=>{
								if(this.props.url){ window.location.assign(this.props.url);}
								else { window.location.assign(this.state.url);}
							}}
							color="primary"
							style={{fontFamily:'微軟正黑體'}}>
						確認
					</Button>
					</DialogActions>
				</Dialog>
				:null}

			</div>
		</div>
        );
        }
        else {
            return (
			<div id="content" style={{width:"100%", height:'100%',position:'absolute',top:'0',left:'0'}} elevation={0} square={true}>
				{/* LOGO條 */}
				<Paper style={{display:'flex',justifyContent:'center',alignItems:'center',width:"100%", height:'8vh', textAlign:'center'}} elevation={0} square={true}>
					<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
						{this.props.corp_name_abbr===null||this.props.corp_name_abbr===""?
						<img src={logo1} alt="Logo1" style={{height:'3vh'}}/>
						:
						<Typography style={{fontFamily:'微軟正黑體',fontSize:'22px',color:UGCblue}}>{this.props.corp_name_abbr}</Typography>
						}
					</div>
				</Paper>
				
				{/* 藍色條 */}
				<Paper style={{backgroundColor:UGCblue,display:'flex',justifyContent:'space-between',alignItems:'center',width:"100%", height:'7vh', textAlign:'center'}} elevation={0} square={true}>
					<Typography variant="h6" noWrap style={{fontFamily:'微軟正黑體',margin:'3px 3px 3px 6px',fontSize:'16px',color:'white'}}>
						{this.props.name !== undefined?`${this.props.name}，您好`:null}
					</Typography>
					<div style={{margin:'2vh'}}>
					{this.state.countdown!==""&&this.state.page_id===4?
						<CountDown color={"white"} fontSize = {"16px"} countdown={this.state.countdown} setCountdown={this.setCountdown} fetchAssess={this.fetchAssess} style={{display:'flex',margin:'0 auto',alignItems:'center',justifyContent:'center'}}/>
					:null}
					</div>
					{this.state.page_id===4?
					<Typography variant="h6" noWrap style={{fontFamily:'微軟正黑體',margin:'3px 6px 3px 3px',fontSize:'16px',color:'white'}}>
					目前進度：{this.state.percent}%
					</Typography>
					:null}
				</Paper>
				
				
				<Paper style={{display:'flex',width:"100%",flexDirection:'column',height:'75vh',overflowY:'auto',alignItems:'center'}} elevation={0} square={true}>
					<Grid container spacing={2} style={{width:'100%'}}>
						{(this.state.prog_bar===false&&this.state.page_id===4)||this.state.page_id===8?null
						:
						<Grid item xs={12} sm={12} style={{marginTop:'5vw',marginBottom:'3vw'}}>
							<div style={{display:'flex',justifyContent:'space-evenly',alignItems:'baseline'}}>
							{steps.map(e=>
								<div style={{display:'flex'}}>
									<Typography style={this.state.page_id===e.page_id?{fontFamily:'微軟正黑體',fontSize:'4.5vw',fontWeight:'bold',color:UGCblue}:{fontFamily:'微軟正黑體',fontSize:'4.5vw',color:'#A6ACAF'}}>{e.desc}</Typography>
									{this.state.page_id===e.page_id && this.state.page_id<6?
										<div style={{display:'flex',alignItems:'center',marginLeft:'3px'}}>
											<PlayArrow style={{fontSize:'4.5vw',color:UGCblue}}/>
										</div>
									:
										<div style={{display:'flex',marginLeft:'3px'}}>
											<PlayArrow style={{fontSize:'4.5vw',color:'white'}}/>
										</div>
									}
								</div>
							)}
							</div>
						</Grid>
						}

					{this.state.page_id===3?
					<PageThree  isMobile={isMobile}
								notice1={this.props.notice1}
								notice2={this.props.notice2}
								countdown={this.props.countdown}
								fetchDoAssess={this.fetchDoAssess}
					/>
					:null}

					{this.state.page_id===4?
					<PageFour   isMobile={isMobile}
								page_info={this.state.page_info}
								fetchAssess={this.fetchAssess}
								setSendAns={this.setSendAns}
								nextBtnDisabled={[...new Set(this.state.send_ans.map(x=>x.qd_seq))].length===this.state.page_info.prob.length?false:true}
								btn={this.state.btn}
					/>
					:null}

					{this.state.page_id===5?
					<PageFive   p5_resp={this.state.p5_resp}
								fetchUpdateInfo={this.fetchUpdateInfo}
								isMobile={isMobile}
								fail_field={this.state.fail_field}
								fail_msg={this.state.fail_msg}
					/>
					:null}

					{this.state.page_id===6||this.state.page_id===7?
					<Grid item xs={12} sm={12} style={{display:'flex',justifyContent:'center'}}>
					<Grid container spacing={2} style={{marginTop:'6vw',marginBottom:'3vh'}}>
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',justifyContent:'flex-start'}}>
							<Typography style={{fontFamily:'微軟正黑體',fontSize:'18px'}}>測驗完成，感謝您的填答！</Typography>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} style={{marginTop:'100px'}}>
						<div style={{display:'flex',justifyContent:'center'}}>
							<Button style={CSS.mobileButtonStyle}
									onClick={()=>{
										if(this.props.url){window.location.assign(this.props.url);}
										else {window.location.assign(this.state.url);}
									}}
							>登出</Button>
						</div>
						</Grid>
					</Grid>
					</Grid>
					:null}

					{this.state.page_id===8?
					<Grid item xs={12} sm={12} style={{display:'flex',justifyContent:'center'}}>
					<Grid container spacing={2} style={{marginTop:'6vw',marginBottom:'3vh'}}>
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',justifyContent:'center'}}>
								<ErrorOutline style={{color:UGCblue,fontSize:'50vw'}}/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',justifyContent:'center'}}>
							<Typography style={{fontFamily:'微軟正黑體',fontSize:'7vw'}}>
								{this.props.msg?this.props.msg:null}
								{this.state.msg?this.state.msg:null}
							</Typography>
							</div>
						</Grid>
						<Grid item xs={12} sm={12}>
						<div style={{display:'flex',justifyContent:'center'}}>
							<Button style={CSS.mobileButtonStyle}
									onClick={()=>{
										if(this.props.url){window.location.assign(this.props.url);}
										else {window.location.assign(this.state.url);}
									}}
							>確認</Button>
						</div>
						</Grid>
					</Grid>
					</Grid>
					:null}

				</Grid>
				</Paper>
				<BottomBar/>
			</div>
        );
        }
	}
}

export default RenderAssess;