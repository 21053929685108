import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Grid from '@material-ui/core/Grid';
import {api_path} from '../../../../environment';
import * as CSS from '../../../../css.js';
import {SearchBtn,ClearSearchBtn,CheckAgainMsgBtn,MsgBtn} from '../../buttons';

const b2cOrderHeadRows = ["姓名","email","電話","身份","訂購日","匯款帳號末四碼","測評名稱","訂購量","測評單價","匯款確認"];

class CheckOrderModal extends React.Component {
    constructor(props) {
        super(props);
		this.state = { modal: false, 
						pay_date: "",
						qprice: "",
						fail_msg: "",
						fail_field: "",
						tp_seq: "",
						tpList: [{}],
						submit: false,
					};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		if(!this.state.modal) {this.fetchTpList();}
		if(this.state.modal) {this.props.fetchB2cOrders();}
		this.setState({ modal: !this.state.modal,
						qprice: this.props.order.qprice,
						pay_date: new Date().toISOString().slice(0,10),
					});
    }
	
	
	fetchTpList = () => {
		let send_body = JSON.stringify({ func: "get_template_list",
										login_uid: this.props.uid,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ tpList:response.result.filter(tp=>tp.qi_seq===this.props.order.qi_seq),
								tp_seq:response.result.filter(tp=>tp.qi_seq===this.props.order.qi_seq)[0].tp_seq,
							});
			}
			else if(response.status==="FAIL"){}
		})
		.catch(error => console.error('Error:', error));
	}


    fetchCheckOrder=()=>{
        let send_body = JSON.stringify({ func:'b2c_order_check',
										login_uid: this.props.uid,
										txid: this.props.order.txid,
										pay_date: this.state.pay_date,
										qprice: this.state.qprice,
										tp_seq: this.state.tp_seq,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({msg: response.msg, submit: true})
            };
        })
        .catch(error => console.error('Error:', error));
    }

    render() {
        return (
        <div>
			{this.props.order.pay_date!==""?
			this.props.order.pay_date
			:
			<Button 
				 
				onClick={this.toggle}
				disabled={this.props.auth!=="write"}
				style={CSS.UGCbtnStyle(this.props.auth!=="write")}
			>確認</Button>}
                
			{this.state.submit?
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={this.state.modal}
                // onClose={this.toggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>匯款確認</Typography></DialogTitle>
				<DialogContent>
                <DialogContentText id="alert-dialog-description">
				<Typography style={CSS.justFont}>{this.state.msg}</Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
                關閉
                </Button>
                </DialogActions>
            </Dialog>
            :
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>匯款確認</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{...{color:"red"},...CSS.justFont}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="測評單價"
							value={this.state.qprice}
                            onChange={e => this.setState({qprice : e.target.value})}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
							error={this.state.fail_field === "qprice"}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="匯款日期"
							value={this.state.pay_date}
							onChange={e => this.setState(({pay_date : e.target.value}))}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
							type="date"
							InputLabelProps={{shrink: true}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="施測情境"
                            SelectProps={{native: true}}
                            InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
							fullWidth
							value={this.state.tp_seq}
                            onChange={e => this.setState({tp_seq : e.target.value})}
                            error = {this.state.fail_field === "qname"}
                        >
							<option value=""></option>
							{this.state.tpList.filter(tp=>tp.tp_type==="0").map(tp=><option value={tp.tp_seq}>{tp.tp_name}</option>)}
                        </TextField>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchCheckOrder} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
			}
        </div>
        );
    }
}

class B2COrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = { searchB2cOrderData:[{}],
						search_dtype:'ORD',
						search_start:'',
						search_end:'',
						search_name:'',
						search_email:'',
						search_tel:'',
						search_tax_id:'',
						seach_corp_name:'',
						search_mtx_code:'',
						select_type:'',
						textfieldValue:'',
						checkedValues: [],
						anchorEl: null,
						show_start: "",
						show_end: "",
						page_id: 1,
					};
	}

	componentDidMount() {
        this.fetchB2cOrders();
	}

	fetchB2cOrders = () => {
		let send_body = JSON.stringify({ func: 'b2c_order_query',
										login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
										mtx_code:this.state.search_mtx_code,
										email:this.state.search_email,
										name:this.state.search_name,
										tel:this.state.search_tel,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2cOrderData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
    }

	handleResetSearch = () => {
		this.setState({ search_dtype:'ORD',
						search_start:'',
						search_end:'',
						search_name:'',
						search_email:'',
						search_tel:'',
						search_mtx_code:'',
						select_type:'',
						textfieldValue:'',
					});
		let send_body = JSON.stringify({ func: 'b2c_order_query',
										login_uid: this.props.uid,
										start: "",
										end: "",
										mtx_code: "",
										email: "",
										name: "",
										tel: "",
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2cOrderData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleChangePage = page_id => {
		let send_body = JSON.stringify({ func: 'b2c_order_query',
										login_uid: this.props.uid,
										start:this.state.search_start,
										end:this.state.search_end,
										mtx_code:this.state.search_mtx_code,
										email:this.state.search_email,
										name:this.state.search_name,
										tel:this.state.search_tel,
										page_id: page_id,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2cOrderData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleSelectSearchType = e => {
		if(this.state.select_type === "name"){
			this.setState({ search_name:e.target.value,
							search_tel:'',
							search_email:'',
							search_mtx_code:'',
							textfieldValue:e.target.value,
						});
		}
		else if(this.state.select_type === "tel"){
			this.setState({ search_name:'',
							search_tel:e.target.value,
							search_email:'',
							search_mtx_code:'',
							textfieldValue:e.target.value,
						});
		}
		else if(this.state.select_type === "email"){
			this.setState({ search_name:'',
							search_tel:'',
							search_email:e.target.value,
							search_mtx_code:'',
							textfieldValue:e.target.value,
						});
		}
		else if(this.state.select_type === "mtx_code"){
			this.setState({ search_name:'',
							search_tel:'',
							search_email:'',
							search_mtx_code:e.target.value,
							textfieldValue:e.target.value,
						});
		}
	}

	fetchDownloadB2cOrder=()=> {
        let send_body = JSON.stringify({ func: 'b2c_order_download',
										login_uid: this.props.uid,
										start: this.state.show_start,
										end: this.state.show_end,
										dtoken: this.state.dtoken,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
	}

  	render() {
	return (
		<div style ={{display:'flex',flexDirection:'column',}}>
            <div style={{display:'flex',textAlign:'right',justifyContent:'flex-start',margin:'5px',alignItems:'flex-end'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>欄位查詢：</Typography>
						<TextField
							id="standard-select-currency-native"
							select
							SelectProps={{native: true}}
							style={{margin:'5px'}} 
							value={this.state.select_type} 
							onChange={e=>{this.setState({select_type:e.target.value, textfieldValue:''})}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						>
							<option value=""></option>
							<option value="mtx_code">匯款帳號末四碼</option>
							<option value="email">email</option>
							<option value="tel">電話</option>
							<option value="name">姓名</option>
						</TextField>
                        <TextField
                            id="standard-name"
                            margin="dense"
                            style={{margin:'5px'}}
                            onChange={this.handleSelectSearchType}
                            value={this.state.textfieldValue}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                        />
                    </div>
                    <div style={{display:'flex', alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>訂購日區間查詢：</Typography>
                        <TextField 
                            id="date"
                            type="date"
                            margin='dense'
							InputProps={{shrink:true,style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            style={{margin:'5px'}}
                            onChange={e=>{this.setState({search_start:e.target.value})}}
                        />
                        <Typography style={CSS.adminInputStyle}>～</Typography>
                        <TextField 
                            id="date"
							type="date"
							InputProps={{shrink:true,style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            style={{margin:'5px'}}
                            onChange={e=>{this.setState({search_end:e.target.value})}}
                        />
                    </div>
                </div>
				<div style={{display:'flex', alignItems:'center'}}>
				<SearchBtn onClick={this.fetchB2cOrders}/>
				<ClearSearchBtn onClick={this.handleResetSearch}/>
				</div>
            </div>
            <Paper style={{width:'100%',overflowX:'auto',minWidth: "100%"}}>
            <Table>
                <TableHead><TableRow style={CSS.headRowStyle}>
					<TableCell align="center" style={CSS.headCellStyle}>
						<IconButton size="small"onClick={e=>this.setState({anchorEl:e.currentTarget})}><ArrowDropDown/></IconButton>
						<Menu id="simple-menu"
							id="simple-menu"
							anchorEl={this.state.anchorEl}
							keepMounted
							open={Boolean(this.state.anchorEl)}
							onClose={()=>this.setState({anchorEl:null})}
						>
							<MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: this.state.searchB2cOrderData.map(order => order.txid)})} style={CSS.justFont}>全選</MenuItem>
							<MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: []})} style={CSS.justFont}>全不選</MenuItem>
						</Menu>
					</TableCell>
                    {b2cOrderHeadRows.map(e=><TableCell key={e} align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
                </TableRow></TableHead>
                <TableBody>
                {this.state.searchB2cOrderData.map((order,index)=>
                    <TableRow key={order.txid} selected={this.state.checkedValues.includes(order.txid)} style={CSS.tablebodyStyle(index)}>
						<TableCell align="center" style={CSS.bodyCellStyle}>
							<Checkbox 
								label={order.txid} key={order.txid}
								checked={this.state.checkedValues.includes(order.txid)}
								onChange={e => this.handleCheck(e,order.txid)}
							/>
						</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>{order.name}</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>{order.email}</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>{order.tel}</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>{order.role}</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>{order.ord_date}</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>{order.mtx_code}</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>{order.qname}</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>{order.ord_amt}</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>{order.qprice}</TableCell>
                        <TableCell align="center" style={CSS.bodyCellStyle}>
                            <CheckOrderModal uid={this.props.uid} order={order} fetchB2cOrders={()=>this.handleChangePage(this.state.page_id)} auth={this.props.auth}/>
                        </TableCell>
                    </TableRow>
				)}
				<TableRow>
					<TableCell  colSpan={5} align="left" style={CSS.adminInputStyle}>{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}</TableCell>
				</TableRow>
                </TableBody>
            </Table>
            </Paper>
			<div style={{display:'flex',justifyContent:'space-between'}}>
				<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
					<Typography style={{...{marginRight:'10px'},...CSS.adminInputStyle}}>已選：{this.state.checkedValues.length} 項</Typography>
					<MsgBtn 
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(false)}}
						send_body={JSON.stringify({ func: 'b2c_order_download',
													login_uid: this.props.uid,
													start: this.state.show_start,
													end: this.state.show_end,
													dtoken: this.state.dtoken,
											})
									}
						btn_name="下載"
					/>
					<CheckAgainMsgBtn
						disabled={this.state.checkedValues.length===0||this.props.auth!=="write"}
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(this.state.checkedValues.length===0||this.props.auth!=="write")}}
						send_body={JSON.stringify({ func: 'b2c_order_del',
													login_uid: this.props.uid,
													txid_list: this.state.checkedValues,
											})
									}
						btn_name="刪除"
						check_msg="確認刪除？"
						succProcess={()=>this.handleChangePage(this.state.page_id)}
					/>
				</div>
				<div style={{display:'flex',margin:'20px'}}>
				<Button size="large" disabled={this.state.pre_page===""} onClick={()=>this.handleChangePage(this.state.pre_page)} style={CSS.justFont}>上一頁</Button>
				<Button size="large" disabled={this.state.nxt_page===""} onClick={()=>this.handleChangePage(this.state.nxt_page)} style={CSS.justFont}>下一頁</Button>
				</div>
			</div>
        </div>
	);
	}
}

export default B2COrder;