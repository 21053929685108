import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import renderHTML from 'react-render-html';
import Search from '@material-ui/icons/Search';
import {api_path} from '../../../environment';
import * as CSS from '../../../css.js';
import {SearchBtn,ClearSearchBtn} from '../buttons';
import ChangeInfo from '../changeInfo';

const ResultHeadRows = ["經銷商名稱","企業名稱","統編","","姓名","轉分送","測評名稱","單位","職稱","職類","職級","施測完成日","施測時長","狀態"];


class DownloadBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false };
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({modal:!this.state.modal,state:"2"});
	}
	fetchDownloadRpt = () => {
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: this.props.send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
					this.setState({modal:false});
				}
				if(response.url){
				    var link = document.createElement("a");
                	link.href = response.url;
					link.target="_blank"
					// link.download = response.rpt_fname;
					link.click();
					this.setState({modal:false});
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<Button
				style={this.props.style} 
				disabled={this.props.disabled}
				variant={this.props.variant}
				onClick={()=>{ 	this.toggle(); this.fetchDownloadRpt(); }}
			>{this.props.btn_name}</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>{this.props.btn_name}</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',}}>關閉</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class RptBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						msg: "",
						state: "1",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal){this.setState({modal:!this.state.modal,state:"2"})}
		if(this.state.modal&&this.state.state==="1"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "2",
						});
		}
		else if(this.state.modal&&this.state.state==="2"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}
		else if(this.state.modal&&this.state.state==="3"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}

	}
	fetchDownloadRpt= qa_seq => {
        let send_body = JSON.stringify({ func: 'download_rpt',
										login_uid: this.props.uid,
										qa_seq: qa_seq,
										pr_id: this.props.pr_id,
										stu_pr_id: this.props.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.url){
                    var link = document.createElement("a");
					link.href = response.url;
					link.target="_blank"
					// link.download = response.rpt_fname;
                    link.click();
                    this.setState({msg: response.msg, state: "3",modal:false});
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button
				 
				onClick={()=>{ 	
								this.toggle();
								this.fetchDownloadRpt(this.props.qa_seq);
				}}
				style={CSS.UGCbtnStyle(false)}
			>看報告</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>報告將顯示，請稍待</Typography></DialogTitle>
				<DialogContent>
				{this.state.state==="2"?
            	<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				:null}
				</DialogContent>
				{this.state.state==="3"?
            	<DialogActions>
					<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
					關閉
					</Button>
                </DialogActions>
				:
				this.state.state==="2"?
				<DialogActions>
				</DialogActions>
				:
				null
				}
			</Dialog>
		</div>
		);
	}
}

class Send extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tax_id: "",
						title_seq: "",
						dept_seq: "",
						title_other: "",
						dept_other: "",
						fail_field: "",
						fail_msg: "",
						dept_list: [{}],
						title_list: [{}],
						qList: [],
						tax_id_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,});
	}

    fetchDept = tax_id => {
        let send_body = JSON.stringify({ func:'get_dept_list',
										login_uid: this.props.uid,
										tax_id: tax_id,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({dept_list:response.result,tax_id_msg:"",fail_field:""})
            }
			else if(response.status==="FAIL"){
				this.setState({ tax_id_msg: response.msg, 
								fail_field: "tax_id",
								dept_list: [{}],
								title_list: [{}],
							});
			}
        })
        .catch(error => console.error('Error:', error));
	}

    fetchTitle = dept_seq => {
        let send_body = JSON.stringify({ func:'get_title_list',
                                        login_uid: this.props.uid,
                                        dept_seq: dept_seq,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({title_list:response.result,})
			}
        })
        .catch(error => console.error('Error:', error));
	}

	fetchQaTrans = () => {
        let send_body = JSON.stringify({ func: 'qa_trans',
										login_uid: this.props.uid,
										qa_seq: this.props.qRpt.qa_seq,
										tax_id: this.state.tax_id,
										dept_seq: this.state.dept_seq,
										// dept_other: this.state.dept_other,
										title_seq: this.state.title_seq,
										// title_other: this.state.title_other,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchQrpt();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button style={CSS.UGCbtnStyle(false)} onClick={this.toggle}>轉分送</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>轉分送</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red"}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={7}>
						<div style={{display:'flex',alignItems:'baseline'}}>
						<TextField
							id="standard-name"
							label="統一編號"
							value={this.state.tax_id}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => {
								this.setState({tax_id : e.target.value});
								if(e.target.value.length >= 8){
									this.fetchDept(e.target.value);
								}
								else{
									if(e.target.value.length === 0) {this.setState({ fail_field: ""});}
									this.setState({ dept_list: [{}],
													title_list: [{}],
												})
								}
							}}
							margin="normal"
							fullWidth
                            error={this.state.fail_field === "tax_id"||(this.state.tax_id.length<8&&this.state.tax_id.length>0)}
						/>
						{this.state.tax_id_msg!==""?
                    	<Typography style={{color:"red", marginLeft:'5px'}}>{this.state.tax_id_msg}</Typography>
						:null}
						</div>
					</Grid>
					<Grid item xs={12} sm={7}>
						<TextField
                            id="standard-select-currency-native"
                            select
                            label="單位"
                            SelectProps={{native: true}}
							margin="normal"
							fullWidth
                            value={this.state.dept_seq}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e=>{
								this.setState({dept_seq:e.target.value,title_seq:""});
								if(e.target.value!==""){
									this.fetchTitle(e.target.value);
									this.setState({dept_other:""});
								}
								else{
									this.setState({title_seq:""});
								}
							}}
                            error = {this.state.fail_field === "dept_seq" ? true:null}
                        >
							<option value=""></option>
                        	{this.state.dept_list.map(dept=><option value={dept.dept_seq}>{dept.dept_name}</option>)}
                		</TextField>
					</Grid>
					{/* <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="新增單位名稱"
							value={this.state.dept_other}
                            onChange={e => {
								this.setState({ dept_other: e.target.value,});
								if(e.target.value!==""){
									this.setState({ dept_seq: "",
													title_seq: "",
													title_list: [{}],
												})
								}
							}}
                            margin="normal"
						/>
					</Grid> */}
					<Grid item xs={12} sm={7}>
						<TextField
                            id="standard-select-currency-native"
                            select
                            label="職稱"
                            SelectProps={{native: true}}
							margin="normal"
							fullWidth
							value={this.state.title_seq}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e => {
								this.setState({title_seq : e.target.value})
								if(e.target.value!==""){
									this.setState({title_other:""});
								}
							}}
                            error = {this.state.fail_field === "title" ? true:null}
                        >
							<option value=""></option>
							{this.state.title_list.map(title => <option value={title.title_seq}>{title.title_name}</option>)}
                        </TextField>
					</Grid>
					{/* <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="新增職稱名稱"
							value={this.state.title_other}
                            onChange={e => {
								this.setState({ title_other: e.target.value})
								if(e.target.value!==""){
									this.setState({ title_seq: "",})
								}
							}}
                            margin="normal"
						/>
					</Grid>	 */}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={this.fetchQaTrans} color="primary" style={CSS.justFont}>確認</Button>
					<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>取消</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class AdminQresult extends React.Component {
	constructor(props) {
		super(props);
		this.state = { qRptSearchData:[{}],
						search_emp_no: "",
						search_name: "",
						search_dept_seq: "",
						search_title_seq: "",
						search_pfm_seq: "",
						search_grp_seq: "",
						search_qf_start: "",
						search_qf_end: "",
						search_email: "",
						search_corp_name: "",
						search_tax_id: "",
						filter: "",
						checkedValues: [],
						anchorEl: null,
						corpGroup:{department:[],performance:[],group:[]},
						rpt_url: "",
						usage_url: "",
						qStatusList: [{}],
						show_start:'',
						show_end:'',
						prList:[{}],
						stuprList:[{}],
						pr_id:"1001",
						stu_pr_id:"2002",
						page_id: 1,
					};
	}

	componentDidMount(){
		this.fetchQrpt();
		this.fetchPRList();
		this.fetchStuPRList();
	}

	handleResetSearch = () =>{
		this.setState({ qi_id: "",
						qi_name: "",
						qi_remark: "",
						qi_status: "",
						textfieldValue: "",
						select_type: "",
						search_name: "",
						search_email: "",
						search_tax_id: "",
						search_corp_name: "",
					});
		let send_body = JSON.stringify({ func: 'query_q_rpt',
										login_uid: this.props.uid,
										name: "",
										emp_no: "",
										dept_seq: "",
										title_seq: "",
										pfm_seq: "",
										grp_seq: "",
										qf_start: "",
										qf_end: "",
										email: "",
										corp_name: "",
										tax_id: "",
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
		method: 'POST',
		body: send_body,
		headers: new Headers()
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
			this.setState({ qRptSearchData:response.result,
							pre_page: response.pre_page,
							nxt_page: response.nxt_page,
							checkedValues: [],
							show_end: response.end,
							show_start: response.start,
							dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
						});
			}
		}).catch(error => console.error('Error:', error));
	}

    fetchQrpt=()=> {
        let send_body = JSON.stringify({ func: 'query_q_rpt',
                                        login_uid: this.props.uid,
                                        name: this.state.search_name,
										emp_no: this.state.search_emp_no,
										email: this.state.search_email,
										dept_seq: this.state.search_dept_seq,
										title_seq: this.state.search_title_seq,
										pfm_seq: this.state.search_pfm_seq,
										grp_seq: this.state.search_grp_seq,
										qf_start: this.state.search_qf_start,
										qf_end: this.state.search_qf_end,
										corp_name:this.state.search_corp_name,
										tax_id: this.state.search_tax_id,
										corp_name:this.state.search_corp_name,
										tax_id: this.state.search_tax_id,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ qRptSearchData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
								page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
	handleChangePage = page_id => {
		let send_body = JSON.stringify({ func: 'query_q_rpt',
                                        login_uid: this.props.uid,
                                        name: this.state.search_name,
										emp_no: this.state.search_emp_no,
										email: this.state.search_email,
										dept_seq: this.state.search_dept_seq,
										title_seq: this.state.search_title_seq,
										pfm_seq: this.state.search_pfm_seq,
										grp_seq: this.state.search_grp_seq,
										qf_start: this.state.search_qf_start,
										qf_end: this.state.search_qf_end,
										corp_name:this.state.search_corp_name,
										tax_id: this.state.search_tax_id,
										page_id: page_id,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ qRptSearchData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
								page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
        .catch(error => console.error('Error:', error));
	}

	fetchDownloadRpt=()=> {
        let send_body = JSON.stringify({ func: 'download_q_rpt',
										login_uid: this.props.uid,
										qa_list: this.state.checkedValues,
										start: this.state.show_start,
										end: this.state.show_end,
										pr_id: this.state.pr_id,
										stu_pr_id: this.state.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
	fetchDownloadRec=()=> {
        let send_body = JSON.stringify({ func: 'q_rec_download',
										login_uid: this.props.uid,
										start: this.state.show_start,
										end: this.state.show_end,
										dtoken: this.state.dtoken,
										pr_id: this.state.pr_id,
										stu_pr_id: this.state.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
	fetchDownloadRaw=()=> {
        let send_body = JSON.stringify({ func: 'raw_data_download',
										login_uid: this.props.uid,
										start: this.state.show_start,
										end: this.state.show_end,
										dtoken: this.state.dtoken,
										pr_id: this.state.pr_id,
										stu_pr_id: this.state.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
	fetchPRList = () => {
		let send_body = JSON.stringify({ func:'get_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ prList:response.result,
								pr_id:"1002",})
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchStuPRList = () => {
		let send_body = JSON.stringify({ func:'get_stu_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ stuprList:response.result,
								stu_pr_id:"2001",
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
	}

  	render() {
	
   	return (
		<div>
			<div style={{display:'flex', flexDirection:'column',marginBottom:'20px'}}>
				<div style={{display:'flex',alignItems:'baseline',alignSelf:'flex-start'}}>
					<Typography style={CSS.adminInputStyle}>
						比對常模：
					</Typography>
					<TextField
						id="standard-select-currency-native"
						select
						label="社會人士"
						SelectProps={{native: true}}
						value={this.state.pr_id}
						onChange={e=>this.setState({pr_id:e.target.value})}
						style={{margin:'5px', width:'100px'}}
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{style:CSS.adminInputStyle,shrink:true}}
					>
						<option value=""></option>
						{this.state.prList.map(pr=><option value={pr.id}>{pr.val}</option>)}
					</TextField>
					<TextField
						id="standard-select-currency-native"
						select
						label="社會新鮮人"
						SelectProps={{native: true}}
						value={this.state.stu_pr_id}
						onChange={e=>this.setState({stu_pr_id:e.target.value})}
						style={{margin:'5px', width:'100px'}}
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{style:CSS.adminInputStyle,shrink:true}}
					>
						<option value=""></option>
						{this.state.stuprList.map(pr=><option value={pr.id}>{pr.val}</option>)}
					</TextField>
				</div>
				<div style={{display:'flex',alignItems:'baseline'}}>
					<div style={{display:'flex',alignItems:'baseline'}}>
						<Typography style={CSS.adminInputStyle}>
							日期區間：
						</Typography>
						<TextField 
							id="date"
							type="date"
							InputLabelProps={{shrink:true}}
							value={this.state.search_qf_start}
							onChange={e=>{this.setState({search_qf_start:e.target.value})}}
							style={{margin:'5px',width:'130px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						/>
						<Typography style={CSS.adminInputStyle}> ～ </Typography>
						<TextField 
							id="date"
							type="date"
							InputLabelProps={{shrink:true}}
							value={this.state.search_qf_end}
							onChange={e=>{this.setState({search_qf_end:e.target.value})}}
							style={{margin:'5px',width:'130px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						/>
					</div>
					<div style={{display:'flex',alignItems:'baseline'}}>
						<TextField
							id="standard-name"
							label="姓名"
							value={this.state.search_name}
							onChange={e => this.setState(({search_name : e.target.value}))}
							style={{margin:'5px',width:'130px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						/>
						<TextField
							id="standard-name"
							label="email"
							value={this.state.search_email}
							onChange={e => this.setState(({search_email : e.target.value}))}
							style={{margin:'5px',width:'130px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						/>
						<TextField
							id="standard-name"
							label="企業名稱"
							value={this.state.search_corp_name}
							onChange={e => this.setState(({search_corp_name : e.target.value}))}
							style={{margin:'5px',width:'130px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						/>
						<TextField
							id="standard-name"
							label="統一編號"
							value={this.state.search_tax_id}
							onChange={e => this.setState(({search_tax_id : e.target.value}))}
							style={{margin:'5px',width:'130px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						/>
						<TextField
							id="standard-select-currency-native"
							select
							label="單位"
							SelectProps={{native: true}}
							value={this.state.search_dept_seq}
							onChange={e=>this.setState({search_dept_seq:e.target.value})}
							style={{margin:'5px', width:'100px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						>
							<option value=""></option>
							{this.state.corpGroup["department"].map(dept=><option value={dept.seq}>{dept.name}</option>)}
						</TextField>
						{/* <TextField
							id="standard-select-currency-native"
							select
							label="績效"
							SelectProps={{native: true}}
							value={this.state.search_pfm_seq}
							onChange={e=>this.setState({search_pfm_seq:e.target.value})}
							error = {this.state.fail_field === "pfm_seq" ? true:null}
							style={{margin:'5px', width:'100px'}}
						>
							<option value=""></option>
							{this.state.corpGroup["performance"].map(pfm=><option value={pfm.seq}>{pfm.name}</option>)}
						</TextField>
						<TextField
							id="standard-select-currency-native"
							select
							label="群組"
							SelectProps={{native: true}}
							value={this.state.search_grp_seq}
							onChange={e=>this.setState({search_grp_seq:e.target.value})}
							error = {this.state.fail_field === "grp_seq" ? true:null}
							style={{margin:'5px', width:'100px'}}
						>
							<option value=""></option>
							{this.state.corpGroup["group"].map(grp=><option value={grp.seq}>{grp.name}</option>)}
						</TextField> */}
						<div style={{display:'flex',alignItems:'center'}}>
							<SearchBtn onClick={this.fetchQrpt}/>
							<ClearSearchBtn onClick={this.handleResetSearch}/>
						</div>
					</div>
				</div>
			</div>
			<Paper style={{width:'100%',overflowX:'auto'}}>
			<Table>
				<TableHead>
					<TableRow style={CSS.headRowStyle}>
						<TableCell colSpan={1}></TableCell>
						<TableCell align="center" colSpan={3} style={CSS.headCellStyle}>企業資訊</TableCell>
						<TableCell align="center" colSpan={9} style={CSS.headCellStyle}>受測者資訊</TableCell>
						<TableCell align="center" colSpan={3} style={CSS.headCellStyle}>其他施測資訊</TableCell>
					</TableRow>
					<TableRow style={CSS.headRowStyle}>
						<TableCell align="center" style={CSS.headCellStyle}>
							<IconButton size="small" onClick={e=>{this.setState({anchorEl:e.currentTarget})}}><ArrowDropDown/></IconButton>
							<Menu id="simple-menu"
								anchorEl={this.state.anchorEl}
								keepMounted
								open={Boolean(this.state.anchorEl)}
								onClose={()=>this.setState({anchorEl:null})}
							>
								<MenuItem onClick={()=>this.setState({anchorEl:null, filter:"all",checkedValues: this.state.qRptSearchData.map(rpt => rpt.qa_seq)})} style={CSS.justFont}>全選</MenuItem>
								<MenuItem onClick={()=>this.setState({anchorEl:null, filter:"none",checkedValues: []})} style={CSS.justFont}>全不選</MenuItem>
							</Menu>
						</TableCell>
						{ResultHeadRows.map(e=><TableCell align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
					</TableRow>
				</TableHead>
				<TableBody>
				{this.state.qRptSearchData.map((qRpt,index) => 
					<TableRow style={CSS.tablebodyStyle(index)}>
						<TableCell align="center" style={CSS.bodyCellStyle} selected={this.state.checkedValues.includes(qRpt.qa_seq)}>
							<Checkbox label={qRpt.qa_seq} key={qRpt.qa_seq} checked={this.state.checkedValues.includes(qRpt.qa_seq)} onChange={e=>this.handleCheck(e,qRpt.qa_seq)}/>
						</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.dealer_name}</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.corp_name}</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.tax_id}</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>
							<RptBtn uid={this.props.uid} qa_seq={qRpt.qa_seq} stu_pr_id={this.state.stu_pr_id} pr_id={this.state.pr_id}/>
						</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>
							<ChangeInfo uid={this.props.uid} qRpt={qRpt} role="ugc"  succProcess={()=>this.handleChangePage(this.state.page_id)}/>
						</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>
							<Send uid={this.props.uid} auth={this.props.auth} qRpt={qRpt} fetchQrpt={()=>this.handleChangePage(this.state.page_id)}/>
						</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.qname}</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.dept_name}</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.title_name}</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.dept2}</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.title2}</TableCell>
						{/* <TableCell style={CSS.bodyCellStyle}>{qRpt.pfm_name}</TableCell>
						<TableCell style={CSS.bodyCellStyle}>{qRpt.grp_name}</TableCell> */}
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.qf_date}</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.qf_time}</TableCell>
						<TableCell align="center" style={CSS.bodyCellStyle}>{qRpt.q_status===-1?"作廢":"已完成"}</TableCell>
					</TableRow>
				)}
					<TableRow><TableCell colSpan={5} align="left" style={CSS.adminInputStyle}>
							{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}
					</TableCell></TableRow>
			</TableBody>
			</Table>
			</Paper>
			<div style={{display:'flex',justifyContent:'space-between'}}>
				<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
					<Typography style={{...{marginRight:'10px'},...CSS.adminInputStyle}}>已選：{this.state.checkedValues.length} 項</Typography>
					<DownloadBtn
						 style={CSS.UGCbtnStyle(false)}
						send_body={JSON.stringify({ func: 'download_q_rpt',
													login_uid: this.props.uid,
													qa_list: this.state.checkedValues,
													start: this.state.show_start,
													end: this.state.show_end,
													dtoken: this.state.dtoken,
													pr_id: this.state.pr_id,
													stu_pr_id: this.state.stu_pr_id,
												})}
						btn_name={"一般報告下載"}
					/>
					<DownloadBtn
						 style={CSS.UGCbtnStyle(false)}
						send_body={JSON.stringify({ func: 'download_eff_rpt',
													login_uid: this.props.uid,
													qa_list: this.state.checkedValues,
													start: this.state.show_start,
													end: this.state.show_end,
													dtoken: this.state.dtoken,
													pr_id: this.state.pr_id,
													stu_pr_id: this.state.stu_pr_id,
												})}
						btn_name={"效標報告下載"}
					/>
					<DownloadBtn
						 style={CSS.UGCbtnStyle(false)}
						send_body={JSON.stringify({ func: 'q_rec_download',
													login_uid: this.props.uid,
													qa_list: this.state.checkedValues,
													start: this.state.show_start,
													end: this.state.show_end,
													dtoken: this.state.dtoken,
													pr_id: this.state.pr_id,
													stu_pr_id: this.state.stu_pr_id,
												})}
						btn_name={"下載使用紀錄"}
					/>
					<DownloadBtn
						 style={CSS.UGCbtnStyle(false)}
						send_body={JSON.stringify({ func: 'raw_data_download',
													login_uid: this.props.uid,
													qa_list: this.state.checkedValues,
													start: this.state.show_start,
													end: this.state.show_end,
													dtoken: this.state.dtoken,
													pr_id: this.state.pr_id,
													stu_pr_id: this.state.stu_pr_id,
												})}
						btn_name={"原始資料下載"}
					/>
			</div>
			<div style={{display:'flex',margin:'20px'}}>
				<Button size="large"
						disabled={this.state.pre_page===""}
						onClick={()=>this.handleChangePage(this.state.pre_page)}
						style={CSS.justFont}
				>上一頁</Button>
				<Button size="large"
						disabled={this.state.nxt_page===""}
						onClick={()=>this.handleChangePage(this.state.nxt_page)}
						style={CSS.justFont}
				>下一頁</Button>
			</div>
		</div>
	</div>
	);
	}
}


export default AdminQresult;