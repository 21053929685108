import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {api_path} from '../../../../environment';
import * as CSS from '../../../../css.js';
import {SearchBtn,ClearSearchBtn,MsgBtn} from '../../buttons';

const b2bAmtHeadRows = ["經銷商名稱","企業名稱","統一編號","結帳型態","訂單日","測評名稱","銷售額","測評單價","訂購量","使用量","剩餘量","測評完成率"]

class B2BAmount extends React.Component {
	constructor(props) {
		super(props);
		this.state = { searchB2bAmtData:[{}],
						search_tax_id: "",
						search_corp_name: "",
						search_qname: "",
						search_start: "",
						search_end: "",
						search_rest1: "",
						search_rest2: "",
						textfieldValue: "",
						b2bUserStatusList: [{}],
                        b2bAmtTotal: "",
                        checkedValues: [],
                        anchorEl: null,
						show_start: "",
						show_end: "",
						page_id: 1,
					};
	}

	componentDidMount() {
        this.fetchB2bAmount();
	}

	fetchB2bAmount = () => {
		let send_body = JSON.stringify({ func: 'query_b2b_amt',
										login_uid: this.props.uid,
										start: this.state.search_start,
										end: this.state.search_end,
										corp_name: this.state.search_corp_name,
										tax_id: this.state.search_tax_id,
										qname: this.state.search_qname,
										rest1: this.state.search_rest1,
										rest2: this.state.search_rest2,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
                this.setState({ searchB2bAmtData: response.result,
                                b2bAmtTotal: response.total,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
								page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
                            });
			}
		})
		.catch(error => console.error('Error:', error));
    }

	handleResetSearch = () => {
		this.setState({ search_tax_id: "",
						search_corp_name: "",
						search_qname: "",
						search_start: "",
						search_end: "",
						search_rest1: "",
						search_rest2: "",
						select_type: "",
						textfieldValue: "",
					});
		let send_body = JSON.stringify({ func: 'query_b2b_amt',
										login_uid: this.props.uid,
										start: "",
										end: "",
										corp_name: "",
										tax_id: "",
										qname: "",
										rest1: "",
										rest2: "",
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2bAmtData: response.result,
								b2bAmtTotal: response.total,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		}).catch(error => console.error('Error:', error));
	}

	handleChangePage = page_id => {
		let send_body = JSON.stringify({ func: 'query_b2b_amt',
										login_uid: this.props.uid,
										start: this.state.search_start,
										end: this.state.search_end,
										corp_name: this.state.search_corp_name,
										tax_id: this.state.search_tax_id,
										qname: this.state.search_qname,
										rest1: this.state.search_rest1,
										rest2: this.state.search_rest2,
										page_id: page_id,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
                this.setState({ searchB2bAmtData: response.result,
                                b2bAmtTotal: response.total,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
                            });
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleSelectSearchType = e => {
		if(this.state.select_type === "tax_id"){
			this.setState({ search_tax_id: e.target.value,
							search_corp_name: '',
							textfieldValue: e.target.value,
						});
		}
		else if(this.state.select_type === "corp_name"){
			this.setState({ search_tax_id: '',
							search_corp_name: e.target.value,
							textfieldValue: e.target.value,
						});
		}
	}

    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
	}

  	render() {
   	return (
        <div style ={{display:'flex',flexDirection:'column'}}>
            <div style={{display:'flex',textAlign:'right',justifyContent:'flex-start',margin:'5px',alignItems:'flex-end'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>欄位查詢：</Typography>
						<TextField
							id="standard-select-currency-native"
							select
							SelectProps={{native: true}}
							value={this.state.select_type} 
							onChange={e=>{this.setState({select_type:e.target.value,textfieldValue:''})}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							style={{margin:'5px'}}
						>
							<option value=""></option>
							<option value="corp_name">企業名稱</option>
							<option value="tax_id">統一編號</option>
						</TextField>
                        <TextField
                            id="standard-name"
                            margin="dense"
                            style={{margin:'5px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                            onChange={this.handleSelectSearchType}
                        />
                    </div>
					<div style={{display:'flex', alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>測評名稱查詢：</Typography>
					    <TextField
                            id="standard-name"
                            margin="dense"
                            style={{margin:'5px'}}
                            onChange={e=>this.setState({search_qname:e.target.value})}
                            value={this.state.search_qname}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                        />
                    </div>
					<div style={{display:'flex', alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>剩餘量查詢：</Typography>
					    <TextField
                            id="standard-name"
                            margin="dense"
                            style={{margin:'5px'}}
                            onChange={e=>this.setState({search_rest1:e.target.value})}
                            value={this.state.search_rest1}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
                        />
						<Typography style={CSS.adminInputStyle}>～</Typography>
						<TextField
							id="standard-name"
							margin="dense"
							style={{margin:'5px'}}
							onChange={e=>this.setState({search_rest2:e.target.value})}
							value={this.state.search_rest2}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						/>
					</div>  
                    <div style={{display:'flex', alignItems:'baseline'}}>
                        <Typography style={CSS.adminInputStyle}>訂購日區間查詢：</Typography>
                        <TextField 
                            id="date"
                            type="date"
							value={this.state.search_start}
                            style={{margin:'5px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
                            onChange={e=>{this.setState({search_start:e.target.value})}}
                        />
                        <Typography style={CSS.adminInputStyle}>～</Typography>
                        <TextField 
                            id="date"
                            type="date"
							value={this.state.search_end}
                            style={{margin:'5px'}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
                            onChange={e=>{this.setState({search_end:e.target.value})}}
                        />
                    </div>
                </div>
				<div style={{display:'flex',alignItems:'center'}}>
				<SearchBtn onClick={this.fetchB2bAmount}/>
				<ClearSearchBtn onClick={this.handleResetSearch}/>
				</div>
            </div>
            <Paper style={{width:'100%',overflowX:'auto',minWidth: "100%"}}>
                <Table>
                    <TableHead><TableRow style={CSS.headRowStyle}>
                        <TableCell align="center" style={CSS.headCellStyle}>
							<IconButton size="small"onClick={e=>this.setState({anchorEl:e.currentTarget})}><ArrowDropDown/></IconButton>
                            <Menu id="simple-menu"
								anchorEl={this.state.anchorEl}
								keepMounted
								open={Boolean(this.state.anchorEl)}
								onClose={()=>this.setState({anchorEl:null})}
                            >
                                <MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: this.state.searchB2bAmtData.map(amt => amt.txid)})} style={CSS.justFont}>全選</MenuItem>
                                <MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: []})} style={CSS.justFont}>全不選</MenuItem>
                            </Menu>
                        </TableCell>
                        {b2bAmtHeadRows.map(e=><TableCell align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
                    </TableRow></TableHead>
                    <TableBody>
                        {this.state.searchB2bAmtData.map((amt,index) => (
                            <TableRow key={amt.id} selected={this.state.checkedValues.includes(amt.txid)} style={CSS.tablebodyStyle(index)}>
                                <TableCell align="center" style={CSS.bodyCellStyle}>
                                    <Checkbox label={amt.txid} key={amt.txid} checked={this.state.checkedValues.includes(amt.txid)} onChange={e => this.handleCheck(e,amt.txid)}/>
                                </TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.dealer_name}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.corp_name}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.tax_id}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.check_type==="0"?"預購型":null}{amt.check_type==="1"?"月結型":null}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.ord_date}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.qname}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.sale_amt}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.qprice}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.ord_amt}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.used_amt}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.rest_amt}</TableCell>
                                <TableCell align="center" style={CSS.bodyCellStyle}>{amt.fin_ratio}</TableCell>
                            </TableRow>
                        ))}
						<TableRow>
							<TableCell  colSpan={13} align="left" style={CSS.adminInputStyle}>
								{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}
							</TableCell>
						</TableRow>
                        <TableRow>
						    <TableCell colSpan={13} style={CSS.adminInputStyle}>區間總銷售量 : {this.state.b2bAmtTotal}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
            <div style={{display:'flex',justifyContent:'space-between'}}>
				<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
					<Typography style={{...{marginRight:'10px'},...CSS.adminInputStyle}}>已選：{this.state.checkedValues.length} 項</Typography>
					<MsgBtn 
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(false)}}
						send_body={JSON.stringify({ func: "b2b_amt_download",
													login_uid: this.props.uid,
													start: this.state.show_start,
													end: this.state.show_end,
													dtoken: this.state.dtoken,
											})
									}
						btn_name="下載"
					/>
				</div>
				<div style={{display:'flex',margin:'20px'}}>
					<Button size="large" disabled={this.state.pre_page===""} onClick={()=>this.handleChangePage(this.state.pre_page)} style={CSS.justFont}>上一頁</Button>
					<Button size="large" disabled={this.state.nxt_page===""} onClick={()=>this.handleChangePage(this.state.nxt_page)} style={CSS.justFont}>下一頁</Button>
				</div>
            </div>
        </div>
            
	);
	}
}

export default B2BAmount;