import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import {api_path,cellStyle,UGCblue,tablebodyStyle,tableheadrowStyle,tableheadcellStyle,entInfoFontSize} from '../../../environment';

class EntPasswd extends React.Component {
	constructor(props) {
		super(props);
		this.state = { fail_field:"",
                        fail_msg:"",
                        old_passwd: "",
                        new_passwd: "",
                        cfm_passwd: "",
                        modal: false,
                    };
        this.toggle = this.toggle.bind(this);
    }
    
    toggle() {
		this.setState({ modal: !this.state.modal });
    }

	fetchModPasswd = () => {
        let send_body = JSON.stringify({ func: "mod_passwd",
										login_uid: this.props.uid,
										old_passwd: this.state.old_passwd,
										new_passwd: this.state.new_passwd,
										cfm_passwd: this.state.cfm_passwd,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json()).then(response => {
			if(response.status==="SUCC"){
                this.setState({ fail_field:"", 
                                fail_msg:"",
                                old_passwd: "",
                                new_passwd: "",
                                cfm_passwd: "",
                            });
				this.toggle();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {

    const entInfoFontSize = {
        fontFamily:'微軟正黑體',
        fontSize:'16px',
        color:'black',
    };

    const buttonStyleBlue = {
        fontFamily:'微軟正黑體',
        backgroundColor:UGCblue,
        color:'white',
        borderRadius:'30px',
        fontSize:'16px',
        width:'140px',
        padding:'0',
        borderColor:UGCblue,
    };

    const buttonStyleWhite = {
        fontFamily:'微軟正黑體',
        backgroundColor:'white',
        color:UGCblue,
        borderRadius:'30px',
        fontSize:'16px',
        width:'140px',
        padding:'0',
        borderColor:UGCblue,
    };

    return(
	<div style={{maxWidth:'350px',textAlign:'left',margin: '0 auto'}}>
		<Grid container spacing={2} style={{maxWidth:'350px', width:'100%',textAlign:'left',marginTop:'40px'}}>
            {this.state.fail_msg!==""?
            <Grid item xs={12} sm={12}>
                <Typography style={{color:"red"}}>{this.state.fail_msg}</Typography>
            </Grid>
            :null}
            <Grid item xs={12} sm={12}>
                <TextField
                    id="standard-name"
                    margin="normal"
                    type="password"
                    value={this.state.old_passwd}
                    onChange={e => this.setState(({old_passwd : e.target.value}))}
                    error = {this.state.fail_field === "old_passwd" ? true:null}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>輸入原密碼：</Typography></InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    id="standard-name"
                    value={this.state.new_passwd}
                    type="password"
                    onChange={e => this.setState(({new_passwd : e.target.value}))}
                    margin="normal"
                    error = {this.state.fail_field === "old_passwd" ? true:null}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>輸入新密碼：</Typography></InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    id="standard-name"
                    value={this.state.cfm_passwd}
                    type="password"
                    onChange={e => this.setState(({cfm_passwd : e.target.value}))}
                    margin="normal"
                    error = {this.state.fail_field === "old_passwd" ? true:null}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>確認新密碼：</Typography></InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12}>
            <div style={{display:'flex', alignItems:'baseline',justifyContent:'space-around',marginTop:'40px'}}>
                <Button 
                    variant="outlined"
                    disabled={this.props.auth!=="write"}
                    onClick={this.fetchModPasswd}
                    style={buttonStyleBlue}
                >
                更新
                </Button>
                <Dialog
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.modal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">密碼修改完成</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    密碼已修改，請重新登入
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button size="large" onClick={this.props.handleLogout} color="primary">
                    確認
                    </Button>
                    </DialogActions>
                </Dialog>
                <Button
                    disabled={this.props.auth!=="write"}
                    onClick={()=>this.setState({ fail_field:"",
                                                fail_msg:"",
                                                old_passwd: "",
                                                new_passwd: "",
                                                cfm_passwd: "",})} 
                    variant="outlined"
                    style={buttonStyleWhite}
                >
                取消
                </Button>
            </div>
            </Grid>
        </Grid>
        
	</div>
    );
  }
}


export default EntPasswd;

