import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import B2C from '../components/admin/adminB2c';
import B2B from '../components/admin/adminB2b';
import AdminVolume from '../components/admin/adminVolume';
import AdminQresult from '../components/admin/adminQresult.js';
import Qmanage from '../components/admin/adminQmanage';
import Admin from '../components/admin/adminAdmin';
import Info from '../components/admin/adminInfo';
import * as CSS from '../../css';

class RenderBackstage extends Component {
    constructor(props){  
        super(props);  
        this.state = { func_now : "",
                        subfunction:'B2C匯款確認及帳號開通',
                    };  
    }
    componentDidMount(){
        this.setState({func_now: this.props.func_list[0].name});
    }
	handleChangeSubfunction = e => { 
		this.setState({subfunction : e.target.innerText, });
	}
    render() {
		return (
            <div style={{textAlign:'center', width:'100%'}}>
                <AppBar 
                    position="sticky" 
                    color="default"
                    style={{position:'fixed',top:'65px',height:'40px', background:'white',padding:'0px'}} 
                >
                    <Toolbar style={{display:'flex',width:'100%', verticalAlign:'middle', overflowX:'auto', alignItems:'baseline'}}>
                        {this.props.func_list.map(func=>func.name).map(func_name => 
                            <Button key={func_name} size="large" onClick={e=>this.setState({func_now:e.target.innerText})}
                                    style={CSS.appbarBtnStyle(func_name,this.state.func_now)}
                            >{func_name}</Button>
                        )}
                    </Toolbar>
                </AppBar>
                <div>
                    {this.state.func_now === "" ?
                        "wrong":null
                    }
                    {this.state.func_now === "B2C訂單及客戶管理" ?
                        <B2C uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="B2C訂單及客戶管理").auth}/>:null
                    }
                    {this.state.func_now === "B2B訂單及客戶管理" ?
                        <B2B uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="B2B訂單及客戶管理").auth}/>:null
                    }
                    {this.state.func_now === "總銷售量查詢" ?
                        <AdminVolume uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="總銷售量查詢").auth}/>:null
                    }
                    {this.state.func_now === "測評報告" ?
                        <AdminQresult uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="測評報告").auth}/>:null
                    }
                    {this.state.func_now === "試卷管理" ?
                        <Qmanage uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="試卷管理").auth}/>:null
                    }
                    {this.state.func_now === "權限管理設定" ?
                        <Admin uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="權限管理設定").auth}/>:null
                    }
                    {this.state.func_now === "管理資料設定" ?
                        <Info uid={this.props.uid} auth={this.props.func_list.find(a=>a.name==="管理資料設定").auth}/>:null
                    }
                </div>
            </div>
		);
	}
}

export default RenderBackstage;