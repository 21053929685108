import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {api_path} from '../../../../environment';
import * as CSS from '../../../../css.js';
import {SearchBtn,ClearSearchBtn,MsgBtn} from '../../buttons';

const b2cAmtHeadRows = ["姓名","email","電話","訂購日","測評名稱","訂購量","測評單價","匯款金額","匯款日","使用狀態","發票號碼","發票日期"];


class ChangeInvoiceModal extends React.Component {
    constructor(props) {
        super(props);
		this.state = { modal:false,
						pay_date:"", 
						fail_msg:"",
						fail_field:"",
						invoice_no:"",
						invoice_date:"",
						txid:"",
					};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState({ modal: !this.state.modal,
						login_uid: this.props.uid,
						txid: this.props.amt.txid,
						invoice_no: this.props.amt.invoice_no,
						invoice_date: this.props.amt.invoice_date,
						fail_msg:"",
						fail_field:"",
					});
		if(this.props.amt.invoice_date === ""){
			this.setState({invoice_date: new Date().toISOString().slice(0,10)});
		}
    }
    
    fetchB2cModInvoice=()=>{
        let send_body = JSON.stringify({ func:'b2c_mod_invoice_no',
										login_uid: this.props.uid,
										txid: this.props.amt.txid,
										invoice_no: this.state.invoice_no,
										invoice_date: this.state.invoice_date,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchB2cAmount();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
    }

    render() {
        return (
        <div>
			{this.props.amt.invoice_no !== "" ?
			<Button onClick={this.toggle} disabled={this.props.auth!=="write"}>
				{this.props.amt.invoice_no}
			</Button>
			:
			<Button onClick={this.toggle} disabled={this.props.auth!=="write"} style={CSS.UGCbtnStyle(this.props.auth!=="write")}>
				輸入發票號碼
			</Button>
			}
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={CSS.justFont}>編輯發票資訊</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red"}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
                    <Grid item xs={12} sm={6}>
					<TextField
						id="standard-name"
						label="發票號碼"
						value={this.state.invoice_no}
						onChange={e => this.setState(({invoice_no : e.target.value}))}
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						error={this.state.fail_field==="invoice_no"?true:false}
					/>
					</Grid>
					<Grid item xs={12} sm={6}>
					<TextField
						id="standard-name"
						label="發票日期"
						value={this.state.invoice_date}
						onChange={e => this.setState(({invoice_date : e.target.value}))}
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
						type="date"
						error={this.state.fail_field==="invoice_date"?true:false}
					/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchB2cModInvoice} color="primary" style={CSS.justFont}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={CSS.justFont}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class B2CAmount extends React.Component {
	constructor(props) {
		super(props);
		this.state = { searchB2cAmtData: [{}],
						search_dtype: 'ORD',
						search_start: '',
						search_end: '',
						search_name: '',
						search_email: '',
						search_tel: '',
						search_qname: '',
						select_type: '',
						textfieldValue: '',
						checkedValues: [],
						anchorEl: null,
						show_start: "",
						show_end: "",
						page_id: 1,
					};

	}

	componentDidMount() {
        this.fetchB2cAmount();
	}
	
	fetchB2cAmount = () => {
		let send_body = JSON.stringify({func: 'query_b2c_amt',
										login_uid: this.props.uid,
										dtype: this.state.search_dtype,
										start:this.state.search_start,
										end:this.state.search_end,
										name:this.state.search_name,
										email:this.state.search_email,
										tel:this.state.search_tel,
										qname:this.state.seach_qname,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2cAmtData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								show_dtype: response.dtype,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}
    
	handleResetSearch = () => {
		this.setState({ search_dtype: 'ORD',
						search_start: '',
						search_end: '',
						search_name: '',
						search_email: '',
						search_tel: '',
						search_qname: '',
						select_type: '',
						textfieldValue: '',
					});
		let send_body = JSON.stringify({func: 'query_b2c_amt',
										login_uid: this.props.uid,
										dtype: "",
										start: "",
										end: "",
										name: "",
										email: "",
										tel: "",
										qname: "",
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2cAmtData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								show_dtype: response.dtype,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleChangePage = page_id => {
		let send_body = JSON.stringify({func: 'query_b2c_amt',
										login_uid: this.props.uid,
										dtype: this.state.search_dtype,
										start:this.state.search_start,
										end:this.state.search_end,
										name:this.state.search_name,
										email:this.state.search_email,
										tel:this.state.search_tel,
										qname:this.state.seach_qname,
										page_id:page_id,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2cAmtData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								show_end: response.end,
								show_start: response.start,
								show_dtype: response.dtype,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchDownloadB2cAmt=()=> {
        let send_body = JSON.stringify({ func: 'b2c_amt_download',
										login_uid: this.props.uid,
										start: this.state.show_start,
										end: this.state.show_end,
										dtype: this.state.show_dtype,
										dtoken: this.state.dtoken,
                                    });
        fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
	handleSelectSearchType = e => {
		if(this.state.select_type === "name"){
			this.setState({ search_name: e.target.value,
							search_tel: '',
							search_email: '',
							textfieldValue: e.target.value,
						});
		}
		else if(this.state.select_type === "tel"){
			this.setState({ search_name: '',
							search_tel: e.target.value,
							search_email: '',
							textfieldValue: e.target.value,
						});
		}
		else if(this.state.select_type === "email"){
			this.setState({ search_name: '',
							search_tel: '',
							search_email: e.target.value,
							textfieldValue: e.target.value,
						});
		}
	}

    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
	}

  	render() {
	return (
        <div style ={{display:'flex',flexDirection:'column'}}>
			<div style={{display:'flex',textAlign:'right',justifyContent:'flex-start',margin:'5px',alignItems:'flex-end'}}>
				<div style={{display:'flex',flexDirection:'column'}}>
					<div style={{display:'flex',alignItems:'baseline'}}>
						<Typography style={CSS.adminInputStyle}>欄位查詢：</Typography>
						<TextField
							id="standard-select-currency-native"
							select
							SelectProps={{native: true}}
							style={{margin:'5px'}}
							value={this.state.select_type} 
							onChange={e=>this.setState({select_type:e.target.value, textfieldValue:''})}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						>
							<option value=""></option>
							<option value="name">姓名</option>
							<option value="tel">電話</option>
							<option value="email">email</option>
						</TextField>
						<TextField
							id="standard-name"
							margin="dense"
							style={{margin:'5px'}}
							onChange={this.handleSelectSearchType}
							value={this.state.textfieldValue}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						/>
					</div>
					<div style={{display:'flex', alignItems:'baseline'}}>
						<Typography style={CSS.adminInputStyle}>測評名稱查詢：</Typography>
						<TextField
							id="standard-name"
							margin="dense"
							style={{margin:'5px'}}
							onChange={e=>this.setState({search_qname:e.target.value})}
							value={this.state.search_qname}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
						/>
					</div>
					<div style={{display:'flex', alignItems:'baseline'}}>
						<Typography style={CSS.adminInputStyle}>區間查詢：</Typography>
						<TextField
							id="standard-select-currency-native"
							select
							SelectProps={{native: true}}
							value={this.state.select_dtype} 
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{style:CSS.adminInputStyle}}
							style={{margin:'5px'}} 
							onChange={e=>this.setState({search_dtype:e.target.value})}
						>
							<option value=""></option>
							<option value="ORD">訂購日</option>
							<option value="PAY">付款日</option>
						</TextField>
						<TextField 
							id="date"
							type="date"
							margin='dense'
							value={this.state.search_start}
							style={{margin:'5px'}}
							onChange={e=>{this.setState({search_start:e.target.value})}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
						<Typography style={CSS.adminInputStyle}>～</Typography>
						<TextField 
							id="date"
							type="date"
							InputLabelProps={{shrink:true}}
							value={this.state.search_end}
							style={{margin:'5px'}}
							onChange={e=>{this.setState({search_end:e.target.value})}}
							InputProps={{style:CSS.adminInputStyle}}
							InputLabelProps={{shrink:true,style:CSS.adminInputStyle}}
						/>
					</div>
				</div>
				<div style={{display:'flex',alignItems:'center'}}>
				<SearchBtn onClick={this.fetchB2cAmount}/>
				<ClearSearchBtn onClick={this.handleResetSearch}/>
				</div>
			</div>
			<Paper style={{width:'100%',overflowX:'auto',minWidth: "100%"}}>
				<Table>
					<TableHead><TableRow style={CSS.headRowStyle}>
                        <TableCell align="center" style={CSS.headCellStyle}>
							<IconButton size="small"onClick={e=>this.setState({anchorEl:e.currentTarget})}><ArrowDropDown/></IconButton>
                            <Menu id="simple-menu"
								anchorEl={this.state.anchorEl}
								keepMounted
								open={Boolean(this.state.anchorEl)}
								onClose={()=>this.setState({anchorEl:null})}
							>
								<MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: this.state.searchB2cAmtData.map(amt => amt.txid)})} style={CSS.justFont}>全選</MenuItem>
								<MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: []})} style={CSS.justFont}>全不選</MenuItem>
							</Menu>
						</TableCell>
						{b2cAmtHeadRows.map(e=><TableCell key={e} align="center" style={CSS.headCellStyle}>{e}</TableCell>)}
					</TableRow></TableHead>
					<TableBody>
						{this.state.searchB2cAmtData.map((amt,index) => (
							<TableRow key={amt.txid} selected={this.state.checkedValues.includes(amt.txid)} style={CSS.tablebodyStyle(index)}>
								<TableCell align="center" align="center" style={CSS.bodyCellStyle}>
									<Checkbox label={amt.txid} key={amt.txid} checked={this.state.checkedValues.includes(amt.txid)} onChange={e => this.handleCheck(e,amt.txid)}/>
								</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.name}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.email}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.tel}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.ord_date}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.qname}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.ord_amt}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.qprice}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.tx_amt}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.pay_date}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.status}</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>
									<ChangeInvoiceModal uid={this.props.uid} amt={amt} auth={this.props.auth} fetchB2cAmount={()=>this.handleChangePage(this.state.page_id)}/>
								</TableCell>
								<TableCell align="center" style={CSS.bodyCellStyle}>{amt.invoice_date}</TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell  colSpan={5} align="left">{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
			<div style={{display:'flex',justifyContent:'space-between'}}>
				<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
					<Typography style={{...{marginRight:'10px'},...CSS.adminInputStyle}}>已選：{this.state.checkedValues.length} 項</Typography>
					<MsgBtn 
						style={{...{margin:'5px'},...CSS.UGCbtnStyle(false)}}
						send_body={JSON.stringify({ func: 'b2c_amt_download',
													login_uid: this.props.uid,
													start: this.state.show_start,
													end: this.state.show_end,
													dtype: this.state.show_dtype,
													dtoken: this.state.dtoken,
											})
									}
						btn_name="下載"
					/>
				</div>
				<div style={{display:'flex',margin:'20px'}}>
				<Button size="large" disabled={this.state.pre_page===""} onClick={()=>this.handleChangePage(this.state.pre_page)} style={CSS.justFont}>上一頁</Button>
				<Button size="large" disabled={this.state.nxt_page===""} onClick={()=>this.handleChangePage(this.state.nxt_page)} style={CSS.justFont}>下一頁</Button>
				</div>
			</div>
		</div>
	);
	}
}

export default B2CAmount;