import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {UGCblue} from '../../../environment';
import test_intro from '../../../pics/test_intro.jpg';
import * as CSS from '../../../assessCss.js'

class PageThree extends Component {
    constructor(props) {
        super(props);
        this.state = { 
                    };
    }

    componentDidMount(){}

    render(){
        if(!this.props.isMobile){
        	return (
				<Grid container spacing={0} style={{width:'50vw',marginTop:'20px'}}>
					{test_intro!==undefined?
					<Grid item xs={12} sm={12} style={{marginTop:'10px',marginBottom:'10px'}}>
						<img alt=""src={`${test_intro}`} style = {{width:'100%'}}/>
					</Grid>
					:null}
					<Grid item xs={12} sm={12} style={{marginTop:'10px',marginBottom:'10px'}}>
						{this.props.notice1!==""&& this.props.notice1!==undefined?
						<Typography style={{fontFamily:'微軟正黑體',fontSize:'1.1vw',whiteSpace:'pre-line',fontWeight:'bold'}}>
							{this.props.notice1}
						</Typography>
						:null}
						{this.props.notice2!==""&& this.props.notice2!==undefined?
						<div style={{display:'flex',flexDirection:'column'}}>
							<Typography style={{fontFamily:'微軟正黑體',fontSize:'1.2vw',fontWeight:'bold',color:UGCblue,marginTop:'1.2vw',marginBottom:'2vh'}}>注意事項：</Typography>
							<Typography style={{fontFamily:'微軟正黑體',fontSize:'1.1vw',whiteSpace:'pre-line'}}>
								{this.props.notice2}
							</Typography>
						</div>
						:null}
					</Grid>
					{this.props.countdown!== undefined?
					<Grid item xs={12} sm={12} style={{marginTop:'10px',marginBottom:'10px'}}>
						<div style={{display:'flex',justifyContent:'center',alignItems:'baseline',marginTop:'1.2vw'}}>
							<Typography style={{fontFamily:'微軟正黑體',fontSize:'1.2vw',fontWeight:'bold',color:UGCblue}}>填答時限:{this.props.countdown/60}分鐘</Typography>
							<Typography style={{fontFamily:'微軟正黑體',fontSize:'1vw',fontWeight:'bold',color:'black',marginLeft:'0.8vw'}}>(如超過填答時限，系統將自動登出並留下紀錄)</Typography>
						</div>
						<div style={{display:'flex',justifyContent:'center',alignItems:'baseline'}}>
							<Typography style={{fontFamily:'微軟正黑體',fontSize:'1.2vw',fontWeight:'bold',color:UGCblue}}>若您已經完全瞭解作答方式，可以開始作答。</Typography>
						</div>
					</Grid>
					:
					<Grid item xs={12} sm={12} style={{marginTop:'10px',marginBottom:'10px'}}>
						<div style={{display:'flex',justifyContent:'center',alignItems:'baseline',marginTop:'1.2vw'}}>
							<Typography style={{fontFamily:'微軟正黑體',fontSize:'1.2vw',fontWeight:'bold',color:UGCblue}}>若您已經完全瞭解作答方式，可以開始作答。</Typography>
						</div>
					</Grid>
					}
					<Grid item xs={12} sm={12} style={{marginTop:'10px',marginBottom:'10px'}}>
						<div style={{display:'flex',justifyContent:'center',alignItems:'baseline'}}>
							<Button onClick={this.props.fetchDoAssess} style={CSS.desktopButtonStyle}>進行測評</Button>
						</div>
					</Grid>
				</Grid>
            );
        }
        else{
            return(
                <Grid item xs={12} sm={12} style={{display:'flex',justifyContent:'center'}}>
					<div style={{width:'90%'}}>
					<Grid container spacing={2} style={{marginTop:'5vw'}}>
						{this.props.image!==undefined?
						<Grid item xs={12} sm={12}>
							<img alt=""src={`${this.props.image}`} style = {{width:'100%'}}/>
						</Grid>
						:null}
						<Grid item xs={12} sm={12} style={{padding:'0'}}>
							{this.props.notice1!==""&& this.props.notice1!==undefined?
							<Typography style={{fontFamily:'微軟正黑體',fontSize:'4vw',whiteSpace:'pre-line',fontWeight:'bold'}}>
								{this.props.notice1}
							</Typography>
							:null}
							{this.props.notice2!==""&& this.props.notice2!==undefined?
							<div style={{display:'flex',flexDirection:'column'}}>
								<Typography style={{fontFamily:'微軟正黑體',fontSize:'4vw',fontWeight:'bold',color:UGCblue,marginTop:'7vw',marginBottom:'1vw'}}>注意事項：</Typography>
								<Typography style={{fontFamily:'微軟正黑體',fontSize:'4vw',whiteSpace:'pre-line'}}>
									{this.props.notice2}
								</Typography>
							</div>
							:null}
						</Grid>
						{this.props.countdown!== undefined?
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',justifyContent:'center',alignItems:'baseline'}}>
								<Typography style={{fontFamily:'微軟正黑體',fontSize:'5vw',fontWeight:'bold',color:UGCblue,marginBottom:'0px'}}>填答時限:{this.props.countdown/60}分鐘</Typography>
							</div>
						</Grid>:null}
						{this.props.countdown!== undefined?
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',justifyContent:'center',alignItems:'baseline'}}>
								<Typography style={{fontFamily:'微軟正黑體',fontSize:'3.5vw',fontWeight:'bold',color:'black',marginLeft:'0.8vw',marginBottom:'0px'}}>(如超過填答時限，系統將自動登出並留下紀錄)</Typography>
							</div>
						</Grid>
						:null}
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',justifyContent:'center',alignItems:'baseline'}}>
								<Typography style={{fontFamily:'微軟正黑體',fontSize:'3.5vw',fontWeight:'bold',color:UGCblue,marginBottom:'0px'}}>若您已經完全瞭解作答方式，可以開始作答。</Typography>
							</div>
						</Grid>
						
						<Grid item xs={12} sm={12}>
							<div style={{display:'flex',justifyContent:'center',alignItems:'baseline'}}>
								<Button onClick={this.props.fetchDoAssess} style={CSS.mobileButtonStyle}>進行測評</Button>
							</div>
						</Grid>
					</Grid>
					</div>
                </Grid>
            );
        }
        
    }
  }
  
  export default PageThree;