import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Done from '@material-ui/icons/Done';
import Delete from '@material-ui/icons/Delete';
import Create from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ReactFileReader from 'react-file-reader';
import FileCopy from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import {api_path,cellStyle,UGCblue,tablebodyStyle,tableheadrowStyle,tableheadcellStyle,entInfoFontSize} from '../../../environment';

const contactHeadRows = ["姓名","信箱","電話","帳號","密碼","權限",""];
const middle= `《自動登入網址》`;


class AuthModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field:"",
						fail_msg:"",
						old_passwd: "",
						new_passwd: "",
						cfm_passwd: "",
                    };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
		this.setState({ modal: !this.state.modal,
					});
    }
    
	render() {
        return (
        <div>
            <Button onClick={this.toggle} style={{marginLeft:'10px',whiteSpace:'nowrap',padding:'5px',fontFamily:'微軟正黑體',}}>點此觀看</Button>
            <Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<Typography style={{fontFamily:'微軟正黑體',}}>
					{this.props.auth.name} 權限
				</Typography>
				</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體',}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
					<Typography style={{fontFamily:'微軟正黑體',}}>
					使用量查詢：{this.props.auth.ac_amt}
					</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
					<Typography style={{fontFamily:'微軟正黑體',}}>
					企業資料設定：{this.props.auth.ac_setup}
					</Typography>
					</Grid>
                    <Grid item xs={12} sm={12}>
					<Typography style={{fontFamily:'微軟正黑體',}}>
					密碼修改：{this.props.auth.ac_passwd}
					</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
					<Typography style={{fontFamily:'微軟正黑體',}}>
					群組設定：{this.props.auth.ac_grp}
					</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
					<Typography style={{fontFamily:'微軟正黑體',}}>
					測評分發設定：{this.props.auth.ac_send}
					</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
					<Typography style={{fontFamily:'微軟正黑體',}}>
					測評報告及分類統計：{this.props.auth.ac_rpt}
					</Typography>
					</Grid>
					{/* <Grid item xs={12} sm={12}>
					測評報告觀看群組：{this.props.auth.rpt_grp}
					</Grid> */}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>
				關閉
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class ContactDelModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState(prevState=>({ modal: !prevState.modal }));
	}
    
	fetchContactDelete = () => {
		let send_body = JSON.stringify({ func:'corp_del_contact',
										login_uid: this.props.uid,
										uid: this.props.contact.uid,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchCorpData();
			}
		})
		.catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<IconButton disabled={this.props.auth!=="write"} onClick={this.toggle} style={{padding:'4px'}}><Delete/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<Typography style={{fontFamily:'微軟正黑體',}}>
					刪除聯絡人
				</Typography>
				</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Typography style={{fontFamily:'微軟正黑體',}}>
				確定刪除 {this.props.contact.name} 嗎？
				</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchContactDelete} color="primary" style={{fontFamily:'微軟正黑體',}}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ContactEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						name: "",
						emp_no: "",
						email: "",
						tel: "",
						uid: "",
						passwd: "",
						fail_field: "",
						fail_msg: "",
						ac_amt: "",
						ac_setup: "",
						ac_passwd: "",
						ac_grp: "",
						ac_send: "",
						ac_rpt: "",
						rpt_grp: "",
						corpGroup: {group:[{}]},
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
						name: this.props.contact.name,
						emp_no: this.props.contact.emp_no,
						email: this.props.contact.email,
						tel: this.props.contact.tel,
						passwd: this.props.contact.passwd,
						ac_amt: this.props.contact.ac_amt,
						ac_setup: this.props.contact.ac_setup,
						ac_passwd: this.props.contact.ac_passwd,
						ac_grp: this.props.contact.ac_grp,
						ac_send: this.props.contact.ac_send,
						ac_rpt: this.props.contact.ac_rpt,
						rpt_grp: this.props.contact.rpt_grp,
					});
		this.fetchGroup();
	}

    fetchGroup = () => {
        let send_body = JSON.stringify({ func:'get_group_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corpGroup:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchContactMod = () => {
		let send_body = JSON.stringify({ func:'corp_mod_contact',
										login_uid: this.props.uid,
										uid: this.props.contact.uid,
										name: this.state.name,
										emp_no: this.state.emp_no,
										email: this.state.email,
										tel: this.state.tel,
										passwd: this.state.passwd,
										ac_amt: this.state.ac_amt,
										ac_setup: this.state.ac_setup,
										ac_passwd: this.state.ac_passwd,
										ac_grp: this.state.ac_grp,
										ac_send: this.state.ac_send,
										ac_rpt: this.state.ac_rpt,
										rpt_grp: this.state.rpt_grp,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchCorpData();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<IconButton disabled={this.props.auth!=="write"} onClick={this.toggle} style={{padding:'4px'}}><Create/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<Typography style={{fontFamily:'微軟正黑體',}}>編輯聯絡人</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="帳號"
							value={this.props.contact.uid}
                            disabled
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="密碼"
							value={this.state.passwd}
							onChange={e => this.setState(({passwd : e.target.value}))}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="姓名"
							value={this.state.name}
                            onChange={e => this.setState(({name : e.target.value}))}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
                    {/* <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="員工編號"
							value={this.state.emp_no}
                            onChange={e => this.setState(({emp_no : e.target.value}))}
                            margin="normal"
						/>
					</Grid> */}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="電話"
							placeholder="02-12345678/0912-345678"		value={this.state.tel}
                            onChange={e => this.setState(({tel : e.target.value}))}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="email"
							multiline
							value={this.state.email}
                            onChange={e => this.setState(({email : e.target.value}))}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography gutterBottom style={{fontFamily:'微軟正黑體'}}>《權限設定》</Typography>
					</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="使用量查詢"
                            SelectProps={{native: true}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_amt}
                            onChange={e => this.setState({ac_amt : e.target.value})}
                            error = {this.state.fail_field === "ac_amt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="企業資料設定"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							value={this.state.ac_setup}
                            onChange={e => this.setState({ac_setup : e.target.value})}
                            error = {this.state.fail_field === "ac_setup" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="密碼修改"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							value={this.state.ac_passwd}
                            onChange={e => this.setState({ac_passwd : e.target.value})}
                            error = {this.state.fail_field === "ac_passwd" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="群組設定"
                            SelectProps={{native: true}}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            fullWidth
							value={this.state.ac_grp}
                            onChange={e => this.setState({ac_grp : e.target.value})}
                            error = {this.state.fail_field === "ac_grp" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="測評分發設定"
                            SelectProps={{native: true}}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            fullWidth
							value={this.state.ac_send}
                            onChange={e => this.setState({ac_send : e.target.value})}
                            error = {this.state.fail_field === "ac_send" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="測評報告及分類統計"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							value={this.state.ac_rpt}
                            onChange={e => this.setState({ac_rpt : e.target.value})}
                            error = {this.state.fail_field === "ac_rpt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					{/* <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="測評報告觀看群組"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.rpt_grp}
                            onChange={e => this.setState({rpt_grp : e.target.value})}
                            error = {this.state.fail_field === "rpt_grp" ? true:null}
                        >
							<option value=""></option>
	                        {this.state.corpGroup["group"].map(grp=><option value={grp.seq}>{grp.name}</option>)}
                        </TextField>
					</Grid> */}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchContactMod} color="primary" style={{fontFamily:'微軟正黑體'}}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ContactAddModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						name: "",
						emp_no: "",
						email: "",
						tel: "",
						uid: "",
						passwd: "",
						fail_field: "",
						fail_msg: "",
						ac_amt: "",
						ac_setup: "",
						ac_passwd: "",
						ac_grp: "",
						ac_send: "",
						ac_rpt: "",
						rpt_grp: "",
						corpGroup: {group:[{}]},
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal });
		this.fetchGroup();
	}

    fetchGroup = () => {
        let send_body = JSON.stringify({ func:'get_group_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corpGroup:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}

	fetchContactIns = () => {
		let send_body = JSON.stringify({ func:'corp_ins_contact',
										login_uid: this.props.uid,
										tax_id: this.props.uid,
										name: this.state.name,
										emp_no: this.state.emp_no,
										email: this.state.email,
										tel: this.state.tel,
										ac_amt: this.state.ac_amt,
										ac_setup: this.state.ac_setup,
										ac_passwd: this.state.ac_passwd,
										ac_grp: this.state.ac_grp,
										ac_send: this.state.ac_send,
										ac_rpt: this.state.ac_rpt,
										rpt_grp: this.state.rpt_grp,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchCorpData();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	render() {

	const desktopButtonStyle2 = {
		fontFamily:'微軟正黑體',
		backgroundColor:UGCblue,
		color:'white',
		paddingTop:'0',
		paddingBottom:'0',
		marginLeft:'10px',
	};

	return (
		<div>
			<Button disabled={this.props.auth} style={desktopButtonStyle2} onClick={this.toggle}>新增管理者</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體'}}>新增管理者</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="姓名"
							value={this.state.name}
                            onChange={e => this.setState(({name : e.target.value}))}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
                    {/* <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="員工編號"
							value={this.state.emp_no}
                            onChange={e => this.setState(({emp_no : e.target.value}))}
                            margin="normal"
						/>
					</Grid> */}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="電話"
							placeholder="02-12345678/0912-345678"		value={this.state.tel}
                            onChange={e => this.setState(({tel : e.target.value}))}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="email"
							fullWidth
							multiline
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							value={this.state.email}
                            onChange={e => this.setState(({email : e.target.value}))}
                            margin="normal"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="使用量查詢"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							value={this.state.ac_amt}
                            onChange={e => this.setState({ac_amt : e.target.value})}
                            error = {this.state.fail_field === "ac_amt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="企業資料設定"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_setup}
                            onChange={e => this.setState({ac_setup : e.target.value})}
                            error = {this.state.fail_field === "ac_setup" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="密碼修改"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							value={this.state.ac_passwd}
                            onChange={e => this.setState({ac_passwd : e.target.value})}
                            error = {this.state.fail_field === "ac_passwd" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            label="群組設定"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_grp}
                            onChange={e => this.setState({ac_grp : e.target.value})}
                            error = {this.state.fail_field === "ac_grp" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="測評分發設定"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_send}
                            onChange={e => this.setState({ac_send : e.target.value})}
                            error = {this.state.fail_field === "ac_send" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="測評報告及分類統計"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.ac_rpt}
                            onChange={e => this.setState({ac_rpt : e.target.value})}
                            error = {this.state.fail_field === "ac_rpt" ? true:null}
                        >
							<option value=""></option>
                            <option value="none">none</option>
                            <option value="read">read</option>
                            <option value="write">write</option>
                        </TextField>
					</Grid>
					{/* <Grid item xs={12} sm={6}>
                        <TextField
                            id="standard-select-currency-native"
                            select
                            label="測評報告觀看群組"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.rpt_grp}
                            onChange={e => this.setState({rpt_grp : e.target.value})}
                            error = {this.state.fail_field === "rpt_grp" ? true:null}
                        >
							<option value=""></option>
	                        {this.state.corpGroup["group"].map(grp=><option value={grp.seq}>{grp.name}</option>)}
                        </TextField>
					</Grid> */}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchContactIns} color="primary" style={{fontFamily:'微軟正黑體'}}>
				新增
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ScenarioShowModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						qdetail:[],
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						qi_seq: "",
						q_time_limit: "",
						q_delta: "",
						tp_notice: "",
						tp_image: "",
						is_stu: "",
						pr_type: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal) {
			this.fetchTpDetail();
			this.fetchQquery();
			this.fetchTpImg();
		}
		this.setState({ modal: !this.state.modal });
	}
	fetchTpImg = () => {
		let send_body = JSON.stringify({ func: 'get_tp_image',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ tp_image: response.result[0].tp_image,});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}

	fetchTpDetail = () => {
		let send_body = JSON.stringify({ func: 'get_tp_detail',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ tp_name: response.result[0].tp_name,
								tp_begin: response.result[0].tp_begin,
								tp_end: response.result[0].tp_end,
								tp_contact: response.result[0].tp_contact,
								tp_subject: response.result[0].tp_subject,
								qname: response.result[0].qname,
								qi_seq: response.result[0].qi_seq,
								q_time_limit: response.result[0].q_time_limit,
								q_delta: response.result[0].q_delta,
								tp_notice: response.result[0].tp_notice,
								tp_image: response.result[0].tp_image,
								is_stu: response.result[0].is_stu,
								pr_type: response.result[0].pr_type,
							});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	fetchQquery= () => {
        let send_body = JSON.stringify({ func: 'q_query', login_uid: this.props.uid});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qdetail:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	render() {
	return (
		<div>
			<Button size="large" onClick={this.toggle} style={{fontSize:'16px',fontFamily:'微軟正黑體'}}>{this.props.tp.tp_name}</Button>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體'}}>{this.props.tp.tp_name}</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體'}}>施測情境名稱：{this.state.tp_name}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體'}}>受測者身份：{this.state.is_stu?"學生":"社會人士"}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體'}}>測評名稱：{this.state.qname}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體'}}>施測時限：{this.state.q_time_limit}分鐘</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體'}}>受測截止日： 發信日 ＋ {this.state.q_delta} 日</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體'}}>比對常模：{this.state.pr_type==="0"?"全部":this.state.pr_type==="1"?"同單位":this.state.pr_type==="2"?"同公司":null}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體'}}>測評開通信件樣式：</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Paper elevation="5" square>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12} >
								<Typography noWrap style={{fontFamily:'微軟正黑體'}}>主旨： {this.state.tp_subject}</Typography>
							</Grid>
						</Grid>
						<Divider variant="middle" style={{margin:'5px'}}/>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12}>
								<Typography style={{fontFamily:'微軟正黑體'}}>《受測者姓名》，您好！</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{fontFamily:'微軟正黑體',whiteSpace:'pre-line'}}>{this.state.tp_begin}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{fontFamily:'微軟正黑體',whiteSpace:'pre-line'}}>{middle}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{fontFamily:'微軟正黑體',whiteSpace:'pre-line'}}>{this.state.tp_end}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{fontFamily:'微軟正黑體',whiteSpace:'pre-line'}}>{this.state.tp_contact}</Typography>
							</Grid>
						</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>測評說明：</Typography>
						<Typography style={{fontFamily:'微軟正黑體',whiteSpace:'pre-line'}}>{this.state.tp_notice}</Typography>
					</Grid>
					{/* <Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>測評說明圖示：</Typography>
						{this.state.tp_image!==null?
							<img alt=""src={`${this.state.tp_image}`} style = {{maxWidth:'200px', margin:'5px'}}/>
						:null}
					</Grid> */}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>
				關閉
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ScenarioEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						qdetail: [],
						tp_image: null,
						qi_seq: "",
						q_time_limit: "",
						q_delta: "",
						tp_notice: "",
						is_stu: null,
						fail_field: "",
						fail_msg: "",
						pr_type: "",
						stuprList:[],
						prList:[],
						status:"fillin",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal) {
			this.fetchTpDetail();
			this.fetchQquery();
			this.fetchTpImg();
			this.fetchPRList();
			this.fetchStuPRList();
		}
		this.setState({ modal: !this.state.modal,
						fail_field: "",
						fail_msg: "",
						status:"fillin",
					});
	}
	fetchTpImg = () => {
		let send_body = JSON.stringify({ func: 'get_tp_image',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ tp_image: response.result[0].tp_image,});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	fetchTpDetail = () => {
		let send_body = JSON.stringify({ func: 'get_tp_detail',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ tp_name: response.result[0].tp_name,
								tp_begin: response.result[0].tp_begin,
								tp_end: response.result[0].tp_end,
								tp_contact: response.result[0].tp_contact,
								tp_subject: response.result[0].tp_subject,
								qi_seq: response.result[0].qi_seq,
								q_time_limit: response.result[0].q_time_limit,
								q_delta: response.result[0].q_delta,
								tp_notice: response.result[0].tp_notice,
								tp_image: response.result[0].tp_image,
								is_stu: response.result[0].is_stu?"true":"false",
								pr_type: response.result[0].pr_type,
							});
            }
            else if(response.status==="FAIL"){
				this.setState({ fail_field:response.fail_field,
								fail_msg:response.msg,
								status:"fillin",
								});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	fetchQquery= () => {
        let send_body = JSON.stringify({ func: 'q_query' , login_uid: this.props.uid,});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qdetail:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	fetchTpMod = () => {
		let send_body = JSON.stringify({ func:'mod_tp',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
										tp_name: this.state.tp_name,
										tp_begin: this.state.tp_begin,
										tp_end: this.state.tp_end,
										tp_contact: this.state.tp_contact,
										tp_subject: this.state.tp_subject,
										qi_seq: this.state.qi_seq,
										q_time_limit: this.state.q_time_limit,
										q_delta: this.state.q_delta,
										tp_notice: this.state.tp_notice,
										tp_image: this.state.tp_image,
										is_stu: this.state.is_stu,
										tp_type: '0',
										pr_type: this.state.pr_type,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({ fail_field:response.fail_field, 
								fail_msg:response.msg,
								status:"fillin",
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchPRList = () => {
		let send_body = JSON.stringify({ func:'get_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ prList:response.result,
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchStuPRList = () => {
		let send_body = JSON.stringify({ func:'get_stu_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ stuprList:response.result,
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	handleFiles = file => {
		this.setState({tp_image:file.base64});
	}

	render() {

	const desktopButtonStyle2 = {
		fontFamily:'微軟正黑體',
		backgroundColor:UGCblue,
		color:'white',
		paddingTop:'0',
		paddingBottom:'0',
		marginLeft:'10px',
	};
	return (
		<div>
			<IconButton size="large" disabled={this.props.auth!=="write"} onClick={this.toggle}><Create/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>編輯 {this.state.tp_name} 施測情境</Typography></DialogTitle>
				<DialogContent>
				{this.state.status==="loading"?
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
				:
				this.state.status==="fillin"?
                <DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography align="center"  style={{fontFamily:'微軟正黑體',color:"red"}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={{fontFamily:'微軟正黑體',}}>施測情境名稱：</Typography>
						<TextField
							value={this.state.tp_name}
							onChange={e=>this.setState({tp_name:e.target.value})}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "tp_name" ? true:null}
						/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={{fontFamily:'微軟正黑體',}}>受測者身份：</Typography>
						<TextField
                            select
							SelectProps={{native: true}}
                            value={this.state.is_stu}
                            onChange={e => this.setState({is_stu : e.target.value})}
                            error = {this.state.fail_field === "is_stu" ? true:null}
                        >
							<option value={null}></option>
                            <option value={true}>學生</option>
                            <option value={false}>社會人士</option>
                        </TextField>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={{fontFamily:'微軟正黑體',}}>測評名稱：</Typography>
						<TextField
                            select
                            SelectProps={{native: true}}
                            value={this.state.qi_seq}
							onChange={e => this.setState({qi_seq : e.target.value})}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "qi_seq" ? true:null}
                        >
							<option value=""></option>
							{this.state.is_stu==="true" || this.state.is_stu === true?
							this.state.qdetail.filter(q=>q.is_stu===true).map(q=><option value={q.qi_seq}>{q.qname}</option>)
							:
							null}
							{this.state.is_stu==="false" || this.state.is_stu === false?
							this.state.qdetail.filter(q=>q.is_stu===false).map(q=><option value={q.qi_seq}>{q.qname}</option>)
							:null}
                        </TextField>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={{fontFamily:'微軟正黑體',}}>施測時限：</Typography>
						<TextField
							value={this.state.q_time_limit}
							onChange={e=>this.setState({q_time_limit:e.target.value})}
							style={{width:'80px'}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "q_time_limit" ? true:null}
						/>
						<Typography  style={{fontFamily:'微軟正黑體',}}>分鐘</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={{fontFamily:'微軟正黑體',}}>受測截止日： 發信日 ＋ </Typography>
						<TextField
							value={this.state.q_delta}
							onChange={e=>this.setState({q_delta:e.target.value})}
							style={{width:'40px'}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "q_delta" ? true:null}
						/>
						<Typography style={{fontFamily:'微軟正黑體',}}>日</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={{fontFamily:'微軟正黑體',}}>比對常模：</Typography>
						<TextField
                            select
							SelectProps={{native: true}}
                            value={this.state.pr_type}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            onChange={e => this.setState({pr_type : e.target.value})}
                            error = {this.state.fail_field === "pr_type" ? true:null}
                        >
							<option value=""></option>
							{this.state.is_stu==="true"?this.state.stuprList.map(e=><option value={e.id}>{e.val}</option>):null}
							{this.state.is_stu==="false"?this.state.prList.map(e=><option value={e.id}>{e.val}</option>):null}
                        </TextField>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>測評開通信件樣式：</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Paper elevation="5" square>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12} >
								<div style={{display:'flex',alignItems:'baseline'}}> 
								<Typography noWrap style={{fontFamily:'微軟正黑體',}}>主旨：</Typography>
								<TextField
									variant="outlined"
									value={this.state.tp_subject}
									onChange={e=>this.setState({tp_subject:e.target.value})}
									style={{width:'70%'}}
									margin="dense"
									InputProps={{style: {fontFamily:'微軟正黑體'}}}
									InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
									error = {this.state.fail_field === "tp_subject" ? true:null}
								/>
								</div>
							</Grid>
						</Grid>
						<Divider variant="middle" style={{margin:'5px'}}/>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12}>
								<Typography style={{fontFamily:'微軟正黑體',}}>《受測者姓名》，您好！</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									InputProps={{style: {fontFamily:'微軟正黑體'}}}
									InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
									label="《信件開頭》"
									value={this.state.tp_begin}
									onChange={e=>this.setState({tp_begin:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_begin" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{fontFamily:'微軟正黑體',whiteSpace:'pre-line'}}>{middle}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《信件結尾》"
									InputProps={{style: {fontFamily:'微軟正黑體'}}}
									InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
									value={this.state.tp_end}
									onChange={e=>this.setState({tp_end:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_end" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《HR聯絡人資訊》"
									InputProps={{style: {fontFamily:'微軟正黑體'}}}
									InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
									value={this.state.tp_contact}
									onChange={e=>this.setState({tp_contact:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_contact" ? true:null}
								/>
							</Grid>
						</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>測評說明：</Typography>
						<TextField
							margin="normal"
							variant="outlined"
							multiline
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							value={this.state.tp_notice}
							onChange={e=>this.setState({tp_notice:e.target.value})}
							fullWidth
                            error = {this.state.fail_field === "tp_notice" ? true:null}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={{fontFamily:'微軟正黑體',}}>測評說明圖示：</Typography>
						<ReactFileReader fileTypes={[".png"]} base64={true} handleFiles={this.handleFiles}>
							<Button style={{...{fontFamily:'微軟正黑體',marginLeft:'20px'},...desktopButtonStyle2}}>上傳</Button>
						</ReactFileReader>
						</div>
						{this.state.tp_image!==null?
							<img alt=""src={`${this.state.tp_image}`} style = {{maxWidth:'200px', margin:'5px'}}/>
						:null}
					</Grid> */}
				</Grid>
				</DialogContentText>
					:
					null
				}
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={()=>{this.setState({status:"loading"});
				 this.fetchTpMod();}} color="primary" style={{fontFamily:'微軟正黑體',}}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ScenarioAddModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						qdetail: [],
						tp_image: null,
						qi_seq: "",
						q_time_limit: "",
						q_delta: "",
						tp_notice: "",
						is_stu:null,
						fail_field: "",
						fail_msg: "",
						pr_type: "",
						stuprList:[],
						prList:[],
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
						tp_name: "",
						tp_begin: "",
						tp_end: "",
						tp_contact: "",
						tp_subject: "",
						qdetail: [],
						tp_image: null,
						qi_seq: "",
						q_time_limit: "",
						q_delta: "",
						tp_notice: "",
						is_stu:null,
						fail_field: "",
						fail_msg: "",
						stuprList:[],
						prList:[],
					});
		this.fetchQquery();
		this.fetchPRList();
		this.fetchStuPRList();
	}

	fetchQquery= () => {
        let send_body = JSON.stringify({ func: 'q_query' , login_uid: this.props.uid,});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qdetail:response.result});
            }
            else if(response.status==="FAIL"){
                this.setState({fail_field:response.fail_field, fail_msg:response.msg});
            }
        })
        .catch(error => console.error('Error:', error));
    }

	handleFiles = file => {
		this.setState({tp_image:file.base64});
	}

	fetchPRList = () => {
		let send_body = JSON.stringify({ func:'get_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ prList:response.result,
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchStuPRList = () => {
		let send_body = JSON.stringify({ func:'get_stu_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ stuprList:response.result,
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}

	fetchTpIns = () => {
		let send_body = JSON.stringify({ func:'ins_tp',
										login_uid: this.props.uid,
										tp_name: this.state.tp_name,
										tp_begin: this.state.tp_begin,
										tp_end: this.state.tp_end,
										tp_contact: this.state.tp_contact,
										tp_subject: this.state.tp_subject,
										qi_seq: this.state.qi_seq,
										q_time_limit: this.state.q_time_limit,
										q_delta: this.state.q_delta,
										tp_notice: this.state.tp_notice,
										tp_image: this.state.tp_image,
										is_stu: this.state.is_stu,
										pr_type: this.state.pr_type,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	render() {

	const desktopButtonStyle2 = {
		fontFamily:'微軟正黑體',
		backgroundColor:UGCblue,
		color:'white',
		paddingTop:'0',
		paddingBottom:'0',
		marginLeft:'10px',
	};

	return (
		<div>
			<Button onClick={this.toggle} disabled={this.props.auth!=="write"} style={desktopButtonStyle2}>新增施測情境</Button>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>新增施測情境</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{fontFamily:'微軟正黑體',color:"red"}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography style={{fontFamily:'微軟正黑體',}}>施測情境名稱：</Typography>
						<TextField
							value={this.state.tp_name}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							onChange={e=>this.setState({tp_name:e.target.value})}
                            error = {this.state.fail_field === "tp_name" ? true:null}
						/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography  style={{fontFamily:'微軟正黑體',}}>受測者身份：</Typography>
						<TextField
                            select
							SelectProps={{native: true}}
                            value={this.state.is_stu}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            onChange={e => this.setState({is_stu : e.target.value})}
                            error = {this.state.fail_field === "is_stu" ? true:null}
                        >
							<option value={null}></option>
                            <option value={true}>學生</option>
                            <option value={false}>社會人士</option>
                        </TextField>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography  style={{fontFamily:'微軟正黑體',}}>測評名稱：</Typography>
						<TextField
                            select
                            SelectProps={{native: true}}
                            value={this.state.qi_seq}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							onChange={e => this.setState({qi_seq : e.target.value})}
                            error = {this.state.fail_field === "qi_seq" ? true:null}
                        >
							<option value=""></option>
							{this.state.is_stu==="true" || this.state.is_stu === true?
							this.state.qdetail.filter(q=>q.is_stu===true).map(q=><option value={q.qi_seq}>{q.qname}</option>)
							:
							null}
							{this.state.is_stu==="false" || this.state.is_stu === false?
							this.state.qdetail.filter(q=>q.is_stu===false).map(q=><option value={q.qi_seq}>{q.qname}</option>)
							:null}
                        </TextField>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography  style={{fontFamily:'微軟正黑體',}}>施測時限：</Typography>
						<TextField
							value={this.state.q_time_limit}
							onChange={e=>this.setState({q_time_limit:e.target.value})}
							style={{width:'80px'}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "q_time_limit" ? true:null}
						/>
						<Typography  style={{fontFamily:'微軟正黑體',}}>分鐘</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography  style={{fontFamily:'微軟正黑體',}}>受測截止日： 發信日 ＋ </Typography>
						<TextField
							value={this.state.q_delta}
							onChange={e=>this.setState({q_delta:e.target.value})}
							style={{width:'40px'}}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "q_delta" ? true:null}
						/>
						<Typography  style={{fontFamily:'微軟正黑體',}}>日</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography  style={{fontFamily:'微軟正黑體',}}>比對常模：</Typography>
						<TextField
                            select
							SelectProps={{native: true}}
                            value={this.state.pr_type}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            onChange={e => this.setState({pr_type : e.target.value})}
                            error = {this.state.fail_field === "pr_type" ? true:null}
                        >
							<option value=""></option>
							{this.state.is_stu==="true"?this.state.stuprList.map(e=><option value={e.id}>{e.val}</option>):null}
							{this.state.is_stu==="false"?this.state.prList.map(e=><option value={e.id}>{e.val}</option>):null}
                        </TextField>
						</div>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography  style={{fontFamily:'微軟正黑體',}}>測評開通信件樣式：</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Paper elevation="5" square>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12} >
								<div style={{display:'flex',alignItems:'baseline'}}> 
								<Typography noWrap style={{fontFamily:'微軟正黑體',}}>主旨：</Typography>
								<TextField
									variant="outlined"
									value={this.state.tp_subject}
									onChange={e=>this.setState({tp_subject:e.target.value})}
									style={{width:'70%'}}
									InputProps={{style: {fontFamily:'微軟正黑體'}}}
									InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
									margin="dense"
									error = {this.state.fail_field === "tp_subject" ? true:null}
								/>
								</div>
							</Grid>
						</Grid>
						<Divider variant="middle" style={{margin:'5px'}}/>
						<Grid container spacing={2} style={{width:'95%',margin:'0 auto'}}>
							<Grid item xs={12} sm={12}>
								<Typography  style={{fontFamily:'微軟正黑體',}}>《受測者姓名》，您好！</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《信件開頭》"
									InputProps={{style: {fontFamily:'微軟正黑體'}}}
									InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
									value={this.state.tp_begin}
									onChange={e=>this.setState({tp_begin:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_begin" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography style={{whiteSpace:'pre-line',fontFamily:'微軟正黑體'}}>{middle}</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《信件結尾》"
									InputProps={{style: {fontFamily:'微軟正黑體'}}}
									InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
									value={this.state.tp_end}
									onChange={e=>this.setState({tp_end:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_end" ? true:null}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									variant="outlined"
									multiline
									label="《HR聯絡人資訊》"
									InputProps={{style: {fontFamily:'微軟正黑體'}}}
									InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
									value={this.state.tp_contact}
									onChange={e=>this.setState({tp_contact:e.target.value})}
									fullWidth
									error = {this.state.fail_field === "tp_contact" ? true:null}
								/>
							</Grid>
						</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography  style={{fontFamily:'微軟正黑體',}}>測評說明：</Typography>
						<TextField
							margin="normal"
							variant="outlined"
							multiline
							value={this.state.tp_notice}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							onChange={e=>this.setState({tp_notice:e.target.value})}
							fullWidth
                            error = {this.state.fail_field === "tp_notice" ? true:null}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography  style={{fontFamily:'微軟正黑體',}}>測評說明圖示：</Typography>
						<ReactFileReader fileTypes={[".png"]} base64={true} handleFiles={this.handleFiles}>
							<Button style={{...{marginLeft:'20px'},...desktopButtonStyle2}}>上傳</Button>
						</ReactFileReader>
						</div>
						{this.state.tp_image!==null?
							<img alt=""src={`${this.state.tp_image}`} style = {{maxWidth:'200px', margin:'5px'}}/>
						:null}
					</Grid> */}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpIns} color="primary" style={{fontFamily:'微軟正黑體',}}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ScenarioCopyModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						tp_name: "",
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		if(!this.state.modal) {
			this.setState({ tp_name: this.props.tp.tp_name+'(複製)'})
		}
		this.setState({ modal: !this.state.modal,
						fail_field: "",
						fail_msg: "",
					});
	}

	fetchTpCpy = () => {
		let send_body = JSON.stringify({ func:'tp_cpy',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
										tp_name: this.state.tp_name,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleFiles = file => {
		this.setState({tp_image:file.base64});
	}

	render() {
	return (
		<div>
			<IconButton size="large" disabled={this.props.auth!=="write"} onClick={this.toggle}><FileCopy/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>複製 {this.props.tp.tp_name} 施測情境</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					{this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體',}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={12}>
						<div style={{display:'flex',alignItems:'baseline'}}> 
						<Typography  style={{fontFamily:'微軟正黑體',}}>新施測情境名稱：</Typography>
						<TextField
							value={this.state.tp_name}
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							onChange={e=>this.setState({tp_name:e.target.value})}
                            error = {this.state.fail_field === "tp_name" ? true:null}
						/>
						</div>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpCpy} color="primary" style={{fontFamily:'微軟正黑體',}}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ScenarioDelModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
					});
	}
	fetchTpDel = () => {
		let send_body = JSON.stringify({ func:'del_tp',
										login_uid: this.props.uid,
										tp_seq: this.props.tp.tp_seq,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchTpList();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<IconButton size="large" disabled={this.props.auth!=="write"} onClick={this.toggle}><Delete/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>刪除 {this.props.tp.tp_name} 施測情境</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Typography style={{fontFamily:'微軟正黑體',}}>
					確定刪除 {this.props.tp.tp_name} 施測情境嗎？
				</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button size="large" onClick={this.fetchTpDel} color="primary" style={{fontFamily:'微軟正黑體',}}>
				確認
				</Button>
				<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>
				取消
				</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class EntInformation extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isAddressChange: false,
						corp_data: { contact:[{}],},
						editAddr: "",
						tpList: [],
						img64: "",
					};
	}
	componentDidMount() {
		this.fetchCorpData();
		this.fetchTpList();
		this.fetchLogoImg64();
	}
	
	fetchCorpData = () => {
		let send_body = JSON.stringify({ func:'get_corp_data',
										login_uid:this.props.uid,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ corp_data: response,
								corp_name_abbr: response.corp_name_abbr,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleFiles = file => {
		let send_body = JSON.stringify({ func: 'corp_logo_upload',
										login_uid: this.props.uid,
                                        file: file.base64,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
            })
        .then(res => res.json())
        .then(response => {
			if(response.status==="SUCC"){
				alert("上傳成功");
				this.fetchLogoImg64();
			}
		})
        .catch(error => console.error('Error:', error));
	}

	fetchLogoImg64 = () => {
		let send_body = JSON.stringify({ func:'get_logo_path',
										login_uid:this.props.uid,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers()
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({img64:response.img64});
			}
			else if(response.status==="FAIL"){
				this.setState({img64:""});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchModAddress = () => {
		let send_body = JSON.stringify({ func:'corp_mod_addr',
										login_uid:this.props.uid,
										addr: this.state.editAddr,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC") {
				this.setState({isAddressChange : false});
				this.fetchCorpData();
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchModAbbr = () => {
		let send_body = JSON.stringify({ func:'corp_mod_abbr',
										login_uid:this.props.uid,
										abbr: this.state.editAbbr,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC") {
				this.setState({isAbbrChange:false});
				this.fetchCorpData();
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchTpList = () => {
		let send_body = JSON.stringify({ func: "get_template_list",
										login_uid: this.props.uid,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({tpList:response.result});
			}
			else if(response.status==="FAIL"){}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
		const desktopButtonStyle = {
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'2.2vw',
			width:'10vw',
			fontSize:'1.1vw',
            margin:'10px'
        };
        
		const desktopButtonStyle2 = {
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			marginBottom:'0.5vw',
		};

		const desktopDisabledButtonStyle = {
            fontFamily:'微軟正黑體',
			backgroundColor:'gray',
			color:'white',
			borderRadius:'2.2vw',
			marginTop:'1.2vw',
			width:'10vw',
			fontSize:'1.1vw',
			marginBottom:'2vw',
		};

		const mobileButtonStyle={
            fontFamily:'微軟正黑體',
			backgroundColor:UGCblue,
			color:'white',
			borderRadius:'5vw',
			marginTop:'1.2vw',
			width:'28vw',
			fontSize:'4.5vw',
			marginBottom:'4vw',
		};

		const mobileDisabledButtonStyle={
            fontFamily:'微軟正黑體',
			backgroundColor:'lightgray',
			color:'white',
			borderRadius:'5vw',
			marginTop:'1.2vw',
			width:'28vw',
			fontSize:'4.5vw',
			marginBottom:'4vw',
		};

		const circleBtnStyle = {
            fontFamily:'微軟正黑體',
			fontSize:'4vw',
			fontWeight:'bold',
			borderRadius:'8vw',
			backgroundColor:'white',
			border:'1px solid white',
			color:UGCblue,
			padding:'0',
			minWidth:'7vw',
			margin:'1vw',
		};

		const mobileInfoFontSize = {
            fontFamily:'微軟正黑體',
			fontSize:'4vw',
		};

		const headerStyle= {
            fontFamily:'微軟正黑體',
			color:UGCblue,
			fontSize:'20px',
			fontWeight:'bold',
			marginTop:'20px'
		};

    return(
	<div style={{maxWidth:'750px', width:'100%',textAlign:'left',margin: '0 auto'}}>
		<Grid container spacing={0} alignItems="baseline" style={{marginTop:'20px'}}>
			<Grid item xs={12} sm={12}>
				<Typography gutterBottom style={headerStyle}>企業基本資料：</Typography>
			</Grid>
			<Grid item xs={12} sm={5}>
				<TextField
					id="corp_name"
					margin="normal"
					disabled
					InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
					value={this.state.corp_data.corp_name}
					onChange={e=>{this.setState({corp_name:e.target.value})}}
					error = {this.state.fail_field === "corp_name" ? true:null}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>企業名稱：</Typography></InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={1}></Grid>
			<Grid item xs={12} sm={5}>
				<TextField
					id="corp_name_abbr"
					margin="normal"
					placeholder="中文8字/英文16字"
					disabled={!this.state.isAbbrChange}
					InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
					value={this.state.isAbbrChange?this.state.editAbbr:this.state.corp_data.corp_name_abbr}
					onChange={e=>{this.setState({editAbbr:e.target.value})}}
					error = {this.state.fail_field === "corp_name_abbr"}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>企業名稱縮寫：</Typography></InputAdornment>,
						endAdornment: <InputAdornment position="end">
										{this.state.isAbbrChange&&this.state.corp_data.is_master?
										<IconButton onClick={this.fetchModAbbr}><Done/></IconButton>
										:!this.state.isAbbrChange&&this.state.corp_data.is_master?
										<IconButton onClick={()=>this.setState({isAbbrChange:true, editAbbr:this.state.corp_data.corp_name_abbr})}><Create/></IconButton>
										:null}
									</InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={5}>
				<TextField
					id="tax_id"
					margin="normal"
					disabled
					InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
					value={this.state.corp_data.tax_id}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>統一編號：</Typography></InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={1}></Grid>
			<Grid item xs={12} sm={5}>
				<TextField
					id="addr"
					margin="normal"
					disabled={!this.state.isAddressChange}
					value={this.state.isAddressChange?this.state.editAddr:this.state.corp_data.addr}
					onChange={e=>{this.setState({editAddr:e.target.value})}}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>地址：</Typography></InputAdornment>,
						endAdornment: <InputAdornment position="end">
										{this.state.isAddressChange&&this.state.corp_data.is_master?
										<IconButton onClick={this.fetchModAddress}><Done/></IconButton>
										:!this.state.isAddressChange&&this.state.corp_data.is_master?
										<IconButton onClick={()=>this.setState({isAddressChange:true, editAddr:this.state.corp_data.addr})}><Create/></IconButton>
										:null}
									</InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
					multiline
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
			<div style ={{display:'flex', alignItems:'baseline'}}>
				<Typography gutterBottom style={headerStyle}>施測情境管理：</Typography>
				<ScenarioAddModal uid={this.props.uid} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
			</div>
			</Grid>
			<Grid item xs={12} sm={12}>
				{this.state.tpList.map(tp=>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={4}>
						<ScenarioShowModal uid={this.props.uid} tp={tp}/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<div style={{display:'flex'}}>
						<ScenarioEditModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
						<ScenarioCopyModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
						<ScenarioDelModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
						</div>
					</Grid>
				</Grid>
				)}
			</Grid>
			{/* <Grid item xs={12} sm={12}>
			<div style ={{display:'flex', alignItems:'baseline'}}>
				<Typography gutterBottom style={headerStyle}>施測情境管理：</Typography>
				<ScenarioAddModal uid={this.props.uid} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
			</div>
			</Grid>
			<Grid item xs={12} sm={12}>
				<div style={{display:'flex'}}>
					<div style={{display:'flex',flexDirection:'column'}}>
					{this.state.tpList.map(tp=>
						<ScenarioShowModal uid={this.props.uid} tp={tp}/>
					)}
					</div>
				
					<div style={{display:'flex',flexDirection:'column'}}>
					{this.state.tpList.map(tp=>
					<div style={{display:'flex'}}>
						<ScenarioEditModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
						<ScenarioCopyModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
						<ScenarioDelModal uid={this.props.uid} tp={tp} fetchTpList={this.fetchTpList} auth={this.props.auth}/>
					</div>
					)}
					</div>
				</div>
			</Grid> */}
			<Grid item xs={12} sm={12}>
				<Typography gutterBottom style={headerStyle}>主要管理者資料：</Typography>
			</Grid>
			<Grid item xs={12} sm={5}>
				<TextField
					id="name"
					margin="normal"
					disabled
					InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
					value={this.state.corp_data.name}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>姓名：</Typography></InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={1}></Grid>
			<Grid item xs={12} sm={5}>
				<TextField
					id="uid"
					margin="normal"
					disabled
					value={this.state.corp_data.uid}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>帳號：</Typography></InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={5}>
				<TextField
					id="email"
					margin="normal"
					disabled
					value={this.state.corp_data.email}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>Email：</Typography></InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={1}></Grid>
			<Grid item xs={12} sm={5}>
				<TextField
					id="tel"
					margin="normal"
					disabled
					value={this.state.corp_data.tel}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>電話：</Typography></InputAdornment>,
						style: entInfoFontSize 
					}}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<div style={{display:'flex', alignItems:'baseline'}}>
				<Typography gutterBottom style={headerStyle}>系統管理者資料：</Typography>
				<ContactAddModal uid={this.props.uid} fetchCorpData={this.fetchCorpData} auth={this.props.auth!=="write"||this.state.corp_data.contact.length>=5}/>
				</div>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Paper elevation={1} style={{width:'100%', overflowX:'auto',minWidth: "100%", marginTop:'20px',  marginBottom:'30px'}}>
				<Table>
					<TableHead><TableRow style={tableheadrowStyle}>
						{contactHeadRows.map(e=><TableCell align="center" style={tableheadcellStyle}>{e}</TableCell>)}
					</TableRow></TableHead>
					<TableBody>
					{this.state.corp_data.contact.map((contact,index) => (
						<TableRow key={contact.uid} style={tablebodyStyle(index)}>
							<TableCell style={cellStyle}>{contact.name}</TableCell>
							<TableCell style={cellStyle}>{contact.email}</TableCell>
							<TableCell style={cellStyle}>{contact.tel}</TableCell>
							<TableCell style={cellStyle}>{contact.uid}</TableCell>
							<TableCell style={cellStyle}>{contact.passwd}</TableCell>
							<TableCell style={cellStyle}><AuthModal auth={contact}/></TableCell>
							<TableCell style={cellStyle}>
								<div style={{display:'flex'}}>
								<ContactEditModal uid={this.props.uid} contact={contact} fetchCorpData={this.fetchCorpData} auth={this.props.auth}/>
								<ContactDelModal uid={this.props.uid} contact={contact} fetchCorpData={this.fetchCorpData} auth={this.props.auth}/>
								</div> 
							</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
				</Paper>
			</Grid>

			{/* <Grid item xs={12} sm={12}>
				<div style ={{display:'flex', alignItems:'baseline'}}>
				<Typography gutterBottom variant="h4" style={{marginTop:'20px'}}>Logo 管理</Typography>
				<ReactFileReader fileTypes={[".png"]} base64={true} handleFiles={this.handleFiles}>
					<Button disabled={this.props.auth!=="write"} style={{marginLeft:'20px'}}>上傳</Button>
				</ReactFileReader>
				</div>
			</Grid>
			{this.state.pic_dir!==""?
			<Grid item xs={12} sm={12}>
				<img alt=""src={`${this.state.img64}`} style = {{maxWidth:'200px', margin:'5px'}}/>
			</Grid>
			:null} */}
		</Grid>
	</div>
    );
  }
}


export default EntInformation;