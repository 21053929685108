import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import renderHTML from 'react-render-html';
import Search from '@material-ui/icons/Search';
import ChangeInfo from '../changeInfo';

import {api_path,entLittleRoundButtonStyleDisabled,cellStyle,qResultbodycellStyle,qResultheadcellStyle,UGCblue,tablebodyStyle,tableheadrowStyle,tableheadcellStyle,entInfoFontSize,entLittleRoundButtonStyle} from '../../../environment';

const ResultHeadRows = ["","姓名","測評名稱","資料修改","單位","職稱","職類","職級","施測完成日","施測時長"];


class Print extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						msg: "",
						state: "1",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal){this.setState({modal:!this.state.modal,state:"2"})}
		if(this.state.modal&&this.state.state==="1"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "2",
						});
		}
		else if(this.state.modal&&this.state.state==="2"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}
		else if(this.state.modal&&this.state.state==="3"){
			this.setState({ modal: !this.state.modal,
							msg: "",
							state: "1",
						});
		}

	}
	fetchDownloadRpt= qa_seq => {
        let send_body = JSON.stringify({ func: 'download_rpt',
										login_uid: this.props.uid,
										qa_seq: qa_seq,
										pr_id: this.props.pr_id,
										stu_pr_id: this.props.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				// if(response.rpt){
                if(response.url){
                    var link = document.createElement("a");
                    link.href = response.url;
					link.target="_blank"
					// link.download = response.rpt_fname;
                    link.click();
                    this.setState({msg: response.msg, state: "3",modal:false});
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button
				style={entLittleRoundButtonStyle} 
				onClick={()=>{ 	
								this.toggle();
								this.fetchDownloadRpt(this.props.qa_seq);
				}}
			>看報告</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>報告將顯示，請稍待</Typography></DialogTitle>
				<DialogContent>
				{this.state.state==="2"?
            	<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				:null}
				</DialogContent>
				{this.state.state==="3"?
            	<DialogActions>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>
					關閉
					</Button>
                </DialogActions>
				:
				this.state.state==="2"?
				<DialogActions>
				</DialogActions>
				:
				null
				}
			</Dialog>
		</div>
		);
	}
}

class GenRptBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false };
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({modal:!this.state.modal,state:"2"});
	}
	fetchDownloadRpt = () => {
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: this.props.send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.url){
				    var link = document.createElement("a");
                	link.href = response.url;
					link.target="_blank"
					// link.download = response.rpt_fname;
					link.click();
					this.setState({modal:false});
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<Button
				style={this.props.style} 
				disabled={this.props.disabled}
				onClick={()=>{ 	this.toggle(); this.fetchDownloadRpt(); }}
			>一般報告下載</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>一般報告下載</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',}}>關閉</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class GenRptBtn2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false };
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({modal:!this.state.modal,state:"2"});
	}
	fetchDownloadRpt = () => {
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: this.props.send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.url){
				    var link = document.createElement("a");
                	link.href = response.url;
					link.target="_blank"
					// link.download = response.rpt_fname;
					link.click();
					this.setState({modal:false});
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<Button
				style={this.props.style} 
				disabled={this.props.disabled}
				onClick={()=>{ 	this.toggle(); this.fetchDownloadRpt(); }}
			>效標報告下載</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>效標報告下載</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
				<CircularProgress/>
				</div>
                </DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',}}>關閉</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}
class GenRptAllBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,msg:'',status:''};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({modal:!this.state.modal,msg:'',status:''});
	}
	fetchDownloadRptAll=()=> {
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: this.props.send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.url){
					var link = document.createElement("a");
                	link.href = response.url;
					link.target="_blank"
					// link.download = response.rpt_fname;
					link.click();
					this.setState({modal:false});
				// if(response.csv){
				// 	var link = document.createElement("a");
				// 	link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
				// 	link.download = response.csv_fname;
				// 	link.click();
				}
			}
			else if (response.status==="FAIL"){
				this.setState({msg:response.msg,status:'FAIL'})
			}
		})
        .catch(error => console.error('Error:', error));
	}
	render() {
	return (
		<div>
			<Button
				style={this.props.style} 
				disabled={this.props.disabled}
				onClick={()=>{ 	this.toggle(); this.fetchDownloadRptAll();}}
			>報告批次下載</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>報告批次下載</Typography></DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
					{this.state.status!=="FAIL"?
					<div style={{display:'flex',width:'100%',justifyContent:'center'}}>
					<CircularProgress/>
					</div>
					:
					<Typography style={{fontFamily:'微軟正黑體'}}>{this.state.msg}</Typography>
					}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',}}>關閉</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class RptModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,
                        fail_field: "",
						fail_msg: "",
                        html: "",
                        pre_page: "",
                        nxt_page: "",
                    };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
		if(!this.state.modal){this.fetchRpt();}
        this.setState({ modal: !this.state.modal,
                        fail_field: "",
                        fail_msg: "",
                        html: "",
                        pre_page: "",
                        nxt_page: "",
                    });
    }

	fetchRpt= () => {
        let send_body = JSON.stringify({ func: 'show_rpt',
										login_uid: this.props.uid,
										qa_seq: this.props.qa_seq,
										pr_id: this.props.pr_id,
										stu_pr_id: this.props.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ html: response.html,
                                pre_page: response.pre_page,
                                nxt_page: response.nxt_page
                            });
			}
		})
        .catch(error => console.error('Error:', error));
	}
    fetchPrev= () => {
        this.setState({ html: ""});
        let send_body = JSON.stringify({ func: 'show_rpt',
										login_uid: this.props.uid,
                                        qa_seq: this.props.qa_seq,
                                        page_id: this.state.pre_page,
										pr_id: this.props.pr_id,
										stu_pr_id: this.props.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ html: response.html,
                                pre_page: response.pre_page,
                                nxt_page: response.nxt_page
                            });
			}
		})
        .catch(error => console.error('Error:', error));
    }
    fetchNext= () => {
        this.setState({ html: ""});
        let send_body = JSON.stringify({ func: 'show_rpt',
										login_uid: this.props.uid,
                                        qa_seq: this.props.qa_seq,
                                        page_id: this.state.nxt_page,
										pr_id: this.props.pr_id,
										stu_pr_id: this.props.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/user_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ html: response.html,
                                pre_page: response.pre_page,
                                nxt_page: response.nxt_page
                            });
			}
		})
        .catch(error => console.error('Error:', error));
	}
    render() {
        return (
            <div>
                <Button onClick={this.toggle} style={entLittleRoundButtonStyle}>查看報告</Button>
                <Dialog
                    fullWidth={true}
                    maxWidth="xl"
                    open={this.state.modal}
                    onClose={this.toggle}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullScreen
                >
                    <DialogTitle id="alert-dialog-title"></DialogTitle>
                    {this.state.html===""?
                    <div style={{height:'100vh',minWidth:'100vw'}}>
                    <CircularProgress style={{top:'50vh',left:'50vw',margin:'0 auto'}}/>
                    </div>
                    :
                    renderHTML(this.state.html)
                    }
                    <Divider style={{marginTop:'20px'}}/>
                    <DialogActions>
                    <Button disabled={this.state.pre_page===""} size="large" onClick={this.fetchPrev} color="primary">
                    上一頁
                    </Button>
                    <Button disabled={this.state.nxt_page===""} size="large" onClick={this.fetchNext} color="primary">
                    下一頁
                    </Button>
                    <Button size="large" onClick={this.toggle} color="primary">
                    關閉
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

class RptEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						emp_no: "",
						dept_seq: "",
						title_seq: "",
						pfm_seq: "",
						grp_seq: "",
						fail_field: "",
						fail_msg: "",
						corpGroup:{department:[],performance:[],group:[]},
						titles: [],
						qList: [],
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({ modal: !this.state.modal,
						emp_no: this.props.qRpt.emp_no,
						dept_seq: this.props.qRpt.dept_seq,
						title_seq: this.props.qRpt.title_seq,
						pfm_seq: this.props.qRpt.pfm_seq,
						grp_seq: this.props.qRpt.grp_seq,
						fail_field: "",
						fail_msg: "",
						titles: [],
						qList: [],
						corpGroup:{department:[],performance:[],group:[]},
					});
		this.fetchGroup();
		this.fetchQList();
		this.fetchTitle(this.props.qRpt.dept_seq);
	}

    fetchGroup = () => {
        let send_body = JSON.stringify({ func:'get_group_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corpGroup:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}

    fetchTitle = dept_seq => {
        let send_body = JSON.stringify({ func:'get_title_info',
                                        login_uid: this.props.uid,
                                        dept_seq: dept_seq,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({titles:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchQList = () => {
		let send_body = JSON.stringify({ func:'get_corp_q_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qList:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}

	fetchQRptMod = () => {
		let send_body = JSON.stringify({ func: "mod_q_rpt",
										login_uid: this.props.uid,
										qa_seq: this.props.qRpt.qa_seq,
										emp_no: this.state.emp_no,
										dept_seq: this.state.dept_seq,
										title_seq: this.state.title_seq,
										pfm_seq: this.state.pfm_seq,
										grp_seq: this.state.grp_seq,
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.toggle();
				this.props.fetchQrpt();
			}
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button disabled={this.props.auth!=="write"} style={entLittleRoundButtonStyle} onClick={this.toggle}>編輯</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>編輯受測者</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體',}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					{/* <Grid item xs={12} sm={6}>
						<TextField
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							id="standard-name"
							label="姓名"
							value={this.props.qRpt.name}
							disabled
                            margin="normal"
                            error = {this.state.fail_field === "name" ? true:null}
						/>
					</Grid> */}
					{/* <Grid item xs={12} sm={6}>
						<TextField
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
							id="standard-name"
							label="員編"
							value={this.state.emp_no}
                            onChange={e => this.setState(({emp_no : e.target.value}))}
                            margin="normal"
                            error = {this.state.fail_field === "emp_no" ? true:null}
						/>
					</Grid> */}
					<Grid item xs={12} sm={6}>
						<TextField
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            id="standard-select-currency-native"
                            select
                            label="單位"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.dept_seq}
                            onChange={e=>{this.setState({dept_seq:e.target.value});
										if(e.target.value!==""){this.fetchTitle(e.target.value);}}}
                            error = {this.state.fail_field === "dept_seq" ? true:null}
                        >
							<option value=""></option>
                        	{this.state.corpGroup["department"].map(dept=><option value={dept.seq}>{dept.name}</option>)}
                		</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            id="standard-select-currency-native"
                            select
                            label="職稱"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.title_seq}
                            onChange={e => this.setState({title_seq : e.target.value})}
                            error = {this.state.fail_field === "title" ? true:null}
                        >
							<option value=""></option>
							{this.state.titles.map(title => <option value={title.seq}>{title.name}</option>)}
                        </TextField>
					</Grid>
					{/* <Grid item xs={12} sm={6}>
						<TextField
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            id="standard-select-currency-native"
                            select
                            label="績效"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.pfm_seq}
                            onChange={e=>{this.setState({pfm_seq:e.target.value});}}
                            error = {this.state.fail_field === "pfm_seq" ? true:null}
                        >
							<option value=""></option>
                        	{this.state.corpGroup["performance"].map(pfm=><option value={pfm.seq}>{pfm.name}</option>)}
                		</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            id="standard-select-currency-native"
                            select
                            label="群組"
                            SelectProps={{native: true}}
                            margin="normal"
                            fullWidth
							value={this.state.grp_seq}
                            onChange={e=>this.setState({grp_seq:e.target.value})}
                            error = {this.state.fail_field === "grp_seq" ? true:null}
                        >
							<option value=""></option>
                        	{this.state.corpGroup["group"].map(grp=><option value={grp.seq}>{grp.name}</option>)}
                		</TextField>
					</Grid> */}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={this.fetchQRptMod} color="primary" style={{fontFamily:'微軟正黑體',}}>確認</Button>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>取消</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class ShowInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						name: "",
						role: "",
						gender: "",
						age: "",
						tel: "",
						education: "",
						seniority: "",
						corp_name: "",
						corp_type: "",
						dept_name: "",
						title_name: "",
						edu_level: "",
						edu_status: "",
						edu_field: "",
						school_dept: "",
						school: "",
						school_year: ""
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if(!this.state.modal){ this.fetchRptInfo(); }
		this.setState({ modal: !this.state.modal,});
		
	}

	fetchRptInfo = () => {
		let send_body = JSON.stringify({ func: 'rpt_user_info',
										login_uid: this.props.uid,
										qa_seq: this.props.qRpt.qa_seq,
									});

		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.role==="社會人士"){
					this.setState({ name: response.name,
									role: response.role,
									gender: response.gender,
									age: response.age,
									tel: response.tel,
									education: response.education,
									seniority: response.seniority,
									corp_name: response.corp_name,
									corp_type: response.corp_type,
									dept_name: response.dept_name,
									title_name: response.title_name,
									title2: response.title2,
									dept2: response.dept2,
								});
				}
				else if(response.role==="學生"){
					this.setState({ name: response.name,
									role: response.role,
									gender: response.gender,
									age: response.age,
									tel: response.tel,
									edu_level: response.edu_level,
									edu_status: response.edu_status,
									edu_field: response.edu_field,
									school_dept: response.school_dept,
									school: response.school,
									school_year: response.school_year,
								});
				}
            }
        })
        .catch(error => console.error('Error:', error));
	}

	render() {
	return (
		<div>
			<Button onClick={this.toggle} style={{fontFamily:'微軟正黑體',textTransform:'none',fontWeight:'500',paddingTop:'0',paddingBottom:'0',}}>{this.props.qRpt.name}</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"><Typography style={{fontFamily:'微軟正黑體',}}>詳細資料</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>姓名：{this.state.tp_name}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>身份：{this.state.role}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>email：{this.state.email}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>性別：{this.state.gender}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>年紀：{this.state.age}</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>電話：{this.state.tel}</Typography>
					</Grid>
					{this.state.role==="社會人士"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>學歷：{this.state.education}</Typography>
					</Grid>
					:null}
					{this.state.role==="社會人士"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>工作年資：{this.state.seniority}</Typography>
					</Grid>
					:null}
					{this.state.role==="社會人士"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>服務公司的產業類別：{this.state.corp_type}</Typography>
					</Grid>
					:null}
					{this.state.role==="社會人士"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>服務的公司/機構：{this.state.corp_name}</Typography>
					</Grid>
					:null}
					{this.state.role==="社會人士"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>部門：{this.state.dept_name}</Typography>
					</Grid>
					:null}
					{this.state.role==="社會人士"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>職級：{this.state.title_name}</Typography>
					</Grid>
					:null}
					{this.state.role==="社會人士"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>職類：{this.state.dept2}</Typography>
					</Grid>
					:null}
					{this.state.role==="社會人士"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>職級：{this.state.title2}</Typography>
					</Grid>
					:null}
					{this.state.role==="學生"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>就學階段：{this.state.edu_level}</Typography>
					</Grid>
					:null}
					{this.state.role==="學生"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>就學狀態：{this.state.edu_status}</Typography>
					</Grid>
					:null}
					{this.state.role==="學生"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>就讀領域：{this.state.edu_field}</Typography>
					</Grid>
					:null}
					{this.state.role==="學生"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>就讀科系：{this.state.school_dept}</Typography>
					</Grid>
					:null}
					{this.state.role==="學生"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>就讀學校：{this.state.school}</Typography>
					</Grid>
					:null}
					{this.state.role==="學生"?
					<Grid item xs={12} sm={12}>
						<Typography style={{fontFamily:'微軟正黑體',}}>就讀年級：{this.state.school_year}</Typography>
					</Grid>
					:null}
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體',}}>關閉</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class EntQresult extends React.Component {
	constructor(props) {
		super(props);
		this.state = { qRptSearchData:[{}],
						search_emp_no: "",
						search_name: "",
						search_dept_seq: "",
						search_title_seq: "",
						search_pfm_seq: "",
						search_grp_seq: "",
						search_qf_start: "",
						search_qf_end: "",
						search_email: "",
						checkedValues: [],
						anchorEl: null,
						corpGroup:{department:[],performance:[],group:[]},
						rpt_url: "",
						usage_url: "",
						qStatusList: [{}],
						prList:[{}],
						pr_id:"1002",
						stu_pr_id: "2001",
						stuprList:[{}],
						show_start: "",
						show_end: "",
					};
	}

	componentDidMount(){
		this.fetchQrpt();
		this.fetchGroup();
		this.fetchQStatus();
		this.fetchPRList();
		this.fetchStuPRList();
	}

    fetchGroup = () => {
        let send_body = JSON.stringify({ func:'get_group_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corpGroup:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}

	fetchQList = () => {
		let send_body = JSON.stringify({ func:'get_corp_q_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({qList:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
	}

    fetchQrpt=()=> {
        let send_body = JSON.stringify({ func: 'query_q_rpt',
                                        login_uid: this.props.uid,
                                        name: this.state.search_name,
										emp_no: this.state.search_emp_no,
										dept_seq: this.state.search_dept_seq,
										title_seq: this.state.search_title_seq,
										pfm_seq: this.state.search_pfm_seq,
										grp_seq: this.state.search_grp_seq,
										qf_start: this.state.search_qf_start,
										qf_end: this.state.search_qf_end,
										email: this.state.search_email,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ qRptSearchData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
	handleResetSearch = () => {
		this.setState({ search_emp_no: "",
						search_name: "",
						search_dept_seq: "",
						search_title_seq: "",
						search_pfm_seq: "",
						search_grp_seq: "",
						search_qf_start: "",
						search_qf_end: "",
						search_email: "",
						checkedValues: [],
					});
		let send_body = JSON.stringify({ func: 'query_q_rpt',
										login_uid: this.props.uid,
										name: "",
										emp_no: "",
										dept_seq: "",
										title_seq: "",
										pfm_seq: "",
										grp_seq: "",
										qf_start: "",
										qf_end: "",
									});
		fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ qRptSearchData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleChangePage = page_id => {
		let send_body = JSON.stringify({ func: 'query_q_rpt',
                                        login_uid: this.props.uid,
                                        name: this.state.search_name,
										emp_no: this.state.search_emp_no,
										dept_seq: this.state.search_dept_seq,
										title_seq: this.state.search_title_seq,
										pfm_seq: this.state.search_pfm_seq,
										grp_seq: this.state.search_grp_seq,
										qf_start: this.state.search_qf_start,
										email: this.state.search_email,
										qf_end: this.state.search_qf_end,
										page_id: page_id,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ qRptSearchData:response.result,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
        .catch(error => console.error('Error:', error));
	}

	fetchDownloadRpt=()=> {
        let send_body = JSON.stringify({ func: 'download_q_rpt',
										login_uid: this.props.uid,
										qa_list: this.state.checkedValues,
										start: this.state.show_start,
										end: this.state.show_end,
										pr_id: this.state.pr_id,
										stu_pr_id: this.state.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
	fetchDownloadRptAll=()=> {
		let send_body = JSON.stringify({ func: 'down_all_rpt',
										login_uid: this.props.uid,
										name: this.state.search_name,
										dept_seq: this.state.search_dept_seq,
										email: this.state.search_email,
										start: this.state.show_start,
										end: this.state.show_end,
										pr_id: this.state.pr_id,
										stu_pr_id: this.state.stu_pr_id,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers()
		})
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
        .catch(error => console.error('Error:', error));
	}
	
	fetchPRList = () => {
		let send_body = JSON.stringify({ func:'get_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ prList:response.result,
								pr_id:"1002",
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}
	
	fetchStuPRList = () => {
		let send_body = JSON.stringify({ func:'get_stu_pr_list',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/misc_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				this.setState({ stuprList:response.result,
								stu_pr_id:"2001",
							});
            }
        })
        .catch(error => console.error('Error:', error));
	}

    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
	}

	fetchQStatus = ()=>{
		let send_body = JSON.stringify({ func: 'q_status_list',
									});
		fetch(`${api_path}/ugc/misc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({qStatusList:response.result});
			}
		})
		.catch(error => console.error('Error:', error));
	}

  	render() {
   	return (
		<div>
			<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'10px',justifyContent:'space-between'}}>
				<div style={{display:'flex',alignItems:'baseline'}}>
				<div style={{display:'flex',alignItems:'baseline'}}>
					<Typography style={{fontSize:'14px', margin:'5px',fontFamily:'微軟正黑體',}}>
						日期區間：
					</Typography>
					<TextField
						id="date"
						type="date"
						InputLabelProps={{shrink:true}}
						value={this.state.search_qf_start}
						onChange={e=>{this.setState({search_qf_start:e.target.value})}}
						style={{margin:'5px',width:'130px'}}
						InputProps={{ style: {fontSize:'14px',fontFamily:'微軟正黑體'} }}
						InputLabelProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
					/>
					<Typography gutterBottom style={{margin:'5px',fontSize:'14px'}}> ～ </Typography>
					<TextField
						id="date"
						type="date"
						InputLabelProps={{shrink:true}}
						value={this.state.search_qf_end}
						onChange={e=>{this.setState({search_qf_end:e.target.value})}}
						style={{margin:'5px',width:'130px'}}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
						InputLabelProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
					/>
				</div>
				<div style={{display:'flex',alignItems:'baseline'}}>
					<TextField
						id="standard-name"
						label="姓名"
						value={this.state.search_name}
						onChange={e => this.setState(({search_name : e.target.value}))}
						style={{margin:'5px',width:'130px'}}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
						InputLabelProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
					/>
					<TextField
						InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
						id="standard-name"
						label="email"
						value={this.state.search_email}
						onChange={e => this.setState(({search_email : e.target.value}))}
						style={{margin:'5px',width:'130px'}}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
						InputLabelProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
					/>
					<TextField
						id="standard-select-currency-native"
						select
						label="單位"
						SelectProps={{native: true}}
						value={this.state.search_dept_seq}
						onChange={e=>this.setState({search_dept_seq:e.target.value})}
						style={{margin:'5px', width:'100px'}}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
						InputLabelProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
					>
						<option value=""></option>
						{this.state.corpGroup["department"].map(dept=><option value={dept.seq}>{dept.name}</option>)}
					</TextField>
					{/* <TextField
						InputLabelProps={{style: {fontFamily:'微軟正黑體'}}}
						id="standard-select-currency-native"
						select
						label="績效"
						SelectProps={{native: true}}
						value={this.state.search_pfm_seq}
						onChange={e=>this.setState({search_pfm_seq:e.target.value})}
						error = {this.state.fail_field === "pfm_seq" ? true:null}
						style={{margin:'5px', width:'100px'}}
					>
						<option value=""></option>
						{this.state.corpGroup["performance"].map(pfm=><option value={pfm.seq}>{pfm.name}</option>)}
					</TextField>
					<TextField
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
						id="standard-select-currency-native"
						select
						label="群組"
						SelectProps={{native: true}}
						value={this.state.search_grp_seq}
						onChange={e=>this.setState({search_grp_seq:e.target.value})}
						error = {this.state.fail_field === "grp_seq" ? true:null}
						style={{margin:'5px', width:'100px'}}
					>
						<option value=""></option>
						{this.state.corpGroup["group"].map(grp=><option value={grp.seq}>{grp.name}</option>)}
					</TextField> */}
					<div style={{display:'flex',alignItems:'center'}}>
					<Button onClick={this.fetchQrpt} style={{marginRight:'5px',fontSize:'14px',fontFamily:'微軟正黑體',}}><Search style={{fontSize:'14px'}}/>查詢</Button>
					<Button onClick={this.handleResetSearch} style={{marginRight:'5px',fontSize:'14px',fontFamily:'微軟正黑體',}}>清除查詢</Button>
					</div>
				</div>
				</div>
				<div style={{display:'flex',alignItems:'baseline',alignSelf:'flex-end'}}>
					<Typography style={{fontSize:'14px', margin:'5px',fontFamily:'微軟正黑體'}}>
						比對常模：
					</Typography>
					<TextField
						id="standard-select-currency-native"
						select
						label="社會人士"
						SelectProps={{native: true}}
						value={this.state.pr_id}
						onChange={e=>this.setState({pr_id:e.target.value})}
						style={{margin:'5px', width:'100px'}}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
						InputLabelProps={{style:{fontSize:'14px',fontFamily:'微軟正黑體'},shrink:true}}
					>
						<option value=""></option>
						{this.state.prList.map(pr=><option value={pr.id}>{pr.val}</option>)}
					</TextField>
					<TextField
						id="standard-select-currency-native"
						select
						label="社會新鮮人"
						SelectProps={{native: true}}
						value={this.state.stu_pr_id}
						onChange={e=>this.setState({stu_pr_id:e.target.value})}
						style={{margin:'5px', width:'100px'}}
						InputProps={{style: {fontSize:'14px',fontFamily:'微軟正黑體'}}}
						InputLabelProps={{style:{fontSize:'14px',fontFamily:'微軟正黑體'},shrink:true}}
					>
						<option value=""></option>
						{this.state.stuprList.map(pr=><option value={pr.id}>{pr.val}</option>)}
					</TextField>
				</div>
			</div>
			<Paper style={{width:'100%', overflowX:'auto',marginTop:'20px'}}>
			<Table>
				<TableHead>
					<TableRow style={tableheadrowStyle}>
						<TableCell align="center" style={qResultheadcellStyle}>
							{/* <Checkbox 
								checked={this.state.checkedValues.length===this.state.qRptSearchData.length && this.state.qRptSearchData.length !== 0}
								indeterminate={this.state.checkedValues.length<this.state.qRptSearchData.length&&this.state.checkedValues.length>0}
								onChange={()=>this.setState({checkedValues:this.state.checkedValues.length=== 0?this.state.qRptSearchData.map(rpt => rpt.qa_seq):[]})}
							/> */}
							<IconButton size="small"onClick={e=>this.setState({anchorEl:e.currentTarget})}><ArrowDropDown/></IconButton>
							<Menu
								id="simple-menu"
								anchorEl={this.state.anchorEl}
								keepMounted
								open={Boolean(this.state.anchorEl)}
								onClose={()=>this.setState({anchorEl:null})}
							>
								<MenuItem onClick={()=>{this.setState({anchorEl:null, checkedValues: this.state.qRptSearchData.map(rpt => rpt.qa_seq)});}} style={{fontFamily:'微軟正黑體'}}>全選</MenuItem>
								<MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: []})} style={{fontFamily:'微軟正黑體'}}>全不選</MenuItem>
							</Menu>
						</TableCell>
						{ResultHeadRows.map(e=><TableCell align="center" style={qResultheadcellStyle}>{e}</TableCell>)}
					</TableRow>
				</TableHead>
				<TableBody>
				{this.state.qRptSearchData.map((qRpt,index) => 
					<TableRow style={tablebodyStyle(index)} selected={this.state.checkedValues.includes(qRpt.qa_seq)}>
						<TableCell style={{padding:'2px',whiteSpace:'nowrap',marginBottom:'0',width:'50px'}}>
							<Checkbox label={qRpt.qa_seq} key={qRpt.qa_seq} checked={this.state.checkedValues.includes(qRpt.qa_seq)} onChange={e => this.handleCheck(e,qRpt.qa_seq)} />
						</TableCell>
						<TableCell align="center" style={{padding:'2px',whiteSpace:'nowrap',marginBottom:'0',width:'80px'}}>
							<Print uid={this.props.uid} qa_seq={qRpt.qa_seq} stu_pr_id={this.state.stu_pr_id} pr_id={this.state.pr_id}/>
							{/* <RptModal uid={this.props.uid} qa_seq={qRpt.qa_seq} stu_pr_id={this.state.stu_pr_id} pr_id={this.state.pr_id}/> */}
						</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>
							<ChangeInfo uid={this.props.uid} qRpt={qRpt} role="corp" succProcess={this.fetchQrpt}/>
							{/* <ShowInfo uid={this.props.uid} qRpt={qRpt}/> */}
						</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qRpt.qname}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>
							<RptEditModal uid={this.props.uid} qRpt={qRpt} auth={this.props.auth} fetchQrpt={this.fetchQrpt}/>
						</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qRpt.dept_name}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qRpt.title_name}</TableCell>
						{/* <TableCell style={qResultbodycellStyle}>{qRpt.pfm_name}</TableCell>
						<TableCell style={qResultbodycellStyle}>{qRpt.grp_name}</TableCell> */}
						<TableCell align="center" style={qResultbodycellStyle}>{qRpt.dept2}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qRpt.title2}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qRpt.qf_date}</TableCell>
						<TableCell align="center" style={qResultbodycellStyle}>{qRpt.qf_time}</TableCell>
					</TableRow>
				)}
				<TableRow>
					<TableCell colSpan={5} align="left" style={{fontFamily:'微軟正黑體'}}>{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}</TableCell>
				</TableRow>
			</TableBody>
			</Table>
		</Paper>
		<div style={{display:'flex',justifyContent:'space-between'}}>
			<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
				<Typography style={{marginRight:'10px',fontFamily:'微軟正黑體'}}>已選：{this.state.checkedValues.length} 項</Typography>
				<GenRptBtn
					disabled={this.state.checkedValues.length===0||this.props.auth!=="write"?true:null} 
					style={this.state.checkedValues.length===0||this.props.auth!=="write"?entLittleRoundButtonStyleDisabled:entLittleRoundButtonStyle} 
					send_body={JSON.stringify({ func: 'download_q_rpt',
												login_uid: this.props.uid,
												qa_list: this.state.checkedValues,
												pr_id: this.state.pr_id,
												stu_pr_id: this.state.stu_pr_id,
											})}
				/>
				<GenRptBtn2
					disabled={this.state.checkedValues.length===0||this.props.auth!=="write"?true:null} 
					style={this.state.checkedValues.length===0||this.props.auth!=="write"?entLittleRoundButtonStyleDisabled:entLittleRoundButtonStyle} 
					send_body={JSON.stringify({ func: 'download_eff_rpt',
												login_uid: this.props.uid,
												qa_list: this.state.checkedValues,
												pr_id: this.state.pr_id,
												stu_pr_id: this.state.stu_pr_id,
											})}
				/>
				<GenRptAllBtn
					disabled={this.props.auth!=="write"?true:null} 
					style={this.props.auth!=="write"?entLittleRoundButtonStyleDisabled:entLittleRoundButtonStyle} 
					send_body={JSON.stringify({ func: 'down_all_rpt',
												login_uid: this.props.uid,
												start: this.state.show_start,
												end: this.state.show_end,
												pr_id: this.state.pr_id,
												qa_list: this.state.checkedValues,
												stu_pr_id: this.state.stu_pr_id,
												name: this.state.search_name,
												emp_no: this.state.search_emp_no,
												dept_seq: this.state.search_dept_seq,
												title_seq: this.state.search_title_seq,
												pfm_seq: this.state.search_pfm_seq,
												grp_seq: this.state.search_grp_seq,
												qf_start: this.state.search_qf_start,
												email: this.state.search_email,
												qf_end: this.state.search_qf_end,
												dtoken: this.state.dtoken,
											})}
				/>
			</div>
			<div style={{display:'flex',margin:'20px'}}>
			<Button size="large" style={{paddingTop:'0',paddingBottom:'0'}} disabled={this.state.pre_page===""} onClick={()=>this.handleChangePage(this.state.pre_page)} style={{fontFamily:'微軟正黑體'}}>上一頁</Button>
			<Button size="large" style={{paddingTop:'0',paddingBottom:'0'}} disabled={this.state.nxt_page===""} onClick={()=>this.handleChangePage(this.state.nxt_page)} style={{fontFamily:'微軟正黑體'}}>下一頁</Button>
			</div>
		</div>
	</div>
	);
	}
}


export default EntQresult;