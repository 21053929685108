import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import {api_path,cellStyle,UGCblue,tablebodyStyle,tableheadrowStyle,tableheadcellStyle,entInfoFontSize,entLittleRoundButtonStyle} from '../../../environment';

class GroupEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						name: "",
                        desc: "",
                        isTitle: "",
						fail_field: "",
                        fail_msg: "",
                        nameLabel: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
        this.setState({ modal: !this.state.modal,
                        name: this.props.e.name,
                        desc: this.props.e.desc,
                        isTitle: (this.props.dept_seq !== ""),
                        nameLabel: this.handleName,
                        fail_field: "",
                        fail_msg: "",
					});
	}

    fetchMod = () => {
        let send_body;
        if(!this.state.isTitle){
            if(this.props.category === "department"){
                send_body = JSON.stringify({ func:'dept_mod',
                                            login_uid: this.props.uid,
                                            dept_seq: this.props.e.seq,
                                            name: this.state.name,
                                            desc: this.state.desc,
                                        });
            }
            else if(this.props.category === "performance"){
                send_body = JSON.stringify({ func:'pfm_mod',
                                            login_uid: this.props.uid,
                                            pfm_seq: this.props.e.seq,
                                            name: this.state.name,
                                            desc: this.state.desc,
                                        });
            }
            else if(this.props.category === "group"){
                send_body = JSON.stringify({ func:'group_mod',
                                            login_uid: this.props.uid,
                                            grp_seq: this.props.e.seq,
                                            name: this.state.name,
                                            desc: this.state.desc,
                                        });
            }
        }
        else{
            send_body = JSON.stringify({ func:'title_mod',
                                        login_uid: this.props.uid,
                                        title_seq: this.props.e.seq,
                                        name: this.state.name,
                                        desc: this.state.desc,
                                    });
        }
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.toggle();
                this.props.fetchGroup();
                this.props.fetchTitle(this.props.dept_seq);
                
            }
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
    }
    handleTitle(){
        if(!this.state.isTitle){
            if(this.props.category === "department"){
                return "部門";
            }
            else if(this.props.category === "performance"){
                return "績效";
            }
            else if(this.props.category === "group"){
                return "群組";
            }
        }
        else{
            return "職稱";
        }
    }
	
    handleName(){
        if(!this.state.isTitle){
            if(this.props.category === "department"){
                return "部門名稱";
            }
            else if(this.props.category === "performance"){
                return "績效等級";
            }
            else if(this.props.category === "group"){
                return "群組名稱";
            }
        }
        else{
            return "職稱";
        }
    }
	render() {
	return (
		<div>
			<IconButton onClick={this.toggle} disabled={this.props.auth!=="write"} style={{padding:'4px'}}><Create/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
                <Typography style={{fontFamily:'微軟正黑體'}}>
                    編輯 {this.handleTitle()}
                </Typography>
                </DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label={this.handleName()}
							value={this.state.name}
                            onChange={e => this.setState({name : e.target.value})}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "name" ? true:null}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="詳細敘述"
							value={this.state.desc}
                            onChange={e => this.setState({desc : e.target.value})}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "desc" ? true:null}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={this.fetchMod} color="primary" style={{fontFamily:'微軟正黑體'}}>確認</Button>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>取消</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class GroupAddModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						name: "",
                        desc: "",
                        isTitle: "",
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
        this.setState({ modal: !this.state.modal,
                        isTitle: (this.props.dept_seq !== ""),
                        name: "",
                        desc: "",
					});
	}

    fetchIns = () => {
        let send_body;
        if(!this.state.isTitle){
            if(this.props.category === "department"){
                send_body = JSON.stringify({ func:'dept_ins',
                                            login_uid: this.props.uid,
                                            name: this.state.name,
                                            desc: this.state.desc,
                                        });
            }
            else if(this.props.category === "performance"){
                send_body = JSON.stringify({ func:'pfm_ins',
                                            login_uid: this.props.uid,
                                            name: this.state.name,
                                            desc: this.state.desc,
                                        });
            }
            else if(this.props.category === "group"){
                send_body = JSON.stringify({ func:'group_ins',
                                            login_uid: this.props.uid,
                                            name: this.state.name,
                                            desc: this.state.desc,
                                        });
            }
        }
        else{
            send_body = JSON.stringify({ func:'title_ins',
                                        login_uid: this.props.uid,
                                        dept_seq: this.props.dept_seq,
                                        name: this.state.name,
                                        desc: this.state.desc,
                                    });
        }
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.toggle();
                this.props.fetchGroup();
                this.props.fetchTitle(this.props.dept_seq);
            }
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
    }
    handleTitle(){
        if(!this.state.isTitle){
            if(this.props.category === "department"){
                return "部門";
            }
            else if(this.props.category === "performance"){
                return "績效";
            }
            else if(this.props.category === "group"){
                return "群組";
            }
        }
        else{
            return "職稱";
        }
    }
	
    handleName(){
        if(!this.state.isTitle){
            if(this.props.category === "department"){
                return "部門名稱";
            }
            else if(this.props.category === "performance"){
                return "績效等級";
            }
            else if(this.props.category === "group"){
                return "群組名稱";
            }
        }
        else{
            return "職稱";
        }
    }
	render() {
	return (
		<div>
			{/* <IconButton onClick={this.toggle} disabled={this.props.auth!=="write"}><Add/></IconButton> */}
            <Button onClick={this.toggle} disabled={this.props.auth!=="write"} style={entLittleRoundButtonStyle}>新增</Button>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
                <Typography style={{fontFamily:'微軟正黑體'}}>
                    新增 {this.handleTitle()}
                </Typography>
                </DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				<Grid container spacing={2}>
                    {this.state.fail_msg!==""?
                    <Grid item xs={12} sm={12}>
                        <Typography style={{color:"red",fontFamily:'微軟正黑體'}}>{this.state.fail_msg}</Typography>
					</Grid>
					:null}
					<Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label={this.handleName()}
							value={this.state.name}
                            onChange={e => this.setState({name : e.target.value})}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "name" ? true:null}
						/>
					</Grid>
                    <Grid item xs={12} sm={6}>
						<TextField
							id="standard-name"
							label="詳細敘述"
							value={this.state.desc}
                            onChange={e => this.setState({desc : e.target.value})}
                            margin="normal"
							InputProps={{style: {fontFamily:'微軟正黑體'}}}
							InputLabelProps={{shrink:true,style: {fontFamily:'微軟正黑體'}}}
                            error = {this.state.fail_field === "desc" ? true:null}
						/>
					</Grid>
				</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={this.fetchIns} color="primary" style={{fontFamily:'微軟正黑體'}}>確認</Button>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>取消</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class GroupDelModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { modal: false,
						name: "",
                        desc: "",
                        isTitle: "",
						fail_field: "",
						fail_msg: "",
					};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
        this.setState({ modal: !this.state.modal,
                        isTitle: (this.props.dept_seq !== ""),
					});
	}

    fetchDel = () => {
        let send_body;
        if(!this.state.isTitle){
            if(this.props.category === "department"){
                send_body = JSON.stringify({ func:'dept_del',
                                            login_uid: this.props.uid,
                                            dept_seq: this.props.e.seq,
                                        });
            }
            else if(this.props.category === "performance"){
                send_body = JSON.stringify({ func:'pfm_del',
                                            login_uid: this.props.uid,
                                            pfm_seq: this.props.e.seq,
                                        });
            }
            else if(this.props.category === "group"){
                send_body = JSON.stringify({ func:'group_del',
                                            login_uid: this.props.uid,
                                            group_seq: this.props.e.seq,
                                        });
            }
        }
        else{
            send_body = JSON.stringify({ func:'title_del',
                                        login_uid: this.props.uid,
                                        title_seq: this.props.e.seq,
                                    });
        }
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.toggle();
                this.props.fetchGroup();
                this.props.fetchTitle(this.props.dept_seq);
            }
			else if(response.status==="FAIL"){
				this.setState({fail_field:response.fail_field, fail_msg:response.msg});
			}
        })
        .catch(error => console.error('Error:', error));
    }
    
    handleTitle(){
        if(!this.state.isTitle){
            if(this.props.category === "department"){
                return "部門";
            }
            else if(this.props.category === "performance"){
                return "績效";
            }
            else if(this.props.category === "group"){
                return "群組";
            }
        }
        else{
            return "職稱";
        }
    }
	
	render() {
	return (
		<div>
			<IconButton onClick={this.toggle} disabled={this.props.auth!=="write"} style={{padding:'4px'}}><Delete/></IconButton>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={this.state.modal}
				// onClose={this.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
                <Typography style={{fontFamily:'微軟正黑體'}}>
                    刪除 {this.handleTitle()}
                </Typography>
                </DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                <Typography style={{fontFamily:'微軟正黑體'}}>
                確定要刪除 {this.props.e.name} {this.handleTitle()}嗎？
				</Typography>
                </DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" onClick={this.fetchDel} color="primary" style={{fontFamily:'微軟正黑體'}}>確認</Button>
					<Button size="large" onClick={this.toggle} color="primary" style={{fontFamily:'微軟正黑體'}}>取消</Button>
				</DialogActions>
			</Dialog>
		</div>
		);
	}
}

class EntGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = { category: "department",
                        corpGroup: {department:[]},
                        dept_seq: "",
                        title: [{}],
                    };
    }

	componentDidMount(){
		this.fetchGroup();
    }
    
    fetchGroup = () => {
        let send_body = JSON.stringify({ func:'get_group_info',
                                        login_uid: this.props.uid,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({corpGroup:response.result})
            }
        })
        .catch(error => console.error('Error:', error));
    }

    fetchTitle = dept_seq => {
        let send_body = JSON.stringify({ func:'get_title_info',
                                        login_uid: this.props.uid,
                                        dept_seq: dept_seq,
                                    });
        fetch(`${api_path}/ugc/corp_mgt.php`, {
                method: 'POST',
                body: send_body,
                headers: new Headers(),
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({title:response.result});
            }
        })
        .catch(error => console.error('Error:', error));
    }
    
    handleName(){
        if(this.state.dept_seq === ""){
            if(this.state.category === "department"){
                return "部門名稱";
            }
            else if(this.state.category === "performance"){
                return "績效等級";
            }
            else if(this.state.category === "group"){
                return "群組名稱";
            }
        }
        else{
            return "職稱";
        }
    }

    render() {
    return(
    <div style={{maxWidth:'600px', width:'100%',textAlign:'left',margin: '0 auto'}}>
	    <Grid container spacing={2} style={{marginTop:'30px'}}>
            <Grid item xs={12} sm={4}>
                <TextField
                    id="category"
                    select SelectProps={{native: true}}
                    margin="normal"
                    value={this.state.category}
                    onChange= {e =>this.setState({category:e.target.value, dept_seq:""})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>選擇編輯類別：</Typography></InputAdornment>,
                        style: entInfoFontSize 
                    }}
                    fullWidth
                >
                    <option value="department">單位</option>
                </TextField>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={5}>
                {this.state.category === "department" ?
                <TextField
                    id="category"
                    select SelectProps={{native: true}}
                    margin="normal"
                    value = {this.state.dept_seq}
                    onChange={e=>{ 
                        this.setState({dept_seq:e.target.value});
                        if(e.target.value!==""){
                            this.setState({isTitle:true});
                            this.fetchTitle(e.target.value);
                        }
                        else{
                            this.setState({isTitle:false});
                        }
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Typography noWrap style={entInfoFontSize}>選擇單位名稱：</Typography></InputAdornment>,
                        style: entInfoFontSize 
                    }}
                    fullWidth
                >
                    <option value="">無</option>
                    {this.state.corpGroup["department"].map(dept=><option value={dept.seq}>{dept.name}</option>)}
                </TextField>
                // <div style ={{display:'flex', alignItems:'baseline' }}>
                //     <Typography gutterBottom>
                //     選擇單位名稱：
                //     </Typography>
                //     <FormControl margin='normal'>
                //     <NativeSelect
                //         onChange={e=>{ this.setState({dept_seq:e.target.value});
                //                         if(e.target.value!==""){this.fetchTitle(e.target.value);}
                //                     }}
                //         value = {this.state.dept_seq}
                //     >
                //         <option value="">無</option>
                //         {this.state.corpGroup["department"].map(dept=><option value={dept.seq}>{dept.name}</option>)}
                //     </NativeSelect>
                //     </FormControl>
                // </div>
                :null}
            </Grid>
        </Grid>
        <div>
            <Paper style={{marginTop:'20px'}}>
            <Table>
                <TableHead><TableRow style={tableheadrowStyle}>
                    <TableCell align="center" style={tableheadcellStyle}>{this.handleName()}</TableCell>
                    <TableCell align="center" style={tableheadcellStyle}>詳細敘述</TableCell>
                    <TableCell align="center" style={tableheadcellStyle}>
                        <GroupAddModal uid={this.props.uid} dept_seq={this.state.dept_seq} category={this.state.category} fetchGroup={this.fetchGroup} fetchTitle={this.fetchTitle} auth={this.props.auth}/>
                    </TableCell>
                </TableRow></TableHead>
                <TableBody>
                {this.state.dept_seq === ""?
                this.state.corpGroup[this.state.category].map((e,index) =>
                    <TableRow key={e.seq} style={tablebodyStyle(index)}>
                        <TableCell style={cellStyle}>{e.name}</TableCell>
                        <TableCell style={cellStyle}>{e.desc}</TableCell>
                        <TableCell align="center" style={cellStyle}>
                            <div style={{display:'flex',justifyContent:'center'}}>
                            <GroupEditModal uid={this.props.uid} e={e} dept_seq={this.state.dept_seq} category={this.state.category} fetchGroup={this.fetchGroup} fetchTitle={this.fetchTitle} auth={this.props.auth}/>
                            <GroupDelModal uid={this.props.uid} e={e} dept_seq={this.state.dept_seq} category={this.state.category} fetchGroup={this.fetchGroup} fetchTitle={this.fetchTitle} auth={this.props.auth}/>
                            </div>
                        </TableCell>
                    </TableRow>
                )
                :
                this.state.title.map((e,index) =>
                    <TableRow key={e.seq} style={tablebodyStyle(index)}>
                        <TableCell style={cellStyle}>{e.name}</TableCell>
                        <TableCell style={cellStyle}>{e.desc}</TableCell>
                        <TableCell align="center" style={cellStyle}>
                            <div style={{display:'flex',justifyContent:'center'}}>
                            <GroupEditModal uid={this.props.uid} e={e} dept_seq={this.state.dept_seq} category={this.state.category} fetchGroup={this.fetchGroup} fetchTitle={this.fetchTitle} auth={this.props.auth}/>
                            <GroupDelModal uid={this.props.uid} e={e} dept_seq={this.state.dept_seq} category={this.state.category} fetchGroup={this.fetchGroup} fetchTitle={this.fetchTitle} auth={this.props.auth}/>
                            </div>
                        </TableCell>
                    </TableRow>
                )}
                </TableBody>
            </Table>
            </Paper>
            
        </div>
    </div>
    );
    }
}

export default EntGroup;