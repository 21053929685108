import {UGCblue} from './environment';

export const cellStyle = {
    fontFamily:'微軟正黑體',whiteSpace: 'nowrap',paddingTop:'4px',paddingBottom:'4px',marginBottom:'0'};

export const tablebodyStyle = (index)=>index%2===0?{backgroundColor:'#D4D4D4'}:{};

export const tableheadcellStyle = {
    fontWeight:'700',
    fontFamily:'微軟正黑體',color:'black',whiteSpace:'nowrap',paddingTop:'8px',paddingBottom:'8px',fontSize:'14px'};

export const entusagetablebodycellStyle = {
    fontFamily:'微軟正黑體',color:'black',whiteSpace:'nowrap',paddingTop:'8px',paddingBottom:'8px',fontSize:'14px'};

export const headRowStyle = {backgroundColor:'#B0B0B0'};

export const entInfoFontSize={ fontFamily:'微軟正黑體',
                                fontSize:'16px',
                                color:'black',
                            };

export const entLittleRoundButtonStyle = {
    fontFamily:'微軟正黑體',
    backgroundColor:UGCblue,
    color:'white',
    paddingTop:'0',
    paddingBottom:'0',
    whiteSpace:'nowrap',
    marginLeft:'3px'
};

export const entLittleRoundButtonStyleDisabled = {
    fontFamily:'微軟正黑體',
    backgroundColor:'#999999',
    color:'white',
    paddingTop:'0',
    paddingBottom:'0',
    whiteSpace:'nowrap',
    marginLeft:'3px'
};

export const UGCbtnStyle=disabled=>disabled?{ fontFamily:'微軟正黑體',
                                            backgroundColor:'#999999',
                                            color:'white',
                                            paddingTop:'0',
                                            paddingBottom:'0',
                                            whiteSpace:'nowrap',
                                            marginLeft:'3px'
                                        }:
                                        { fontFamily:'微軟正黑體',
                                            backgroundColor:UGCblue,
                                            color:'white',
                                            paddingTop:'0',
                                            paddingBottom:'0',
                                            whiteSpace:'nowrap',
                                            marginLeft:'3px'
                                        };

export const headCellStyle={ padding:'2px 10px 2px 10px',
                                    color:'black',
                                    whiteSpace:'nowrap',
                                    fontWeight:'700',
                                    fontFamily:'微軟正黑體',
                                    fontSize:'14px',
                                };

export const bodyCellStyle={ padding:'2px 10px 2px 10px',
                                    whiteSpace: 'nowrap',
                                    fontFamily:'微軟正黑體',
                                    marginBottom:'0',
                                };


export const entbuttonStyleBlue = { fontFamily:'微軟正黑體',
                                    backgroundColor:UGCblue,
                                    color:'white',
                                    borderRadius:'30px',
                                    fontSize:'16px',
                                    width:'140px',
                                    padding:'2px',
                                    borderColor:UGCblue,
                                };
export const entbuttonStyleBlueDisabled = { fontFamily:'微軟正黑體',
                                            backgroundColor:'#999999',
                                            color:'white',
                                            borderRadius:'30px',
                                            fontSize:'16px',
                                            width:'140px',
                                            padding:'2px',
                                            borderColor:'#D4D4D4',
                                        };

export const adminInputStyle = { whiteSpace:'nowrap',
                                fontSize:'14px',
                                fontFamily:'微軟正黑體',
                            };

export const adminScenarioInputStyle = { whiteSpace:'nowrap',
                            fontSize:'16px',
                            fontFamily:'微軟正黑體',
                        };
export const justFont = {fontFamily:'微軟正黑體'};


export const appbarBtnStyle = (btn_name,func_now)=>btn_name===func_now?
    {
        fontFamily:'微軟正黑體',
        fontWeight:'700',padding:'5px 10px 5px 10px',color:UGCblue,whiteSpace:'nowrap'}
    :
    {
        fontFamily:'微軟正黑體',
        fontWeight:'400',padding:'5px 10px 5px 10px',whiteSpace:'nowrap',color:'#AAAAAA'};

export const ent_appbarBtnStyle = (btn_name,func_now)=>btn_name===func_now?
    {fontSize:'17px',fontFamily:'微軟正黑體',fontWeight:'600',padding:'5px 10px 5px 10px',color:UGCblue,whiteSpace:'nowrap',margin:'3px'}
    :
    {fontSize:'17px',fontFamily:'微軟正黑體',fontWeight:'500',padding:'5px 10px 5px 10px',whiteSpace:'nowrap',color:'#AAAAAA',margin:'3px'};