import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NativeSelect from '@material-ui/core/NativeSelect';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {api_path} from '../../../../environment';
import * as CSS from '../../../../css.js';
import {SearchBtn,ClearSearchBtn, MsgBtn} from '../../buttons';

const monthCheckHeadRows = ["經銷商名稱","企業名稱","統編","受測完成日","訂單編號","測評名稱","測評單價","姓名","職稱"];

class B2BMonthCheck extends React.Component {
	constructor(props) {
		super(props);
		this.state = { searchB2bMonthCheckData:[{}],
						search_tax_id: "",
						search_corp_name: "",
						search_qname: "",
						search_start: "",
						search_end: "",
                        textfieldValue:'',
						check_end: '',
						check_start: '',
                        checkedValues: [],
						anchorEl: null,
						show_start: "",
						show_end: "",
					};
	}

	componentDidMount() {
		this.fetchB2bMonthCheck();
	}

	fetchB2bMonthCheck = () => {
		let send_body = JSON.stringify({func: "b2b_month_check_list",
										login_uid: this.props.uid,
										start: this.state.search_start,
										end: this.state.search_end,
										corp_name: this.state.search_corp_name,
										tax_id: this.state.search_tax_id,
										qname: this.state.search_qname,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2bMonthCheckData:response.result,
								check_start: response.start,
								check_end: response.end,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleResetSearch = () => {
		this.setState({ search_tax_id: "",
						search_corp_name: "",
						search_qname: "",
						search_start: "",
						search_end: "",
						select_type: "",
						textfieldValue: "",
					});
		let send_body = JSON.stringify({func: "b2b_month_check_list",
										login_uid: this.props.uid,
										start: "",
										end: "",
										corp_name: "",
										tax_id: "",
										qname: "",
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2bMonthCheckData: response.result,
								check_start: response.start,
								check_end: response.end,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								checkedValues: [],
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleChangePage = page_id => {
		let send_body = JSON.stringify({func: "b2b_month_check_list",
										login_uid: this.props.uid,
										start: this.state.search_start,
										end: this.state.search_end,
										corp_name: this.state.search_corp_name,
										tax_id: this.state.search_tax_id,
										qname: this.state.search_qname,
										page_id: page_id,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
			method: 'POST',
			body: send_body,
			headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.setState({ searchB2bMonthCheckData:response.result,
								check_start: response.start,
								check_end: response.end,
								pre_page: response.pre_page,
								nxt_page: response.nxt_page,
								show_end: response.end,
								show_start: response.start,
								dtoken: response.dtoken,
							page_id: response.pre_page!==""?response.pre_page+1:response.nxt_page!==""?response.nxt_page-1:1,
							});
			}
		})
		.catch(error => console.error('Error:', error));
	}
	
	fetchB2bMonthCheckDownload=()=> {
		let send_body = JSON.stringify({func: "b2b_check_list_download",
										login_uid: this.props.uid,
										start: this.state.show_start,
										end: this.state.show_end,
										dtoken: this.state.dtoken,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				if(response.csv){
					var link = document.createElement("a");
					link.href = 'data:text/csv;base64,' + encodeURI(response.csv);
					link.download = response.csv_fname;
					link.click();
				}
			}
		})
		.catch(error => console.error('Error:', error));
	}

	fetchB2bMonthGenOrder=()=> {
		let send_body = JSON.stringify({func: "b2b_month_gen_order",
										login_uid: this.props.uid,
										qa_list: this.state.checkedValues,
										start: this.state.check_start,
										end: this.state.check_end,
									});
		fetch(`${api_path}/ugc/ugc_mgt.php`, {
				method: 'POST',
				body: send_body,
				headers: new Headers(),
		})
		.then(res => res.json())
		.then(response => {
			if(response.status==="SUCC"){
				this.fetchB2bMonthCheck();
				this.setState({checkedValues:[]})
			}
		})
		.catch(error => console.error('Error:', error));
	}

	handleSelectSearchType = e => {
		if(this.state.select_type === "tax_id"){
			this.setState({ search_tax_id: e.target.value,
							search_corp_name: '',
							textfieldValue: e.target.value,
						});
		}
		else if(this.state.select_type === "corp_name"){
			this.setState({ search_tax_id: '',
							search_corp_name: e.target.value,
							textfieldValue: e.target.value,
						});
		}
	}
    
    handleCheck(e,x) {
        this.setState(state => ({
        checkedValues: state.checkedValues.includes(x)
            ? state.checkedValues.filter(c => c !== x)
            : [...state.checkedValues, x]
		}));
	}

  	render() {
   	return (
	<div style ={{display:'flex',flexDirection:'column'}}>
		<div style={{display:'flex',textAlign:'right',justifyContent:'flex-start',margin:'5px',alignItems:'flex-end'}}>
			<div style={{display:'flex', flexDirection:'column'}}>
				<div style={{display:'flex', alignItems:'baseline'}}>
					<Typography style={CSS.adminInputStyle}>欄位查詢：</Typography>
					<TextField
						id="select_type"
						select
						SelectProps={{native: true}}
						value={this.state.select_type} 
						onChange={e=>{this.setState({select_type:e.target.value,textfieldValue:''})}}
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{style:CSS.adminInputStyle}}
						style={{margin:'5px'}}
					>
						<option value=""></option>
						<option value="corp_name">企業名稱</option>
						<option value="tax_id">統一編號</option>
					</TextField>
					<TextField
						id="standard-name"
						margin="dense"
						style={{margin:'5px'}}
						onChange={this.handleSelectSearchType}
						value={this.state.textfieldValue}
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{style:CSS.adminInputStyle}}
					/>
				</div>
				<div style={{display:'flex', alignItems:'baseline'}}>
					<Typography style={CSS.adminInputStyle}>測評名稱查詢：</Typography>
					<TextField
						id="standard-name"
						margin="dense"
						style={{margin:'5px'}}
						onChange={e=>this.setState({search_qname:e.target.value})}
						value={this.state.search_qname}
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{style:CSS.adminInputStyle}}
					/>
				</div>
				<div style={{display:'flex', alignItems:'baseline'}}>
					<Typography style={CSS.adminInputStyle}>訂購日區間查詢：</Typography>
					<TextField 
						id="date"
						type="date"
						margin='dense'
						value={this.state.search_start}	
						style={{margin:'5px'}}
						onChange={e=>{this.setState({search_start:e.target.value})}}
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
					/>
					<Typography style={CSS.adminInputStyle}>～</Typography>
					<TextField 
						id="date"
						type="date"
						value={this.state.search_end}
						style={{margin:'5px'}}
						onChange={e=>{this.setState({search_end:e.target.value})}}
						InputProps={{style:CSS.adminInputStyle}}
						InputLabelProps={{shrink: true,style:CSS.adminInputStyle}}
					/>
				</div>
			</div>
			<div style={{display:'flex',alignItems:'center'}}>
			<SearchBtn onClick={this.fetchB2bMonthCheck}/>
			<ClearSearchBtn onClick={this.handleResetSearch}/>
			</div>
		</div>
		<Paper style={{width:'100%',overflowX:'auto',minWidth: "100%"}}>
			<Table>
				<TableHead><TableRow style={CSS.headRowStyle}>
					<TableCell style={CSS.headCellStyle}>
						<IconButton size="small" onClick={e=>this.setState({anchorEl:e.currentTarget})}><ArrowDropDown/></IconButton>
						<Menu
							id="simple-menu"
							anchorEl={this.state.anchorEl}
							keepMounted
							open={Boolean(this.state.anchorEl)}
							onClose={()=>this.setState({anchorEl:null})}
						>
							<MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: this.state.searchB2bMonthCheckData.map(mCheck => mCheck.qa_seq)})} style={CSS.justFont}>全選</MenuItem>
							<MenuItem onClick={()=>this.setState({anchorEl:null, checkedValues: []})} style={CSS.justFont}>全不選</MenuItem>
						</Menu>
					</TableCell>
					{monthCheckHeadRows.map(e=><TableCell style={CSS.headCellStyle}>{e}</TableCell>)}
				</TableRow></TableHead>
				<TableBody>
					{this.state.searchB2bMonthCheckData.map((mCheck,index) => (
						<TableRow key={mCheck.id} selected={this.state.checkedValues.includes(mCheck.qa_seq)} style={CSS.tablebodyStyle(index)}>
							<TableCell style={CSS.bodyCellStyle}>
								<Checkbox label={mCheck.qa_seq} key={mCheck.qa_seq} checked={this.state.checkedValues.includes(mCheck.qa_seq)} onChange={e => this.handleCheck(e,mCheck.qa_seq)}/>
							</TableCell>
							<TableCell style={CSS.bodyCellStyle}>{mCheck.dealer_name}</TableCell>
							<TableCell style={CSS.bodyCellStyle}>{mCheck.corp_name}</TableCell>
							<TableCell style={CSS.bodyCellStyle}>{mCheck.tax_id}</TableCell>
							<TableCell style={CSS.bodyCellStyle}>{mCheck.qf_date}</TableCell>
							<TableCell style={CSS.bodyCellStyle}>{mCheck.qa_seq}</TableCell>
							<TableCell style={CSS.bodyCellStyle}>{mCheck.qname}</TableCell>
							<TableCell style={CSS.bodyCellStyle}>{mCheck.qprice}</TableCell>
							<TableCell style={CSS.bodyCellStyle}>{mCheck.name}</TableCell>
							<TableCell style={CSS.bodyCellStyle}>{mCheck.title_name}</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell colSpan={9} align="left" style={CSS.adminInputStyle}>{"區間： "+this.state.show_start.replace("-","/").replace("-","/")+" ～ "+this.state.show_end.replace("-","/").replace("-","/")}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Paper>
		<div style={{display:'flex',justifyContent:'space-between'}}>
			<div style={{display:'flex',alignItems:'baseline',margin:'20px'}}>
				<Typography style={{...{marginRight:'10px'},...CSS.adminInputStyle}}>已選：{this.state.checkedValues.length} 項</Typography>
				<MsgBtn 
					style={{...{margin:'5px'},...CSS.UGCbtnStyle(false)}}
					send_body={JSON.stringify({ func: "b2b_check_list_download",
												login_uid: this.props.uid,
												start: this.state.show_start,
												end: this.state.show_end,
												dtoken: this.state.dtoken,
										})
								}
					btn_name="下載對帳單"
				/>
				<MsgBtn 
					style={{...{margin:'5px'},...CSS.UGCbtnStyle(this.state.checkedValues.length===0||this.props.auth!=="write")}}
					disabled={this.state.checkedValues.length===0||this.props.auth!=="write"}
					succProcess={()=>{
						this.fetchB2bMonthCheck();
						this.setState({checkedValues:[]});
					}}
					send_body={JSON.stringify({func: "b2b_month_gen_order",
											login_uid: this.props.uid,
											qa_list: this.state.checkedValues,
											start: this.state.check_start,
											end: this.state.check_end,
										})
								}
					btn_name="對帳完成"
				/>

			</div>
			<div style={{display:'flex',margin:'20px'}}>
				<Button size="large" disabled={this.state.pre_page===""} onClick={()=>this.handleChangePage(this.state.pre_page)} style={CSS.justFont}>上一頁</Button>
				<Button size="large" disabled={this.state.nxt_page===""} onClick={()=>this.handleChangePage(this.state.nxt_page)} style={CSS.justFont}>下一頁</Button>
			</div>
		</div>
	</div>
	);
	}
}

export default B2BMonthCheck;