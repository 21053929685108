import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {api_path,UGCblue} from '../../../environment';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import * as CSS from '../../../assessCss.js'


class PrivacyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false,};
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal});
    }

    render() {
        return (
        <div>
            <Dialog
				fullWidth={true}
				maxWidth="sm"
				open={this.props.privacymodal}
				onClose={this.props.toggle}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" style={{fontFamily:'微軟正黑體',}}><Typography style={{fontFamily:'微軟正黑體'}}>UGCareer測評系統網站個資蒐集前告知</Typography></DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    <Typography style={{fontFamily:'微軟正黑體',whiteSpace:'pre-line',color:'black'}}>
						{
`
UGCareer測評系統網站為優駿企管顧問有限公司經營管理，為了確保消費者之個人資料、隱私及消費者權益之保護，於交易過程中將使用消費者之個人資料，謹依個人資料保護法第8條規定告知以下事項：

(一) 蒐集目的及方式
本公司之蒐集目的在於進行其他諮詢與顧問服務、人事管理、客戶管理與服務、會員管理及行銷與統計調查與分析(法定特定目的項目編號為182, 002, 090, 052, 040, 157)。蒐集方式將透過企業徵才、加入會員或訂購單填寫方式進行個人資料之蒐集。

(二) 蒐集之個人資料類別
本公司於網站內蒐集的個人資料包括
1. C001辨識個人者： 如姓名、電子郵件、電話/行動電話、網路平臺申請之帳號等資訊。
2. C002辨識財務者： 如信用卡或轉帳帳戶資訊。
3. C011個人描述、C014個性：如年齡、性別、國籍、個性等評述意見等。
4. C038職業、C061現行之受僱情形、C071工作之評估細節： 職業、工作描述、職級、工作表現等。
5. C051學校記錄、C052資格或技術： 學校、學歷等。

三、利用期間、地區、對象及方式
(一) 期間：本公司營運期間。
(二) 地區：蒐集之個人資料將用於營運地區。
(三) 利用對象及方式：個人資料蒐集除用於本公司之其他諮詢與顧問服務、人事管理、會員管理、客戶管理之檢索查詢等功能外，將有以下利用：
1. 金融交易及授權：消費者所提供之財務相關資訊，將於金融交易過程(如信用卡授權、轉帳)時提交給金融機構以完成金融交易。
2. 行銷：本公司將利用個人資料之郵件、電話號碼進行本公司或合作廠商商品之宣傳行銷。

四、個人資料之權利
交付本公司之個人資料者，依個資法得行使以下權利：
(一) 查詢或請求閱覽。
(二) 請求製給複製本。
(三) 請求補充或更正。
(四) 請求停止蒐集、處理或利用。
(五) 請求刪除。

可來信洽詢本公司客服進行申請，客服信箱: service@ugc.com.tw。`
}
</Typography>
				</DialogContentText>
				</DialogContent>
                <DialogActions>
				<Button size="large" onClick={this.props.toggle} style={{fontFamily:'微軟正黑體',color:UGCblue}}>
				關閉
				</Button>
				</DialogActions>
			</Dialog>
        </div>
        );
    }
}

class PageFive extends Component {
    constructor(props) {
        super(props);
        this.state = {  age_list:[],
                        edu_list:[],
                        seniority_list: [],
                        corp_type_list: [],
                        dept_list: [],
                        title_list: [],
                        edu_field_list:[],
                        edu_status_list:[],
                        edu_level_list:[],
                        edu_year_list:[],
                        fail_field: [],
                        buyer_type: "2",
                        name: "",
                        age: "",
                        gender: "",
                        email: "",
                        is_stu: false,
                        tel: "",
                        education: "",
                        seniority: "",
                        corp_type: "",
                        corp_type_other: "",
                        corp_name: "",
                        dept_name: "",
                        dept_other: "",
                        title_name: "",
                        title_other: "",
                        year_other: "",
                        fail_msg: "",
						needEmail: false,
                        privacyCheck: false,
                        privacymodal: false,
                    };
    }

    componentDidMount(){
        this.setState({ is_stu: this.props.p5_resp.is_stu,
                        buyer_type: this.props.p5_resp.buyer_type,
                        name: this.props.p5_resp.name,
                        email: this.props.p5_resp.email,
                        age: this.props.p5_resp.age,
                        tel: this.props.p5_resp.tel,
                        gender: this.props.p5_resp.gender,
                        education: this.props.p5_resp.education,
                        seniority: this.props.p5_resp.seniority,
                        corp_type: this.props.p5_resp.corp_type,
                        corp_type_other: this.props.p5_resp.corp_type_other,
                        corp_name: this.props.p5_resp.corp_name,
                        dept_name: this.props.p5_resp.dept_name,
                        dept_other: this.props.p5_resp.dept_other,
                        title_name: this.props.p5_resp.title_name,
                        title_other: this.props.p5_resp.title_other,
                        edu_level: this.props.p5_resp.edu_level,
                        edu_status: this.props.p5_resp.edu_status,
                        edu_field: this.props.p5_resp.edu_field,
                        field_other: this.props.p5_resp.field_other,
                        school_dept: this.props.p5_resp.school_dept,
                        school: this.props.p5_resp.school,
                        school_year: this.props.p5_resp.school_year,
                        year_other: this.props.p5_resp.year_other,
                        needEmail: this.props.p5_resp.email==="",
                        // fail_field: this.props.fail_field,
                        // fail_msg: this.props.fail_msg,
                    });
        this.handleUpdateInfoList();
    }

    componentDidUpdate(prevProps) {
		// if(this.props.page_id===5){
			if(this.props.p5_resp!==prevProps.p5_resp){
				this.setState({ is_stu: this.props.p5_resp.is_stu,
								buyer_type: this.props.p5_resp.buyer_type,
								name: this.props.p5_resp.name,
								email: this.props.p5_resp.email,
								age: this.props.p5_resp.age,
								tel: this.props.p5_resp.tel,
								gender: this.props.p5_resp.gender,
								education: this.props.p5_resp.education,
								seniority: this.props.p5_resp.seniority,
								corp_type: this.props.p5_resp.corp_type,
								corp_type_other: this.props.p5_resp.corp_type_other,
								corp_name: this.props.p5_resp.corp_name,
								dept_name: this.props.p5_resp.dept_name,
								dept_other: this.props.p5_resp.dept_other,
								title_name: this.props.p5_resp.title_name,
								title_other: this.props.p5_resp.title_other,
								edu_level: this.props.p5_resp.edu_level,
								edu_status: this.props.p5_resp.edu_status,
								edu_field: this.props.p5_resp.edu_field,
								field_other: this.props.p5_resp.field_other,
								school_dept: this.props.p5_resp.school_dept,
								school: this.props.p5_resp.school,
								school_year: this.props.p5_resp.school_year,
								year_other: this.props.p5_resp.year_other,
								needEmail: this.props.p5_resp.email==="",
                                // fail_field: this.props.fail_field,
                                // fail_msg: this.props.fail_msg,
							});
				if(this.props.p5_resp.email===""){
					this.setState({needEmail: true});
				}
			}
		
    }

	fetchAgeList = () => {
		let send_body = JSON.stringify({ func:'age_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ age_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchEduList = () => {
		let send_body = JSON.stringify({ func:'edu_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ edu_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchSeniorityList = () => {
		let send_body = JSON.stringify({ func:'seniority_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ seniority_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchCorpTypeList = () => {
		let send_body = JSON.stringify({ func:'corp_type_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ corp_type_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchDeptList = () => {
		let send_body = JSON.stringify({ func:'dept_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ dept_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchTitleList = () => {
		let send_body = JSON.stringify({ func:'title_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ title_list: response.result,});
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchEduLevelList = () => {
		let send_body = JSON.stringify({ func:'edu_level_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				console.log(response.result);
                this.setState({ edu_level_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
	}

	fetchEduYearList = () => {
		let send_body = JSON.stringify({ func:'edu_year_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
				console.log(response.result);
                this.setState({ edu_year_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
	}

    fetchEduStatusList = () => {
		let send_body = JSON.stringify({ func:'edu_status_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ edu_status_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
    }

    fetchEduFieldList = () => {
		let send_body = JSON.stringify({ func:'edu_field_list',});
        fetch(`${api_path}/ugc/misc_mgt.php`, {
            method: 'POST',
            body: send_body,
            headers: new Headers()
        })
        .then(res => res.json())
        .then(response => {
            if(response.status==="SUCC"){
                this.setState({ edu_field_list: response.result,
                            });
            };
        })
        .catch(error => console.error('Error:', error));
	}
	
	handleUpdateInfoList = ()=>{
		this.fetchEduYearList();
		this.fetchAgeList();
		this.fetchEduList();
		this.fetchSeniorityList();
		this.fetchCorpTypeList();
		this.fetchDeptList();
		this.fetchTitleList();
        this.fetchEduLevelList();
        this.fetchEduStatusList();
        this.fetchEduFieldList();
    }
    
	privacymodaltoggle=()=> { this.setState({ privacymodal: !this.state.privacymodal}); }
	
    render(){
        let send_body;
        if(!this.state.is_stu){
            send_body = JSON.stringify({ func:'update_info',
                                            token:this.state.nxt_token?this.state.nxt_token:this.props.nxt_token,
                                            name: this.state.name,
                                            is_stu: this.state.is_stu,
                                            gender: this.state.gender,
                                            age: this.state.age,
                                            email: this.state.email,
                                            tel: this.state.tel,
                                            education: this.state.education,
                                            seniority: this.state.seniority,
                                            corp_type: this.state.corp_type,
                                            corp_type_other: this.state.corp_type_other,
                                            corp_name: this.state.corp_name,
                                            dept_name: this.state.dept_name,
                                            dept_other: this.state.dept_other,
                                            title_name:  this.state.title_name,
                                            title_other: this.state.title_other,
                                        });
        }
        else{
            send_body = JSON.stringify({ func:'update_info',
                                            token:this.state.nxt_token?this.state.nxt_token:this.props.nxt_token,
                                            name: this.state.name,
                                            is_stu: this.state.is_stu,
                                            gender: this.state.gender,
                                            email: this.state.email,
                                            age: this.state.age,
                                            tel: this.state.tel,
                                            edu_level: this.state.edu_level,
                                            edu_status: this.state.edu_status,
                                            edu_field: this.state.edu_field,
                                            field_other: this.state.field_other,
                                            school_dept: this.state.school_dept,
                                            school: this.state.school,
                                            school_year: this.state.school_year,
                                            year_other:this.state.year_other,
                                        });
        }

        if(!this.props.isMobile){
            return (
                <Paper elevation={0} style={{width:'50vw', minWidth:'700px', marginTop:'3vw'}}>
                    <Grid container spacing={0}>
                        {this.props.fail_msg!==""?
                        <Grid item xs={12} sm={12}>
                            <Typography align="center" style={{...{color:"red", margin:'1vw'}, ...CSS.desktopInfoFontSize}}>{this.props.fail_msg}</Typography>
                        </Grid>
                        :null}
                        <Grid item xs={7} sm={6} style={{marginLeft:'20px'}}>
                            <TextField 
                                disabled={this.state.buyer_type!=="1"}
                                id="name" 
                                value={this.state.name} 
                                onChange={e => this.setState({name : e.target.value})}
                                margin="normal"
                                error={this.props.fail_field.includes("name")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您的姓名 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={7} sm={6} style={{marginLeft:'20px'}}>
                            <TextField 
                                disabled 
                                id="is_stu" 
                                value={this.state.is_stu?"學生":"社會人士"} 
                                margin="normal"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您的身份 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={7} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="gender"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.gender}
                                onChange={e => this.setState({gender : e.target.value})}
                                error={this.props.fail_field.includes("gender")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您的性別 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize 
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                <option value="1">男</option>
                                <option value="0">女</option>
								<option value="-1">其他</option>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="age"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.age}
                                onChange={e => this.setState({age : e.target.value})}
                                error={this.props.fail_field.includes("age")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您的年齡 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize 
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.age_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        
                    </Grid>
                    
                    {/* 社會人的問題 */}

                    {!this.state.is_stu?
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="education"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.education}
                                onChange={e => this.setState({education : e.target.value})}
                                error={this.props.fail_field.includes("education")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您的學歷 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.edu_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="seniority"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.seniority}
                                onChange={e => this.setState({seniority : e.target.value})}
                                error={this.props.fail_field.includes("seniority")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>工作年資 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.seniority_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="corp_type"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.corp_type}
                                onChange={e => this.setState({corp_type : e.target.value})}
                                error={this.props.fail_field.includes("corp_type")}
                                style={{marginRight:'5px'}}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您服務公司的產業類別 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.corp_type_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        {this.state.corp_type==="-1"?
                        <Grid item xs={12} sm={5} style={{marginLeft:'20px'}}>
                            <TextField
                                placeholder="其他"
                                id="corp_type_other" 
                                value={this.state.corp_type_other}
                                onChange={e => this.setState({corp_type_other : e.target.value})}
                                error={this.props.fail_field.includes("corp_type_other")}
                                margin="normal"
                                InputProps={{style: CSS.desktopInfoFontSize }}
                                fullWidth
                            />
                        </Grid>
                        :null}
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField 
                                id="corp_name" 
                                value={this.state.corp_name}
                                onChange={e => this.setState({corp_name : e.target.value})}
                                error={this.props.fail_field.includes("corp_name")}
                                margin="normal" 
                                required
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您服務的公司/機構 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="dept_name"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.dept_name}
                                onChange={e => this.setState({dept_name : e.target.value})}
                                error={this.props.fail_field.includes("dept_name")}
                                style={{marginRight:'5px'}}
                                required
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您在貴公司擔任的職務類別 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.dept_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        {this.state.dept_name==="-1"?
                        <Grid item xs={12} sm={5} style={{marginLeft:'20px'}}>
                            <TextField 
                                placeholder="其他"
                                id="dept_other" 
                                value={this.state.dept_other}
                                onChange={e => this.setState({dept_other : e.target.value})}
                                margin="normal"
                                InputProps={{style: CSS.desktopInfoFontSize }}
                                error={this.props.fail_field.includes("dept_other")}
                            />
                        </Grid>
                        :null}
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="title_name"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.title_name}
                                onChange={e => this.setState({title_name : e.target.value})}
                                error={this.props.fail_field.includes("title_name")}
                                style={{marginRight:'5px'}}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您在貴公司的職級為 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.title_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        {this.state.title_name==="-1"?
                        <Grid item xs={12} sm={5} style={{marginLeft:'20px'}}>
                            <TextField 
                                placeholder="其他"
                                id="title_other"
                                value={this.state.title_other}
                                onChange={e => this.setState({title_other : e.target.value})}
                                margin="normal"
                                InputProps={{style: CSS.desktopInfoFontSize }}
                                error={this.props.fail_field.includes("title_other")}
                            />
                        </Grid>
                        :null}
                    </Grid>
                    :null}

                    {/* 學生的問題 */}

                    {this.state.is_stu?
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="edu_level"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.edu_level}
                                onChange={e => this.setState({edu_level : e.target.value})}
                                error={this.props.fail_field.includes("edu_level")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>就學階段 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.edu_level_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="edu_status"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.edu_status}
                                onChange={e => this.setState({edu_status : e.target.value})}
                                error={this.props.fail_field.includes("edu_status")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>就學狀態 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.edu_status_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField
                                id="edu_field"
                                select
                                SelectProps={{native: true}}
                                margin="normal"
                                value={this.state.edu_field}
                                onChange={e => this.setState({edu_field : e.target.value})}
                                error={this.props.fail_field.includes("edu_field")}
                                style={{marginRight:'5px'}}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您就讀的領域 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            >
                                <option value=""></option>
                                {this.state.edu_field_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        {this.state.edu_field==="-1"?
                        <Grid item xs={12} sm={5} style={{marginLeft:'20px'}}>
                            <TextField 
                                placeholder="其他"
                                id="field_other" 
                                value={this.state.field_other}
                                onChange={e => this.setState({field_other : e.target.value})}
                                margin="normal"
                                InputProps={{style: CSS.desktopInfoFontSize }}
                                error={this.props.fail_field.includes("field_other")}
                            />
                        </Grid>
                        :null}
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField 
                                id="school" 
                                value={this.state.school}
                                onChange={e => this.setState({school : e.target.value})}
                                margin="normal"
                                error={this.props.fail_field.includes("school")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>就讀學校 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField 
                                id="school_dept" 
                                value={this.state.school_dept}
                                onChange={e => this.setState({school_dept : e.target.value})}
                                margin="normal"
                                error={this.props.fail_field.includes("school_dept")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>就讀科系 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField 
                                id="school_year" 
                                value={this.state.school_year}
                                select SelectProps={{native: true}}
                                onChange={e => this.setState({school_year : e.target.value})}
                                margin="normal"
                                error={this.props.fail_field.includes("school_year")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>就讀年級 * ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize
                                }}
                                fullWidth
                            
                            >
                            <option value=""></option>
                            {this.state.edu_year_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
                            </TextField>
                        </Grid>
                        {this.state.school_year==="-1"?
                        <Grid item xs={12} sm={5} style={{marginLeft:'20px'}}>
                            <TextField 
                                placeholder="其他"
                                id="year_other" 
                                value={this.state.year_other}
                                onChange={e => this.setState({year_other : e.target.value})}
                                InputProps={{style: CSS.desktopInfoFontSize }}
                                error={this.props.fail_field.includes("year_other")}
                                margin="normal"
                            />
                        </Grid>
                        :null}
                    </Grid>
                    :null}
                    <Grid container spacing={0}>
                        <Grid item xs={7} sm={6} style={{marginLeft:'20px'}}>
                            <TextField 
                                // disabled={!this.state.needEmail}
                                id="email" 
                                value={this.state.email} 
                                onChange={e => this.setState({email : e.target.value})}
                                margin="normal"
                                error={this.props.fail_field.includes("email")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您的信箱E-mail  ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{marginLeft:'20px'}}>
                            <TextField 
                                id="tel" 
                                placeholder="02-12345678/0912-345678"		value={this.state.tel}
                                onChange={e => this.setState({tel : e.target.value})}
                                margin="normal"
                                error={this.props.fail_field.includes("tel")}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.desktopInfoFontSize}>您的電話  ：</Typography></InputAdornment>,
                                    style: CSS.desktopInfoFontSize 
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {this.props.fail_msg!==""?
                        <Grid item xs={12} sm={12}>
                            <Typography align="center" style={{...{color:"red", margin:'1vw'}, ...CSS.desktopInfoFontSize}}>{this.props.fail_msg}</Typography>
                        </Grid>
                        :null}
                        <Grid item xs={12} sm={12} style={{marginLeft:'10px',marginTop:'20px'}}>
                            <div style={{display:'flex',alignItems:'center',width:'100%'}}>
                                {/* <div style={{...{marginRight:'2px',marginLeft:'10px'}, ...CSS.desktopInfoFontSize}}> */}
                                <Checkbox
                                    checked={this.state.privacyCheck}
                                    onChange={()=>{this.setState({privacyCheck: !this.state.privacyCheck})}}
                                    value="privacyCheck"
                                    color={UGCblue}
                                    inputProps={{
                                        style:{...{marginRight:'2px',marginLeft:'10px'}, ...CSS.desktopInfoFontSize}
                                    }}
                                />
                                {/* </div> */}
                                <p style={{...{fontSize:'14px',fontFamily:'微軟正黑體',margin:'5px',whiteSpace:'pre-wrap'}, ...{fontFamily:'微軟正黑體',fontSize:'1vw'}}}>我同意接受
                                    <a href="javascript:void(0)" style={{...{fontFamily:'微軟正黑體',color:'black',fontSize:!this.props.isMobile?'14px':'14px',textDecoration:'underline'}, ...{fontFamily:'微軟正黑體',fontSize:'1vw'}}} onClick={this.privacymodaltoggle} >UGCareer測評系統隱私權政策</a>
                                </p>
                                <PrivacyModal privacymodal={this.state.privacymodal} toggle={this.privacymodaltoggle}/>
                            </div>
                            <div style={{display:'flex',alignItems:'center',width:'100%',marginTop:'8px'}}>
                                <Typography style={{...{height:'42px',width:'31px',alignItems:'center',justifyContent: "center", marginRight:'2px',marginLeft:'10px'}, ...{fontFamily:'微軟正黑體',fontSize:'1vw'}}}>註：</Typography>
                                <Typography style={{...{height:'42px',margin:'5px',whiteSpace:'pre-wrap'}, ...{fontFamily:'微軟正黑體',fontSize:'1.2vw'}, ...{fontFamily:'微軟正黑體',fontSize:'1vw'}}}>其中標註 * 者為必填項目</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <Button disabled={!this.state.privacyCheck} 
                                    onClick={send_body=>this.props.fetchUpdateInfo(
                                        !this.state.is_stu?
                                                        {name: this.state.name,
                                                        is_stu: this.state.is_stu,
                                                        gender: this.state.gender,
                                                        age: this.state.age,
                                                        email: this.state.email,
                                                        tel: this.state.tel,
                                                        education: this.state.education,
                                                        seniority: this.state.seniority,
                                                        corp_type: this.state.corp_type,
                                                        corp_type_other: this.state.corp_type_other,
                                                        corp_name: this.state.corp_name,
                                                        dept_name: this.state.dept_name,
                                                        dept_other: this.state.dept_other,
                                                        title_name:  this.state.title_name,
                                                        title_other: this.state.title_other,
                                                    }
                                        :
                                                        {name: this.state.name,
                                                        is_stu: this.state.is_stu,
                                                        gender: this.state.gender,
                                                        email: this.state.email,
                                                        age: this.state.age,
                                                        tel: this.state.tel,
                                                        edu_level: this.state.edu_level,
                                                        edu_status: this.state.edu_status,
                                                        edu_field: this.state.edu_field,
                                                        field_other: this.state.field_other,
                                                        school_dept: this.state.school_dept,
                                                        school: this.state.school,
                                                        school_year: this.state.school_year,
                                                        year_other:this.state.year_other,
                                    }
                                    )} 
                                    style={!this.state.privacyCheck?CSS.desktopDisabledButtonStyle:CSS.desktopButtonStyle}
                            >下一步</Button>
                        </div>
                        </Grid>
                    </Grid>
				</Paper>
            );
        }
        else{
            return(
                <Grid item xs={12} sm={12} style={{display:'flex',justifyContent:'center'}}>
					<Paper elevation={0} style={{margin:'7vw'}}>
					<Grid container spacing={0}>
						{this.props.fail_msg!==""?
						<Grid item xs={12} sm={12}>
							<Typography align="center" style={{fontFamily:'微軟正黑體',color:"red", margin:'1vw', fontSize:'4vw'}}>{this.props.fail_msg}</Typography>
						</Grid>
						:null}
						<Grid item xs={12} sm={12}>
							<TextField 
								disabled={this.state.buyer_type!=="1"}
								id="name" 
								value={this.state.name} 
								onChange={e => this.setState({name : e.target.value})}
								margin="normal"
								error={this.props.fail_field.includes("name")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您的姓名 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField 
								disabled 
								id="is_stu" 
								value={this.state.is_stu?"學生":"社會人士"} 
								margin="normal"
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您的身份 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize 
								}}
								fullWidth
							/>
						</Grid>
						
						<Grid item xs={12} sm={12}>
							<TextField
								id="gender"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.gender}
								onChange={e => this.setState({gender : e.target.value})}
								error={this.props.fail_field.includes("gender")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您的性別 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize 
								}}
								fullWidth
							>
								<option value=""></option>
								<option value="1">男</option>
								<option value="0">女</option>
								<option value="-1">其他</option>
							</TextField>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								id="age"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.age}
								onChange={e => this.setState({age : e.target.value})}
								error={this.props.fail_field.includes("age")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您的年齡 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize 
								}}
								fullWidth
							>
								<option value=""></option>
								{this.state.age_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
					</Grid>
					
					{!this.state.is_stu?
					<Grid container spacing={0}>
						<Grid item xs={12} sm={12}>
							<TextField
								id="education"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.education}
								onChange={e => this.setState({education : e.target.value})}
								error={this.props.fail_field.includes("education")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您的學歷 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							>
								<option value=""></option>
								{this.state.edu_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								id="seniority"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.seniority}
								onChange={e => this.setState({seniority : e.target.value})}
								error={this.props.fail_field.includes("seniority")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>工作年資 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							>
								<option value=""></option>
								{this.state.seniority_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								id="corp_type"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.corp_type}
								onChange={e => this.setState({corp_type : e.target.value})}
								error={this.props.fail_field.includes("corp_type")}
								style={{marginRight:'5px'}}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您服務公司的產業類別 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							>
								<option value=""></option>
								{this.state.corp_type_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
						{this.state.corp_type==="-1"?
						<Grid item xs={12} sm={6}/>
						:null}
						{this.state.corp_type==="-1"?
						<Grid item xs={12} sm={6}>
							<TextField
								placeholder="其他"
								id="corp_type_other" 
								value={this.state.corp_type_other}
								onChange={e => this.setState({corp_type_other : e.target.value})}
								error={this.props.fail_field.includes("corp_type_other")}
								margin="normal"
								InputProps={{style: CSS.mobileInfoFontSize}}
								fullWidth
							/>
						</Grid>
						:null}
						<Grid item xs={12} sm={12}>
							<TextField 
								id="corp_name" 
								value={this.state.corp_name}
								onChange={e => this.setState({corp_name : e.target.value})}
								error={this.props.fail_field.includes("corp_name")}
								margin="normal" 
								required
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您服務的公司/機構 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								id="dept_name"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.dept_name}
								onChange={e => this.setState({dept_name : e.target.value})}
								error={this.props.fail_field.includes("dept_name")}
								style={{marginRight:'5px'}}
								required
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您在貴公司擔任的職務類別 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							>
								<option value=""></option>
								{this.state.dept_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
						{this.state.dept_name==="-1"?
						<Grid item xs={12} sm={6}/>
						:null}
						{this.state.dept_name==="-1"?
						<Grid item xs={12} sm={6}>
							<TextField 
								placeholder="其他"
								id="dept_other" 
								value={this.state.dept_other}
								onChange={e => this.setState({dept_other : e.target.value})}
								margin="normal"
								InputProps={{style: CSS.mobileInfoFontSize}}
								error={this.props.fail_field.includes("dept_other")}
							/>
						</Grid>
						:null}
						<Grid item xs={12} sm={12}>
							<TextField
								id="title_name"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.title_name}
								onChange={e => this.setState({title_name : e.target.value})}
								error={this.props.fail_field.includes("title_name")}
								style={{marginRight:'5px'}}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您在貴公司的職級為 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							>
								<option value=""></option>
								{this.state.title_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
						{this.state.title_name==="-1"?
						<Grid item xs={12} sm={6}></Grid>
						:null}
						{this.state.title_name==="-1"?
						<Grid item xs={12} sm={6}>
							<TextField 
								placeholder="其他"
								id="title_other"
								value={this.state.title_other}
								onChange={e => this.setState({title_other : e.target.value})}
								margin="normal"
								InputProps={{style: CSS.mobileInfoFontSize}}
								error={this.props.fail_field.includes("title_other")}
							/>
						</Grid>
						:null}
					</Grid>
					:null}

					{/* 學生的問題 */}

					{this.state.is_stu?
					<Grid container spacing={0}>
						<Grid item xs={12} sm={12}>
							<TextField
								id="edu_level"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.edu_level}
								onChange={e => this.setState({edu_level : e.target.value})}
								error={this.props.fail_field.includes("edu_level")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>就學階段 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							>
								<option value=""></option>
								{this.state.edu_level_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								id="edu_status"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.edu_status}
								onChange={e => this.setState({edu_status : e.target.value})}
								error={this.props.fail_field.includes("edu_status")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>就學狀態 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							>
								<option value=""></option>
								{this.state.edu_status_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								id="edu_field"
								select
								SelectProps={{native: true}}
								margin="normal"
								value={this.state.edu_field}
								onChange={e => this.setState({edu_field : e.target.value})}
								error={this.props.fail_field.includes("edu_field")}
								style={{marginRight:'5px'}}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您就讀的領域 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							>
								<option value=""></option>
								{this.state.edu_field_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
						{this.state.edu_field==="-1"?
						<Grid item xs={12} sm={6}/>
						:null}
						{this.state.edu_field==="-1"?
						<Grid item xs={12} sm={6}>
							<TextField 
								placeholder="其他"
								id="field_other" 
								value={this.state.field_other}
								onChange={e => this.setState({field_other : e.target.value})}
								margin="normal" 
								InputProps={{style: CSS.mobileInfoFontSize}}
								error={this.props.fail_field.includes("field_other")}
							/>
						</Grid>
						:null}
						<Grid item xs={12} sm={12}>
							<TextField 
								id="school" 
								value={this.state.school}
								onChange={e => this.setState({school : e.target.value})}
								margin="normal"
								error={this.props.fail_field.includes("school")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>就讀學校 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField 
								id="school_dept" 
								value={this.state.school_dept}
								onChange={e => this.setState({school_dept : e.target.value})}
								margin="normal"
								error={this.props.fail_field.includes("school_dept")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>就讀科系 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField 
								id="school_year" 
								value={this.state.school_year}
								select SelectProps={{native: true}}
								onChange={e => this.setState({school_year : e.target.value})}
								margin="normal"
								error={this.props.fail_field.includes("school_year")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>就讀年級 * ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize
								}}
								fullWidth
							
							>
							<option value=""></option>
							{this.state.edu_year_list.map(opt=><option value={opt.id}>{opt.val}</option>)}
							</TextField>
						</Grid>
						{this.state.school_year==="-1"?
						<Grid item xs={12} sm={6}></Grid>
						:null}
						{this.state.school_year==="-1"?
						<Grid item xs={12} sm={6}>
							<TextField 
								placeholder="其他"
								id="year_other" 
								value={this.state.year_other}
								onChange={e => this.setState({year_other : e.target.value})}
								margin="normal" 
								InputProps={{style: CSS.mobileInfoFontSize}}
								error={this.props.fail_field.includes("field_other")}
							/>
						</Grid>
						:null}
					</Grid>
					:null}
					<Grid container spacing={0}>
						<Grid item xs={12} sm={12}>
							<TextField 
								// disabled={!this.state.needEmail}
								id="email" 
								value={this.state.email} 
								onChange={e => this.setState({email : e.target.value})}
								margin="normal"
								error={this.props.fail_field.includes("email")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您的信箱E-mail  ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize 
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField 
								id="tel" 
								placeholder="02-12345678/0912-345678"		value={this.state.tel}
								onChange={e => this.setState({tel : e.target.value})}
								margin="normal"
								error={this.props.fail_field.includes("tel")}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography noWrap style={CSS.mobileInfoFontSize}>您的電話  ：</Typography></InputAdornment>,
									style: CSS.mobileInfoFontSize 
								}}
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						{this.props.fail_msg!==""?
						<Grid item xs={12} sm={12}>
							<Typography align="center" style={{fontFamily:'微軟正黑體',color:"red", margin:'3.5vw', fontSize:'4vw',fontFamily:'微軟正黑體'}}>{this.props.fail_msg}</Typography>
						</Grid>
						:null}
						<Grid item xs={12} sm={12} style={{padding:'0px',marginLeft:'0px',marginTop:'20px'}}>
							<div style={{display:'flex',alignItems:'center',width:'100%'}}>
							<Checkbox
								checked={this.state.privacyCheck}
								onChange={()=>{this.setState({privacyCheck: !this.state.privacyCheck})}}
								value="privacyCheck"
								color={UGCblue}
                                style={{transform: "scale(0.8)",}}
							/>
							<p style={{fontSize:'3.5vw',fontFamily:'微軟正黑體',margin:'5px',whiteSpace:'pre-wrap'}}>我同意接受
								<a href="javascript:void(0)" style={{fontFamily:'微軟正黑體',color:'black',fontSize:!this.props.isMobile?'3.5vw':'3.5vw',textDecoration:'underline'}} onClick={this.privacymodaltoggle} >UGCareer測評系統隱私權政策</a>
							</p>
							<PrivacyModal privacymodal={this.state.privacymodal} toggle={this.privacymodaltoggle}/>
							</div>

							<div style={{display:'flex',alignItems:'center',width:'100%'}}>
                                <Typography style={{width:'32px',fontFamily:'微軟正黑體',marginLeft:'12px', fontSize:'3.5vw',fontFamily:'微軟正黑體'}}>註：</Typography>
                                <Typography style={{fontFamily:'微軟正黑體', fontSize:'3.5vw',margin:'3px',fontFamily:'微軟正黑體'}}>其中標註 * 者為必填項目</Typography>
                            </div>
                        </Grid>
						<Grid item xs={12} sm={12}>
						<div style={{display:'flex',justifyContent:'center',margin:'3.5vh'}}>
                            <Button disabled={!this.state.privacyCheck} 
                                    onClick={this.fetchUpdateInfo} 
                                    style={!this.state.privacyCheck?CSS.mobileDisabledButtonStyle:CSS.mobileButtonStyle}
                                    disabled={!this.state.privacyCheck} 
                                    onClick={()=>this.props.fetchUpdateInfo(
                                        !this.state.is_stu?{name: this.state.name,
                                                            is_stu: this.state.is_stu,
                                                            gender: this.state.gender,
                                                            age: this.state.age,
                                                            email: this.state.email,
                                                            tel: this.state.tel,
                                                            education: this.state.education,
                                                            seniority: this.state.seniority,
                                                            corp_type: this.state.corp_type,
                                                            corp_type_other: this.state.corp_type_other,
                                                            corp_name: this.state.corp_name,
                                                            dept_name: this.state.dept_name,
                                                            dept_other: this.state.dept_other,
                                                            title_name:  this.state.title_name,
                                                            title_other: this.state.title_other,
                                                            }
                                                            :
                                                            {name: this.state.name,
                                                            is_stu: this.state.is_stu,
                                                            gender: this.state.gender,
                                                            email: this.state.email,
                                                            age: this.state.age,
                                                            tel: this.state.tel,
                                                            edu_level: this.state.edu_level,
                                                            edu_status: this.state.edu_status,
                                                            edu_field: this.state.edu_field,
                                                            field_other: this.state.field_other,
                                                            school_dept: this.state.school_dept,
                                                            school: this.state.school,
                                                            school_year: this.state.school_year,
                                                            year_other:this.state.year_other,
                                                            }
                                    )}
                            >下一步</Button>
						</div>
						</Grid>
					</Grid>
					</Paper>
					</Grid>
            );
        }
        
    }
  }
  
  export default PageFive;